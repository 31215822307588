.isNFTAdress.columns-1,
.isNFTAdress.columns-2,
.isNFTAdress.columns-3,
.isNFTAdress.columns-4,
.isNFT.columns-1,
.isNFT.columns-2,
.isNFT.columns-3,
.isNFT.columns-4 {
    .content {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: space-around;
        align-items: center;

        .visual,
        .border-container {
            width: 85%;
            margin-bottom: 70px;
        }
    }
}

.double-border-container {
    max-width: 600px;
    height: 100%;
    margin: 0 20px 0px 0px;

    box-shadow:  var(--main-color) 0px 0px 0px 3px, rgb(255, 255, 255) 38px 43px 0px -8px, var(--main-color) 38px 43px 0px -5px, rgb(255, 255, 255) 20px -20px 0px -16px;

    .double-border-wrapper {
        height: 100%;
    }

    .content {
        padding: 20px;

        .visual {
        width: 50%;
        margin: 0 auto;
        }
    }

    .red {
        background:  var(--main-color);
    }
}
  
.two-row__top-region {
    display: flex;
    border-bottom: 2px solid  var(--main-color);
    max-height: 50px;

    .visual {
        width: fit-content !important;
    }

    .visual__image {
        max-height: 50px !important;
    }

    p {
        margin-left: 10px;
    }
}

.isNFTAdress {
    display: none;

    .visual {
        display: none;
    }

    .double-border-container {
        box-shadow:  none;
    }
}
  
.isNFTAdress-wrapper {
    align-items: center;

    .two-row__top-region {
        display: none;
    }

    .double-border-container {
        width: 100%;
    }
}