.d-md-none {
    display: none;
}
.d-md-inline {
    display: inline;
}
.d-md-inline-block {
    display: inline-block;
}
.d-md-block {
    display: block;
}
.d-md-table {
    display: table;
}
.d-md-table-row {
    display: table-row;
}
.d-md-table-cell {
    display: table-cell;
}
.d-md-flex {
    display: flex;
}
.d-md-inline-flex {
    display: inline-flex;
}