.justify-content-xl-start {
    justify-content: flex-start;
}
.justify-content-xl-end {
    justify-content: flex-end;
}
.justify-content-xl-center {
    justify-content: center;
}
.justify-content-xl-between {
    justify-content: space-between;
}
.justify-content-xl-around {
    justify-content: space-around;
}
.align-items-xl-start {
    align-items: flex-start;
}
.align-items-xl-end {
    align-items: flex-end;
}
.align-items-xl-center {
    align-items: center;
}
.align-items-xl-baseline {
    align-items: baseline;
}
.align-items-xl-stretch {
    align-items: stretch;
}
.align-content-xl-start {
    align-content: flex-start;
}
.align-content-xl-end {
    align-content: flex-end;
}
.align-content-xl-center {
    align-content: center;
}
.align-content-xl-between {
    align-content: space-between;
}
.align-content-xl-around {
    align-content: space-around;
}
.align-content-xl-stretch {
    align-content: stretch;
}
.align-self-xl-auto {
    align-self: auto;
}
.align-self-xl-start {
    align-self: flex-start;
}
.align-self-xl-end {
    align-self: flex-end;
}
.align-self-xl-center {
    align-self: center;
}
.align-self-xl-baseline {
    align-self: baseline;
}
.align-self-xl-stretch {
    align-self: stretch;
}