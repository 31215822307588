.module-07-section {
  align-items: flex-start;

  .module-07 {
    position: relative;
    width: 100%;
    padding: 0 2rem;
    margin: 6.5rem 0;

    .content-heading {
      display: inline-block;
      padding: 0.8rem 1rem;
      font-size: 0;
      text-align: left;
      border: 1px solid var(--border-color);
      border-bottom: none;
      height: auto;
      overflow: initial;

      &:hover {
        //border: 1px solid $white;

        .element-heading span {
          color: var(--white);
        }
      }

      .heading-link {
        position: relative;
        max-width: 100%;

        span {
          padding-right: 3rem;
        }
      }

      .element-heading {
        display: inline-block;
        vertical-align: middle;
        font-size: 2.4rem;
        margin: 0;
        max-width: 100%;

        span {
          display: inline-block;
          vertical-align: middle;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 2.4rem;
          max-width: 100%;
        }
      }

      .icon-arrow-cta {
        display: block;
        width: 2.4rem;
        height: 2.4rem;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        z-index: 1;
      }
    }

    .content-element {
      display: block;
      position: relative;

      @for $i from 2 through 5 {
        .module-7__item:nth-child(#{$i}n) {
          .single-tile {
            top: 84px * ($i - 1);
          }
        }
      }

      &.main-look{

        .single-tile{
          display: none;
        }

        .look{

          .look-image{

            &>img{
              width:100%;
            }
          }
        }
      }
    }

    .content-description{
      display: block;
      width: 100%;
      border: 1px solid var(--main-color);
      border-top: none;
      padding: 1.5rem;

      p,span{
        @include primaryFont;
        line-height: 2rem;
        letter-spacing: .2px;
        color: var(--main-color);
        margin: 0;
      }
    }

    .look-description {
      border: 1px solid var(--main-color);
      border-top: none;
      color: var(--main-color);
      padding: 1.5rem;

      p,span{
        display: block;
        @include primaryFont;
        line-height: 2rem;
        letter-spacing: .2px;
        color: var(--main-color);
        margin: 0;
      }
    }

    a {
      text-decoration: none;
    }
  }

  .module-7__item {
    display: flex;

    &:first-of-type {

      .single-tile {

        img {
          border-top: 1px solid var(--main-color);
        }
      }
    }

    &.active {
      .single-tile {
        background-color: var(--main-color);

        img {
          opacity: .8;
        }
      }

      .look {
        display: block;
      }
    }

    .experience-productRegion {

      .experience-assets-module7product {
        position: absolute;

        .product-box-container{
          display: block;
          border: 1px solid var(--main-color);

          .img-box{
            background-color: var(--white);

            img{

            }
          }

          .info-box{
            background-color: var(--white);
            border-top: 1px solid var(--main-color);
          }
        }
      }
    }

    .single-tile {
      position: absolute;
      width: 17%;
      height: 84px;
      top: 0;
      z-index: 9;
      cursor: pointer;

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border: 1px solid var(--main-color);
        border-top: none;
      }
    }

    .look {
      display: none;
      width: 100%;
    }

    .look-image {
      position: relative;
      height: 420px;
      border: 1px solid var(--main-color);
      display: flex;
      align-items: center;

      &>img {
        display: block;
        height: 100%;
        width: calc(100% - 17%);
        margin-left: auto;
        object-fit: cover;
      }

      .btn-viewlook{
        position: absolute;
        right: 0;
        top: 0;
        border: none;
        background-color: transparent;
        text-decoration: underline;
        color: var(--main-color);
        padding: 10px;
        cursor: pointer;
        @include primaryFont;
        line-height: 1rem;
        letter-spacing: .2px;
      }

      .side-panel-productsbox{

        .inner-productsbox {
          height: 295px;
          top: auto;
          bottom: 0;
          max-width: none;
          width: 100%;
          overflow: visible;
          padding: 2rem;

          .side-panel__close{

            &.open{
              opacity: 1!important;
              transform: none!important;
              display: block;
              right: 0;
              left: auto;
              cursor: auto;
              top: -40px;
            }
          }

          .title-wearingBy{
            @include primaryFont;
            line-height: 2rem;
            letter-spacing: .2px;
            margin-bottom: 2.5rem;
            color: var(--main-color);
          }

          .productsbox-container{
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            overflow-x: scroll;

            .experience-assets-module7product{

              .product-box-container{

                .img-box{
                  display: block;
                  width: 110px;
                  height: 165px;
                  border: 1px solid var(--main-color);
                  border-left: none;

                  img{
                    display: block;
                    margin: auto;
                  }
                }

                .info-box{
                  @include primaryFont;
                  line-height: 2rem;
                  letter-spacing: .2px;
                  margin-top: 2.5rem;
                  color: var(--main-color);
                  text-align: center;
                }
              }

              &:first-of-type{

                .product-box-container{

                  .img-box {
                    border-left: 1px solid var(--main-color);
                  }
                }
              }
            }
          }
        }
      }
    }

    .product-tile__presentation {
      display: flex;
      flex-direction: column-reverse;
      // height: 100%;
      align-items: center;
    }
  }
}