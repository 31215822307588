.btn-anchor-shop-the-look {
  padding: 0;
  padding-right: 1rem;
  z-index: 1;
  flex-direction: row;

  svg {
    height: 100%;
    width: 4rem;
  }
}
.container-shop-the-look-modal {
  .shop-the-look-modal_product-detail {
    display: flex;
    flex-wrap: wrap;
  }
}
.side-panel__shop-the-look {
  .side-panel__action {
    &.action-bar {
      border-right: 0;
      right: 0;
      position: absolute;
      top: 0;
      z-index: 1;
      width: auto;
      .action-bar__button {
        border-right: 0;
        border-left: 1px solid var(--main-color);
      }
    }
  }
  .container-shop-the-look-modal__product-big {
    display: none;
    padding: 0;
    border: 1px solid var(--main-color);
    border-left: 0;
    align-items: center;
    height: 100vh;
    .shop-the-look-modal__product-big {
      margin: 0 auto;
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .shop-the-look-modal__container-product {
    position: relative;
    border-top: 1px solid var(--main-color);
    min-height: 100vh;
    padding: 0;
    .shop-the-look-modal__container-wrapper-product {
      overflow: auto;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
  }
  .shop-the-look-modal__product {
    position: relative;
    .product {
      margin-bottom: 2rem;
    }

    .shop-the-look-modal__single-product {
      padding: 2rem 0;
    }
    .product-tile {
      .product-tile__presentation {
        flex-direction: unset;
        justify-content: space-between;
        align-items: flex-start;
        .product-tile-image {
          width: 14rem;
          height: 21rem;
          border: 1px solid var(--main-color);
          margin: 0;
          .tile-image-link {
            height: 100%;
            display: flex;
            align-items: center;
            padding-top: 0;
            .product-tile-image__picture {
              height: 100%;
              img {
                margin: 0 auto;
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: unset;
              }
            }
          }
        }
        .product-tile-body {
          margin: 0 0rem 1.2rem 1.2rem;
          padding: 0;
          width: 58%;
          .container-price-name-product {
            flex-direction: column;
            align-items: unset;
            position: relative;
            .add-to-wish-list {
              position: absolute;
              top: 0;
              right: 0;
            }
            .section-info-single-product-plp {
              width: 80%;
            }
          }
          .product-tile-body__price  {
            margin-top: 0.6rem;
            justify-content: flex-start;
            text-align: left;
            .product-total-discount {
              padding-right: 0.5rem;
              font-size: 1.8rem;
            }
            .value {
              flex: unset;
              max-width: unset;
              font-size: 1.8rem;
            }
          }
          .product-tile-body__badge, .product-tile-body__color {
            display: none;
          }
          .form-add-to-cart-from-shop-the-look {
            .shop-the-look__select {
              color: var(--black);
              border-bottom: 1px solid var(--black);
              background-image: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='UTF-8'?%3E%3Csvg width='14px' height='8px' viewBox='0 0 14 8' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EPath-8-Copy-12%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolygon id='Path-8-Copy-12' fill='%23002b' fill-rule='nonzero' transform='translate(7.000000, 4.000000) scale(-1, 1) rotate(-270.000000) translate(-7.000000, -4.000000) ' points='3 -1.86488423 4.11947847 -3 11 4.021617 4.11767963 11 3.00179883 9.8612246 8.7642725 4.0173077'%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E");

              &.disabled {
                background-image: none;
              }
            }
          }
        }
        .product-tile-image {
          border: 1px solid var(--light-grey);
        }
      }
      .product-tile__actions {
        width: 100%;
        margin-top: 2rem;
        .form-element {
          margin-bottom: 2rem;
          .form-element__select {
            color: var(--main-color);
            margin-bottom: 2rem;
            background-color: transparent;
          }
          &.invalid {
            .shop-the-look-form-error {
              display: inline-flex;
              opacity: 1;
              transform: translateY(0);
              height: auto;
              margin-top: 0;
              align-items: center;
              svg {
                width: 1.5rem;
                height: 1.5rem;
                fill: var(--white);
                margin-right: 0.3rem;
              }
            }
          }
        }
        .add-to-cart-shop-the-look {
          button {
            width: 100%;
            height: 3.5rem;
          }
          .shop-the-look-add-to-cart-feedback {
            width: 100%;
            background-color: var(--green-success);
            padding: 0 1.3rem;
            .container-feedback-shop-the-look {
              display: flex;
              align-items: center;
              justify-content: space-between;
              height: 3.5rem;
              p {
                margin: 0;
                color: var(--white);
              }
              svg {
                width: 2rem;
                height: 2rem;
              }
            }
          }
        }
      }
      .product-tile__action--remove {
        display: none;
      }
    }
  }
}
