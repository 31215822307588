.module-17 {
  .wrapper-carousel-module17 {
    position: relative;
  }
  &__swiper-button-prev {
    position: relative;
    height: 40px;
  }
  &__swiper-button-prev, &__swiper-button-next {
    cursor: pointer;
    position: absolute;
    top: 0.9rem;
    z-index: 10;
  }
  &__swiper-button-prev {
    right: 5.5rem;
  }
  &__swiper-button-next {
    right: 2.1rem;
  }
  svg {
    width: 24px;
    height: 24px;
  }
  &__title-container {
    border: 1px solid var(--main-color);
    border-bottom: none;
    padding: 0.8rem 1rem 0.2rem 1rem;
    width: fit-content;
    height: unset;
  }
  &__link {
    text-decoration: none;
    display: flex;
    align-items: flex-start;
    &:hover {
      h3 {
        color: var(--white);
      }
    }
  }
  &__title {
    font-size: 2.4rem;
    margin: 0 1rem 0 0;
    white-space: normal;
    line-height: 2.9rem;
  }
  &__slide {
    .pd-video__container {
      display: block;
      height: 100%;
      width: auto;
      border: 1px solid var(--border-color);
    }
    .visual {
      &.module17_fullbleed_autohigh {
        picture {
          display: block;
          height: 100%;
          width: auto;
          border: 1px solid var(--border-color);

          .visual__image {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
}

.module-17__banner-cta {

  &.version1 {
    @include debug;
    max-width: 40rem;
    width: 80%;
    position: absolute;
    left: 50%;
    top: 55%;
    transform: translate(-50%, -50%);
    z-index: 5;
    background-color: var(--alternative-color);
    border: 1px solid var(--main-color);
    padding: 1.6rem;
    .pd-heading {
      @include primaryFont($weight: 500);
      font-size: 1.4rem;
      text-transform: uppercase;
      letter-spacing: 0.2px;
      text-align: left;
      &__container {
        padding-bottom: 1.6rem;
        position: relative;
        &:after {
          content: '';
          border-bottom: 1px solid var(--main-color);
          width: calc(100% + 3.2rem);
          position: absolute;
          bottom: 0;
          left: -1.6rem;
        }
      }
    }
    .button {
      margin: 1.6rem 1.2rem 0 0;
      max-width: 46%;
      &:nth-child(odd) {
        margin-right: 0;
      }
      .button__content {
        text-transform: capitalize;
      }
    }
  }

  &.version2 {
    @include debug;
    max-width: 40rem;
    width: 80%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    background-color: var(--alternative-color);
    border: 1px solid var(--main-color);
    padding: 1.6rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .pd-text {
      margin-bottom: 2.4rem;

      p {
        margin: 0;
      }
    }
  }
}

.swiper-wrapper {
  .swiper-slide {

  }
}
