.ts-wrapper.single {

  &.dropdown-active{

    .ts-control{

      &:after{
        transform: rotate(90deg);
        margin-top: 0;
        border: none;
      }
    }
  }

  .icon-pd{
    vertical-align: middle;

    svg{
      width: 1.6rem;
      height: 1.6rem;

      &>*{
        fill:var(--black);
      }
    }
  }

  .tom-option-title{
    font-size: 1.4rem;
    line-height: 2rem;
    letter-spacing: 0.02rem;
    margin-left: 0.8rem;
    color: var(--black);
  }

  .ts-control{
    border-color: var(--light-grey);
    box-shadow: none;
    background-color: transparent;
    background-image: none;
    border-radius: 0;
    padding: 1.5rem 1rem;

    &:after{
      content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMC42NjY2IDQuMDkwNzNMOS45MjAzMSAzLjMzMzk4TDUuMzMzMjkgOC4wMTUwNkw5LjkyMTUxIDEyLjY2NzNMMTAuNjY1NCAxMS45MDgxTDYuODIzNzggOC4wMTIxOUwxMC42NjY2IDQuMDkwNzNaIiBmaWxsPSIjMTIxMjEyIi8+Cjwvc3ZnPgo=');
      border: none;
      width: auto;
      height: auto;
      margin-top: 0;
      top: auto;
      right: 10px;
      line-height: normal;
      transform: rotate(-90deg);
    }
  }

  .ts-dropdown.single {
    margin-top: 0;
    border-color: var(--light-grey);

    .tom-option {
      padding: 1.5rem 1rem;

      &.active {
        background-color: transparent;
      }

      &.selected {

        color: var(--main-color);

        .icon-pd {

          svg {

            & > * {
              fill: var(--main-color);
            }
          }
        }

        .tom-option-title {
          color: var(--main-color);
        }
      }
    }
  }
}
