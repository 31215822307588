.module-static-plp--06 {
  background: var(--main-color);

  .module-wrp {
    padding: 6rem 2rem;
  }

  .module-text {
    display: block;
    margin: 0;
    color: var(--white);

    a {
      color: inherit;
      text-decoration: underline;
    }

    .text-square {
      display: inline-block;
      //vertical-align: middle;
      vertical-align: text-bottom;
      width: 0.8rem;
      height: 1.4rem;
      background: var(--white);
      margin-left: 0.2rem;
    }
  }
}
