.pd-lt-helparea{

  .section-body{

    &.layout-main{
      &>.experience-component:not(.pd-container-help), &>.pd-container-help > .experience-region{
        padding: 0 25.2rem;
      }
    }

    &.layout-menu{
      padding: 3rem 25.2rem 0;
    }
  }

  .section-body-full{
    &>.experience-component:not(.pd-container-help), &>.pd-container-help > .experience-region{
      padding: 0 24rem;
    }
  }

  .section-pref{
    &>.experience-component:not(.pd-container-help), &>.pd-container-help > .experience-region{
      padding: 0 25.2rem;
    }
  }
}

.pd-lt-helpsectionarea-editmode{

  .section-body{
    padding: 0 24rem;
  }
}