@import '../utils/vars';

.pd-whiteSpace {
  width: 100%;
}
.pd-whiteSpace-1x {
  height: $base_margin;
}
.pd-whiteSpace-2x {
  height: $base_margin * 2;
}
.pd-whiteSpace-3x {
  height: $base_margin * 3;
}
.pd-whiteSpace-4x {
  height: $base_margin * 4;
}
.hideMobile {
  display: block;
}
@media (max-width: 991px) {
  .hideMobile {
    display: none;
  }
}

.hideDesktop {
  display: block;
}
@media (min-width: 992px) {
  .hideDesktop {
    display: none;
  }
}
