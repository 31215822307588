.drag-area {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  &.full-viewport {
    width: 100vw;
    @include fullViewportHeight();
  }
  .draggable {
    cursor: move;
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;

    &.is-dragging {
      cursor: grabbing;
      cursor: -moz-grabbing;
      cursor: -webkit-grabbing;
    }
  }
}
