@import '../utils/mixins';
.checkbox__container {
  display: flex;
  align-items: center;
  .form-element__checkbox {
    width: 1.8rem;
    height: 1.8rem;
    margin: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid var(--main-color);
    cursor: pointer;
    flex-shrink: 0;
    border-radius: 0;
    margin-right: 0.6rem;
    &::after {
      content: ' ';
      position: relative;
      display: block;
      @include fixedSprite(check-on);
    }
    &:checked {
      background-color: var(--main-color);
    }
    &:checked::after {
      content: '';
    }
  }
  .form-element__label_checkbox {
    display: inline-block;
    padding-left: 0.8rem;
    cursor: pointer;
    position: relative;
    // @include darkMode {
    //   color: white;
    // }
  }
  &.checkbox__container--right {
    .form-element__label_checkbox {
      order: 2;
    }
    .form-element__checkbox {
      order: 1;
    }
  }
  &.checkbox__container--toggle {
    .form-element__checkbox {
      visibility: hidden;
      width: 0;
      height: 0;
      overflow: hidden;
      &::after {
        content: none;
      }
      &:checked {
        & + .form-element__label_checkbox {
          --primary-color: var(--alternative-color);
          --secondary-color: var(--main-color);
        }
      }
    }
    .form-element__label_checkbox {
      --primary-color: var(--main-color);
      --secondary-color: var(--alternative-color);
      border: 1px solid var(--border-color);
      background-color: var(--secondary-color);
      color: var(--primary-color);
      margin: 0;
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 1.6rem;
      height: 3.2rem;
    }
  }
}
