.pd-editorial-modules {

  // ----- GENERIC RULES FOR EDITORIAL MODULES -----

  // Hover link effect inside Text Component
  .pd-text {
    a {
        color: var(--main-color);
        position: relative;
        text-decoration: none;
        width: fit-content;

        &::after {
          content: ' ';
          position: absolute;
          left: 0;
          top: 100%;
          width: 100%;
          height: 1px;
          background-image: linear-gradient(90deg, var(--main-color), var(--main-color));
          background-size: 6px 1px;
          transition: 500ms;
        }

        &:hover,
        &:focus,
        &:active {
          &::after {
            background-image: linear-gradient(90deg, var(--main-color), var(--main-color) 75%, transparent 75%, transparent 100%);
            animation-duration: 2s;
            animation-name: animateBorder;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
          }
        }

      u {
        text-decoration: none;
      }
    }
  }
}