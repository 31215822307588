@import '../../mixins';
@import '../../../pages/account/mixins';

.magazine__hero {
  color: var(--main-color);
  margin: 0;
  padding: 0;
  position: relative;

  &__image {
    width: 100%;
    height: 0;
    padding-bottom: 125%;
    position: relative;

    @include media-breakpoint-up(lg) {
      padding-bottom: 35%;
    }

    img {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 1;
    }
  }

  &__info {
    position: absolute;
    z-index: 2;
    bottom: 2rem;
    left: 50%;
    width: calc(100% - 4rem);
    max-width: 42rem;
    transform: translateX(-50%);
    text-align: center;

    @include media-breakpoint-up(lg) {
      text-align: left;
      bottom: auto;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    a.heading > div {
      position: relative;
      z-index: 0;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        transition: transform var(--transition);
        transform-origin: left top;
        background-color: var(--main-color);
        transform: scaleX(0);
        z-index: -1;
      }

      &:hover {
        color: var(--main-color);

        &::before {
          transform: scaleX(1);
        }

        h3 {
          color: var(--white);
          svg {
            fill: var(--white);
          }

        }
      }
    }
  }

  &__badge {
    @include magazine-badge;
    color: var(--main-color);
    background: var(--white);
    max-width: 100%;
    padding-right: 0.8rem;
    margin: 0 auto;

    a {
      text-decoration: none;
      display: inline-block;
    }

    * {
      margin: 0;
      color: var(--main-color);
    }

    h3 {
      padding-right: 3rem;
      position: relative;
    }

    svg {
      display: block;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 2.4rem;
      height: 2.4rem;
      z-index: 1;
    }
  }

  &__box {
    border: 1px solid var(--main-color);
    margin-top: -1px;
    position: relative;
    background: var(--white);
    display: none;

    @include media-breakpoint-up(lg) {
      display: block;
    }

    * {
      margin: 0;
    }

    &__content-text {
      padding: 1.2rem;
    }

    &__text {
      display: block;
      padding: 0;
      margin: 0;
      text-align: left;
    }
  }
}
