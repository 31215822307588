@mixin resetButton {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
}
@keyframes animateBorder {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -10rem 0;
  }
}

.button {
  @include resetButton;
  --primary-color: var(--main-color);
  --secondary-color: var(--alternative-color);
  white-space: nowrap;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 4rem;
  height: 4rem;
  font-size: 1.4rem;
  line-height: 1;
  color: var(--secondary-color);
  background-color: var(--primary-color);
  border: 1px solid var(--border-color);
  position: relative;
  overflow: hidden;
  z-index: 0;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: transform var(--transition);
    transform-origin: left top;
    background-color: var(--secondary-color);
    transform: scaleX(0);
    z-index: -1;
  }
  &:not([disabled]) {
    &:hover,
    &:focus,
    &:active {
      color: var(--primary-color);
      .icon-pd{
        svg{
          fill: var(--primary-color);
          &>*{
            fill: var(--primary-color);
          }
        }
      }
      svg {
        fill: var(--primary-color);
      }
      &::before {
        transform: scaleX(1);
      }
      .pd-heading {
        color: var(--primary-color);
      }
    }
  }
  &[disabled],
  &.disabled {
    opacity: 0.5;
    cursor: default;
  }
  &.secondary {
    --primary-color: var(--alternative-color);
    --secondary-color: var(--main-color);
  }
  &.success {
    color: var(--green-success) !important;
    background-color: white;
    --border-color: var(--green-success);
    &:hover,
    &:focus,
    &:active {
      color: white !important;
      background-color: var(--primary-color);
    }
  }
  &.fluid {
    width: 100%;
  }
  &.tag {
    padding: 0 1.6rem;
    height: 3.2rem;
  }
  &.d-none {
    display: none;
  }
  &.not-effect:before {
    background: none;
    color: initial;
    transition: none;
    transform: none;
  }
  &.not-effect:hover {
    color: var(--main-color);
  }
}

.cta {
  @include resetButton;
  position: relative;
  --primary-color: var(--main-color);
  --secondary-color: var(--alternative-color);
  color: var(--primary-color);
  &:not(.highlight) {
    &::after {
      content: ' ';
      position: absolute;
      left: 0;
      top: 100%;
      width: 100%;
      height: 1px;
      background-image: linear-gradient(90deg, var(--main-color), var(--main-color));
      background-size: 6px 1px;
      transition: 500ms;
    }
  }

  &.cta-black {
    color: var(--black);
    &:not(.highlight) {
      &::after {
        content: ' ';
        position: absolute;
        left: 0;
        top: 100%;
        width: 100%;
        height: 1px;
        background-image: linear-gradient(90deg, var(--black), var(--black));
        background-size: 6px 1px;
        transition: 500ms;
      }
    }
    &:hover,
    &:focus,
    &:active {
      &:not(.highlight) {
        &::after {
          background-image: linear-gradient(90deg, var(--black), var(--black) 75%, transparent 75%, transparent 100%);
          animation-duration: 2s;
          animation-name: animateBorder;
          animation-iteration-count: infinite;
          animation-timing-function: linear;
        }
      }
    }
  }

  &.cta-grey {
    color: var(--black);
    &:not(.highlight) {
      &::after {
        content: ' ';
        position: absolute;
        left: 0;
        top: 100%;
        width: 100%;
        height: 1px;
        background-image: linear-gradient(90deg, rgba(18, 18, 18, 0.6), rgba(18, 18, 18, 0.6));
        background-size: 6px 1px;
        transition: 500ms;
      }
    }
    &:hover,
    &:focus,
    &:active {
      &:not(.highlight) {
        &::after {
          background-image: linear-gradient(90deg, rgba(18, 18, 18, 0.6), rgba(18, 18, 18, 0.6) 75%, transparent 75%, transparent 100%);
          animation-duration: 2s;
          animation-name: animateBorder;
          animation-iteration-count: infinite;
          animation-timing-function: linear;
        }
      }
    }
  }

  &.has-icon {
    &::after {
      width: calc(100% - 2.2rem);
      left: 2.2rem;
    }
  }

  &:hover,
  &:focus,
  &:active {
    &:not(.highlight) {
      &::after {
        background-image: linear-gradient(90deg, var(--main-color), var(--main-color) 75%, transparent 75%, transparent 100%);
        animation-duration: 2s;
        animation-name: animateBorder;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
      }
    }
  }
  &.highlight {
    padding: 0 0.2rem;
    color: var(--primary-color);
    background: transparent;
    background-image: linear-gradient(var(--primary-color), var(--primary-color));
    background-repeat: no-repeat;
    background-position: 0% 0%;
    background-size: 0% 100%;
    box-decoration-break: clone;
    transition: background-size 0.5s, background-position 0.3s ease-in 0.5s;
    &:hover {
      color: var(--secondary-color);
      background-size: 100% 100%;
      background-position: 0% 0%;
      transition: background-position 0.5s, background-size 0.3s ease-in 0.5s, color 500ms 500ms;
    }
  }
}

.full-box {
  width: 100%;
}

.button__badge {
  display: none;
  &:not(.hidden) {
    display: block;
    position: absolute;
    display: flex;
    width: 1.2rem;
    height: 1.2rem;
    left: 70%;
    top: 60%;
    background-color: var(--main-color);
    border-radius: 50%;
    font-size: 0.9rem;
    color: var(--alternative-color);
    text-align: center;
    align-items: center;
    justify-content: center;

    [data-user-action='account'] & {
      color: var(--main-color);
    }

    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}

.has-icon {
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;

  &.d-none {
    display: none;
  }
  svg {
    margin: 0 0.2rem;
    display: block;
    height: 2rem;
    width: 2rem;
  }
  .anchor-text__icon,
  .button__icon {
    svg {
      fill: var(--secondary-color);
    }
  }
  &.icon-align-left {
    .button__icon {
      order: 1;
    }
    .button__content {
      order: 2;
    }
  }
  &.icon-align-right {
    .button__icon {
      order: 2;
    }
    .button__content {
      order: 1;
    }
  }
}
.oauth-button {
  &.oauth-button--apple {
    margin-top: 1rem;
    > div {
      max-width: none !important;
    }
  }
}
