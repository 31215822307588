.module-3 {
  .module-3__item {
    .module-3__container-text {
      margin-bottom: 3px;
      border: 1px solid var(--main-color);
      border-top: none;
      .module-3__text.use-single-block {
        .pd-text {
          box-shadow: none;
        }
      }
    }
  }
}