@import '../../mixins';
@import '../../../pages/account/mixins';

.loyalty-orders__strip {
  background: var(--main-color);
  padding: 2rem;

  .card & {
    margin: -2rem -2rem 1rem;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      align-items: center;
    }
  }

  &__img {
    margin-bottom: 2rem;

    @include media-breakpoint-up(lg) {
      width: 17.7rem;
      flex: 17.7rem;
      margin: 0;
    }

    img {
      display: block;
      width: 17.7rem;
      height: 4rem;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__text {
    @include media-breakpoint-up(lg) {
      width: calc(100% - 17.7rem);
      flex: calc(100% - 17.7rem);
      padding-left: 3rem;
    }

    &__title {
      font-size: 1.4rem;
      margin: 0 0 1rem 0;
      color: var(--white);

      @include media-breakpoint-up(lg) {
        margin-bottom: 0.7rem;
      }
    }

    &__description {
      font-size: 1.2rem;
      margin: 0;
      color: var(--white);
    }
  }
}
