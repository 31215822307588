:root {
  --main-color: #e4002b;
  --white: #fff;
  --alternative-color: var(--white);
  --transition: 300ms ease-in;
  --green-validation: #0dc137;
  --green-success: #068922;
  --yellow: #f8e71c;
  --black: #121212;
  --lighting-grey: #f9f9f9;
  --light-grey: #dfdfdf;
  --grey: #9d9d9d;
  --dark-grey: #7f7f7f;
  --alternative-grey: #666;
  --disabled-color: #ededed;
  --text-color: var(--main-color);
  --border-color: var(--main-color);
  --pdp-sticky-offset: 0;
  --success: #068922;
  --main-color-opacity: rgba(228, 0, 43, 0.3);
  --sidePanelWidth: 431px;
  --nav-position: 0;
  --header-position: 0;
  --black-opacity: rgba(0, 0, 0, 0.6);
  --main-color-opacity-variant: rgba(228, 0, 43, 0.05);
}
.dark {
  --main-color: #fff;
  --alternative-color: #121212;
}
.alternative {
  --text-color: var(--black);
  --border-color: var(--light-grey);
}
.dubug__dev {
  background-color: bisque;
  padding: 10px;
  border: 2px dotted #e83e8c;
}
