.experience-layout-modulePromotion {
  border: 0.1rem solid var(--main-color);
  background-color: var(--white);

  &.red {
    background-color: var(--main-color);
  }

  .experience-assetAndPanel {

    // HIDING ASSET (IMAGE OR VIDEO) ON MOBILE
    img, .pd-video__container {
      display: none;
    }
  }
}
