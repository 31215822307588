.module__multiple-title {
  --dark-color: var(--main-color);
  --light-color: var(--alternative-color);
  .module__title {
    border: 1px solid var(--dark-color);
    border-bottom: none;
    background-color: var(--light-color);
    color: var(--dark-color);
    padding: 0.8rem;
    width: fit-content;
    display: flex;
    align-items: center;
    .pd-heading {
      font-size: 3.2rem;
      @include secondaryFont();
      margin: 0;
      color: var(--dark-color);
    }
    .pd-icon {
      margin-left: 0.8rem;
      svg[class^='icon'] {
        width: 2.4rem;
        height: 2.4rem;
      }
    }
    &:hover {
      // border: 1px solid var(--main-color);
      border-bottom: none;
      .pd-icon {
        svg[class^='icon'] {
          fill: var(--main-color);
        }
      }
    }
  }

  .module__title {
    display: block;
    border: none;
    background: transparent;
    padding: 0;
    color: inherit;
    width: 100%;
    white-space: initial;

    &--button {
      .pd-heading__container {
        .pd-heading {
          &::before {
            background-color: var(--dark-color);
          }
        }
      }
      &:hover .pd-heading__container .pd-heading {
        color: var(--light-color);
        background-color: var(--main-color);
        box-shadow: 0 0 0 1px var(--light-color);
      }
    }
  }

  .pd-heading__container {
    padding: 0;
    width: 100%;
    display: block;
    //margin-bottom: -1px;
    padding-top: 1px;

    .pd-heading {
      line-height: 3.8rem;
      display: inline;
      box-shadow: 0 0 0 1px var(--dark-color);
      background-color: var(--light-color);
      margin: 0 1px;
      padding: 0 0.8rem;
      -webkit-box-decoration-break: clone;
      box-decoration-break: clone;
      font-size: 3.6rem;
    }
  }
}
