.experience-assets-module1BPanel {
  display: flex;
  align-items: end;

  .experience-textAndButton {
    margin: 4rem 2rem;
    border: 0.1rem solid var(--main-color);
    padding: 1.6rem;
    background-color: var(--white);

    .pd-text {
      h1, h2, h3, h4, h5, h6, p {
        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    a, button {
      &:first-child {
        margin-bottom: 1.6rem;
      }

      &:last-child {
        margin-top: 1.6rem;
      }
    }
  }
}