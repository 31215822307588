.vue-module {
  .product-detail {
    .row {
      > div {
        .vue-module-main-image {
          padding-right: 0;
          .title-box {
            border: 1px solid var(--main-color);
            padding: 0.5rem 0.8rem;

            .vue-module__title {
              margin: 0;
            }
          }

          .vue-module-main-image-content {
            padding: 2rem;
            border: 1px solid var(--main-color);
            .vue-module__description {
              margin-top: 0;
            }
          }
        }
      }
    }
  }
}
.vue-module__navigation {
  flex-wrap: nowrap;
  overflow-x: auto;
}
.vue-module__navigation-item {
  height: 3.2rem;
  padding: 0 1.6rem;
  margin-right: 1rem;
}
.vue-module__carousels {
  position: relative;
  white-space: nowrap;
  font-size: 0;
  width: 100%;
}
.vue-module__carousel-nav {
  display: none;
}
.swiper-container-initialized {
  & ~ {
    .vue-module__carousel-nav {
      display: none;
    }
  }
}
.vue-module__carousel-progress {
  height: 2px;
  width: 95%;
  background-color: rgba(255, 0, 0, 0.2);
  margin-top: 2rem;
  .progress-bar{
    height: 2px;
    width: 100%;
    position: absolute;
    background-color: var(--main-color);
  }
  &.disabled{
    display: none;
  }
  .swiper-pagination-progressbar-fill {
    content: '';
    background-color: var(--main-color);
    height: 5px;
    width: 100%;
  }
}
.vue-module__tile-image {
  display: block;
  width: 100%;
  height: auto;
  border: 1px solid var(--main-color);
  margin-bottom: 1.2rem;
}
.vue-module--style-with {
  .vue-module__product {
    width: 100%;
    border: none;
  }
  .vue-module__content {
    .vue-module-navigation-wrapper {
      margin-bottom: 0;
      .vue-module-navigation-nav-wrapper {
        border: none;
        padding: 2rem 0;
      }
    }
  }
}
