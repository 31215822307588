.module-static-plp--10 {
  max-width: 58rem;
  min-width: unset;

  .product-carousel-container {
    overflow: visible;
    margin: auto;

    .swiper-button-prev {
      display: block;
      left: -3rem;
      top: calc(50% - 1.2rem);
      right: unset;

      &.hidden-desktop-arrows {
        display: none;
      }
    }

    .swiper-button-next {
      display: block;
      right: -3rem;
      top: calc(50% - 1.2rem);
      left: unset;

      &.hidden-desktop-arrows {
        display: none;
      }
    }

    .scrollbar-filters {
      display: none;
    }
  }

  .content-heading {
    .icon-arrow-fill-down {
      display: block;
    }
  }
  .content-heading {
    .element-heading {
      font-size: 3.4rem;
    }
  }

  .element-scrollable {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }

  .element-wrapper {
    padding: 0;

    .single-item {
      width: 100%;
      flex: 0 0 100%;
    }
  }
}

.hide-box {
  .module-static-plp--10 {
    .content-heading {
      .element-heading {
        text-align: center;
        padding-left: 0.8rem;
      }
      
      .content-element {
        padding-left: 1.2rem;

        p {
          text-align: center;
        }
      }
    }
  }
}

.enhanced-visual-filters.view-desktop {
  display: block;
  margin-bottom: 0.4rem;
  overflow: hidden;
  text-align: -webkit-center;

  .loader-class {
    background-color: transparent;
  }

  .content-heading {
    .element-heading {
      font-size: 3.6rem;
      margin-bottom: 1.6rem;
    }

    .content-element {
      padding: 0 0 2.3rem;
      max-width: 582px;

      p {
        margin: 0;
      }
    }
  }

  .visual-filter-slide {
    width: auto!important;
  }

  .wrapper-carousel.default {

    .swiper-wrapper {
      justify-content: center;
    }

    .content-img {
      height: 295px;
      width: 202px;

      @media (max-width: 1300px) {
        height: 195.229px;
        width: 134.55px;
      }

      .vf-image-ratio {
        width: 100%;
        height: 29.5rem;
        max-height: 29.5rem;
        object-fit: fill;

        @media (max-width: 1300px) {
          height: 19.5rem;
          max-height: 19.5rem;
        }
      }
    }

    .content-text {
      height: 50px;
      width: 202px;

      @media (max-width: 1300px) {
        height: 32.978px;
        width: 134.55px;
      }

      .text {
        @media (max-width: 1300px) {
          font-size: 1.1872rem;
          line-height: 11.872px;
          letter-spacing: 0.132px;
          padding: 1.055rem;
          padding-top: 1.2rem;
        }
      }
    }

    .container-cta-arrows {
      display: none;
    }
  }

  .wrapper-carousel.view-all {
    margin-right: 3rem;
    margin-left: 11rem;

    &.margin-auto {
      margin-left: auto;
      margin-right: auto;
    }

    .content-img {
      height: 295px;
      width: 202px!important;

      @media (max-width: 1040px) {
        height: 195.229px;
        width: 134.55px!important;
      }

      .vf-image-ratio {
        width: 100%;
        height: 29.5rem;
        max-height: 29.5rem;
        object-fit: fill;

        @media (max-width: 1040px) {
          height: 19.5rem;
          max-height: 19.5rem;
        }
      }
    }

    .content-text {
      height: 50px;
      width: 202px!important;

      @media (max-width: 1040px) {
        height: 32.978px;
        width: 134.55px!important;
      }

      .text {
        @media (max-width: 1040px) {
          font-size: 1.1872rem;
          line-height: 11.872px;
          letter-spacing: 0.132px;
          padding: 1.055rem;
        }
      }
    }

    .category-slide-description {
      width: 251px!important;
      height: 345px!important;

      @media (max-width: 1040px) {
        width: 180px !important;
        height: 228.207px !important;
      }

      p {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 15;
        
        @media (max-width: 1040px) {
          font-size: 1.2rem;
          line-height: 18px;
          letter-spacing: 0.2px;

          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 10;
        }
      }
    }
  }

  .margin-carousel {
    margin-top: 4rem;
  }

  .wrapper-carousel {
    margin-top: 3.2rem;
    transition: opacity 0.5s ease;
    opacity: 1;

    &.hidden {
      opacity: 0.001;
    }

    .swiper {
      overflow: visible;
    }

    .swiper-slide {
      border: 1px solid var(--main-color);

      &:first-child {
        border-left: 1px solid var(--main-color);
      }

      &:not(:first-child) {
        border-left: none;
      }
    }

    .single-item {
      text-decoration: none;

      .content-img {
        text-align: center;
        position: relative;
      }

      &--active {
        .content-img {
          &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: var(--main-color);
            opacity: 0.1;
          }
        }

        .content-text {
          background: var(--border-color);

          .text {
            color: var(--white);
          }
        }
      }

      &:hover {
        .content-img {
          &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: var(--main-color);
            opacity: 0.1;
          }
        }
      }

      .text {
        border-top: 1px solid var(--main-color);
        font-family: franklin-gothic-compressed;
        font-size: 1.8rem;
        line-height: 1.8rem;
        letter-spacing: 0.2px;
        margin: 0;
        padding: 1.7rem 1.6rem 1.4rem;
        text-align: center;
        text-transform: uppercase;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .text:not(:last-child) {
        border-right: 1px solid var(--main-color);
      }
    }

    .category-slide-description {
      .description {
        margin: 2.4rem;
        text-align: initial;
      }
    }
  }

  .container-cta-arrows {
    align-items: center;
    display: flex;
    justify-content: end;
    margin-bottom: 1.6rem;
    opacity: 1;
    transition: opacity 0.5s ease;

    &.hidden {
      opacity: 0.001;
    }

    .view-all-cta {
      align-content: center;
      height: 2.7rem;
    }

    .filter-swiper-button--prev {
      margin-right: 0.8rem;
      margin-left: 1.6rem;
    }

    .filter-swiper-button--prev, .filter-swiper-button--next {
      svg {
        width: 24px;
        height: 24px;
      }
    }

    .hidden-arrows {
      display: none;
    }
  }
}

.enhanced-visual-filters.view-mobile {
  display: none;
}