.module-empty-cart-container {
  --text-color: var(--main-color);
  --border-color: var(--main-color);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  max-width: 90%;
  align-items: baseline;
  margin: 10rem auto;
  
  .empty-cart-container__title {
    display: inline-flex;
    align-items: center;
    font-size: 3.4rem;
    border: 1px solid var(--border-color);
    border-bottom: none;
    padding: 0.2rem 0.8rem;
    min-width: 15rem;
    margin: 0;
  }
  
  .cart-item__items {
    display: flex;
    width: 100%;

    .cart-item__item {
      margin-top: 0;
      margin-left: 2rem;
      width: 100%;

      &:first-of-type {
        margin-left: 0;
      }

      .cart-item__picture {
        position: relative;
        display: flex;
        aspect-ratio: 1;
        border: 1px solid var(--border-color);

        &::after {
          content: '';
          width: 1px;
          height: 100%;
          border-right: 1px solid var(--border-color);
          position: absolute;
          right: -1px;
          z-index: 1;
          top: 0;
        }

        img {
          width: 100%;
          object-fit: contain;
          background: var(--light-grey);
        }
      }
      .cart-item__link {
        text-decoration: none;

        .cart-item__name {
          display: inline-flex;
          align-items: center;
          margin: 0;
          font-size: 3.4rem;
          width: 100%;
          border: 1px solid var(--border-color);
          border-top: none;
          padding: 0.2rem 0.8rem;
          justify-content: space-between;

          svg {
            width: 2.4rem;
            height: 2.4rem;
            margin-left: 5px;
          }
        }
      }

      @media (min-width: 768px) {
        &:first-of-type {
          .cart-item__picture {
            border-left: 1px solid var(--border-color);
          }
        }
        &:last-of-type {
          .cart-item__picture {
            border-right: 1px solid var(--border-color);
          }
        }
        
      }
    }
  }

  svg {
    fill: var(--main-color);
  }
}



