.product-detail {
  [name="product-add-to-cart"] {
    display: grid;
    grid-template-columns: auto 4rem;
    grid-template-areas:
      'addtocart wishlist'
      'preorder preorder'
      'limitation limitation';
    .add-to-cart-section {
      grid-area: addtocart;
    }
    .add-to-wish-list-mobile {
      grid-area: wishlist;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border: 1px solid var(--main-color);
      background-color: #ffffff;
    }
    .add-preorder-to-cart-section {
      grid-area: preorder;
    }
    .preorder-limitation-message {
      grid-area: limitation;
    }
  }
}

.product-tile {
  .add-to-wish-list-mobile {
    display: none;
  }
}
