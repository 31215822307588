.header-panel {
  height: 100%;
  overflow: auto;
  white-space: normal;

  .anchor-text {
    --primary-color: var(--black);
    --secondary-color: var(--black);
    
    &.cta {
      color: var(--black);
    }

    &:hover,
    &:focus,
    &:active {
      &.cta:not(.highlight)::after {
        background-image: linear-gradient(90deg, var(--black), var(--black) 75%, transparent 75%, transparent 100%);
      }
    }

    &.cta:not(.highlight)::after {
      background-image: linear-gradient(90deg, var(--black), var(--black));
    }
  }

  .pd-heading {
    @include primaryFont;
    font-size: 1.8rem;
    font-weight: 500;
    margin: 0;

    &.pd-h3-style {
      color: var(--black);
    }
  }
  .loyalty-register-page__benefits {
    display: block;
    position: unset;
    width: 100%;
    transform: unset;
    margin-bottom: 0;
    .loyalty-register__benefit {
      color: var(--white);
      i[class^='icon'] { 
        filter: brightness(0) invert(1);
        margin-right: 1rem;
      }
    }
  }
}

.header-panel__title,
.header-panel__links {
  padding: 2rem;
}
.header-panel__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--light-grey);
  color: var(--black);
}
.header-panel__links {
  padding-bottom: 0;
  .anchor-text {
    display: flex;
    margin-bottom: 1.8rem;
    color: var(--black);
  }
}
.header-panel__login,
.header-panel__password-recovery,
.header-panel__password-recovery--success,
.header-panel__create-account {
  padding: 2rem;
}
.header-panel__create-account {
  border-top: 1px solid var(--light-grey);
  .button {
    margin-bottom: 1rem;
  }
}

.header-panel__login-dcc{
  .form-element__label,
  .form-element__input,
  p{
    color: var(--black);
    text-align: left;
  }
  .form-element__input {
    border-bottom: 1px solid var(--black);
    color: var(--black);
  }
}

.header-panel__login {
  .form-element__input {
    border-bottom: 1px solid var(--black);
    color: var(--black);
  }

  .form-element__label,
  .form-element__label_checkbox {
    color: var(--black);
  }

  .pd-text {
    color: var(--black);

    p {
      color: var(--black);
    }
  }

  .cta {
    color: var(--black);
    --primary-color: var(--black);
    --secondary-color: var(--black);

    &:not(.highlight)::after {
      background-image: linear-gradient(90deg, var(--black), var(--black));
    }

    &:hover,
    &:focus,
    &:active {
      &:not(.highlight)::after {
        background-image: linear-gradient(90deg, var(--black), var(--black) 75%, transparent 75%, transparent 100%);
      }
    }
  }

  .show-password-button {
    svg {
      fill: var(--black);
    }
  }
}
