.module-11A_container {
padding-left: 0;
padding-right: 0;

  .innerContent{
    margin-left: auto;
    margin-right: auto;
  }
  .module-11A-title_container {
    max-width: 62rem;
  }
  .module-11A-content_container {
    max-width: 62rem;
  }
}
