.pd-livechat-button {

  button{
    display:flex;
    flex-direction: row;
    padding-left: 2rem;
    padding-right: 2rem;

    .icon-pd{
      margin-right: 1rem;
      position: relative;

      &:before{
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 25px;
        background-color: var(--light-grey);
        display: block;
        position: absolute;
        top: -2px;
        right: -2px;
      }

      svg{
        vertical-align: middle;
      }
    }

    &.online{

      .icon-pd {

        &:before {
          background-color: #00BF29;
        }
      }
    }
  }
}
