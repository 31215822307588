@import '../../mixins';
@import '../../../pages/account/mixins';

.magazine__shop-the-look {
  color: var(--main-color);
  margin-bottom: 3.2rem;

  @include media-breakpoint-up(lg) {
    margin-bottom: 6rem;
  }

  &__badge {
    @include magazine-badge;

    a {
      text-decoration: none;
    }

    * {
      margin: 0;
    }
    
    &.secondary {
      h3 {
        color: inherit;
      }
    }
  }

  &__box {
    border: 1px solid var(--main-color);
    margin-top: -1px;

    * {
      margin: 0;
    }

    &__content-image {
      border-bottom: 1px solid var(--main-color);
      margin-bottom: -1px;
      position: relative;
      padding-bottom: 146%;
      height: 0;

      @include media-breakpoint-up(lg) {
        padding-bottom: 110%;
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        z-index: 1;
      }
    }

    &__content-text {
      padding: 1.2rem;
      display: flex;
      flex-direction: column;
      margin-top: -1px;

      @include media-breakpoint-up(lg) {
        padding: 0.9rem 1.2rem;
      }
    }

    &__content-title {
      display: flex;
      flex-direction: row;

      .magazine__shop-the-look__box__text {
        &--title {
          width: calc(100% - 7rem);
          flex: 0 0 calc(100% - 7rem);
        }
        &--data {
          width: 7rem;
          flex: 0 0 7rem;
          text-align: right;
        }
      }

      + .magazine__shop-the-look__box__text {
        margin-top: 1.6rem;
      }
    }
  }

  .button-open-info {
    display: block;
    border: none;
    position: absolute;
    top: 42%;
    left: 63%;
    z-index: 2;
    cursor: pointer;
    background: var(--main-color);
    width: 3.2rem;
    height: 3.2rem;
    padding: 0.6rem;

    .icon-close {
      transform: rotate(45deg);
      width: 2rem;
      height: 2rem;
      fill: var(--white);
    }

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  .button-close-info {
    position: absolute;
    width: 50px;
    height: 40px;
    border: 1px solid var(--main-color);
    top: -40px;
    right: 0;
    background: var(--white);

    &::after {
      content: '';
      position: absolute;
      display: block;
      right: 12px;
      top: 6px;
      filter: invert(21%) sepia(81%) saturate(5979%) hue-rotate(339deg) brightness(83%) contrast(118%);

      @include fixedSprite(close);
    }

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  &__products {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 998;
    display: none;

    @include media-breakpoint-up(lg) {
      display: block;
      position: absolute;
      left: calc(100% + 4rem);
      width: 32rem;
      background: transparent;
      z-index: 2;
    }

    &.state-active {
      display: block;
    }

    &__wrp {
      display: flex;
      flex-direction: row;
      background: var(--white);
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      z-index: 1;
      padding: 2rem;
      border-top: 1px solid var(--main-color);

      @include media-breakpoint-up(lg) {
        display: block;
        position: relative;
        height: 100%;
        width: 100%;
        padding: 0;
        border: none;
      }
    }

    &__item {
      display: flex;
      flex-direction: column;
      width: 33.3333333%;
      flex: 0 0 33.3333333%;
      text-decoration: none;

      @include media-breakpoint-up(lg) {
        width: 25rem;
        position: absolute;
        background: var(--white);
      }

      &--1 {
        @include media-breakpoint-up(lg) {
          top: 0;
          left: 2.2rem;
          z-index: 1;
        }
      }
      &--2 {
        @include media-breakpoint-up(lg) {
          top: 50%;
          transform: translateY(-50%);
          right: 0;
          z-index: 2;
        }
      }
      &--3 {
        @include media-breakpoint-up(lg) {
          bottom: 0;
          left: 0;
          z-index: 3;
        }
      }

      &__head {
        order: 2;
        padding: 1.4rem 1.2rem 0 1.2rem;

        @include media-breakpoint-up(lg) {
          border: 1px solid var(--main-color);
          border-bottom: none;
          order: 0;
          padding: 0.6rem 0.8rem;
          display: flex;
          flex-direction: row;
        }

        &__title {
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: center;

          @include media-breakpoint-up(lg) {
            width: calc(100% - 6rem);
            flex: 0 0 calc(100% - 6rem);
            text-align: left;
          }
        }

        &__price {
          display: block;
          text-align: center;
          margin-top: 0.6rem;

          @include media-breakpoint-up(lg) {
            width: 6rem;
            flex: 0 0 6rem;
            text-align: right;
            margin: 0;
          }
        }
      }

      &__body {
        padding-bottom: 150%;
        height: 0;
        position: relative;
        border: 1px solid var(--main-color);
        margin-right: -1px;

        @include media-breakpoint-up(lg) {
          margin: 0;
          padding-bottom: 90%;
        }

        img {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: contain;
          z-index: 1;
        }
      }
    }
  }
}
