.m-xl-0 {
    margin: 0;
}
.mt-xl-0,
.my-xl-0 {
    margin-top: 0;
}
.mr-xl-0,
.mx-xl-0 {
    margin-right: 0;
}
.mb-xl-0,
.my-xl-0 {
    margin-bottom: 0;
}
.ml-xl-0,
.mx-xl-0 {
    margin-left: 0;
}
.m-xl-1 {
    margin: 0.25rem;
}
.mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem;
}
.mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem;
}
.mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem;
}
.ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem;
}
.m-xl-2 {
    margin: 0.5rem;
}
.mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem;
}
.mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem;
}
.mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem;
}
.ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem;
}
.m-xl-3 {
    margin: 1rem;
}
.mt-xl-3,
.my-xl-3 {
    margin-top: 1rem;
}
.mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem;
}
.mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem;
}
.ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem;
}
.m-xl-4 {
    margin: 1.5rem;
}
.mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem;
}
.mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem;
}
.mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem;
}
.ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem;
}
.m-xl-5 {
    margin: 3rem;
}
.mt-xl-5,
.my-xl-5 {
    margin-top: 3rem;
}
.mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem;
}
.mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem;
}
.ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem;
}
.p-xl-0 {
    padding: 0;
}
.pt-xl-0,
.py-xl-0 {
    padding-top: 0;
}
.pr-xl-0,
.px-xl-0 {
    padding-right: 0;
}
.pb-xl-0,
.py-xl-0 {
    padding-bottom: 0;
}
.pl-xl-0,
.px-xl-0 {
    padding-left: 0;
}
.p-xl-1 {
    padding: 0.25rem;
}
.pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem;
}
.pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem;
}
.pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem;
}
.pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem;
}
.p-xl-2 {
    padding: 0.5rem;
}
.pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem;
}
.pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem;
}
.pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem;
}
.pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem;
}
.p-xl-3 {
    padding: 1rem;
}
.pt-xl-3,
.py-xl-3 {
    padding-top: 1rem;
}
.pr-xl-3,
.px-xl-3 {
    padding-right: 1rem;
}
.pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem;
}
.pl-xl-3,
.px-xl-3 {
    padding-left: 1rem;
}
.p-xl-4 {
    padding: 1.5rem;
}
.pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem;
}
.pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem;
}
.pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem;
}
.pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem;
}
.p-xl-5 {
    padding: 3rem;
}
.pt-xl-5,
.py-xl-5 {
    padding-top: 3rem;
}
.pr-xl-5,
.px-xl-5 {
    padding-right: 3rem;
}
.pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem;
}
.pl-xl-5,
.px-xl-5 {
    padding-left: 3rem;
}
.m-xl-auto {
    margin: auto;
}
.mt-xl-auto,
.my-xl-auto {
    margin-top: auto;
}
.mr-xl-auto,
.mx-xl-auto {
    margin-right: auto;
}
.mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto;
}
.ml-xl-auto,
.mx-xl-auto {
    margin-left: auto;
}