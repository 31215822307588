.experience-assets-moduleClipboard {
  flex-direction: row;
  align-items: center;

  .module-clipboard-container {
    margin: 0;
  }
}

.popup-notification {
  &.module-clipboard-popup {
    width: fit-content;
    margin-top: 2rem;
  }
}