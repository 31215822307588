@import './mixins';

[data-component='Loyalty'] {
  [data-loyalty-properties] {
    @include loyalty-skeleton;

    padding-right: 3em !important;
    animation: loyalty-loading 3s ease-in-out infinite;
  }
}

.loyalty-template-skeleton {
  @include loyalty-skeleton;

  animation: loyalty-loading 3s ease-in-out infinite;
}

@keyframes loyalty-loading {
  0% {
    opacity: 0.1;
  }

  33% {
    opacity: 0.05;
  }

  100% {
    opacity: 0.1;
  }
}
