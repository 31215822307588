@import '../../mixins';
@import '../../../pages/account/mixins';

.magazine__carousel {
  color: var(--main-color);
  background: var(--main-color);
  margin: 0;
  padding: 4rem 0;

  @include media-breakpoint-up(lg) {
    padding: 5.5rem 0 8.2rem 0;
  }

  &__wrp {
    width: 100%;
    padding: 0 2rem;

    // TODO: Impostare come il container della pagina listing articles
    @include media-breakpoint-up(lg) {
      margin: 0 auto;
      width: calc(43% + 4rem);
      min-width: 66rem;
      max-width: 86.5rem;
    }

    a.heading > div {
      position: relative;
      z-index: 0;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        transition: transform var(--transition);
        transform-origin: left top;
        background-color: var(--main-color);
        border-left: none;
        transform: scaleX(0);
        z-index: -1;
      }

      &:hover {
        color: var(--main-color);

        &::before {
          transform: scaleX(1);
        }
        h3 {
          color: var(--white);
          svg {
            fill: var(--white);
          }
        }
      }
    }
  }

  &__badge {
    @include magazine-badge;
    color: var(--main-color);
    background: var(--white);
    max-width: calc(100% - 7rem);
    border: 1px solid var(--white);
    margin-left: 1px;

    a {
      text-decoration: none;
    }

    * {
      margin: 0;
      color: var(--main-color);
    }

    h3 {
      padding-right: 3rem;
      position: relative;

      &.no-r-padd {
        padding-right: 0;
      }
    }

    svg {
      display: block;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 2.4rem;
      height: 2.4rem;
      z-index: 1;
    }
  }

  &__box {
    border: 1px solid var(--main-color);
    margin-top: 0px;
    position: relative;

    * {
      margin: 0;
    }
  }

  &__item {
    display: block;
    border-right: 1px solid var(--main-color);
    background: var(--white);
    text-decoration: none;

    &__content-img {
      padding-bottom: 133.3333333%;
      height: 0;
      position: relative;

      img {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &__content-text {
      padding: 1.2rem;
    }

    &__text {
      display: block;
      padding: 0;
      margin: 0;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__navigations {
    position: absolute;
    top: -3.6rem;
    right: 0;
    z-index: 1;
    display: flex;
    flex-direction: row;
    width: 6.4rem;

    .swiper-button-prev,
    .swiper-button-next {
      width: 2.4rem;
      flex: 0 0 2.4rem;
      cursor: pointer;
    }

    .swiper-button-next {
      margin-left: auto;
    }

    svg {
      fill: var(--white);
      width: 2.4rem;
      height: 2.4rem;
    }
  }
}
