.experience-layout-modulePromotion {

  .experience-assetAndPanel {
    display: flex;
    justify-content: center;

    // IF NO ASSET IS LOADED, PROMOTIONBOX HAS FIXED WIDTH
    &:not(:has(img, video)) {
      .experience-assets-modulePromotionPanel {
        .experience-ancorAndPromotionBox {
          padding: 8rem 0;
          .experience-assets-modulePromotionBox {
            .experience-textAndButton {
              width: 62rem;
            }
          }
        }
      }
    }

    // IF ASSET IS CORRECTLY LOADED, ARE DISPLAYED 50% ASSET AND 50% PROMOTIONPANEL
    &:has(img, video) {
      .experience-assets-modulePromotionPanel {
        width: 50vw;
        max-width: 96rem;

        .experience-ancorAndPromotionBox {
          width: 100%;

          .experience-assets-modulePromotionBox {
            width: 100%;
          }
        }
      }

      img {
        display: block;
        width: 50vw;
        max-width: 96rem;
      }

      .pd-video__container {
        display: block;
        width: 50vw;
        max-width: 96rem;
      }
    }
  }
}