.footer {
  margin-top: 3rem;
}
.footer__boxes {
  &.footer__boxes--editorials {
    flex-flow: row nowrap;
    justify-content: space-around;
  }
  &.footer__boxes--link-list {
    grid-template-columns: repeat(4, 1fr);
    .footer__link-box-content {
      .footer__link-box-title {
        margin: 4rem 0 2rem 0;
      }
      .footer__link-box-links {
        .anchor-text {
          padding: 0;
          margin-bottom: 0.8rem;
        }
      }
      .ac-trigger {
        padding: 0;
        cursor: default;
        .icon-plus {
          display: none;
        }
      }
    }
  }
}
.footer__editorial-box {
  flex-grow: 1;
  flex-basis: 0;
  &:not(:last-child) {
    margin-bottom: 0;
    margin-right: 2rem;
  }
}
.container-img-cta_box-footer {
  padding: 0;
  height: 21vh;
  object-fit: cover;
  margin-top: auto;
  width: 100%;
  position: relative;
  .img_box_footer {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .button_img_box-footer {
    position: absolute;
    bottom: 2rem;
    left: 2rem;
    width: calc(100% - 4rem);
    margin-top: 0;

    &:not(:last-child) {
      bottom: 8rem;
    }
  }
  .container-img-cta_box-footer {
    height: 100%;
  }
}

.footer__boxes--link-list,
.footer__link-box {
  padding: 0 2rem;
}

.footer__boxes--link-list {
  .footer__link-box:not(:empty) {
    border-bottom: none;
  }
}
.footer__info {
  grid-template-areas: 'social copyright countries';
  border-top: 1px solid var(--border-color);
  margin-top: 4rem;
}
.footer__social {
  border-right: 1px solid var(--border-color);
  .footer__link-box-links {
    svg[class^='icon'] {
      display: block;
      width: 2.5rem;
      height: 2.5rem;
    }
    .anchor-text {
      margin: 2rem 0;
    }
  }
}
.footer__copyright {
  padding: 2rem;
  border-right: 1px solid var(--border-color);
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer__countries {
  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  button {
    @include resetButton;
    font-size: 1.4rem;
    color: var(--main-color);
  }
}
