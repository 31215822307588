@import '../../mixins';
@import '../../../pages/account/mixins';

.loyalty-experience__rooms__container{
  .loyalty-experience__questions__badge{
    background: var(--white);
    color: var(--main-color);
    .title,
    .loyalty-experience__rooms__item__icon{
      display: inline-block;
    }
  }

}

.loyalty-experience__rooms {
  color: var(--main-color);
  padding: 6rem 0 3.3rem;
  background: var(--main-color);

  @include media-breakpoint-up(lg) {
    padding: 6rem 0;
  }

  &__main {
    width: 100%;
    padding: 0 2rem;
    margin: 0 auto;
    max-width: 100rem;

    @include media-breakpoint-up(xl) {
      min-width: 100rem;
      max-width: 132rem;
      width: calc(69% + 4rem);
    }
  }

  &__intro {
    max-width: 62rem;
    margin: 0 auto 6rem auto;

    @include media-breakpoint-up(xl) {
      min-width: 62rem;
      width: 43%;
      max-width: 82.5rem;
    }
  }

  &__badge {
    @include loyalty-badge;
    padding: 0.8rem 1rem 0;

    h3 {
      font-size: 2.4rem;
      font-weight: 400;
    }

    border-color: var(--alternative-color);

    * {
      margin: 0;
      color: var(--alternative-color);
    }
  }

  &__box {
    @include loyalty-box;
    border-color: var(--alternative-color);
    padding: 1.6rem;

    * {
      margin: 0;
      color: var(--alternative-color);
    }
  }

  &__container {
    width: 100%;
    padding: 0 1rem;
    margin: 0 auto;
    max-width: 110rem;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      flex-wrap: nowrap;
      overflow-x: auto;
    }

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
      background: transparent;
    }

    @include media-breakpoint-up(lg) {
      min-width: 110rem;
      max-width: 146rem;
      width: calc(67% + 2rem);
    }

    .single-item {
      padding: 0 1rem;
      margin-bottom: 1.6rem;
      flex: 0 0 33.3333333%;
      min-width: 29rem;

      @include media-breakpoint-up(lg) {
        min-width: 33.3333333%;
        width: 33.3333333%;
        margin-bottom: 0;
        padding: 0 1rem 0 0;
      }
    }
  }

  &__item {
    border: 1px solid var(--alternative-color);
    background: var(--alternative-color);
    padding: 2rem;
    display: flex;
    flex-direction: column;
    height: 100%;

    &__points {
      color: var(--black);
      display: block;
      margin: 0 0 2.4rem 0;
    }

    &__title {
      display: block;
      @include secondaryFont();
      font-size: 3rem;
      line-height: 2.8rem;
      font-weight: 400;
      text-transform: uppercase;

      h3 {
        font-size: 3rem;
        margin: 0 0 1.6rem;
      }
    }

    &__element {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 2.4rem;
    }

    &__icon {
      display: block;
      width: 7.8rem;
      height: 7rem;
      flex: 0 0 2.4rem;
      margin-bottom: 2.4rem;
    }

    &__text {
      display: block;
      width: calc(100% - 2.4rem);
      flex: 0 0 calc(100% - 2.4rem);
      padding-left: 1.2rem;
      font-size: 1.6rem;
      margin: 0;
    }

    &__content-cta {
      text-align: left;
      margin-top: auto;
      padding-top: 1.6rem;

      &__cta {
        display: inline-block;
        padding-bottom: 0.1rem;
        border-bottom: 1px solid var(--main-color);
        text-decoration: none;
      }
    }

  }

  ul {
    color: var(--black);
    padding: 0 2rem;
  }

  .experience-loyalty_room_benefits {

    .li {
      display: list-item;
    }

    .not-list-item {
      text-align: center;
      .loyalty-experience__rooms__item__text {
        font-size: 1.4rem;
      }
    }

    .loyalty-experience__rooms__item {
      &__icon {
        display: block;
        width: 2.4rem;
        height: 2.4rem;
        flex: 0 0 2.4rem;
        margin-bottom: 0;
        margin-right: 1.2rem;
      }

      &__text {
        color: var(--black);
        padding-left: 0;
      }
    }

  }
}

.loyalty-box-button {
  text-align: center;
  padding-bottom: 3.2rem;

  @include media-breakpoint-up(lg) {
    padding-bottom: 6rem;
  }

  .button.secondary {
    padding: 1.3rem 4rem;
  }

  .button.secondary::before {
       background: none;
       color: initial;
       transition: none;
       transform: none;
     }

  .button.secondary:hover {
    color: var(--main-color);
  }
}
