
[data-scrollbar] {
    display: block;
    position: relative;
  }
  
  .scroll-content {
    display: flow-root;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  
  .scrollbar-track {
    position: absolute;
    opacity: 0;
    z-index: 1;
    background: #ededed;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    -webkit-transition: opacity 0.5s 0.5s ease-out;
            transition: opacity 0.5s 0.5s ease-out;
  }
  .scrollbar-track.show,
  .scrollbar-track:hover {
    opacity: 1;
    -webkit-transition-delay: 0s;
            transition-delay: 0s;
  }
  
  .scrollbar-track-x {
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
  }
  .scrollbar-track-y {
    top: 0;
    right: 0;
    width: 2px;
    height: 100%;
  }
  .scrollbar-thumb {
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 2px;
    background: var(--main-color);
    border-radius: 0;
  }