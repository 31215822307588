.m-md-0 {
    margin: 0;
}
.mt-md-0,
.my-md-0 {
    margin-top: 0;
}
.mr-md-0,
.mx-md-0 {
    margin-right: 0;
}
.mb-md-0,
.my-md-0 {
    margin-bottom: 0;
}
.ml-md-0,
.mx-md-0 {
    margin-left: 0;
}
.m-md-1 {
    margin: 0.25rem;
}
.mt-md-1,
.my-md-1 {
    margin-top: 0.25rem;
}
.mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem;
}
.mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem;
}
.ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem;
}
.m-md-2 {
    margin: 0.5rem;
}
.mt-md-2,
.my-md-2 {
    margin-top: 0.5rem;
}
.mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem;
}
.mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem;
}
.ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem;
}
.m-md-3 {
    margin: 1rem;
}
.mt-md-3,
.my-md-3 {
    margin-top: 1rem;
}
.mr-md-3,
.mx-md-3 {
    margin-right: 1rem;
}
.mb-md-3,
.my-md-3 {
    margin-bottom: 1rem;
}
.ml-md-3,
.mx-md-3 {
    margin-left: 1rem;
}
.m-md-4 {
    margin: 1.5rem;
}
.mt-md-4,
.my-md-4 {
    margin-top: 1.5rem;
}
.mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem;
}
.mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem;
}
.ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem;
}
.m-md-5 {
    margin: 3rem;
}
.mt-md-5,
.my-md-5 {
    margin-top: 3rem;
}
.mr-md-5,
.mx-md-5 {
    margin-right: 3rem;
}
.mb-md-5,
.my-md-5 {
    margin-bottom: 3rem;
}
.ml-md-5,
.mx-md-5 {
    margin-left: 3rem;
}
.p-md-0 {
    padding: 0;
}
.pt-md-0,
.py-md-0 {
    padding-top: 0;
}
.pr-md-0,
.px-md-0 {
    padding-right: 0;
}
.pb-md-0,
.py-md-0 {
    padding-bottom: 0;
}
.pl-md-0,
.px-md-0 {
    padding-left: 0;
}
.p-md-1 {
    padding: 0.25rem;
}
.pt-md-1,
.py-md-1 {
    padding-top: 0.25rem;
}
.pr-md-1,
.px-md-1 {
    padding-right: 0.25rem;
}
.pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem;
}
.pl-md-1,
.px-md-1 {
    padding-left: 0.25rem;
}
.p-md-2 {
    padding: 0.5rem;
}
.pt-md-2,
.py-md-2 {
    padding-top: 0.5rem;
}
.pr-md-2,
.px-md-2 {
    padding-right: 0.5rem;
}
.pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem;
}
.pl-md-2,
.px-md-2 {
    padding-left: 0.5rem;
}
.p-md-3 {
    padding: 1rem;
}
.pt-md-3,
.py-md-3 {
    padding-top: 1rem;
}
.pr-md-3,
.px-md-3 {
    padding-right: 1rem;
}
.pb-md-3,
.py-md-3 {
    padding-bottom: 1rem;
}
.pl-md-3,
.px-md-3 {
    padding-left: 1rem;
}
.p-md-4 {
    padding: 1.5rem;
}
.pt-md-4,
.py-md-4 {
    padding-top: 1.5rem;
}
.pr-md-4,
.px-md-4 {
    padding-right: 1.5rem;
}
.pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem;
}
.pl-md-4,
.px-md-4 {
    padding-left: 1.5rem;
}
.p-md-5 {
    padding: 3rem;
}
.pt-md-5,
.py-md-5 {
    padding-top: 3rem;
}
.pr-md-5,
.px-md-5 {
    padding-right: 3rem;
}
.pb-md-5,
.py-md-5 {
    padding-bottom: 3rem;
}
.pl-md-5,
.px-md-5 {
    padding-left: 3rem;
}
.m-md-auto {
    margin: auto;
}
.mt-md-auto,
.my-md-auto {
    margin-top: auto;
}
.mr-md-auto,
.mx-md-auto {
    margin-right: auto;
}
.mb-md-auto,
.my-md-auto {
    margin-bottom: auto;
}
.ml-md-auto,
.mx-md-auto {
    margin-left: auto;
}