@import '../../mixins';
@import '../../../pages/account/mixins';

.magazine__highlight-news {
  color: var(--main-color);
  background: var(--main-color);
  margin: 0;
  padding: 3.2rem 0 4rem 0;

  @include media-breakpoint-up(lg) {
    padding: 6rem 0;
  }

  &__wrp {
    width: 100%;
    padding: 0 2rem;

    // TODO: Impostare come il container della pagina Landing
    @include media-breakpoint-up(lg) {
      margin: 0 auto;
      width: calc(62% + 4rem);
      min-width: 89rem;
      max-width: 119rem;
    }

    a.heading > div {
      position: relative;
      background-color: var(--main-color);
      z-index: 0;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        transition: transform var(--transition);
        transform-origin: left top;
        background-color: var(--white);
        transform: scaleX(0);
        z-index: -1;        
      }
      
      &:hover {
        color: var(--white);

        &::before {
          transform: scaleX(1);          
        }

        h3 {
          color: var(--main-color);
          svg {
            fill: var(--main-color);
          }

        }
      }
    }
  }

  &__badge {
    @include magazine-badge;
    color: var(--white);
    background: var(--main-color);
    border-color: var(--white);
    max-width: 100%;
    padding-right: 0.8rem;
    margin: 0 auto;

    a {
      text-decoration: none;
      display: inline-block;
    }

    * {
      margin: 0;
      color: var(--white);
    }

    h3 {
      padding-right: 3rem;
      position: relative;

      &.no-r-padd {
        padding-right: 0;
      }
    }

    svg {
      display: block;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 2.4rem;
      height: 2.4rem;
      z-index: 1;
      fill: var(--white);
    }
  }

  &__box {
    border: 1px solid var(--white);
    margin-top: -1px;
    position: relative;
    background: transparent;

    @include media-breakpoint-up(lg) {
      display: flex;
      flex-direction: row;
    }

    * {
      margin: 0;
    }

    &__content-img {
      position: relative;
      height: 0;
      padding-bottom: 100%;
      border-bottom: 1px solid var(--white);

      @include media-breakpoint-up(lg) {
        width: 50%;
        flex: 0 0 50%;
        border-bottom: none;
        border-right: 1px solid var(--white);
        padding-bottom: 50%;
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      }
    }

    &__content-text {
      padding: 1.2rem;

      @include media-breakpoint-up(lg) {
        padding: 2rem;
      }

      * {
        color: var(--white);
      }
    }

    &__title {
      font-size: 1.8rem;
      line-height: 2.4rem;
      margin: 0;
    }

    &__text {
      display: block;
      padding: 0;
      margin: 1rem 0;
      text-align: left;
    }

    &__info {
      display: block;
      padding: 0;
      margin: 0;
      text-align: left;
    }
  }
}
