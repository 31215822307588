.search__container {
  input:focus {
    -webkit-tap-highlight-color: transparent;
    background-color: white;
  }
  input {
    background-color: white;
    outline: 0;
  }
  .form-element__search {
    border: 1px solid var(--main-color);
    border-radius: 0;
    padding: 1.3rem;
    color: var(--main-color);
    font-size: 1.4rem;
    width: 100%;
    background-repeat: no-repeat;
    background-size: 2rem 2rem;
    background-position: right 1rem center;
    &::placeholder {
      color: var(--main-color);
      font-size: 1.4rem;
    }
    &:placeholder-shown {
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.99 21.01l-5.22-5.3a8.27 8.27 0 001.95-5.35 8.36 8.36 0 10-8.35 8.36 8.26 8.26 0 005.34-1.95l5.21 5.24 1.07-1zM3.51 10.36a6.86 6.86 0 1113.72 0 6.86 6.86 0 01-13.72 0z' fill='%23e4002b' fill-rule='evenodd'/%3E%3C/svg%3E");
    }
    &::-webkit-search-cancel-button {
      cursor: pointer;
      -webkit-appearance: none;
      background-position: right 1rem center;
      margin-right: -0.5rem;
      content: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='UTF-8'?%3E%3Csvg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EIcons/Close%3C/title%3E%3Cg id='Icons/Close' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpath d='M18.8837209,4 L20,5.11627907 L13.116,11.999 L20,18.8837209 L18.8837209,20 L11.999,13.116 L5.11627907,20 L4,18.8837209 L10.883,12 L4,5.11627907 L5.11627907,4 L12,10.883 L18.8837209,4 Z' id='Combined-Shape' fill='%23E4002B'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
    }
  }
}
.side-panel--search_header,
.navigation-categories {
  .search__container {
    .form-element__label,
    .form-element__label_mobile {
      width: fit-content;
      &.search-animation {
        white-space: nowrap;
        overflow: hidden;
        top: 1.5rem;
        left: 1.5rem;
        animation: type 2s steps(80, end);
        animation-fill-mode: forwards;
      }
      .form-element__label_mobile.search-animation{
        white-space: nowrap;
        overflow: hidden;
        top: 1.5rem;
        left: 1.5rem;
        animation: type 2s steps(80, end);
        animation-fill-mode: forwards;
      }
      @keyframes type {
        1% {
          width: 0;
        }
        99.9% {
          border-right: 1px solid var(--main-color);
        }
        100% {
          border: none;
        }
      }
    }
    .form-element__search {
      &::placeholder {
        opacity: 0;
      }
    }
  }
}

