@import '../../mixins';
@import '../../../pages/account/mixins';

.loyalty-experience__questions {
  color: var(--main-color);
  padding: 3.2rem 0;

  @include media-breakpoint-up(lg) {
    padding: 6rem 0;
  }

  &__main {
    width: 100%;
    padding: 0 2rem;
    max-width: 66rem;
    margin: 0 auto;

    @include media-breakpoint-up(xl) {
      min-width: 66rem;
      width: 43%;
      max-width: 86.5rem;
    }
  }

  &__badge {
    @include loyalty-badge;

    border: none;
    margin-bottom: 2.4rem;
    padding: 0;

    h3 {
      color: var(--black);
      font-size: 2.4rem;
      font-weight: 400;
      line-height: 2.2rem;
    }

    * {
      margin: 0;
    }
  }

  &__item {
    border-bottom: 1px solid var(--light-grey);
    margin-top: -1px;
    padding: 1.8rem 0;

    &__values {
      * {
        margin: 1.6rem 0 0.5rem 0;
      }
    }

    .ac-trigger {
      text-align: left;
    }

    .ac-text {
      color: var(--alternative-grey);
    }

  }

  &__item:last-child {
    border-bottom: none;
  }
}

.pd-text {
  p {
    margin: 0;

    @include media-breakpoint-up(lg) {
      margin: 0 0 3rem;
    }
  }
}
