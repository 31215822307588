.modulePLPBanner {

  .main-container {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .background-container{
      display: block;
      width: 100%;

      img{
        object-fit: cover;
        width: 100%;
      }
    }
    .box-container{
      display: block;
      position: absolute;
      background-color: var(--white);
      border:1px solid var(--main-color);
      padding: 15px;
      width: calc(100% - 40px);

      &.style-primary{
        height: 180px;

        .pd-text {

          p {

            &:first-child {
              @include secondaryFont();
              height: auto;
              font-size: 32px;
              line-height: 30px;
            }
          }
        }
      }

      &.style-secondary{
        height: 165px;

        .pd-text {

          p {

            &:first-child {
              @include primaryFont();
              height: auto;
              font-size: 18px;
              line-height: 18px;
              letter-spacing: .2px;
            }
          }
        }
      }

      .pd-text {

        p {
          color: var(--main-color);
          margin: 0;
          @include primaryFont();
          font-size: 14px;
          line-height: 20px;
          letter-spacing: .2px;
          height: 60px;
          overflow: hidden;

          &:first-child {
            margin-bottom: 5px;

          }

        }
      }

      button, a {
        margin-top: 15px;
        position: absolute;
        bottom: 15px;
      }

    }
  }
}