.module-11 {
  max-width: calc(100% - 4rem);
  --dark-color: var(--main-color);
  --light-color: var(--alternative-color);
  margin: 0 auto 2rem;
  .module-11__title {
    border: 1px solid var(--dark-color);
    border-bottom: none;
    background-color: var(--light-color);
    color: var(--dark-color);
    padding: 0.8rem 1rem 0.2rem 0.8rem;
    width: fit-content;
    display: flex;
    align-items: center;
    .pd-heading {
      font-size: 2.4rem;
      line-height: 2.9rem;
      @include secondaryFont();
      margin: 0;
      color: var(--dark-color);
    }
    .pd-icon {
      margin-left: 1rem;
      svg[class^='icon'] {
        width: 2.4rem;
        height: 2.4rem;
      }
    }
    &:hover {
      border: 1px solid var(--main-color);
      border-bottom: none;
      .pd-icon {
        svg[class^='icon'] {
          fill: var(--main-color);
        }
      }
    }
  }
  .module-11__link {
    text-decoration: none;
    width: fit-content;

    &:hover .module-11__title {
      border-color: $white;
      border-bottom: none;
    }
    .module-11__title {
      height: auto;
      flex-direction: unset;

      &:hover {
        .pd-icon {
          svg[class^='icon'] {
            fill: var(--white);
          }
        }
      }
    }
    .icon-button-section-module-description {
      margin-left: 0.8rem;
    }
  }
  .module-11__container-img {
    width: 100%;
    border: 1px solid var(--main-color);
    margin-bottom: -1px;
    .module-11__img {
      display: block;
      width: 100%;
    }
  }
  .module-11__text {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    position: relative;
    height: 100%;
    
    .pd-text {
      margin: 1.6rem 0 2rem 0;
      strong em u {
        background-color: var(--main-color);
        color: var(--alternative-color);
        font-style: normal;
        font-weight: 500;
        text-decoration: none;
        padding: 0 0.2rem;
      }
    }
    border: 1px solid var(--dark-color);
    // color: var(--light-color);
    color: var(--dark-color);
    background-color: var(--light-color);
    p {
      margin: 0;
    }
    .button.tag {
      margin: 1.2rem;
      border: 1px solid var(--dark-color);
      color: var(--dark-color);
      margin: 0 1.2rem 1.2rem 0;
    }
    .anchor-text {
      margin-bottom: 1.2rem;
    }
    .button {
      margin-right: 1.2rem;
      margin-bottom: 1.6rem;
    }
    .pd-countdown__container {
      position: absolute;
      bottom: 1.2rem;
      color: var(--main-color);
      letter-spacing: 0.2px;
    }
  }

  .module-11__multiple-title {
    .module-11__link {
      .module-11__title {
        &:hover .pd-heading__container .pd-heading {
          box-shadow: 0 0 0 1px var(--light-color);
          background-color: var(--dark-color);
          color: var(--light-color);
        }
      }
    }
    .module-11__title {
      border: none;
      background: transparent;
      padding: 0;
      color: inherit;
      width: 100%;
      white-space: initial;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      :not(:first-child){
        margin-top: -1px;
      }
      :last-child {
        margin-bottom: -1px;
      }


      &--button {
        .pd-heading__container {
          .pd-heading {
            &::before {
              background-color: var(--dark-color);
            }
          }
        }
        &:hover .pd-heading__container .pd-heading {
          color: var(--light-color);
          background-color: transparent;
          box-shadow: 0 0 0 1px var(--light-color);
        }
      }
    }

    .pd-heading__container {
      padding: 0;
      width: 100%;
      display: block;

      .pd-heading {
        line-height: 1.2;
        background-color: var(--light-color);
        margin: 0;
        padding: 0 0.8rem;
        -webkit-box-decoration-break: clone;
        box-decoration-break: clone;
        border: 1px solid var(--dark-color);
        padding-top: 0.4rem;
        display: inline-block;
      }
    }
  }
  .container-module-11 {
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
    height: 100%;

    &--ordered {
      .module-11__container-img-link {
        order: 1;
      }
      .module-11__container-img {
        margin-bottom: 0;
        margin-top: -1px;
        order: 1;
      }
    }

    &.module-11-variant-2 {
      .module-11__title, .module-11__text {
        border: none;
      }
      .module-11__title {
        width: 100%;
        justify-content: flex-start;
      }
      .module-11__text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-size: 1.4rem;
        .pd-text {
          margin: 2rem 0;
        }
      }
    }
  }
}

.module-11.negative {
  --dark-color: var(--alternative-color);
  --light-color: var(--main-color);
  --border-color: var(--alternative-color);
  .module-11__title {
    .pd-icon {
      svg[class^='icon'] {
        fill: var(--white);
      }
    }
    &:hover {
      border: 1px solid $white;
      border-bottom: none;
    }
  }
  .module-11__multiple-title {
    .module-11__title {
      &:hover {
        border: none;
      }
    }
  }
  .container-module-11 {
    padding: 1px 0;
  }
  .module-11__text {
    .pd-text p,
    .pd-text p a,
    .pd-countdown__container {
      color: var(--alternative-color);
    }
    p {
      color: inherit;
      strong {
        em {
          background: var(--main-color);
          color: var(--alternative-color);
        } 
      }
      a {
        color: var(--alternative-color);
        position: relative;
        text-decoration: none;
        width: fit-content;

        &::after {
          content: ' ';
          position: absolute;
          left: 0;
          top: 100%;
          width: 100%;
          height: 1px;
          background-image: linear-gradient(90deg, var(--alternative-color), var(--alternative-color));
          background-size: 6px 1px;
          transition: 500ms;
        }

        &:hover,
        &:focus,
        &:active {
          &::after {
            background-image: linear-gradient(90deg, var(--alternative-color), var(--alternative-color) 75%, transparent 75%, transparent 100%);
            animation-duration: 2s;
            animation-name: animateBorder;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
          }
        }

        u {
          text-decoration: none;
        }
      }
    }
  }
  .module-11__container-img {
    border-color: var(--alternative-color);
  }
  .module-11__link {
    &:hover .module-11__title {
      border-color: var(--main-color);
      border-bottom: none;
    }
    .module-11__title {
      &:hover {
        --secondary-color: var(--alternative-color);
        .pd-heading {
          color: var(--main-color);
        }
        .pd-icon {
          svg[class^='icon'] {
            fill: var(--main-color);
          }
        }
      }
    }
  }
}

.module-11_container {

  &.module-12 {
    .module-11_items_container {
      .module-11 {
        .container-module-11 {
          .module-11__text {
            .button {
              padding: 0 1.6rem;
            }
          }
        }
      }
    }
  }

  &.module-13A {
    .module-11_items_container {
      .swiper-slide {
        margin-bottom: 2.4rem;
        .container-module-11 {
          padding-top: 0;
          .module-11__text {
            .button {
              width: fit-content;
            }
          }
        }
      }
    }
  }
}