.experience-layout-productCarousel {

  &.bg-red{
    background-color: var(--main-color);

    .pd-product-carousel__title{
      background-color: var(--white);
    }

    .swiper-main-button{

      svg{
        fill: var(--white);
      }
    }

    .pd-product-carousel__toggleContainer{
      border-color: var(--white);
    }
  }

  &.bg-white{
    background-color: var(--white);
  }

  &.bg-offwhite{
    background-color: var(--lighting-grey);

    .pd-product-carousel__title{
      background-color: var(--white);
    }
  }

  .background-container{
    position: relative;
    overflow: hidden;
    padding-top: 4rem;
    padding-bottom: 4rem;

    .swiper-main-button{
      top: 50px;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    top: 10px;

    &:after {
      display: none; // hot-fix, overwriting swiper.css in module8
    }

    &:hover {
      cursor: pointer;
    }

    svg {
      width: 24px;
      height: 24px;
    }

    &.disappear{
      display: none;
    }
  }

  .swiper-button-next {
    right: 6px;
  }
  .swiper-button-prev {
    left: auto;
    right: 33px;
  }

  .pd-product-carousel__title {
    border: 1px solid var(--main-color);
    border-bottom: 0;
    padding: 0.6rem 0.8rem;
    width: fit-content;

    &.noBorder{
      border: none;
      height: 4.75rem;
    }

    a {
      text-decoration: none;
      display: flex;
      align-items: center;
    }

    svg {
      width: 24px;
      height: 24px;
      margin-left: 5px;
    }

    h3 {
      font-size: 3rem;
      margin: 0;
    }
  }
}
.pd-c-container {
  .experience-assets-product {
    flex-shrink: 0;
    width: 100%;
    height: auto;
    position: relative;
    transition-property: transform;

    .product {
      border-right: 1px solid var(--main-color);
      height: 100%;
      .swiper-slide {
        border: none;
      }
    }
  }

  .product-tile {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    padding: 0;

    .tile-image-without-carousel {
      img {
        height: 100%;
        object-fit: contain;
      }
    }
  }

  &.swiper-container {
    background-color: var(--white);
    width: 100%;
    max-width: 100vw;
    border: 1px solid var(--main-color);
    overflow: hidden;
  }

  .swiper-slide {
    height: auto;
    border-right: 1px solid var(--main-color);
    box-sizing: border-box;
    flex: 0 0 auto;
    min-width: 116px;

    .product,
    .product-tile {
      height: 100%;

      .swiper-slide {
        border: 0;
      }
    }

    /* Required in order to avoid CLS */
    .vf-image-ratio {
      aspect-ratio: 2/3; // This is important to preload the required space.
      width: 100%;
      height: 17.3rem;
      max-height: 17.3rem;
      object-fit: contain;
    }

    &.is-view-all {
      display: flex;
      align-items: center;

      &.vf-slide-active {
        background-color: var(--main-color);
      }

      .single-item {
        justify-content: center;
        align-items: center;
        height: 100%;

        &:hover {
          background-color: var(--main-color);
        }

        .content-text {
          border: 0;
          background-color: transparent;
        }
      }
    }
  }

}
.product-carousel-container {
  .product-carousel-title {
    width: fit-content;
    border: 1px solid var(--main-color);
    border-bottom: 0;
    margin-bottom: 0;
    padding: 0 0.6rem 0.6rem;
    font-size: 3.2rem;
  }
}

.adobe-recommendations {
  &.recommendations {
    .product-carousel-products {
      flex-direction: column;
    }
  }
}

.empty-cart__carousel {
  .product-carousel-title {
    &.recently-view-title {
      font-size: 2rem ;
    }
  }
}

.product-tile-image {
  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    top: 50%;
    z-index: 2;
    display: none;
    svg {
      width: 24px;
      height: 24px;
      fill: var(--main-color);
    }
  }
  .swiper-button-prev {
    left: 1rem;
  }
  .swiper-button-next {
    right: 1rem;
  }
  &:hover {
    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }
  }
}
.swiper-wrapper {
  &.no-transform {
    transform: none !important;
  }
}
.cart-wishlist,
.cart-recommender {

  &.hidden, .hidden {
    display: none;
  }

  &.pd-c-container {
    background-color: var(--white);
    .pd-c-wrapper {
      .product {
        border: none;
        .product-tile-body {
          padding: 1.6rem 1.9rem 1.6rem 1.2rem;

          .container-badges {
            display: none;
          }

          .section-info-single-product-plp {
            width: 70%;

            @media (max-width: 991px) {
              width: 100%;
            }
          }

          .open-attributes {
            bottom: 0;
            right: 1.9rem;
            top: 40%;
            transform: translateY(-50%);
          }
        }
      }
      @media (max-width: 991px) {
        .open-attributes{
          display: none;
        }
      }
    }
  }

}

.pd-carousel__products-area {
  background-color: var(--white);

  .pd-c-container {
    border-top: 1px solid var(--main-color);
    border-bottom: 1px solid var(--main-color);
  }
}

.pd-product-carousel__toggleContainer{
  display: flex;
  justify-content: flex-start;
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding: 0.2rem;
  border: 1px solid var(--main-color);
  width: fit-content;

  .pd-product-carousel__mainToggle,
  .pd-product-carousel__alternativeToggle{
    width: auto;
    border: none;
    outline: none;
    cursor: pointer;
    text-decoration: none;
    --primary-color: var(--main-color);
    --secondary-color: var(--alternative-color);
    white-space: nowrap;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.4rem 1.25rem;
    height: 4.4rem;
    font-size: 1rem;
    line-height: 1.6rem;
    position: relative;
    overflow: hidden;
    z-index: 0;
  }

  .activeToggle{
    color: var(--secondary-color);
    background-color: var(--primary-color);
  }

  .notActiveToggle{
    color: var(--primary-color);
    background-color: var(--secondary-color);
  }
}

.pd-product-carousel__container2 {

  .pd-c-container{
    border-left: 1px solid var(--main-color);

    .product-tile-image{
      .swiper-slide{
        padding-top: 1.4rem;
        padding-left: 1.4rem;
        padding-right: 1.4rem;
          img {
            height: 100%;
            object-fit: contain;
          }
      }
    }
  }

  .product-tile-body {
    padding: 1.6rem 1.9rem 1.6rem 1.2rem;

    .section-price-single-product-plp {
      margin-bottom: 0;
    }

    .product-tile__actions {
      .section-info-single-product-plp {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        white-space: nowrap;
      }

      .section-price-single-product-plp {
        margin-bottom: 2.4rem;
      }
    }
  }

  .open-attributes{
    display: none;
  }

  .tile-image-link{
    padding-top: 123%;
  }

  .section-colors-single-product-plp {
    margin-top: 0;
  }
}
