.module-static-plp--15 {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .element-wrapper {
    width: 25rem;
    margin: 6rem 0;
  }

  .module-static_content-title {
    padding: 1.3rem 1.1rem;
    border: 1px solid var(--main-color);
    border-bottom: 0;
    .item-title {
      font-size: 1.4rem;
      margin: 0;
      word-wrap: break-word;
    }
  }
  .module-static_content-button {
    padding: 1.3rem 1.1rem;
    border: 1px solid var(--main-color);
    a {
      padding: 1.2rem;
      margin: 0 0.8rem 1.2rem 0;
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      height: auto;
      &.module-static_button-img {
        display: flex;
        align-items: center;
        flex-direction: unset;
        justify-content: unset;
        padding: 0 1.1rem 0 0;
        width: fit-content;
        img {
          width: 3.9rem;
          height: 3.9rem;
          margin-right: 1.1rem;
        }
      }
    }
  }
}
