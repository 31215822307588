.experience-assets-quickLinkBox{

  .pd-quick-link-box {
    align-items: center;

    .pd-quick-link-box-header {
      text-align: center;
      flex-direction: column;
    }

    img {
      margin-right: 0;
      margin-bottom: 0.8rem;
    }
  }
}
