.swiper-container {
  .product-tile--add-to-bag {
    display: block;
  }
}

.product-grid {
  .product-tile--add-to-bag {
    display: block;

    .open-attributes {
      bottom: 1.6rem;
      right: 1.6rem;
    }
  }
}
