.loyalty-registration__row,
.registration-form {
    .dob {
        &__wrapper {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;

          select {
            max-width: 30%;
            margin: 0;

            @at-root .order-conf-registration-form {
                .dob__wrapper {
                    select {
                        border-bottom: 1px solid var(--black);
                        background-image: none;
                    }
                }
            }
          }

          .form-element__error {
            &--is-invalid {
              display: inline-block;
              opacity: 1;
              transform: translateY(0);
              height: auto;
              margin-top: 1rem;                
            }
          }
        }
    }    
}