.module-16_container {
  padding-top: 6rem;
  padding-bottom: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &.red-text{
    p,h1,h2,h3,h4{
      color: var(--main-color);
    }
  }
  &.black-text{
    p,h1,h2,h3,h4{
      color: var(--black);
    }
  }
  &.white-text{
    p,h1,h2,h3,h4{
      color: var(--white);
    }
  }
  &.white-bg {
    background-color: var(--white);
  }
  &.red-bg {
    background-color: var(--main-color);
  }
  &.off-white-bg {
    background-color: var(--lighting-grey);
  }
}

.module-16_item-container {
  max-width: calc(100% - 4rem);
  //--dark-color: var(--main-color);
  //--light-color: var(--alternative-color);

  p {
    @include secondaryFont();

    margin: 0;
    font-size: 2.4rem;
    line-height: 3rem;

    strong em u {
      //background-color: var(--main-color);
      //color: var(--alternative-color);
      font-style: normal;
      font-weight: 600;
      text-decoration: none;
      padding: 0 0.2rem;
    }
  }
}
