.experience-assets-contactUsBox {
  height: 100%
}

.pd-contact-us-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  p {
    margin-top: 0;
    margin-bottom: 0.8rem;

    &:nth-child(2) {
      margin-bottom: 2.4rem;
    }
  }

  .row {
    margin: 0;

    .pd-button-wrapper {
        margin-right: 0.8rem;
        margin-bottom: 0.8rem;
    }

    a, button {
      padding: 0.7rem 1.6rem;
    }

    .button__icon {
      svg {
        margin: 0 0.4rem 0 0;
        width: 2.4rem;
        height: 2.4rem;
      }
    }
  }
}