@use 'sass:math';

@import '../helpers/z-index';
@mixin darkMode {
  @media (prefers-color-scheme: dark) {
    @content;
  }
  .dark & {
    @content;
  }
}
@mixin lightMode {
  @media (prefers-color-scheme: light) {
    @content;
  }
  .light & {
    @content;
  }
}
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}
@mixin fixedSprite($name) {
  $sprite: map-get($sprites, $name);
  background: url($sprite) center no-repeat;
  @if map-has-key($sizes, $name) {
    $size: map-get($sizes, $name);
    width: (math.div(strip-unit(map-get($size, width)), 10)) * 1rem;
    height: (math.div(strip-unit(map-get($size, height)), 10)) * 1rem;
  }
  display: inline-block;
}
@mixin sprite($name) {
  $sprite: map-get($sprites, $name);
  background: url($sprite);
}
@mixin z($name: default) {
  z-index: map-get($z-layers, $name);
}
@mixin fullViewportHeight() {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}
@mixin minFullViewportHeight {
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
}

@mixin custom-checkbox($border-color: var(--main-color), $check-color: white) {
  margin-top: 2rem;
  position: relative;
  input {
    &[type='checkbox']:not(:checked),
    &[type='checkbox']:checked {
      position: absolute;
      left: -9999px;
    }

    &[type='checkbox']:not(:checked) + label,
    &[type='checkbox']:checked + label {
      color: black;
      position: relative;
      padding-left: 3rem;
      cursor: pointer;
      display: inline-block;
    }

    &[type='checkbox']:not(:checked) + label::before,
    &[type='checkbox']:checked + label::before {
      content: '';
      position: absolute;
      top: -0.2rem;
      left: 0;
      border: 0.1rem solid $border-color;
      height: 1.5rem;
      width: 1.5rem;
      transition: all 0.2s;
      padding: 0;
    }

    &[type='checkbox']:not(:checked) + label::after,
    &[type='checkbox']:checked + label::after {
      color: $check-color;
      content: '';
      position: absolute;
      top: -0.1rem;
      left: 0.5rem;
      height: 1rem;
      width: 0.6rem;
      transition: all 0.2s;
      font-size: 2rem - 2;
      border: 0.1rem solid $check-color;
      border-width: 0 0.1rem 0.1rem 0;
      padding: 0;
    }

    &[type='checkbox']:not(:checked) + label::after {
      transform: rotate(45deg) scale(0);
      opacity: 1;
    }
    &[type='checkbox']:checked + label::after {
      transform: rotate(45deg) scale(1);
      opacity: 1;
    }
    &[type='checkbox']:checked + label::before {
      background-color: var(--main-color);
    }
  }

  label {
    padding-left: 3rem;
  }
}

@mixin custom-radio($border-color: var(--main-color), $check-color: var(--main-color)) {
  margin-bottom: 4rem;
  margin-top: 2rem;
  position: relative;

  input {
    &[type='radio']:not(:checked),
    &[type='radio']:checked {
      position: absolute;
      left: -9999px;
    }

    &[type='radio']:not(:checked) + label,
    &[type='radio']:checked + label {
      color: black;
      position: relative;
      padding-left: 3rem;
      cursor: pointer;
      display: inline-block;
    }

    &[type='radio']:not(:checked) + label::before,
    &[type='radio']:checked + label::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      border: 0.2rem solid $border-color;
      border-radius: 50%;
      height: 1.6rem;
      width: 1.6rem;
      transition: all 0.2s;
      padding: 0;
      transform: translateY(-50%);
    }

    &[type='radio']:not(:checked) + label::after,
    &[type='radio']:checked + label::after {
      color: $check-color;
      background-color: $check-color;
      content: '';
      position: absolute;
      top: 50%;
      left: 3.5px;
      border-radius: 50%;
      height: 1.2rem;
      width: 1.2rem;
      transition: all 0.2s;
      font-size: 2rem - 2;
      border-width: 0 0.2rem 0.2rem 0;
      padding: 0;
      transform: translateY(-50%);
    }

    &[type='radio']:not(:checked) + label::after {
      transform: translateY(-50%) scale(0);
      opacity: 1;
    }
    &[type='radio']:checked + label::after {
      transform: translateY(-50%) scale(1);
      opacity: 1;
    }
  }

  label {
    padding-left: 3rem;
  }
}

@mixin sr-only() {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px; // Fix for https://github.com/twbs/bootstrap/issues/25686
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

@mixin mq-short-screen($max-height: 850px) {
  @media screen and (max-height: $max-height) {
    @content;
  }
}
