.product-tile--mini {
  .product-tile__actions {
    padding: 0;

    .product-tile__actions-content{
      display: flex;
      flex-direction: column;

      div {
        order: 3;
      }

      .preorder-eta-release-date {
        display: flex;
        padding: 0.8rem;
        margin: 1.6rem;
        margin-top: 0;
        background: rgba(237, 237, 237, 0.50);
        gap: 0.8rem;
        align-items: center;

        svg {
          width: 1.6rem;
          height: 1.6rem;
          color: var(--black);
        }

        .preorder-eta-release-date-text {
          font-family: BananaGrotesk, sans-serif;
          font-size: 12px;
        }
      }
      
      .plp_prices {
        width: 100%;
        .product-tile-body__price {
          display: flex;
          flex-direction: column;

          &.discounted-container {
            flex-wrap: wrap;
            margin: 0;

            .value {
              order: 4;
            }
          }
        }
      }

      .section-info-single-product-plp-container {
        border-bottom: 1px solid var(--main-color-opacity);
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        padding: 1rem 1.6rem;
        order: 1;

        .section-info-single-product-plp {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          width: 50%;
        }

        .section-conditions-single-product-minitile {
          width: 100%;
          margin-top: 0.8rem;
          color: var(--main-color);
          font-size: 1.2rem;
          display: flex;
          align-items: center;

          span {
            display: flex;
          }

          .second-hand__conditions__stars {
            margin-right: 1rem;

            .icon-star {
              width: 1.2rem;
              height: 1.2rem;
            }
          }
        }
      }

      .variation-attribute{
        &.color{
          order: 2;
          padding: 1rem 1.6rem;
          margin: 0;
          border-bottom: 1px solid var(--main-color-opacity);

          .variation-attribute-color-container {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .swatch-attribute-values{
              flex-flow: row nowrap;
              width: auto;
              gap: 1rem;
            }

            .other-colors {
              order: 4;
              color: var(--black);
              text-decoration: none;
              line-height: 1.5;
            }

            .attribute-label {
              display: flex;
              gap: 0.2rem;
              margin: 0;

              &.color {
                max-width: 48%;
              }

              span {
                line-height: 1;
              }
            
              .selected-color {
                display: block;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                font-weight: bold;
              }
            }

            .attribute-value--swatch {
              margin: 0;
              width: 2.4rem;
              height: 2.4rem;
            }

          }
        }

        &.size,
        &.length {
          padding-top: 1rem;
          margin: 0;

          &.single-attribute {
            margin: 0;
          }

          .inline-size {
            display: flex;
            align-items: center;
            .attribute-label {
              margin: 0;
              line-height: 1;
            }
            .attribute-values {
              display: block;
              margin: 0;

              &.single {
                .attribute-value {
                  background-color: transparent;
                  color: var(--black);
                }
              }

              .attribute-value {
                border: 0;
                margin: 0;
                min-height: unset;
                height: auto;
                padding: 0;
              }
            }
          }

          .title-container--size,
          .title-container--length {
            padding: 0 1.6rem;
          }

          .attribute-values {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(max(5rem, 100% / 6), 1fr));
            grid-gap: 0px;
            margin-top: 0.8rem;

            .error-label-wrapper {
              grid-column: 1 / 4;
              transform: translate(1rem, 2.5rem);

              .error-label {
                margin: 0 0 0.8rem;
              }
            }

            .attribute-value {
              margin-bottom: 0;
              min-height: 2.8rem;
              border-left: 0;
              margin-top: -1px;
              

              &.unavailable:not(.selected) {
                color: var(--black);
              }

              &.selected {
                color: var(--white);
              }
            }

            &.pdp-longtext-sizes {
              display: flex;
              flex-wrap: wrap;
              .pdp-longtext-attribute {
                flex: 1 0 50%;
                max-width: 50%;
              }
            }
          }
        }

      }

      .add-to-cart {
        margin: 0;
        margin-top: 1rem;

        button {
          height: 3.2rem;
        }
      }

      .section-price-single-product-plp {
        margin: 0;
      }

      .js_additional-msg-container {
        .additional-msg {
          margin: 0;
          padding-bottom: 0;
          padding: 0.8rem 1.6rem 0;
          color: var(--main-color);
        }
      }
    }
  }

  .product-tile-body {
    .container-price-name-carousel {
      .section-colors-single-product-plp {
        display: none;
      }
    }
    .section-info-single-product-plp {
      width: calc(100% - 4rem);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .section-price-single-product-plp {
      margin: 0;
    }
    .container-badges {
      display: none;
    }
  }
}