.module-01__slide > .module01_half_full {
  justify-self: end;
  margin-right: 5rem;
}
.module-01__slide > .module01_half_full ~ .module01_half_full {
  justify-self: start;
  margin-left: 5rem;
}

.visual {
  margin: 0;
  display: block;
  width: 100%;
  height: 100%;

  &.tile,
  &.module01_fullbleed,
  &.module01_half,
  &.module01_half_v2,
  &.module01_fullbleed_focal {
    picture {
      display: block;
      height: 100%;
      width: auto;
      border: 1px solid var(--border-color);
      .visual__image {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &.module01_half_full {
    width: fit-content;
    height: fit-content;
    img {
      max-width: 35vw;
      border: 1px solid var(--border-color);
    }
    @media (min-width: 990px) {
      margin-top: 10rem;
      margin-bottom: 5rem;
    }
  }

  &.tile_vertical,
  &.tile_rescale {
    picture {
      display: block;
      height: 100%;
      width: fit-content;
      margin: auto;
      border: 1px solid var(--border-color);
      max-width: 50vw;
      margin: 10% 25%;
      .visual__image {
        display: block;
        object-fit: cover;
      }
    }
  }
}
