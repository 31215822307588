.pd-accordions-container {
  .title {
    margin-top: 0;
    margin-bottom: 1.6rem;
  }

  .row {
    margin: 0;
  }

  .experience-assets-accordion {
    &:not(:last-child):not(:only-child)::after {
      content: '';
      display: block;
      margin: 0.8rem 0;
      width: 100%;
      border-top: 1px solid var(--light-grey);
    }
  }
}
