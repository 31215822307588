.module-static-plp--09 {
  position: relative;

  .content-heading {
    display: block;
    padding: 0;
    font-size: 0;
    text-align: left;

    .heading-link {
      display: inline-block;
      padding: 0 0.8rem;
      border: 1px solid var(--border-color);
      border-bottom: none;
      position: relative;
      max-width: 100%;
    }

    .element-heading {
      display: inline-block;
      vertical-align: middle;
      font-size: 3rem;
      margin: 0;
      max-width: 100%;

      span {
        display: inline-block;
        vertical-align: middle;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding-right: 3rem;
        font-size: 3rem;
        max-width: 100%;
      }
    }

    .icon-arrow-fill-down {
      display: block;
      width: 2.4rem;
      height: 2.4rem;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0.8rem;
      z-index: 1;
    }
  }

  .element-scrollable {
    display: block;
    overflow-x: auto;
    width: calc(100% + 4rem);
    margin-right: -2rem;
    margin-left: -2rem;
  }

  .element-wrapper {
    display: flex;
    flex-direction: row;
    padding: 0 0 0 2rem;
    width: 100%;
    white-space: nowrap;
    overflow: visible;

    .single-item {
      display: flex;
      flex-direction: column;
      width: 35%;
      flex: 0 0 35%;
      margin-right: -1px;
      position: relative;

      &:hover,
      &--active {
        .content-img:after {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 2;
          opacity: 0.2;
          background: var(--black);
        }
      }
    }
  }

  a {
    text-decoration: none;
  }

  .content-img {
    width: 100%;
    flex: 1;
    border: 1px solid var(--border-color);
    border-bottom: none;
    position: relative;

    img {
      display: block;
      width: 100%;
    }
  }

  .content-text {
    display: block;
    border: 1px solid var(--border-color);
    border-top: none;
    background: var(--white);
    padding: 1.2rem;

    .text {
      display: block;
      font-size: 1.4rem;
      text-align: center;
      margin: 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
