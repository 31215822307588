.pd-accordion {
  .accordion-item {
    .ac-header {
      padding: 1rem 0;

      button {
        text-align: left;
      }
    }
  }
}