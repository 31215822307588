.container-price-name-carousel {
  display: flex;
  flex-direction: column;

  .section-price-single-product-plp {
    margin-bottom: 0.5rem;
    padding-right: 4rem;
  }

  .product-tile-body__price {
    display: flex;
    flex-direction: column;
    white-space: nowrap;

    .value{
      line-height: 1.4rem;
      margin-right: 0.5rem;
    }
  }
}

.container-badges {

  .badges-list {
    margin-bottom: 0.4rem;
  }

  .product-tile-body__badge {
    margin-bottom: 0.4rem;
    margin-right: 0.8rem;
  }
}

.product-tile-body__badge {
  &.responsible-badge {
    .text-micro {
      display: inline-block;
    }

    &.standard {
      .badge-icon-container {
        margin: 0rem 0.5rem 0 -0.4rem;
      }
    }
  }
}

.product-tile-body__price {
  .price-info-omnibus {
    display: inherit;
  }
}

