.container-button-bk_module {
  width: 40rem;
  max-width: 40rem;
}
.section-module_product-description,
.section-module_eight_product-description {
  width: 620px;
  height: 620px;
}
.section-description_module-product {
  position: absolute;
  top: 70.3%;
  left: 6%;
  .description_module-product {
    font-size: 3rem;
    line-height: 2.8rem;
  }
}
.container_general-cols-product_module {
  .col-product_module {
    .product_module-category {
      .container-img-product_module-category {
        width: 31rem;
        .img-product_module-category {
          width: 31rem;
          height: 46.5rem;
        }
        .counter-unit-product {
          top: 20%;
          right: -25%;
          bottom: unset;
        }
        .counter-unit-product-left {
          right: unset;
          left: -25%;
        }
      }
    }
  }
}
.button-section-module-description {
  font-size: 3.6rem;
}
.section--module-description-second-version {
  max-width: 62rem;
}
