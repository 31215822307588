.module-11B_container {
  overflow: hidden;
  position: relative;
  margin: 6.4rem 2rem;

  .module-11B-head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    .slider-controls {
      position: relative;
      margin-top: 1rem;

      .module-11B-swiper-button-prev,
      .module-11B-swiper-button-next {
        -webkit-tap-highlight-color: transparent;
        display: block;
        cursor: pointer;
        position: absolute;
        z-index: 10;

        &.whiteArrows {
          svg{
            fill: var(--white);
          }
        }

        svg {
          width: 2.4rem;
          height: 2.4rem;
          fill: var(--main-color);
        }

        &.noArrows {
          display: none;
        }
      }

      .module-11B-swiper-button-next {
        right: 0;
      }

      .module-11B-swiper-button-prev {
        right: 4rem;
      }
    }
  }

  .module-11B-title_container {
    -webkit-tap-highlight-color: transparent;
    align-items: center;
    background-color: var(--white);
    border: 1px solid var(--main-color);
    border-bottom: none;
    padding: 0.8rem 1rem 0.2rem 1rem;
    width: fit-content;

    svg[class^='icon'] {
      width: 2.4rem;
      height: 2.4rem;
      margin-left: 1rem;
    }

    a {
      display: inline-flex;
      align-items: center;
      text-decoration: none;
      flex-flow: row nowrap;
    }

    &.noBorder{
      border: none;
    }
    &.noLink {
      -webkit-appearance: none;
      text-decoration: none;
      margin: 0;
      font-family: inherit;
      white-space: nowrap;
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      height: 4rem;
      color: var(--main-color);
      background-color: var(--white);
      position: relative;
      overflow: hidden;
      z-index: 0;
      a{
        cursor: default;
      }
    }
  }

  .module-11B-swiper {

    width: 100%;
    margin: 0;
    border: 1px solid var(--main-color);

    .module-11B-slide {
      position: relative;
      height: 100%;
      width: 100%;

      .module-11B-container-img {
        height: 100%;
        width: 100%;

        .module-11B-img {
          box-sizing: border-box;
          height: 33.5rem;
          width: 100%;

          img{
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
      }
    }

    .swiper-slide {
      background-color: transparent;
      display: flex;
      align-items: center;
      width: auto;

      &.center {
        justify-content: center;
      }

      &.left {
        justify-content: flex-start;
      }

      &.right {
        justify-content: flex-end;
      }
    }
  }

  .module-11B-content_container {
    background-color: var(--white);
    border: 1px solid var(--main-color);
    border-top: none;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    padding: 1.6rem;
    width: 100%;

    p {
      margin: 0;
      letter-spacing: 0.02rem;
    }

    .module11B-link {
      -webkit-tap-highlight-color: transparent;
      display: flex;
      flex-direction: row;
      gap: 1rem;
    }
  }
}
