.product-grid-component {
  .title {
    font-size: 3.2rem;
    border: 1px solid var(--main-color);
    text-decoration: none;
    font-family: franklin-gothic-compressed, sans-serif;
    font-weight: 600;
    font-style: normal;
    display: inline-block;

    a,
    span {
      text-decoration: none;
      font-family: inherit;
      padding: 0.8rem;
    }
  }
  .product-grid {
    display: grid;
    border-top: 1px solid var(--main-color);
    border-left: 1px solid var(--main-color);

    .product-grid-item {
      border-right: 1px solid var(--main-color);
      border-bottom: 1px solid var(--main-color);
    }
  }

  @media (min-width: 992px) {
    margin-top: 2rem;
  }
}
.product-grid-component {
  &.show-tile {
    .product-tile-body {
      display: none;
    }
    .add-to-wish-list {
      display: none;
    }
    .product-badge {
      display: none;
    }
    .product-grid-item {
      &:hover {
        .product-tile-body {
          display: none;
        }
        .add-to-wish-list {
          display: none;
        }
        .product-badge {
          display: none;
        }
      }
    }
  }
  &.on-hover {
    .container-price-name-carousel {
      opacity: 0;
    }
    .product-badge {
      opacity: 0;
    }
    .add-to-wish-list {
      opacity: 0;
    }
    .product-grid-item {
      &:hover {
        .container-price-name-carousel {
          opacity: 1;
        }
        .add-to-wish-list {
          opacity: 1;
        }
        .product-badge {
          opacity: 1;
        }
      }
    }
  }
}
