.hamburger__panel {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  transform: scaleY(0);
  visibility: hidden;
  opacity: 0;
  transition: transform .5s ease 0.3s, opacity 0.3s linear 0.8s, visibility 0.3s linear 0.8s;
  transform-origin: top center;
  z-index: -1;
  list-style-type: none;
  padding: 10rem 0 0;
  margin: 0;
  background-color: var(--alternative-color);
  border: 1px solid var(--main-color);
  overflow-y: auto;

  &.submenu-is-open {
    overflow-y: hidden;
  }

  &.is-open {
    opacity: 1;
    pointer-events: visible;
    visibility: visible;
    transform: scaleY(1);
    transition: transform .5s ease,opacity 0ms linear 0s, visibility 0ms linear 0s;

    & > .hamburger__item {
      opacity: 1;
      visibility: visible;
      transition: opacity 0.3s linear 0.3s, visibility 0.3s linear 0.3s;
    }

    &:not(.submenu-is-open) { 
      & > {
        .hamburger__item {
          .hamburger__item--link {
            opacity: 1;
            visibility: visible;
            transition: opacity 0.3s linear 0.3s, visibility 0.3s linear 0.3s;
          }

          &.hamburger-bottom {
            & > * {
              opacity: 1;
              visibility: visible;
              transition: opacity 0.3s linear 0.3s, visibility 0.3s linear 0.3s;
            }
          }
        }
      }
    }

    .navigation--logo {
      visibility: visible;
      opacity: 1;
      transition: opacity 0.3s;
      transition-delay: 0.3s;
    }

    &.submenu-is-open {
      .hamburger__sidepanel.is-focused {
        opacity: 1;
        visibility: visible;
        transition: opacity 0.5s linear 0.3s, visibility 0.5s linear 0ms;
        pointer-events: all;

        .hamburger__category,
        .mobile-action {
          opacity: 1;
        }
      }
    }
  }

  .navigation--logo {
      transition: opacity 0.3s;
  }

  .hamburger__sidepanel {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    list-style: none;
    margin: 0;
    padding: 10rem 0 0;
    flex-direction: column;
    justify-content: normal;
    opacity: 0;
    visibility: hidden;
    z-index: 9;
    transition: opacity 0.3s linear 0.3s, visibility 0.3s linear 0.6s;
    overflow-y: auto;
    overflow-x: hidden;
    pointer-events: none;
  }

  .hamburger__item {
    display: block;
    width: 100%;
    margin-bottom: 2rem;
    padding: 0 2rem;

    &:last-child {
      margin-bottom: 0;
    }

    .hamburger__item--link {
      display: block;
      position: relative;
      background-color: transparent;
      font-size: 3.4rem;
      border: 0;
      color: var(--black);
      width: 100%;
      text-align: left;
      height: auto;
      font-family: franklin-gothic-compressed, sans-serif;
      font-weight: 600;
      text-transform: uppercase;
      padding: 0;
      text-decoration: none;
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.3s, visibility 0.3s linear 0.3s;

      &.highlighted {
        color: var(--main-color);
      }

      .icon__arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        width: 2.5rem;
        pointer-events: none;
      
        svg {
          width: 100%;
          fill: var(--black);
          pointer-events: none;
        }
      }
    }

    &.hamburger-bottom {
      margin-top: auto;
      padding: 0;

      & > * {
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.3s, visibility 0.3s linear 0.3s;
      }
    }
  }

  .mobile-action {
    opacity: 0;
    transform: translateY(-1rem);
    transition: transform .1s,opacity .1s;
    // transition-delay: 0;
    margin-bottom: 2.5rem;
    margin-left: 2rem;
    
    .mobile-action--cta {
      display: block;
      text-decoration: underline;
  
      &.mobile-back {
        color: var(--main-color);
        margin-bottom: 2.5rem;
        background-color: transparent;
        border: 0;
        padding: 0;
      }
  
      &.mobile-action--category-parent {
        text-transform: uppercase;
        color: var(--black);
        font-family: franklin-gothic-compressed, sans-serif;
        font-weight: 600;
        font-size: 3.4rem;
      }
    }
  }

  .hamburger__category--btn {
    position: relative;
    font-family: BananaGrotesk, sans-serif;
    font-size: 1.8rem;
    line-height: 0.9;
    color: #121212;
    cursor: default;
    margin: 0;
    padding: 2rem 0 2rem 2rem;
    width: 100%;
    background-color: transparent;
    text-align: left;
    border: 0;
  
    &.is-open {
      background-color: #fafafa;
  
      .icon__arrow {
        transform: rotate(270deg);
        transition: transform .4s;
      }
    }
  
    .icon__arrow {
      position: absolute;
      right: 2rem;
      top: -4.5rem;
      width: 2.5rem;
      transform-origin: center;
      transform: rotate(90deg);
      transition: transform .4s;
      pointer-events: none;
  
      svg {
        width: 100%;
        fill: var(--black);
        pointer-events: none;
      }
    }
  }

  .hamburger__sidepanel--subcategories {
    list-style-type: none;
    display: block;
    margin: 0;
    padding: 10rem 0;
  }
  
  .hamburger__category {
    flex: 0 0 100%;
    max-width: 100%;
    border-top: 1px solid var(--light-grey);
    padding: 0;
    opacity: 0;
    transition: opacity 0.5s;

    &:last-child {
      border-bottom: 0.1rem solid var(--light-grey);
    }

    &.editorial__category {
      border-bottom: 0;
    }
  }

  .hamburger__subcategories {
    padding: 0;
    margin: 0;
    height: 0;
    visibility: hidden;
    transition: height .6s, visibility 0ms linear .6s;
    overflow: hidden;

    &:not(div) {
      list-style-type: none;
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;

      &.navigation-subcategories-grouped {
        padding: 1.25rem 0 0;
      }
    }
  
    &.is-open {
      background-color: #fafafa;
      transition: height .6s, visibility .6s;
      visibility: visible;
    }

    .hamburger__subcategory {  
      &:last-child {
        padding-bottom: 1rem;
      }
    }

    .hamburger__category--link {
      font-size: 1.4rem;
      color: var(--black);
      text-decoration: none;
      display: block;
      position: relative;
      padding: 1rem 2rem;
    }
  }

  .hamburger-menu-wishlist-counter {
    &.hidden {
      display: none;
    }

    &:before {
      content: "(";
    }

    &:after {
      content: ")";
    }
  }
}
