.loading {
  .loader {
    @include z(loader);
    position: fixed;
    width: 100vw;
    @include fullViewportHeight();
    background-color: rgba(255, 255, 255, 0.6);
    background-image: url('../imgs/loader.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 20rem;
    left: 0;
    top: 0;
  }
}

.loading-class {
  position: relative;

  .loader-class {
    @include z(loader);
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.6);
    background-image: url('../imgs/loader.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 20rem;
    left: 0;
    top: 0;
  }
}
