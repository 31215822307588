.icon {
  border: none;
  min-width: initial;
  padding: 0;
  height: auto;
  @each $name, $uri in $sprites {
    &--#{$name} {
      @extend .icon;
      @include fixedSprite($name);
    }
  }
}
svg {
  fill: var(--main-color);
}
.product-badge-promotion__icon {
  width: 1.4rem;
  height: 1.4rem;
}
