.module-05 {
  flex-direction: column;
  position: relative;

  &:last-child {
    margin-right: 0;
  }

  &__text {
    order: 2;
    background: var(--white);
    position: absolute;
    z-index: 2;
    bottom: 4rem;
    left: -20rem;
    max-width: 31rem;
  }

  &.on-hover,
  &.show-wishlist {
    &:hover {
      .product-tile .add-to-wish-list {
        display: block;
      }

      .product-tile .product-tile-body {
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: var(--white);
        width: 100%;

        .section-colors-single-product-plp {
          display: none;
        }
      }
    }
  }
  .product-tile-body {
    padding-top: 1.2rem;
  }
}

.module-05_container {
  max-width: 62rem;
  margin: 0 auto;
  margin-top: 6rem;
  margin-bottom: 6rem;

  &-full {
    max-width: 100%;
    .module-05_items_container {
      &--8 {
        display: flex;

        .module-05 {
          width: 12.5%;
          flex: 0 0 12.5%;
          margin-top: 0;
          min-width: 185px;

          &:nth-child(n+9) {
            margin-top: -1px;
          }
        }
      }
    }
  }

  .module-05__title {
    .pd-heading {
      font-size: 3.6rem;
    }
  }

  .tab-container {
    .tab-content {
      order: 1;
    }
  }
}

.module-05_items_container {
  flex-direction: row;
  flex: 1 1 0;
}

.module-05_container-full {
  .product-grid-component {
    .product-grid-item:hover {
      .product-tile-body {
        bottom: 0;
        padding-top: 1.2rem;
      }
    }
  }
}
