.justify-content-sm-start {
    justify-content: flex-start;
}
.justify-content-sm-end {
    justify-content: flex-end;
}
.justify-content-sm-center {
    justify-content: center;
}
.justify-content-sm-between {
    justify-content: space-between;
}
.justify-content-sm-around {
    justify-content: space-around;
}
.align-items-sm-start {
    align-items: flex-start;
}
.align-items-sm-end {
    align-items: flex-end;
}
.align-items-sm-center {
    align-items: center;
}
.align-items-sm-baseline {
    align-items: baseline;
}
.align-items-sm-stretch {
    align-items: stretch;
}
.align-content-sm-start {
    align-content: flex-start;
}
.align-content-sm-end {
    align-content: flex-end;
}
.align-content-sm-center {
    align-content: center;
}
.align-content-sm-between {
    align-content: space-between;
}
.align-content-sm-around {
    align-content: space-around;
}
.align-content-sm-stretch {
    align-content: stretch;
}
.align-self-sm-auto {
    align-self: auto;
}
.align-self-sm-start {
    align-self: flex-start;
}
.align-self-sm-end {
    align-self: flex-end;
}
.align-self-sm-center {
    align-self: center;
}
.align-self-sm-baseline {
    align-self: baseline;
}
.align-self-sm-stretch {
    align-self: stretch;
}