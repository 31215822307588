.pd-lt-helparea{

  .section-head {

    .title-container {
      display: flex;
      width: 100%;
      height: 23rem!important;
      justify-content: center;
      align-items: center!important;

      -webkit-box-ordinal-group: -1;
      -ms-flex-order: -1;
      order: -1;

      .title {
        margin: 0;
        font-size: 3.6rem;
        @include secondaryFont();
      }
    }

    .breadcrumbs-container {
      display: block;
      height: auto;
      -webkit-box-ordinal-group: 1;
      -ms-flex-order: 1;
      order: 1;
      margin: 0;
      padding: 1.5rem 2rem 2rem;

      .breadcrubs-list {

        .breadcrumb-item {

          &.dotted {
            display: inline-block;

            &:before {
              content: "";
              width: 5px;
              height: 5px;
              margin: 0 2px;
              display: inline-block;
              background-color: #666666;
              border-radius: 15px;
              vertical-align: middle;
            }
          }
        }
      }
    }
  }

  .section-body{

    &.layout-menu{
      padding: 3rem 17vw 0;
      flex-direction: row;
    }

    &.layout-main{
      flex-direction: column;

      &>.experience-component:not(.pd-container-help), &>.pd-container-help > .experience-region{
        padding: 0 17vw;
      }
    }

    .menu-area{
      margin-right: 20px;
      margin-bottom: 0;
      flex: 0 0 200px;
    }
  }

  .section-body-full{
    &>.experience-component:not(.pd-container-help), &>.pd-container-help > .experience-region{
      padding: 0 17vw;
    }
  }

  .section-pref{
    &>.experience-component:not(.pd-container-help), &>.pd-container-help > .experience-region{
      padding: 0 17vw;
    }
  }

  form{

    fieldset {
      &.bordered {
        padding: 2.4rem 0.9rem;
      }

      .form-group {
        width: 50%;
        float: left;
        &.lg-wide {
          width: 100%;
          float: none;
          .form-group {
            &.column-3 {
              width: 32%;
              margin-right: 2%;
              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
        &.contactus-message {
          width: 100%;
          float: left;
          textarea {
            min-height: 300px;
          }
        }
        &.privacy-policy {
          float: none;
        }
      }
      .button {
        &.giftcard__apply-button {
          width: 100%;
        }
      }
    }
  }
}

.pd-lt-helpsectionarea-editmode{
  display: block;
  margin-top: 23rem;

  .section-body{
    padding: 0 17vw;
    flex-direction: column;

    &.layout-menu{
      flex-direction: row;
    }

    .menu-area{
      margin-right: 20px;
      flex: 0 0 200px;
    }
  }
}

// --- Content Assets Specific Customizations ---
.check-order-before-login {
  padding-bottom: 4rem;

  form {

    fieldset {
      p {
        margin: 0 0 2.4rem 0;
      }

      .form-group {
        margin-bottom: 2.4rem;
      }
    }
  }
}