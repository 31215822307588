.experience-layout-module19 {

  &.bg-red {
    background-color: var(--main-color);
  }

  &.bg-white {
    background-color: var(--white);
  }

  &.bg-offwhite {
    background-color: var(--lighting-grey);
  }

  .module-19 {
    overflow: hidden;
    position: relative;
    padding: 65px 0 65px 20px;

    &.bg-red {
      background-color: var(--main-color);
    }

    &.bg-white {
      background-color: var(--white);
    }

    &.bg-offwhite {
      background-color: var(--lighting-grey);
    }

    .module-19-head {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-right: 20px;
      margin-bottom: 20px;

      .title {
        @include secondaryFont;
        font-size: 32px;
        line-height: 30px;
        max-width: 80%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        margin: 0;

        &.title-red {
          color: var(--main-color);
        }

        &.title-white {
          color: var(--white);
        }

        &.title-offwhite {
          color: var(--lighting-grey);
        }
      }

      .slider-controls {
        position: relative;

        .module-19-swiper-button-prev,
        .module-19-swiper-button-next {
          display: block;
          cursor: pointer;
          position: absolute;
          z-index: 10;

          svg {
            width: 2.4rem;
            height: 2.4rem;
          }

          &.icon-red {

            svg {
              fill: var(--main-color);
            }
          }

          &.icon-white {

            svg {
              fill: var(--white);
            }
          }

          &.icon-offwhite {

            svg {
              fill: var(--lighting-grey);
            }
          }
        }

        .module-19-swiper-button-next {
          right: 0px;
        }

        .module-19-swiper-button-prev {
          right: 40px;
        }
      }
    }

    .module-19-swiper {

      .module-19-slide {
        position: relative;

        .module-19-container-img {
          height: 400px;
          width: 275px;

          .module-19-img {
            border: 1px solid var(--main-color);
            box-sizing: border-box;
            height: 400px;
            width: 275px;
            object-fit: cover;
          }
        }

        .module-19-container-box {
          //visibility: hidden;
          padding: 1.5rem;
          border: 1px solid var(--main-color);
          border-top: none;
          background-color: var(--alternative-color);
          cursor: pointer;
          min-height: 150px;
          width: 275px;
          box-sizing: border-box;

          .module-19-box {
            height: 100%;

            &:empty {
              display: none;
            }

            .pd-text {
              color: var(--main-color);
              position: relative;
              height: 92px;

              p {
                @include primaryFont;
                margin: 0;
                font-size: 1.4rem;
                line-height: 2rem;
                letter-spacing: 0.2px;
                overflow: hidden;
                text-overflow: ellipsis;

                &:first-of-type {
                  font-size: 1.8rem;
                  line-height: 2.4rem;
                  margin-bottom: 8px;
                  white-space: nowrap;
                }

                &:not(:first-of-type){
                  display: -webkit-box;
                  -webkit-line-clamp: 3;
                  -webkit-box-orient: vertical;
                }

                strong {
                  em {
                    u {
                      font-weight: 500;
                      // background-color: var(--main-color);
                      // color: var(--alternative-color);
                      font-style: normal;
                      text-decoration: none;
                    }
                  }

                  background: var(--main-color);
                  color: var(--alternative-color);
                }
              }
            }

            button {
              margin-top: 10px;
            }

            a {
              margin-top: 10px;

              &.link {
                display: block;
                text-decoration: underline;

                &:after {
                  display: none !important;
                }
              }
            }
          }
        }
      }

      .swiper-slide {
        background-color: transparent;
        display: flex;
        align-items: center;
        width: auto;

        &.center {
          justify-content: center;
        }

        &.left {
          justify-content: flex-start;
        }

        &.right {
          justify-content: flex-end;
        }

        /*&-active {
          .module-8__title,
          .module-8__container-text {
            visibility: visible;
          }
        }*/
      }
    }
  }
}