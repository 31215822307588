// Characters which are escaped by the escape-svg function
$escaped-characters: (
  ("<", "%3c"),
  (">", "%3e"),
  ("#", "%23"),
  ("(", "%28"),
  (")", "%29"),
);


$white: #fff;
$info: #3fafff;
$success: #068922;
$warning: #ffad49;
$danger: #ff3f3f;

@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);
  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }
  @return $string;
}

@function escape-svg($string) {

  @if str-index($string, 'data:image/svg+xml') {
    @each $char, $encoded in $escaped-characters {
      // Do not escape the url brackets
      @if str-index($string, 'url(') == 1 {
        @if (str-index($string, $char)) {
          $string: url('#{str-replace(str-slice($string, 6, -3), $char, $encoded)}');
        }
      } @else {
        @if (str-index($string, $char)) {
          $string: str-replace($string, $char, $encoded);
        }
      }
    }
  }

  @return $string;
}

.badge-feedback {
  display: flex;
  flex-direction: row;
  flex: 0 0 0%;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.2rem;
  margin-top: 0;
  margin-bottom: 0;
  color: $white;
  overflow: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;

  &--info {
    background-color: $info;
  }

  &--success {
    background-color: $success;
  }

  &--warning {
    background-color: $warning;
  }

  &--danger {
    background-color: $danger;
  }

  &__icon {
    cursor: pointer;
    height: 2.4rem;
    width: 2.4rem;
    background-size: contain;
    background-position: center;

    &--info {
      background-image: url(escape-svg("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18.1' height='18.1' viewBox='0 0 18.1 18.1'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:#{$white};fill-rule:evenodd;%7D%3C/style%3E%3C/defs%3E%3Cpath fill='#{$white}' d='M21.1,3V21.1H3V3ZM19.55,4.45H4.45V19.6H19.6ZM13,10.48v6.68H11V10.48Zm0-3.64V8.67H11V6.84Z' transform='translate(-3 -3)'/%3E%3C/svg%3E"));
    }

    &--success {
      background-image: url(escape-svg("data:image/svg+xml,%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$white}' stroke-width='1' fill='#{$white}' d='M2 11.313l1.043-1.06 6.435 6.54L20.957 5.126 22 6.186 9.478 18.916z' fill-rule='evenodd'/%3E%3C/svg%3E"));
    }

    &--warning {
      background-image: url(escape-svg("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:none;%7D.b%7Bfill:#{$white};fill-rule:evenodd;%7D%3C/style%3E%3C/defs%3E%3Crect fill='none' width='24' height='24'/%3E%3Cpath class='b' d='M12,3,23,21H1Zm0,1.918L2.783,20H21.216Zm.73,12V18H11.64V16.92ZM12.71,11l-.17,5.07h-.7L11.67,11Z' fill='#{$white}'/%3E%3C/svg%3E"));
    }

    &--danger {
      background-image: url(escape-svg("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:#{$white};fill-rule:evenodd;%7D%3C/style%3E%3C/defs%3E%3Cpath fill='#{$white}' d='M6,0A6,6,0,1,1,0,6,6,6,0,0,1,6,0ZM6,1a5,5,0,1,0,5,5A5,5,0,0,0,6,1Zm.5,7V9h-1V8Zm0-5V7h-1V3Z'/%3E%3C/svg%3E"));
    }
  }

  &.show {
    flex: 1 1 auto;
    padding: 0.8rem 1.2rem;
    margin-top: 0.6rem;
    margin-bottom: 0.6rem;
    opacity: 1;
  }
}
