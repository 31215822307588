.footer {
  border-top: 1px solid var(--main-color);
  margin-top: 6rem;
  background-color: var(--white);
}
.title-box_footer,
.label-select_title-footer {
  font-size: 1.8rem;
  font-weight: 500;
}
.title-box_footer {
  svg {
    width: 150px;
    height: 34px;
    fill: initial;
  }
}
.footer__boxes {
  &.footer__boxes--link-list {
    display: grid;
    column-gap: 2rem;
    .footer__link-box-content {
      .pd-heading {
        font-size: 1.8rem;
        font-weight: 500;
        margin: 0;
      }
      .footer__link-box-links {
        .anchor-text {
          text-decoration: none;
          margin-bottom: 2rem;
          display: block;
          padding: 0 2rem;
        }
      }
      .ac-trigger {
        padding: 2.4rem 2rem;
      }
    }
  }
  &.footer__boxes--editorials {
    padding: 2rem;
    display: flex;
    flex-flow: column wrap;
  }
}
.footer__editorial-box {
  &:not(:last-child) {
    margin-bottom: 2rem;
  }
}

.footer__boxes--editorials {
  background-color: var(--main-color);
}
.container-box_footer_content {
  height: 100%;
  position: relative;
  display: flex;
  flex-flow: column wrap;
}
.button_img_box-footer {
  align-self: flex-end;
  margin-top: 1.2rem;

  &:not(:last-child) {
    margin-top: auto;
  }
}
.container-box_footer_col {
  .experience-region {
    height: 100%;
  }
}
.container-img-cta_box-footer,
.description-box_footer {
  padding: 2rem;
}
.container-img-cta_box-footer {
  padding-top: 0;
}
.footer__social {
  grid-area: social;
  flex: 1 0 100%;
  border-bottom: 1px solid var(--border-color);
  .footer__link-box-links {
    display: flex;
    justify-content: space-around;
    svg[class^='icon'] {
      display: block;
      width: 3rem;
      height: 3rem;
    }
    .anchor-text {
      display: block;
      margin: 3rem 0;
    }
  }
}
.footer__boxes--link-list {
  .footer__link-box:not(:empty) {
    border-bottom: 1px solid var(--border-color);
  }
}
.footer__countries {
  grid-area: countries;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  border-bottom: 1px solid var(--border-color);
  div {
    text-align: center;
    &:first-child {
      border-right: 1px solid var(--border-color);
    }
    padding: 2.4rem;
  }
  button {
    @include resetButton;
    font-size: 1.8rem;
    color: var(--main-color);
  }
}
.footer__copyright {
  grid-area: copyright;
  border-bottom: 1px solid var(--border-color);
  font-size: 1.3rem;
  padding: 2rem 2.4rem;
  text-align: center;
  color: var(--main-color);
}
.footer__info {
  display: grid;
  grid-template-areas:
    'social'
    'countries'
    'copyright';
}
.box_footer_form {
  display: flex;
  flex-flow: column wrap;
  padding: 2rem;
  height: 100%;
  .title-box_footer {
    margin: 0 0 5rem 0;
  }
  .form-element,
  .privacy-policy {
    margin: 0 0 1rem 0;
    // margin-bottom: 1rem;
  }
  .form-footer {
    margin-top: auto;
  }
}
