.module-10 {
  margin-top: 8rem;
  margin-bottom: 8rem;
  .module-10_item {
    .module-10_item__text {
      .pd-heading {
        font-size: 3.6rem;
        line-height: 3.4rem;
      }
    }
  }
}
