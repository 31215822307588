.module-07-section {
  display: flex;
  flex-direction: column;

  .module-7__item {
    display: flex;

    .experience-productRegion {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 0;
      right: -315px;
      width: 290px;
      height: 100%;

      &.length-4 {
        align-items: center;

        .experience-assets-module7product {

          &:nth-child(1) {
            top: 0;
          }

          &:nth-child(2) {
            top: 16%;
            right: 0;
            z-index: 3;
          }

          &:nth-child(3) {
            top: 44%;
            left: 0;
          }

          &:nth-child(4) {
            bottom: 0;
            z-index: 3;
          }
        }
      }

      &.length-3 {
        justify-content: center;
        align-items: center;

        .experience-assets-module7product {

          &:nth-child(1) {
            top: 6%;
          }

          &:nth-child(2) {
            right: 0;
            z-index: 3;
          }

          &:nth-child(3) {
            bottom: 7%;
            left: 0;
          }
        }
      }

      &.length-2 {

        .experience-assets-module7product {

          &:nth-child(1) {
            right: 0;
            top: 16%;
            z-index: 3;
          }

          &:nth-child(2) {
            left: 0;
            top: 44%;
          }
        }
      }

      &.length-1 {
        justify-content: center;

        .experience-assets-module7product {

          &:nth-child(1) {
            right: 0;
          }
        }
      }

      .experience-assets-module7product {
        width: 220px;
        flex-grow: 1;

        &:selected {

          .img-box {

            &:after {
              opacity: .2;
            }
          }
        }

        &:hover {
          z-index: 5;

          .info-box{
            background-color: var(--main-color);
            color: var(--white);
          }
        }

        .img-box{

          &:after{
            background-color: var(--main-color);
            transition: opacity var(--transition);
            position: absolute;
            top: 0;
            content: '';
            width: 100%;
            height: 220px;
            opacity: 0;
          }

          img{
            display: block;
            height: 220px;
            width: auto;
            margin: auto;
          }
        }

        .info-box{
          display: flex;
          justify-content: center;
          align-items: center;
          height: 35px;
          @include primaryFont;
          line-height: 2rem;
          letter-spacing: .2px;
          color: var(--main-color);
        }
      }
    }

    .single-tile {
      width: 105px;
      height: 155px;
    }

    .look {
      width: 620px;

      .look-image {
        height: 775px;
        width: 100%;

        &>img {
          width: calc(100% - 104px);
        }
      }
    }

    .product {
      height: 100%;
    }

    .product-tile {
      height: 100%;

      figure {
        height: 100%;
        margin-bottom: 0;
      }

      img {
        width: auto;
        height: 100%;
        border: 1px solid var(--main-color);
      }
    }

    .product-tile__presentation {
      .container-price-name-carousel {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        text-align: center;
        display: flex;
      }
    }

    .product-tile-image {
      width: 100%;
      margin: 0;
    }

    .add-to-wish-list {
      top: 4.2rem;
    }

    .product-tile-body {
      margin-top: 0;
      width: 100%;
      padding: 5px;
      border: 1px solid var(--main-color);
      border-bottom: 0;

      .product-tile-body__link {
        flex: 1;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .product-tile-body__price {
        margin-top: 0;
        margin-left: 10px;
      }
    }
  }

  .module-07 {
    padding: 0;
    margin-left: 30px;
    margin-right: 30px;
    width: 620px;

    .content-heading {
      .heading-link {
        &::before {
          display: block;
        }

        &:hover .pd-heading {
          color: var(--primary-color);
        }
      }
    }

    .content-element{
      @for $i from 2 through 5 {
        .module-7__item:nth-child(#{$i}n) {
          .single-tile {
            top: 155px * ($i - 1);
          }
        }
      }
    }
  }
}
