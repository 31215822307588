.module-07-section {

  .module-7__item {

    .experience-productRegion {
      right: -370px;
      width: 345px;

      .experience-assets-module7product {
        width: 265px;

        .img-box {

          &:after{
            height: 265px;
          }

          img {
            height: 265px;
          }
        }
      }
    }
  }
}