.main-header {
  &.main-color {
    background-color: var(--main-color);
  }
  &.flat-mobile {
    height: 0;
    overflow: visible;
  }
}
.header__logo {
  &.is-absolute {
    position: absolute;
  }
}
