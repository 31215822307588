.d-none {
    display: none;
}
.d-none-important {
    display: none !important;
}
.d-inline {
    display: inline;
}
.d-inline-block {
    display: inline-block;
}
.d-block {
    display: block;
}
.d-table {
    display: table;
}
.d-table-row {
    display: table-row;
}
.d-table-cell {
    display: table-cell;
}
.d-flex {
    display: flex;
}
.d-inline-flex {
    display: inline-flex;
}