.experience-layout-module1BContainer {

  .experience-assetAndPanel {
    display: flex;
    justify-content: center;

    .experience-assets-module1BPanel {
      width: 50vw;
      max-width: 96rem;
    }

    img {
      width: 50vw;
      max-width: 96rem;
    }

    .pd-video__container {
      width: 50vw;
      max-width: 96rem;
    }
  }
}