.module-static-plp--12 {
  .content-heading {
    .element-heading {
      font-size: 3.6rem;

      span {
        font-size: 3.6rem;
        padding-right: 3rem;
      }
    }
    .icon-arrow-fill-down {
      display: block;
    }
  }

  .content-element {
    .content-text {
      display: block;
      position: absolute;
      bottom: 3.6rem;
      right: -20%;
      max-width: 33rem;
      z-index: 1;

      .content-info {
        border: 1px solid var(--border-color);
      }
    }
  }
}
