.product-tile-image {
  position: relative;

  .product-not-available-banner {
    position: absolute;
    top: -0.8rem;
    left: -0.6rem;
    display: flex;
    padding: 0.4rem;
    z-index: 1;
    background: rgba(237, 237, 237, 0.50);
    align-items: center;
    justify-content: center;

    .text {
      font-size: 1rem;
      display: inline-flex;
      color: var(--black);
      margin-left: 0.4rem;
    }

    .icon-attention {
      width: 1.6rem;
      height: 1.6rem;
      fill: var(--black);
    }
  }

  .swiper-wrapper .swiper-slide {
    max-width: 100%;
  }
}

.product-grid {
  .product-tile-image {
    .swiper-wrapper .swiper-slide {
      max-width: initial;
    }
  }
}

a.tile-image-link, 
div.tile-image-link {
  padding-top: calc(1170 / 780 * 100%);
  display: block;
  position: relative;
}

.product-tile-image__picture {
  img {
    display: block;
    margin: 0;
    padding: 0;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index:-1;
  }
}

.tile-image-link {
  &.offline-product {
    .product-tile-image__picture {
      img {
        opacity: 0.5;
      }
    }
  }
}
