.module-11B_container {
  margin: 8rem auto;
  width: 62rem;

  .module-11B-swiper {

    .module-11B-slide {

      .module-11B-container-img {
        height: 62rem;
        width: 62rem;

        .module-11B-img {
          height: 62rem;
          width: 62rem;
        }
      }
    }

  }
}
