.header__logo {
  width: 25vw;
  position: absolute;
  z-index: 100;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0;
    padding-top: calc((19.8rem / 36rem ) * 100%);
    background-color: rgba(0,0,0,0.6);
    transition: opacity 0.5s linear;
  }

  &.header__logo--small {
    width: 18rem;
  }
}

header.is-open:not(.no-backdrop) {
  .header__logo {
    pointer-events: none;

    &::before {
      opacity: 1;
      transition: none;
    }
  }
}
.sticky-header {
  .header__logo {
    &::before {
      bottom: 0;
    }
  }
}

.main-header {
  &.active-editorial-bar-header {
    .popup-notification {
      top: 4rem;
    }
  }

  .popup-notification {
    width: 41.5rem;
    transition: transform 300ms linear;
    top: 0;
    
    &.open {
      transform: translate(0, 37px);
    }
  }
}