.module-static-plp--04 {
  .element-wrapper {
    padding: 6rem 0;
    margin: 0 auto;
    width: 100%;
    max-width: 90%;
    justify-content: center;

    .single-item {
      max-width: fit-content;
    }
  }

  .content-text {
    top: 8rem;
    bottom: auto;
    left: 0;
    transform: translate(-50%, 0);
    &--right {
      left: auto;
      right: 0;
      transform: translate(50%, 0);
    }
  }
}
