body {
    &.red-is-active {
        &.pdp-page {
            .action-buttons {
                &.pdp-mobile {
                    bottom: 60px;

                    &:not(.d-none) + .cta-panel {
                        bottom: 110px
                    }
                }
            }
        }
    }
}

.action-buttons {
    bottom: 60px;
    right: 20px;
}

.cta-panel {
    bottom: 110px;
    right: 20px;


    &.ai-panel-open {
        max-width: 665px;
        max-height: 550px;
        bottom: 60px;
        right: 20px;
    }
}

@media (max-height: 600px) {
    .cta-panel {
        &.ai-panel-open {
            max-width: 100%;
            max-height: 100vh;
            height: 100%;
            bottom: 0;
            right: 0;
            border-bottom: 1px solid #E4002B;
        
            .cta-panel-header {
                display: none;
            }
        }
    }
}
