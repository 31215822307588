.module-2 {
  width: 100%;
  height: 100%;
}
.module-2__area {
  display: grid;
  width: calc(100% - 4rem);
  margin: 0 auto;
  grid-template-columns: repeat(5, auto);
  grid-template-rows: repeat( 9, auto);
  
}

.module-2__item {
  .module-2__title {
    border: 1px solid var(--main-color);
    border-bottom: none;
    background-color: var(--alternative-color);
    color: var(--main-color);
    padding: 0.8rem;
    width: fit-content;
    display: flex;
    align-items: center;
    max-width: 80%;
    &:hover {
      border: 1px solid $white;
    }
    .pd-heading {
      font-size: 3.2rem;
      @include secondaryFont();
      margin: 0;
      white-space: normal;
    }
    .pd-icon {
      margin-left: 0.8rem;
      align-self: flex-end;
      svg[class^='icon'] {
        width: 2.4rem;
        height: 2.4rem;
      }
    }
  }
  .module-2__link {
    text-decoration: none;
    width: fit-content;
    .module-2__title {
      height: auto;
      flex-direction: unset;
    }
  }
  .product-tile {
    border: 1px solid var(--main-color);
    max-width: 1000px;
    position: relative;
    padding: 0;
    .product-tile-image {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      margin: 0;
    }
  }
}

.module-2 {
  .module11_area {
    margin: 0 auto;
    position: relative;
    z-index: 2;
    width: fit-content;
    padding-top: 2rem;

    .module-11 {
      height: auto;
      padding-bottom: 0;
      margin-top: 0;
    }
  }
}

.module-2 {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  .drag-area {
    display: flex;
    flex: 1;
  }
}
