.main-header {
  @include z('header');

  &:not(.sticky-header) {
    .menu-backdrop {
      background-color: transparent;
    }
  }
}
.header__logo {
  // width: 14.9rem;
  width: 110px;
  display: block;
  z-index: 100;
  max-width: 420px;
  transition: width ease-in-out .4s;
  // &.header__logo--product {
  //   @include z('header');
  //   position: absolute;
  //   width: 15rem;
  // }

    &.globale-fix-width {
      width: 180px;
    }
  img {
    display: block;
    width: 100%;
    height: auto;
    will-change: transform;
    transition: opacity 150ms ease;
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
    fill: initial;
  }
}

.no-events {
  pointer-events: none;
}

.main-header {
  &.active-editorial-bar-header {
    .popup-notification {
      top: 10rem;
    }
  }
  .popup-notification {
    position: fixed;
    right: 0;
    z-index: 10;
    padding: 1rem 1.5rem;
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: var(--green-success);
    transition: transform 500ms linear;
    transform: translate(0, -100px);
    top: 6rem;

    span {
      color: var(--white);
      width: calc(100% - 3rem);
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.open {
      transform: translate(0, 0);
    }

    &::after {
      content: '';
      background-image: url('../../imgs/agree.svg');
      background-repeat: no-repeat;
      background-position: center;
      width: 2rem;
    }
  }
}

// ADA Accessibility Style
body.cart-page.accessibility-zoom-active {
  .header__logo {
    width: 90px;
  }
}