.icon-product-condition {
  position: relative;
  display: inline-flex;
  width: 1rem;
  height: 1rem;

  & > svg {
    display: block;
    width: 100%;
    height: 100%;
  }
  &.icon-product-condition--center {
    background-color: var(--main-color);
    color: var(--alternative-color);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &.icon-product-condition--hidden {
    display: none;
  }
}
