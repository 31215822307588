.side-panel {
  svg{
    &.icon-close{
      fill: var(--main-color) !important;
    }
  }
  &.side-panel--bottom {
    .side-panel__close {
      display: block;
    }
    .side-panel__inner {
      top: auto;
      bottom: 0;
      left: 0;
      right: auto;
      height: auto;
      max-width: none;
      transform: translateY(100%);
      &.open {
        left: 0;
        transform: translateY(0);
      }
    }
  }
}


.side-panel__size-selector > .side-panel__close{
  display: none;
}

.side-panel__inner {
  .header-side-panel {
    .header-side-panel-title {
      line-height: 2rem;
    }
  }
}