@import '../../mixins';
@import '../../../pages/account/mixins';

.loyalty-tabs-container {
  display: block !important;
  margin: 3rem auto 6rem;
  max-width: 62rem;

  @include media-breakpoint-down(sm) {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .form-login-wrapper {
    border: 1px solid var(--main-color);
    padding: 2rem;
  }

  .account-registration {
    margin-top: 0;
  }

  @include media-breakpoint-up(lg) {
    margin-top: 4rem;
  }
}

.loyalty-tabs {
  display: flex;

  &__item {
    @include secondaryFont();

    display: inline-block;
    border: 1px solid var(--main-color);
    border-bottom: none;
    text-decoration: none;
    font-size: 3rem;
    line-height: 2.8rem;
    color: var(--main-color);
    margin: 0;
    padding: 0.6rem 0.6rem 0.4rem;

    @at-root {
      a#{&} {
        opacity: 0.3;

        &:first-child {
          border-right: none;
        }

        &:last-child {
          border-left: none;
        }
      }
    }
  }
}

.loyalty-register-page {
  &__benefits {
    margin-bottom: 2rem;

    &:not(.d-block) {
      display: none;
    }

    @include media-breakpoint-up(lg) {
      display: block;
      position: absolute;
      top: 9.8rem;
      width: 26.2rem;
      right: calc(calc(calc(100vw - 62rem) * 0.5) * 0.5); // halfway between right edge and register box
      transform: translateX(50%);
      padding: 2rem;
      border: 1px solid var(--main-color);

      &:not(.d-block) {
        display: block;
      }
    }

    .content-asset {
      height: 3.2rem;
      overflow: hidden;

      ul {
        margin: 0;
        padding: 0;
      }
    }

    &__logo-container {
      width: 210px;
      height: 48px;
      
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}
