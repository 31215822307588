.product-badge {
  &:not(:empty) {
    position: absolute;
    left: 1px;
    top: 1px;
    background-color: var(--main-color);
    color: var(--alternative-color);
    font-size: 1.2rem;
    line-height: 1;
    font-weight: 500;
    padding: 0.4rem;
    z-index: 2;
  }
}
