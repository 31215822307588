.module4__items {
  max-width: 62rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 6rem;
  margin: 6.4rem 2rem 6.4rem 2rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.module4__item {
  position: relative;
  width: 50%;
  &.module4__item--double {
    grid-column: 1 / span 2;
    width: 100%;
  }
}

.module4__link {
  display: block;
  text-decoration: none;
  width: fit-content;

  h3.button.secondary.module4__name {
    font-size: 2.4rem;
    border-bottom: none;
    height: auto;
    flex-direction: unset;
    @include secondaryFont();
    svg[class^='icon'] {
      width: 2.4rem;
      height: 2.4rem;
      margin-left: 0.8rem;
    }
  }
}
.module4__picture {
  border: 1px solid var(--border-color);
  .module4__item--double & {
    &.module4__picture--first {
      border-right: none;
    }
  }
  img {
    display: block;
    width: 100%;
  }
}
.module4__visual {
  display: flex;
  flex-flow: row nowrap;
}

.module4__counter {
  width: 11rem;
  text-decoration: none;
  white-space: nowrap;
  border: 1px solid var(--border-color);
  position: absolute;
  font-size: 1.4rem;
  @include primaryFont;
  color: var(--main-color);
  background-color: var(--white);
  padding: 1.3rem;
  right: 2rem;
  bottom: -2rem;
  z-index: 1;
}

.deleteRightEdge{
  border-right-color: transparent;
}
