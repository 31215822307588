.static-carousel-container {
  background-color: var(--main-color);
  width: 100%;
  position: relative;

  .static-carousel {
    position: relative;
    max-width: 100%;
    margin: 0 2rem;
    padding: 2rem 0;
    border: 1px solid var(--main-color);
    overflow: hidden;

    .swiper-wrapper {
      border-bottom: 1px solid var(--main-color);
      align-items: flex-end;
      overflow: scroll;
      
      .swiper-slide {
        width: 50% !important;
        border-left: 1px solid var(--main-color);
        box-sizing: border-box;

        .content-img {
          img {
            display: block;
            width: 100%;
          }
        }

        .content-text {
          text-align: center;
          background-color: white;

          a {
            line-height: 4rem;
            text-decoration: none;
          }
        }

        .content-title {
          width: fit-content;
          background-color: white;
          display: flex;
          align-items: center;

          a {
            text-decoration: none;
            display: flex;
            align-items: center;
          }

          h3 {
            font-size: 2.4rem;
            padding: 0.5rem;
            margin: 0;
          }

          .icon-arrow-cta {
            width: 2rem;
            height: 2rem;
            margin: 0 1rem;
          }
        }
      }
    }

    .navigation-control {
      display: none;
    }
    
  }
    .content-description-container {
    width: fit-content;
    position: static;
    margin-bottom: 2rem;

        .content-description {
            padding: 1.2rem;
            background: white;
            border: 1px solid var(--main-color);
            color: var(--main-color);
        }

        .content-title {
            border: 1px solid var(--main-color);
            display: block;
            width: fit-content;
            position: relative;
            background-color: white;
        
            a {
                text-decoration: none;
            }
        
            h3 {
                font-size: 2.4rem;
                padding: 0.5rem;
                padding-right: 3.5rem;
                margin: 0;
            }
        
            .icon-arrow-cta {
                width: 2rem;
                height: 2rem;
                position: absolute;
                right: 0;
                top: 0;
                transform: translate(-40%, 50%);
            }
        
        }

        &.mobile {
            display: block;
        }

        &.desktop {
            display: none;
        }
    }

  &.white-bg {
    background-color: white;

    .static-carousel {
      border: none;
      margin: 0 2rem;

      .swiper-wrapper {
        .swiper-slide {

          .content-title,
          .content-text,
          .content-img {
            background-color: white;
            border-bottom: 0;
          }

          .content-title {
            border: 1px solid var(--main-color);
            border-left: 0;
            border-bottom: 0;
          }

          .content-text {
            border-bottom: 1px solid var(--main-color);
          }

          .content-img {
            border-top: 1px solid var(--main-color);
          }
        }
      }
    }
  }
}