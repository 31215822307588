.search-category__list {
  display: none;
  flex-flow: row wrap;
  margin: 2rem 0;
  column-gap: 0.8rem;
  .search-category__item {
    margin-bottom: 0.6rem;
    .checkbox__container {
      .form-element__checkbox {
        position: absolute;
        z-index: 2;
        visibility: visible;
        width: 100%;
        height: 100%;
        background: none;
        margin-right: 0;
      }
    }
  }
  @media (min-width: 992px) {
    display: flex;
  }
}

.search-editorial__modules{
  &.suggest-keyword{
    display: block;

    .visible_mobile{
      display: block;
      &.d-none{
        display: none;
      }
    }
    .visible_only_desktop{
      display: none;
    }
    @media (min-width: 992px) {
      .visible_mobile{
        display: block;
        &.d-none{
          display: none;
        }
      }
      .visible_only_desktop{
        display: block;
        &.d-none{
          display: none;
        }
      }
    }
  }
}
