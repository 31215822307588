@import './typography';
html {
  box-sizing: border-box;
  font-size: 62.5%;
  font-family: sans-serif;
}
body {
  background-color: var(--white);
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

.bk-red {
  background-color: var(--main-color);
}

.bk-white {
  background-color: var(--alternative-color);
}
.color-white {
  color: var(--alternative-color);
}
.color-black {
  color: var(--black);
}
a {
  color: var(--main-color);
}
p,
li,
h1,
h2,
h3,
h4,
h5 {
  color: var(--text-color);
}
h1,
h2,
h3 {
  @include secondaryFont();
}
p,
a,
li {
  @include primaryFont($weight: 500);
}
p {
  line-height: 2rem;
}
.title-1 {
  font-size: 6rem;
  line-height: 5.8rem;
  letter-spacing: 0;
}
.title-2 {
  font-size: 3.6rem;
  line-height: 3.4rem;
  letter-spacing: 0;
}
.title-3 {
  font-size: 3.2rem;
  line-height: 3rem;
  letter-spacing: 0;
}
.title-4 {
  font-size: 3rem;
  line-height: 3.4rem;
  letter-spacing: 0;
}

.text-red {
  color: var(--main-color);
}

.text-white {
  color: var(--alternative-color);
}

.title-secondaryfont {
  @include secondaryFont();
}

.value-big {
  font-size: 1.8rem;
  line-height: 2.4rem;
  letter-spacing: 0.2;
}

.value-regular {
  font-size: 1.4rem;
  line-height: 2rem;
  letter-spacing: 0.2;
}

.text-micro {
  font-size: 1rem;
  line-height: 1.6rem;
  letter-spacing: 0.2;
}

.text-small {
  font-size: 1.2rem;
  line-height: 1.8rem;
  letter-spacing: 0.2;
}

.text-large {
  font-size: 2rem;
  line-height: 2.6rem;
}

.text-big {
  font-size: 2.8rem;
  line-height: 2.6rem;
}

p.text-big {
  font-size: 1.8rem;
  line-height: 2.4rem;
  letter-spacing: 0.2;
}

p.text-regular {
  font-size: 1.4rem;
  line-height: 2rem;
  letter-spacing: 0.2;
}

.text-medium {
  font-size: 1.6rem;
  line-height: 1.8rem;
  letter-spacing: 0.2;
}

p.text-small {
  font-size: 1.2rem;
  line-height: 1.8rem;
  letter-spacing: 0.2;
}

.price-basic-small {
  font-size: 1.4rem;
  line-height: 1.4rem;
  letter-spacing: 0.2;
}

.price-basic-big {
  font-size: 1.8rem;
  line-height: 1.8rem;
  letter-spacing: 0.2;
}

.price-sale {
  font-size: 1.4rem;
  line-height: 1.4rem;
  letter-spacing: 0.2;
  .sale {
    text-decoration: line-through;
  }
}

.text-decoration-none {
  text-decoration: unset;
}
.strike-through {
  text-decoration: line-through;
}
html,
body {
  @include primaryFont();
  min-height: 100%;
}
body {
  font-size: 1.4rem;
  @include minFullViewportHeight();
  display: flex;
  flex-flow: column nowrap;
}
img {
  max-width: 100%;
  &.lazyload {
    transition: opacity var(--transition);
    opacity: 0;
  }
  &.lazyloaded {
    transition: opacity var(--transition);
    opacity: 1;
  }
}
.list-no-style {
  margin: 0;
  padding: 0;
  list-style: none;
}
.disabled {
  pointer-events: none;
}
.container-general-page-assets {
  margin: 4rem 2rem 2rem 2rem;
}

@media (min-width: 992px) {
  .container-general-page-assets {
    margin: 26rem 10rem 0px 10rem;
  }
}

.visually-hidden {
  clip: rect(0.1rem 0.1rem 0.1rem 0.1rem);
  clip-path: inset(50%);
  height: 0.1rem;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 0.1rem;
}

.visible-on-focus {
  &:not(:focus):not(:active):not(:focus-within) {
    @extend .visually-hidden;
  }
}

.a11y-action-bar {
  background-color: var(--main-color);
  color: var(--alternative-color);
  min-height: 3.2rem;
  padding: 0 1.6rem;
  display: flex;
  align-items: center;
  text-decoration: none;

  a {
    &:link,
    &:visited {
      color: var(--alternative-color);
      position: relative;
      text-decoration: none;
      margin-right: 0.8rem;

      &::after {
          transition: all .5s 0s cubic-bezier(.25,1,.5,1);
          content: "";
          height: .1rem;
          width: 100%;
          background: #fff;
          position: absolute;
          left: 0;
          opacity: 0;
          bottom: -0.1rem;
        }
      }

    &:focus {
      outline: none;

      &::after {
        opacity: 1;
      }
    }
  }

  &:not(:focus):not(:active):not(:focus-within) {
    @extend .visually-hidden;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

[data-whatintent="mouse"] a.skip-link:focus, [data-whatintent="mouse"] a.skip-link:active,
[data-whatinput="touch"] a.skip-link:focus, [data-whatintent="touch"] a.skip-link:active {
  left: -9999px !important;
}