.discord-element {
    background-color: var(--main-color);
    width: fit-content;
    position: fixed;
    right: 30px;
    width: 43px;
    height: 43px;
    color: var(--white);
    font-size: 10px;
    transition: 0.3s ease-in-out;
    z-index: 20;
    top: 10%;

    .discord-element-wrapper {
        width: 100%;
        height: 100%;
    }

    .container-right {
        position: relative;
        height: 100%;
    }

    .container-left {
        display: none;
        overflow: hidden;
    }

    .image {
        width: 28px;
        height: 20px;
        object-fit: contain;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: 0.3s ease-in-out;
    }

    .button {
        position: absolute;
        background-color: var(--white);
        color: var(--main-color);
    }

    .info {
        position: absolute;
        height: 0;
        margin-top: 0;
        color: var(--white);
        margin-bottom: 5px;
    }
}

.discord-element:hover {
    width: 338px;
    height: 125px;
    box-sizing: border-box;

    .discord-element-wrapper {
        display: flex;
        width: 94%;
        height: 83%;
        border: 1px solid var(--white);
        padding: 10px;
        margin: 10px;
        box-sizing: border-box;

        .container-left {
            width: 70%;
            display: block;
        }

        .container-right {
            width: 30%;
        }
    }

    .image {
        width: 66px;
        height: 49px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .info {
        position: unset;
    }

    .button {
        position: relative;
    }

    .info {
        height: unset;
    }
}

@media (max-width: 767px) {
    .discord-element {
        top: 90vh;
        &:hover {
            top: 75vh;
            right: 4%;
        }
    }
}