.noresult-page-wrapper {
  height: calc(80vh - 82px);
}

.noresults-wrapper {
  width: 100%;
  max-width: 62rem;
  margin: 0 2rem;

  .label-box,
  .content-box {
    background-color: var(--main-color);
    border: 1px solid white;
  }

  .label-box {
    display: inline-block;
    margin-bottom: -0.1rem;
    padding: 0 0.8rem;

    .noresults-header {
      display: inline-block;
      margin: 0;
      color: white;
      font-size: 3.2rem;
      padding: 0.2rem 0;

      .icon-search {
        fill: white;
        width: 2rem;
        height: 2rem;
        margin-left: 0.5rem;
      }
    }
  }

  .content-box {
    padding: 0.8rem;

    .noresults-text {
      margin-bottom: 2rem;

      p {
        color: white;
      }
    }

    .noresults-suggestions {
      .noresults-link-wrapper {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;

        .noresults-link {
          margin: 0 0.8rem 0.8rem 0;

          a {
            display: inline-block;
            text-decoration: none;
            padding: 1.6rem;
            background-color: white;
          }
        }
      }
    }
  }
}
