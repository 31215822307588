.side-panel__inner {
  &.open {
    border: 1px solid var(--main-color);
  }

  .action-bar__filters {
    .title-container {
      align-items: center;
    }
  }
}

.search-filters {
  border: solid var(--border-color);
  border-width: 1px 0;
  padding: 1rem 2rem;
  justify-content: space-between;
  overflow: visible;
  position: sticky;
  top: 0;
  z-index: 98;
  background-color: var(--white);
  transition: transform ease-in .4s;
  body.lock & {
    z-index: 98;
  }
  &.border-bottom-only {
    border: none;
    border-bottom: 1px solid var(--border-color);
  }
}

.scroll-up {
  .search-filters {
    &.fixed {
      width: 100%;
      position: fixed;
      & ~ .product-grid {
        padding-top: 74px;
      }
    }
    &.stuck {
      transform: translateY(var(--mainHeaderHeight));
      overflow: visible;
    }
  }
}
.filters-active {
  width: 0px;
}

.search-filters__filters,
.search-filters__grid,
.dropdown-filter,
.search-filters {
  display: flex;
  overflow: visible;

  .num-items-visible {
    display: flex;
    align-items: center;
    margin-right: 0.8rem;
    font-size: 1.2rem;
    font-weight: 500;
    white-space: nowrap;
  }
}
.search-filters__toggle,
.dropdown-toggle {
  &.button {
    margin-right: 0.8rem;
    padding: 0.9rem 0.8rem;
    font-size: 1.2rem;

    .button__icon {
      .icon-filters {
        width: 1.6rem;
        height: 1.6rem;
      }
    }
  }
  
  .icon__badge {
    width: 1.2rem;
    height: 1.2rem;
    margin-left: 0.5rem;
    font-size: 0.9rem;
    line-height: 1rem;
  }
}
.dropdown_refinments_form {
  .dropdown-filter:last-child {
    .dropdown-toggle {
      &.button {
        margin-right: 0;
        font-size: 1.2rem;
      }
    }
  }
}
.search-filters__grid {
  [data-component='GridSwiper'],
  .delivery-today-button {
    margin-left: 0;
    padding-left: 0.8rem;
  }
}
.search-filters__filters {
  flex: 1 0 auto;
  white-space: nowrap;
  padding-left: 0.4rem;
  width: 30%;
  /* overflow-x: auto;
  overflow-y: hidden; */

  .filters-on-navbar {
    display: none;
  }
}
.search-filters__refinements {
  display: flex;
  flex-direction: column;
}
.search-filters__container {
  flex: 1;
  overflow: auto;
  margin-right: 0.8rem;

  &::-webkit-scrollbar {
    width: 0.2rem;
  }

  .filter-root {
    opacity: 0;

    &.loaded {
      opacity: 1;
      transition: opacity 0.5s linear 0.5s;
    }
  }
  
  &::-webkit-scrollbar-thumb {
    background: var(--main-color);
  }
}
.search-filters__actions {
  flex-shrink: 0;
  padding: 2rem 1.6rem;
  display: flex;
  justify-content: space-between;
}
.shadows {
  box-shadow: 0px -9px 19px -9px var(--light-grey);
  -webkit-box-shadow: 0px -9px 19px -9px var(--light-grey);
}
