.d-xl-none {
    display: none;
}
.d-xl-inline {
    display: inline;
}
.d-xl-inline-block {
    display: inline-block;
}
.d-xl-block {
    display: block;
}
.d-xl-table {
    display: table;
}
.d-xl-table-row {
    display: table-row;
}
.d-xl-table-cell {
    display: table-cell;
}
.d-xl-flex {
    display: flex;
}
.d-xl-inline-flex {
    display: inline-flex;
}