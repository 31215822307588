.georedirect_popup {
  width: 100%;
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 100;
  text-align: right;
  .georedirect__content {
    padding: 2rem;
    border: 1px solid var(--main-color);
    background-color: var(--white);
    text-align: left;
    .button {
      width: 100%;
      &.primary-btn {
        margin-bottom: 1rem;
      }
    }
    .georedirect__title {
      margin-top: 0;
      margin-bottom: 1.5rem;
    }
    .georedirect__text {
      margin-top: 0;
    }
  }
  .button-close {
    background-color: var(--white);
    border: 1px solid var(--main-color);
    border-bottom: 0;
    cursor: pointer;
    svg {
      width: 2.4rem;
      height: 2.4rem;
    }
  }
}
