.pd-quick-links-container {
  .row {
    .pd-quick-link-box-wrapper {
      padding: 0 2.4rem 0 0;

      &:last-child,
      &:only-child {
        padding-right: 0;
      }
    }
  }
}

[data-whatintent="keyboard"] {
  .red-link:focus {
    position: relative;
    outline: 2px solid #121212;
    border-radius: 1px;
  }
}