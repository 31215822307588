.product-grid {
  .content-asset {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-bottom: 1px solid var(--main-color);
    border-right: 1px solid var(--main-color);
  }

  .col-span-2 {
    grid-column: span 2;
  }
  .col-span-3 {
    grid-column: span 2;
  }
  .col-span-4 {
    grid-column: span 2;
  }
  .col-span-5 {
    grid-column: span 2;
  }
}

.module-static-plp {
  width: auto;
  margin: 0 2rem 2rem 2rem;
}

.product-grid {
  .module-static-plp {
    width: 100%;
    min-width: unset;
    margin: 0;
  }
}
