.isNFTAdress.columns-1,
.isNFT.columns-1 {
    .content {
        display: flex;
        flex-direction: column;
    }
}
.isNFTAdress.columns-2,
.isNFT.columns-2 {
    .content {
        display: flex;
        align-items: stretch;
        justify-content: space-around;

        .visual,
        .border-container {
            width: 50%;
            margin-bottom: 0;
        }

        .visual {
            align-self: center;
        }
    }
}
.isNFTAdress.columns-3,
.isNFT.columns-3 {
    .content {
        display: flex;
        align-items: stretch;
        justify-content: space-around;

        .visual,
        .border-container {
            width: 33.3%;
            margin-bottom: 0;
        }

        .visual {
            align-self: center;
        }
    }
}
.isNFTAdress.columns-4,
.isNFT.columns-4 {
    .content {
        display: flex;
        align-items: stretch;
        justify-content: space-around;

        .visual,
        .border-container {
            width: 25%;
            margin-bottom: 0;
        }

        .visual {
            align-self: center;
        }
    }
}


.double-border-container {
    width: 65%;
    max-width: 400px;
    margin: 0 auto 0px auto;
}

.isNFTAdress {
    .visual {
        display: block;
    }

    .double-border-container {
        box-shadow:  var(--main-color) 0px 0px 0px 3px, rgb(255, 255, 255) 38px 43px 0px -8px, var(--main-color) 38px 43px 0px -5px, rgb(255, 255, 255) 20px -20px 0px -16px;
    }
}
  

.isNFT {
    padding: 0 5%;
}

.isNFTAdress-wrapper {
    .two-row__top-region {
        display: flex;
    }
    .double-border-container {
        width: 85%;
    }
}