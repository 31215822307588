.module-3 {
  margin: 0;
  padding: 10rem 0 4rem 0;
  .module-3__item {
    .module-3__container-img {
      width: 620px;
    }
    .draggable {
      position: absolute;
      width: auto !important;
    }
    .button.module-3__title {
      &::before {
        display: block;
      }
      &:hover {
        .pd-heading {
          color: var(--primary-color);
        }
      }

    }

  }
  .module-3__container-text {
    position: absolute;
    max-width: 54rem;
    bottom: 12%;
    &.left {
      left: 6%;
    }
    &.right {
      right: 6%;
    }
     .module-3__text {
       max-width: 45.1rem;
     }
  }
  .module-3__container-description {
    position: absolute;
    max-width: 40rem;
    bottom: 6%;
    &.left {
      left: 6%;
    }
    &.right {
      right: 6%;
    }
    .module-3__text {
      p {
        font-size: 1.4rem;
        line-height: 2rem;
      }
    }
  }
}