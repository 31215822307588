.module-2__item {
  .module-2__link {
    &.icon {
      .module-2__title {
        .pd-heading__container {
          width: 70%;
        }
      }
    }
    .module-2__title {
      justify-content: flex-start;
      .pd-heading__container {
        width: 100%;
        .pd-heading {
          font-size: 2.4rem;
        }
      }
    }
  }
}
.module-2__area {
  .module-2__item {
    &:nth-child(1) {
      grid-column: 1/4;
      grid-row: 1/4;
      z-index: 1;
    }
    &:nth-child(2) {
      grid-column: 3/6;
      grid-row: 3/6;
      z-index: 2;
    }
    &:nth-child(3) {
      grid-column: 1/4;
      grid-row: 5/8;
      z-index: 3;
    }
    &:nth-child(4) {
      grid-column: 3/6;
      grid-row: 7/10;
      z-index: 4;
    }
  }
}
