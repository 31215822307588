.module-8 {

  .module-8-swiper {
    margin-bottom: auto;

    .module-8__item {
      .module-8__title {
        &-empty {
          min-height: 50px;
        }
      }

      .module-8__container-img {

        .module-8__img {
          height: 620px;
        }
      }

      .module-8__container-text {
        right: 40px;
        left: 105px;
        top: -35px;
        position: absolute;

        .module-8__text {
          font-size: 1.4rem;
          line-height: 2rem;
          letter-spacing: .2rem;
        }
      }
    }

    .module-8-swiper-button-next {
      right: 40px;
    }

    .module-8-swiper-button-prev {
      right: 90px;
    }

    /*@media screen and (max-width: 1440px) {
      .swiper-slide {
        width: 44%;
      }
    } */
  }

  .module-8-header {
    padding-top: 8rem;

    .module-8-maintitle {
      line-height: 3.4rem;
      font-size: 3.6rem;
      margin-bottom: 5rem;
    }
  }
}