.module-15B_container {
  padding: 8rem 2rem;

  .module15B {
    margin-right: auto;
    margin-left: auto;
    max-width: 104.2rem;

    .module-15B-title_container {
      font-size: 3.088rem;
      line-height: 3.7313rem;

      svg[class^='icon'] {
        width: 3.08rem;
        height: 3.08rem;
        margin-left: 1.287rem;
      }
    }

    .module-15B-swiper {

      .swiper-wrapper {
        transform: none!important;
        transition-duration: initial!important;
      }

      .module-15B__item {
        width: 33.3%;

        .module15B-item-text {
          gap: 1.681rem;
          padding: 2.69rem 1.681rem;

          .module-15B-item-title {
            p {
              font-size: 1.8rem;
              line-height: 2.4rem;
            }
          }

          .module-15B-item-paragraph {
            p {
              font-size: 1.4rem;
              line-height: 2rem;
            }
          }
        }

        .module-15B-container-img {
          width: 100%;

          .module-15B-img {
            height: 100%;
            width: 100%;
          }
        }
      }
    }
  }

}
