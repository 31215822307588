.tile-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  height: 100%;
  width: 100%;
  display: none;

  &.attribute-opened {
    display: block;
  }
}

.product-grid {
  .product-tile--add-to-bag {
    display: none;
  }

  &.acc-wishlist__grid {
    .product-tile--add-to-bag {
      display: block;
    }
  }
}

.product-tile--add-to-bag {
  .open-attributes {
    position: absolute;
    bottom: 1.6rem;
    right: 1.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4rem;
    height: 4rem;
    border: 0.1rem solid var(--main-color);
    cursor: pointer;
    z-index: 5;
  
    svg {
      width: 1.6rem;
      height: 1.6rem;
      fill: var(--main-color);
    }
  }

  .add-preorder-to-cart-section{
    .container-informations {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      column-gap: 0.5rem;
      position: relative;
      margin-top: -0.4rem;
      margin-bottom: 1.6rem;
      background-color: rgba(237, 237, 237, 0.5);
      padding: 0.8rem;

      .in-stock-date,
      .only-store {
        order: 2;
        color: var(--black);
        font-size: 1.2rem;
      }
      svg {
        height: 1.4rem;
        width: 1.4rem;
        fill: var(--black);
      }
      p {
        margin-top: 0.2rem;
      }
      .open {
        display: block;
      }
      .close {
        display: none;
        .icon-close {
          border-top: 1px solid var(--black);
          border-right: 1px solid var(--black);
          border-left: 1px solid var(--black);
        }
      }
      .open,
      .close {
        background: none;
        border: none;
        padding: 0;
        order: 1;
      }
      .content {
        display: none;
      }
      &.open {
        .open {
          display: none;
        }
        .close {
          display: block;
        }
        .content {
          color: var(--black);
          display: block;
          position: absolute;
          background-color: white;
          padding: 1.2rem;
          border: 1px solid var(--black);
          width: 100%;
          z-index: 2;
          top: 1.4rem;
          left: 0;
          font-size: 1.2rem;
          text-align: center;
          p,
          a {
            color: var(--black);
            margin: 0;
            font-size: 1.2rem;
          }

          .content-asset {
            border-bottom: none;
            border-right: none;
          }
        }
      }
    }
  }
  
  .product-tile--product-information {
    visibility: hidden;
    transition: visibility 0ms linear 0.8s;

    &.open-card {
      visibility: visible;
      transition: visibility 0.5s;

      .product-tile__actions {
        transition: transform 0.5s;
        transform: translateY(0%);
        min-height: 14rem;
 
      .product-tile__actions-content {
          transition: opacity 0.3s linear 0.5s;
          opacity: 1;
        }
      }
    }
  }  
  
  .product-tile__actions {
    height: auto;
    position: absolute;
    box-sizing: border-box;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 2rem;
    //padding: 2rem 1.36rem 1.46rem;
    border-top: 1px solid var(--main-color);
    background-color: var(--white);
    z-index: 5;
    transform: translateY(100%);
    transition: transform 0.5s linear 0.3s;

    button {
      box-sizing: border-box;
    }
  
    .product-tile__actions-content {
      opacity: 0;
      transition: opacity 0.3s linear 0ms;
    }
  
    .add-to-cart {
      margin-top: 1rem;
    }
  
    .add-to-wish-list {
      display: none;
    }
  
    .variation-attribute {
      margin-bottom: 0.9rem;
      color: var(--main-color);

      .attribute-values {
        &.invalid {
          .form-element__error {
            display: flex;
            opacity: 1;
            align-items: center;
            column-gap: 0.5rem;
            transform: translateY(-1.5rem);
            padding: 0.4rem;
            height: auto;
            width: fit-content;
            svg{
              width: 1.5rem;
              height: 1.5rem;
              fill: var(--white);
            }
          }
        }
      }
  
      .variation-attribute-color-container {
        display: flex;
        justify-content: space-between;
        width: 100%;
        display: block;
  
        .product-variation-attribute {
          button {
            margin-left: 0;
            margin-right: 1rem;
          }
        }
      }
  
      &.color {
        margin: 0 -1.2rem;
        padding: 1.8rem 1.2rem 0;
  
        .attribute-value {
          margin-top: 0;
          margin-bottom: 1rem;
        }
      }

      &.length {
        padding-top: 1.8rem;
      }
  
      .swatch-attribute-values {
        display: flex;
        flex-flow: row wrap;
      }
      .attribute-value {
        background-color: transparent;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        height: 2rem;
        min-width: 3rem;
        padding: 0 0.8rem;
        color: #121212;
        border: 0.1rem solid #dfdfdf;
        margin-bottom: 0.6rem;

        span {
          font-size: 1.2rem;
          line-height: 1;
        }

        svg {
          margin-left: 0.5rem;
          width: 1.2rem;
          height: 1.2rem;
        }
        &.selected {
          // border: 1px solid var(--alternative-color);
          background-color: var(--main-color);
          color: var(--white);
          border-color: var(--main-color-opacity);
        }
        &.unavailable {
          text-decoration: line-through;
          color: #dfdfdf;
          svg{
            fill: #dfdfdf;
          }
        }
        &.unavailable.selected {
          svg {
            fill: white;
          }
        }
        &.attribute-value--swatch {
          padding: 0;
          width: 1.6rem;
          height: 1.6rem;
          border-radius: 50%;
          margin-right: 1rem;
          background-repeat: no-repeat;
          border: 0.1rem solid var(--white);
          box-shadow: 0 0 0.1rem 0.2rem #dfdfdf;
          overflow: hidden;
          min-width: auto;

          &:last-child {
            margin-right: 0;
          }

          &.selected {
            box-shadow: 0 0 0.1rem 0.2rem var(--main-color);
          }
        }
      }
      .product-variation-attribute {
        color: var(--main-color);
        margin: .5rem 0 0;
        &.invalid{
          animation-name: shake;
          animation-duration: 0.6s;
          animation-timing-function: ease-in-out;
          animation-direction: alternate;
        }
        &.giftcard-amount {
          margin-bottom: 2rem;
          .attribute-value {
            padding: 0 0.5rem;
          }
        }
      }
      .attribute-label {
        font-size: 1.2rem;
        margin: 0 0 0.6rem;

        @include primaryFont($weight: 500);
        color: black;
  
        button {
          font-size: 1.2rem;
        }

        .selected-color {
          display: none;
        }
      }
  
      &.size,
      &.length {
        @media (max-width: 991px) {
          margin-top: 2rem;
        }
      }
    }

    .additional-msg {
      margin-top: 1.6rem;
      color: rgba(0, 0, 0, 0.6);
      font-size: 1.2rem;
    }

    .section-info-single-product-plp {
      margin-bottom: 0.8rem;
    }

    .section-price-single-product-plp {
      margin-bottom: 2.4rem;
    }

    .price-info-omnibus {
      height: auto;
    }
  }

  .button-add-to-cart-none,
  .button_out-of-stock-none {
    display: none !important;
  }
}

.swiper-container {
  .product-tile--add-to-bag {
    display: none;
  }
}

.section-info-single-product-plp {
  .product-tile-body__link {
    font-size: 1.2rem;
  }
}

.promo-login--wrapper {
  margin-top: 1.85rem;

  .promo-login {
    display: block;
    text-align: center;
    text-decoration: none;

    svg {
      width: 1.4rem;
      height: 1.8rem;
      margin-right: 0.2rem;
    }

    span {
      display: inline-block;
      vertical-align: middle;

      font-size: 1rem;
      text-transform: uppercase;

      &.text-micro {
        text-decoration: underline;
      }
    }
  }
}

.mini-carousel {
  .product-tile--add-to-bag {
    .product-tile--product-information {
      &.open-card {
        .product-tile__actions {
          min-height: 15rem;
        }
      }
    }
  }
}
