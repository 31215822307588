.experience-assets-moduleClipboard {
  display: flex;
  flex-direction: column;
  align-items: start;

  p {
    &.before-text {
      margin: 0 0.8rem 0 0;
    }

    &.white {
      color: var(--white);
    }

    &.black {
      color: var(--black);
    }
  }

  .module-clipboard-container {
    background-color: var(--white);
    color: var(--main-color);
    display: inline-flex;
    align-items: center;
    border: 0.1rem solid var(--main-color);
    margin-top: 0.8rem;
    padding: 0.4rem;
    cursor: pointer;

    svg {
      height: 1.6rem;
      width: 1.6rem;
    }

    p {
      line-height: 1.4rem;
      margin: 0 0 0 0.4rem;
    }

    &.negative {
      color: var(--white);
      background-color: var(--main-color);
      border: 0.1rem solid var(--white);

      svg {
        fill: var(--white);
      }

      p {
        color: var(--white);
      }
    }

    &.black {
      color: var(--white);
      background-color: var(--black);
      border: 0.1rem solid var(--white);

      svg {
        fill: var(--white);
      }

      p {
        color: var(--white);
      }
    }
  }
}

.popup-notification {
  &.module-clipboard-popup {
    width: fit-content;
    margin-right: 2rem;

    &::after {
      display: none;
    }

    &::before {
      content: '';
      background-image: url('../../imgs/agree.svg');
      background-repeat: no-repeat;
      background-position: center;
      width: 2rem;
    }

    span {
      margin-left: 1rem;
      width: unset;
    }
  }
}