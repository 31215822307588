.visual {
  &.tile {
    padding: 10% 20%;
  }
  &.module01_half,
  &.module01_half_v2 {
    width: fit-content;
    margin: auto;
  }

  &.module01_half {
    padding: 15%;

    &:nth-child(1) {
      padding-left: 30%;
    }
    &:nth-child(2) {
      padding-right: 30%;
    }
    &.module01_half_full {
      margin-top: 0;
      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }
  }

  &.tile_vertical,
  &.tile_rescale {
    picture {
      max-width: 75vw;
      margin: 5% 15%;
    }
  }

  &.double_border_top {
    width: fit-content;
    margin: 0;
  }

  &.double_border_content {
    width: fit-content;
    margin: 0;
  }

  &.module01_half_v2 {
    padding: 15%;
  }

  @media (min-width: 990px) {
    &.module01_half,
    &.module01_half_v2 {
      &:nth-child(1) {
        padding-left: 30%;
      }
      &:nth-child(2) {
        padding-right: 30%;
      }
    }
  }
}
@media screen and (min-width: 992px) {
  .visual {
    &.tile_vertical,
    &.tile_rescale {
      picture {
        max-width: 50vw;
        margin: 10% 25%;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .visual {
    &.module01_half_full {
      margin: 10rem auto;
      img {
        max-width: 50vw;
        border: 1px solid var(--border-color);
      }
    }
  }
}
