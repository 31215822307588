// .product-grid-component {
//   .product-grid {
//     grid-template-columns: repeat(auto-fit, 45vw);
//     grid-auto-flow: column;
//     border: none;
//   }
//   .product-grid-item {
//     min-width: 45vw;
//   }
//   .top-row {
//     grid-row-start: 1;
//   }
//   .bottom-row {
//     grid-row-start: 2;
//   }
//   .full-row {
//     display: none;
//   }
//   &.on-hover {
//     .container-price-name-carousel {
//       opacity: 1;
//     }
//     .add-to-wish-list {
//       opacity: 1;
//     }
//     .product-badge {
//       opacity: 1;
//     }
//   }
// }
// .product-grid-content {
//   overflow-x: auto;
//   border-top: 1px solid var(--main-color);
//   border-left: 1px solid var(--main-color);
// }
.product-grid-component {
  .product-grid {
    grid-template-columns: repeat(8, 120px);
  }

  &.on-hover {
    .add-to-wish-list {
      display: none;
    }
    .product-badge {
      display: none;
    }

    .product-tile-body {
      display: none;
    }
  }
}