.promo-strip__close {
  margin-left: 5rem;
}
.promo-strip {
  width: 60rem;
  &.promo-strip--full-width {
    width: 100%;
    .promo-strip__close {
      margin-left: auto;
    }
  }
}
