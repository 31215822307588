.navigation-user__item {
  &:not(.navigation-user__item--login) {
    border-top: 1px solid var(--main-color);
  }
}

.password-recovery-panel,
.transfer-social-account-panel {
  .action-bar {
    display: flex;
  }
}
