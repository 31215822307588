.module-1C {

  .module-1C-swiper {

    .module-1C-slide {

      .module-1C-container-box {
        width: 400px;
        margin: 0;
      }
    }
  }

  .module-1C-swiper-pagination {

    .swiper-pagination-container {
      padding: 0 40px;
    }
  }

  .module-1C-swiper-controls{

    .module-1C-swiper-button-play,
    .module-1C-swiper-button-pause {
      right: 40px;
      bottom: 35px;
    }
  }
}