.experience-layout-module13B {
  &.white {
    background-color: var(--white);
  }

  &.off-white {
    background-color: var(--lighting-grey);
  }

  .pd-contact-us-container {
    padding: 4rem 2rem;

    .title, .p-grey-sm {
      width: fit-content;
    }


    .pd-conctact-us-box-wrapper {

      &:last-child {
        padding-bottom: 0;
      }

      .region {
        border: 0.1rem solid var(--light-grey);
        padding: 1.6rem;
        height: 100%;
      }
    }

    .pd-conctact-us-box-wrapper > * {
      background-color: var(--white);
    }


    .pd-vertical-boxes {
      display: flex;
      flex-wrap: wrap;
      gap: 1.6rem;
      margin: 0;

      .pd-conctact-us-box-wrapper {
        padding: 0;
      }
    }
  }
}


