.product-grid {
  .col-span-2 {
    grid-column: span 2;
  }
  .col-span-3 {
    grid-column: span 3;
  }
  .col-span-4 {
    grid-column: span 4;
  }
  .col-span-5 {
    grid-column: span 5;
  }
  .row-span-2 {
    grid-row: span 2;
  }
  .row-span-3 {
    grid-row: span 3;
  }
  .row-span-4 {
    grid-row: span 4;
  }
  .row-span-5 {
    grid-row: span 5;
  }
}

.module-static-plp {
  width: 43%;
  min-width: 62rem;
  margin: 0 auto;
}

.module-static-plp--top {
  .pd-c-container {
    border-width: 1px;
  }
}

.product-grid {
  .module-static-plp {
    min-width: unset;
  }
}
