.panel-language-selector {
  .language-selector {
    padding: 0 2rem;
  }
  .header-panel {
    margin-top: 0;
    height: 100%;

    .action-bar__button {
      svg {
        fill: var(--main-color);
        width: 2.4rem;
        height: 2.4rem; 
        margin-left: 0;
      }
      background-color: var(--white);
      position: absolute;
      right: 0;
      width: 5rem;
      height: 4rem;
      top: 0;
    }
  }
}
.language-selector__title {
  margin: 2rem 0 0 0;
  @include primaryFont;
  font-size: 1.8rem;
}
.language-selector__language {
  text-decoration: none;
  text-transform: capitalize;
  display: block;
  margin-top: 1.6rem;
  color: black;

  svg {
    margin-left: 0.5rem;
    display: inline-block;
    width: 1.6rem;
    height: 1.6rem;
    position: absolute;
    top: 0;
    left: 100%;
  }

  &.language-selector__language--current {
    position: relative;
    width: fit-content;
    color: var(--main-color);
  }

  &.language-selector__language--first {
    margin-top: 3.5rem;
  }

  &.language-selector__language--current:hover {
    &::after{
      content: " ";
      position: absolute;
      left: 0;
      top: 100%;
      width: 100%;
      height: 1px;
      background-image: linear-gradient(90deg, var(--main-color), var(--main-color));
      background-size: 6px 1px;
      transition: 500ms;
    }
  }
}

.panel-country-selector {

  ::-webkit-scrollbar {
    width: 20px;
  }

}