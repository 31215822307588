@import './text-style';

.pd-text {
  &.pd-text--hide-for-small {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
  &.pd-text--hide-for-medium {
    @media screen and (max-width: 991px) {
      display: none;
    }
  }
  &.pd-text--hide-for-large {
    @media screen and (min-width: 992px) {
      display: none;
    }
  }
}
