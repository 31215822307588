@keyframes shake {
  0% {
    transform: translateX(0);
  }
  60% {
    transform: translateX(1.2rem);
  }
  75% {
    transform: translateX(-0.5rem);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes mmfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
form {
  // overflow: hidden;
}
.form-element {
  --spacing: 0.5rem;
  position: relative;
  margin-bottom: 3.2rem;
  &.invalid {
    animation-name: shake;
    animation-duration: 0.6s;
    animation-timing-function: ease-in-out;
    animation-direction: alternate;
  }

  .show-password-button {
    position: absolute;
    height: 3rem;
    width: 3rem;
    right: 0;
    top: 0;
    margin: 0;
    padding: 0;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    .icon-eye, .icon-eye-close {
      display: inline-block;
      width: 2.4rem;
      height: 2.4rem;
      opacity: 1;
      transition: all ease-in .1s;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .icon-eye-close {
      opacity: 0;
      width: 2rem;
      height: 2rem;
    }
    &.visible {
      .icon-eye-close {
        opacity: 1;
      }
      .icon-eye {
        opacity: 0;
      }
    }
  }
}
.form-element__input,
.form-element__label,
.form-element__label_mobile,
.form-element__label_classic {
  color: var(--text-color);
  font-size: 1.4rem;
}
.form-element__input {
  @include primaryFont();
  border: none;
  background-image: none;
  background-color: transparent;
  box-shadow: none;
  width: 100%;
  border-bottom: 1px solid var(--border-color);
  padding: var(--spacing) 0;
  border-radius: 0;
  font-size: 1.6rem;
  &::placeholder {
    color: var(--alternative-color);
  }
  &:not(:placeholder-shown),
  &:focus {
    + .form-element__label, .form-element__label_mobile{
      font-size: 1rem;
      line-height: 8px;
      letter-spacing: 0.2px;
      top: -1rem;
      animation: mmfadeIn 0.2s cubic-bezier(0, 0, 0.2, 1);
    }
  }
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }

    &:placeholder-shown {
      & + .form-element__label , .form-element__label_mobile{
        top: var(--spacing);
      }
    }
    &.invalid {
      & ~ .form-element__error {
        display: inline-block;
        opacity: 1;
        transform: translateY(0);
        height: auto;
        margin-top: 1rem;

      }
    }
    &.valid {
      //color: green;
    }

  &:disabled {
    + .form-element__label, .form-element__label_mobile {
      color: var(--black);
      opacity: 0.3;
    }

    opacity: 0.3;
  }
}
.form-element__select {
  &.invalid {
    & ~ .form-element__error {
      display: inline-block;
      opacity: 1;
      transform: translateY(0);
      height: auto;
      margin-top: 1rem;
    }
  }
}
.form-element__label,
.form-element__label_mobile {
  position: absolute;
  width: 100%;
  left: 0;
  cursor: pointer;
  transition: top 200ms;
  pointer-events: none;
  white-space: nowrap;
  //text-overflow: ellipsis;
  overflow: hidden;
}
.form-element__error {
  // display: inline-block;
  display: none;
  opacity: 0;
  height: 0;
  position: relative;
  padding: 0.3rem 0.4rem;
  margin-top: 2rem;
  color: var(--alternative-color);
  background-color: var(--main-color);
  transform: translateY(100%);
  transition: transform 500ms;
  font-size: 1.2rem;
  &::before {
    content: ' ';
    height: 0;
    top: -10px;
    position: absolute;
    width: 0;
    border: 5px solid transparent;
    border-bottom-color: var(--main-color);
  }
}

.form-error {
  padding: 0.3rem 0.4rem;
  margin: 1rem 0 2rem;
  color: var(--alternative-color);
  background-color: var(--main-color);
}
.cart,
.checkout-login-container,
.checkout-container {
  .form-element__input {
    border-bottom: 1px solid var(--black);
    &::placeholder {
      color: transparent;
    }
  }
  .adyen-checkout__input-wrapper {
    border-bottom: 1px solid var(--black);
  }
  .addressSelector {
    border-bottom: 1px solid var(--black);
    font-size: 1.4rem;
  }
  .custom-select {
    border-bottom: 1px solid var(--black);
    margin-bottom: 0;
  }
  .shippingAddressSelector {
    padding-right: 3rem;
    overflow: hidden;
    margin-bottom: 3.2rem;
  }
}

.store-element {
  margin-bottom: 0;

  &.invalid {
    & ~ .form-element__error {
      display: inline-block;
      opacity: 1;
      transform: translateY(0);
      height: auto;
      margin-top: 1rem;
      position:absolute;
      bottom: -3.2rem;
      z-index: 1;

      svg {
        width: 1.1rem;
        height: 1.1rem;
        display: inline-block;
        vertical-align: middle;
        margin-right: 0.3rem;
      }
    }
  }
}

.group-prefix-phone {
  display: flex;

  .form-prefix-input {
    width: 70px;
    margin-right: 0.8rem;
  }

  .form-element:last-child {
    flex-grow: 1;
  }

  .form-element:first-child {
    .form-element__error {
      display: none;
    }
  }

  .form-element:last-child {
    .form-element__error {
      transform: translateX(-78px);
    }
  }
}

.label-mandatory-fields {
  color: var(--alternative-grey);
  font-size: 1.4rem;
  line-height: 1.4rem;
  letter-spacing: 0.02rem;
  margin: 2.4rem 0;
}