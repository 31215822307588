.side-panel__close {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: none;
  &.open {
    opacity: 1;
  }
}
.side-panel--header-panel {
  .side-panel__inner {
    border-top: none;
  }
}
.side-panel--search {
  .side-panel__inner {
    z-index: 1;
  }
}