.side-panel__inner_cookie {
  padding: 0 !important;
  .panel-cookie-management {
    padding: 2rem;
    .form-element {
      margin-bottom: 1rem;
    }
    .cookie-paragraph {
      margin: 0 0 2rem 0;
      padding-left: 3rem;
    }
    .form-element__label_checkbox {
      font-weight: 800;
    }
    .wrapper-actions {
      button {
        margin-bottom: 1rem;
      }
    }
  }
}
