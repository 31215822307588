.pd-editorial-header_bar {
  .editorial-bar-header_carousel {
    width: 50vw;
    padding: 0 2rem;
    margin: 0 auto;
    &:after,
    &:before{
      content: '';
      width: 2rem;
      height: 100%;
      position: absolute;      
      top: 0;
      z-index: 1;
    }
    &:after {
      right: 0;
    }
    &:before{
      left: 0;
      z-index: 2;
    }
    @at-root .pd-editorial-header_bar.section-color_grey {
      .editorial-bar-header_carousel {
        &:after,
        &:before{
          background-color: #ebebeb;
        }
      }
    }
    @at-root .pd-editorial-header_bar.section-color_black {
      .editorial-bar-header_carousel {
        &:after,
        &:before{
          background-color: var(--black);
        }
      }
    } 
    @at-root .pd-editorial-header_bar.section-color_white {
      .editorial-bar-header_carousel {
        &:after,
        &:before{
          background-color: var(--white);
        }
      }
    }
    @at-root .pd-editorial-header_bar.section-color_red {
      .editorial-bar-header_carousel {
        &:after,
        &:before{
          background-color: var(--main-color);
        }
      }
    }                 
  }
  .editorial-bar-header_item {
    justify-content: center;
    overflow: hidden;
    .editorial-bar-header_item-content {
      justify-content: center;
      &--long-content {
        &-double-cta,
        &-single-cta {
          > div {
            justify-content: center;
          }
        }
        &-single-cta {
          a {
            justify-content: center;
          }
          p {
            max-width: 40%;
          }          
        }
        &-double-cta {
          p {
            max-width: 20%;
          }
        }        
      }      
    }
  }
  .btn-swiper-editorial-bar__next, .btn-swiper-editorial-bar__prev {
    display: block;
  }
}
