.module-static-plp--08 {
  .element-wrapper {
    padding: 6rem 0;
    margin: 0 auto;
    width: 64%;

    .item {
      &.open {
        .content-text {
          display: block;
        }
      }
    }
  }

  .content-img {
    .info-button {
      display: none;
    }
  }

  .content-text {
    display: block;
  }
}
