.module-static-plp--13 {
  .content-heading {
    .element-heading {
      font-size: 3.6rem;

      span {
        font-size: 3.6rem;
      }
    }
  }
}
