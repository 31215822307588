.module-static-plp--14 {
  padding: 2rem;

  .content-img {
    height: 8.6rem;
    width: 8.6rem;
  }

  .content-text {
    padding-top: 2.4rem;

    .item-title {
      margin: 0 0 1.6rem 0;
      font-size: 3.2rem;
      line-height: 3.2rem;
    }

    p {
      margin: 0 0 1.6rem;
      font-size: 1.6rem;
      line-height: 2.2rem;
    }
  }
}
