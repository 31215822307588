.module-01__item--double {
  .module-01__slide {
    margin: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    .visual {
      &:not(:first-child) {
        display: block;
      }
    }
  }
}
.module-01__slides {
  &.swiper-wrapper {
    box-sizing: inherit;
  }
}
.module-01 {
  padding: 0;
}
.module-01__slide {
  height: 100%;
}
