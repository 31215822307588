.login-form {
  .login-form__title {
    margin-bottom: 1.6rem;
  }
  .login-form__text {
    margin-bottom: 2.4rem;
  }
  .form-element {
    .form-element__label {
      text-align: left;
    }
  }
}
