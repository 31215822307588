.out-of-stock_panel {
  .side-panel__inner {
    overflow: auto;
    z-index: 10;

    &.open {
      .action-bar {
        display: block!important;
      }
    }
  }
  .contenent_out-of-stock_panel {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    height: 100%;
    .title-out-of-stock_panel {
      font-size: 1.8rem;
      @include primaryFont($weight: 500);
      margin: 0 2rem 3rem 0;
    }
    .section_product-out-of-stock {
      margin-bottom: 2.4rem;
      .container-img-out-of-stock {
        width: 12.2rem;
        height: 18.1rem;
        border: 1px solid var(--light-grey);
        margin-right: 1.2rem;
        .img-out-of-stock {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .container-info-out-of-stock {
        p {
          margin: 0;
          color: var(--black);
        }
        .attributes_product-out-of-stock {
          margin-top: 0.3rem;

          p {
            color: #666;
            font-size: 1.2rem;
          }
        }
        .prices {
          margin-top: 2rem;
          padding: 0;
          .product-tile-body__price {
            text-align: left;
            padding: 0;
            .value {
              font-size: 1.8rem;
            }
          }
        }
      }
    }
    .section-form_out-of-stock {
      flex: 1;
      position: relative;
      p {
        color: var(--black);
        margin: 0;
        margin-bottom: 3rem;
      }

      .label-mandatory-fields {
        color: var(--alternative-grey);
        margin: 2.4rem 0;
      }

      .invalid-notify-me {
        color: var(--main-color);
      }

      .form-element__input {
        border-bottom: 1px solid var(--black);
        color: var(--black);
      }
      .form-element__label {
        color: var(--black);
      }
      .title-form-out-of-stock_panel {
        font-size: 1.8rem;
        @include primaryFont($weight: 500);
        margin: 0;
        margin-bottom: 3.2rem;
      }
      .gender-newsletter__label,
      .custom-control-label {
        color: #666;
        margin-right: .4rem;
      }
      .gender-newsletter__label {
        display: block;
        margin: 2rem 0;
      }
      .guest-login-form-disclaimer {
        a {
          color: #666;
          font-size: 1.4rem;
        }
      }
      .product-notify-body {
        .form-group {
          .form-element {
            margin-bottom: 1.7rem;
          }
        }
        button {
          position: absolute;
          bottom: 0;
        }
        @media (min-width: 992px) {
          button {
            position: relative;
          }
        }
      }
    }
  }
  .action-bar {
    position: absolute;
    width: auto;
    right: 0;
    border-left: 1px solid var(--main-color);
    border-right: 0;
  }
}
.out-of-stock-newsletter {
  margin: 0;
  .container-subscribe-newsletter {
    margin-bottom: 3rem;
    .custom-control-input {
      border: 1px solid var(--black);
      &:checked {
        background-color: var(--black);
      }
      &.form-element__radio {
        background-color: unset;
        &::after {
          background: var(--black);
        }
      }
    }
  }
}
