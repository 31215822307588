@import '../../pages/account/mixins';

.newsletter__box {
  background-color: var(--alternative-color);
  padding: 2rem;
}

.newsletter__privacy-disclaimer {
  p {
    font-size: 1rem;
  }
}
.newsletter__heading {
  margin-bottom: 2rem;
  
  .pd-heading__container {
    .pd-heading {
      @include primaryFont;
      margin: 0;
      font-size: 1.8rem;
    }
    p {
      font-size: 1rem;
      line-height: 1.8rem;

      @include media-breakpoint-up (md) {
        font-size: 1.2rem;
      }
    }
  }
}
.newsletter__form {
  .form-element {
    margin-bottom: 1.5rem;
  }
}
.container-subscribe-newsletter {
  margin-bottom: 3.6rem;
  .gender-newsletter {
    padding: 0;
    .guest-login-form-disclaimer,
    .gender-newsletter__label {
      display: block;
      margin-bottom: 1rem;
    }
    .gender-newsletter__label {
      display: none;
    }
    .conatiner_radio-gender {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 1.8rem 0 2rem 0;
      .radio-button-wrapper {
        margin-right: 3rem;
      }
    }
  }
  .gender-newsletter {
    padding: 0 2rem;
    display: none;
  }
  .checkbox__container {
    margin-bottom: 1rem;
  }
  .gender-newsletter--show {
    display: block;
  }
}
