.experience-article {
  &__main {
    max-width: 62rem;
    margin: 3.2rem auto 0;
    padding: 0 2rem;
  }

  &__quick-share {
    z-index: 2;
    background: $white;

    &__title {
      padding-left: 1.4rem;
      height: 4rem;
      border: 1px solid var(--main-color);
      border-bottom: none;
    }

    &.desktop {
      position: sticky;
      top: 52vh;
      max-width: 25rem;
      margin-left: 8rem;
      margin-top: -13.2rem; // 100px + 32px

      button {
        padding-left: 0.8rem;
        justify-content: flex-start;

        &:not(:last-child) {
          border-bottom: none;
        }
      }
    }
    button {
      .section-link {
        display: flex;
        align-items: center;
        &__copied {
          display: none;
        }
      }
      &.link-copied {
        color: var(--green-validation);
        border: 1px solid var(--green-validation);
        svg {
          fill: var(--green-validation);
        }
        &:not([disabled]) {
          &:hover,
          &:focus,
          &:active {
            color: var(--green-validation);
            svg {
              fill: var(--green-validation);
            }
          }
        }
        &::before {
          display: none;
        }
        .section-link {
          display: none;
          &__copied {
            display: flex;
          }
        }
      }
    }

    &.mobile {
      border-top: 1px solid var(--main-color);
      background-color: var(--white);
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 6rem;
      padding: 0 2rem;
      z-index: 3;

      .experience-article__quick-share__container {
        height: 100%;
      }

      button {
        padding: 0;
        border: none;
        justify-content: flex-start;
        min-width: 25rem;
        height: 100%;

        &.has-border {
          border-right: 1px solid var(--main-color);
        }

        .button_icon {
          display: inline-block;
          margin-right: 1rem;
        }
      }

      svg {
        width: 2.4rem;
        height: 2.4rem;
      }
    }

    &__arrows {
      width: 100%;
      justify-content: space-evenly;
      margin-left: 2rem;
    }
  }

  &__navigation-wrapper {
    position: sticky;
    top: 95vh;
    border: 1px solid var(--main-color);
    background: $white;
    margin-left: auto;
    margin-right: 1px;
    max-width: 18rem;
    z-index: 3;

    a {
      display: inline-block;
      padding: 8px;
      min-width: 90px;
      text-decoration: none;

      &:first-of-type {
        border-right: 1px solid var(--main-color); 
      }
    }

    span {
      display: flex;
      align-items: center;
      justify-content: space-around;
    }

    i {
      &, svg {
        width: 12px;
        height: 20px;
      }
    }
  }  

  &__related {
    padding: 6rem 0;

    .container {
      max-width: 62rem;
    }

    &__title {
      font-size: 3.4rem;
      line-height: 3.2rem;
      margin: 0;
      border: 1px solid var(--main-color);
      border-bottom: none;
      padding: 0.3rem 0.8rem;
      width: fit-content;
    }

    &__list {
      border: 1px solid var(--main-color);
      padding: 1.2rem 0;

      a {
        text-decoration: none;
      }

      &.row {
        margin: 0 !important;
      }

      .col-12,
      .col-md-3 {
        padding: 0 1.2rem;
      }

      .cms-article {
        &__title {
          margin: 1.2rem 0 0 0;
          font-size: 2.8rem;
          line-height: 2.6rem;
        }

        &__excerpt {
          margin: 8px 0;
          font-size: 1.4rem;
          line-height: 2rem;
        }

        &__image {
          width: 100%;
          height: 19rem;
          object-fit: cover;
          object-position: top;
        }

        &__tag {
          margin-bottom: 2rem;
        }
      }
    }
  }

  .experience-magazine-magazineStrip{
    position: relative;
    min-height: 18rem;
  }
}

@media (min-width: 992px) {
  .experience-magazine-magazineStrip{
    margin-bottom: 5rem;
  }
}
