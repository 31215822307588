.module-static-plp--01 {
  position: relative;
  .element-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    // width: fit-content;
    margin: auto;
  }
  a {
    text-decoration: none;
  }
  .content-img {
    width: 100%;
    flex: 1;

    img {
      display: block;
      width: 100%;
    }
  }

  .content-text {
    padding: 1.2rem;
    border-top: 1px solid var(--border-color);
    p {
      margin: 0;
    }
  }
  &__border {
    width: calc(100% - 4rem);
    padding: 4rem 0;
    margin: auto;
    margin-top: 0;
    .element-wrapper {
      border: 1px solid var(--main-color);
    }
  }
}

.module-plp-video.module-static-plp--01 {

  .link-title {
    border-bottom: none;
    flex-direction: row;
    gap: 1rem;
    justify-content: start;
    padding: 0.8rem 1rem 0;

    &:hover {
      .ctaText, svg {
        color: var(--white);
      }

    }

    .ctaText {
      font-size: 2.4rem;
    }

    svg {
      height: 24px;
      width: 24px;
    }
  }

  .container-video-description {
    border: 1px solid var(--main-color);

    .element-wrapper {
      position: relative;
      width: 100%;
      //aspect-ratio: 16 / 9;
    }

    .element-wrapper video {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .controls {
      position: absolute;
      bottom: 10px;
      right: 10px;
    }

    .custom-play, .custom-pause {
      background-color: transparent;
      border: none;
      cursor: pointer;
    }

    .content-text {
      padding: 1.6rem 1.5rem;
    }

    .wrapper-buttons {
      display: flex;
      gap: 1rem;
      margin-top: 1rem;

      .button.primary {
        padding: 1.3rem 4rem;
      }
    }
  }

}

.static-plp-1-full-mobile,
.static-plp-1-title-video-desc-mobile,
.static-plp-1-video-desc-cta-mobile,
.static-plp-1-video-desc-mobile,
.static-plp-1-video-only-mobile {
  padding: 3.25rem 2rem;
}

.product-grid {
  .module-static-plp--01 {
    &__border {
        width: 100%;
        padding: 0;
        margin: 0;

        .element-wrapper {
          width: calc(100% - 8rem);
          margin: 0 auto;
        }
    }

    .content-img {
      a {
        padding-top: 150%;
        position: relative;
        display: block;
        overflow: hidden;

        img {
          display: block;
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
        }
      }
    }
  }
}

.product-grid {
  .content-asset {
    .module-static-plp--01:not(.module-static-plp--01__border) {
      align-self: flex-start;
    }
  }

  .content-asset-desktop {
    display: none;
  }

  .content-asset-mobile {
    display: flex;
  }

  .col-span-1 {
    .content-asset {
      .module-static-plp--01:not(.module-static-plp--01__border) {
        .content-img {
          a {
            padding-top: 150%;

            img {
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
      }
    }
  }

  .col-span-2 {
    .module-static-plp--01:not(.module-static-plp--01__border) {
      align-self: center;

      .content-img {
        a {
          padding-top: unset;

          img {
            position: static;
          }
        }
      }
    }
  }
}
