.module-static-plp--04 {
  position: relative;

  .element-scrollable {
    display: block;
    overflow-x: auto;
    width: 100%;
  }
  .element-wrapper {
    display: flex;
    flex-direction: row;
    padding: 6rem 2rem 10rem 0;
    width: calc(100% - 2rem);
    white-space: nowrap;
    overflow: visible;
    margin-left: 2rem;

    .single-item {
      display: flex;
      flex-direction: column;
      width: 50%;
      flex: 0 0 50%;
      margin-right: -1px;
      position: relative;
    }
  }

  a {
    text-decoration: none;
  }

  .content-heading {
    display: block;
    padding: 0;
    font-size: 0;
    text-align: left;

    .heading-link {
      display: inline-block;
      padding: 0 0.8rem;
      border: 1px solid var(--border-color);
      border-bottom: none;
      position: relative;
      max-width: 100%;
    }

    .element-heading {
      display: inline-block;
      vertical-align: middle;
      font-size: 3.6rem;
      margin: 0;
      max-width: 100%;

      span {
        display: inline-block;
        vertical-align: middle;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding-right: 3rem;
        font-size: 3.6rem;
        max-width: 100%;
      }
    }

    .icon-arrow-cta {
      display: block;
      width: 2.4rem;
      height: 2.4rem;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0.8rem;
      z-index: 1;
    }
  }

  .content-img {
    width: 100%;
    flex: 1;
    border: 1px solid var(--border-color);

    img {
      display: block;
      width: 100%;
    }
  }

  .content-text {
    display: block;
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    max-width: 100%;
    border: 1px solid var(--border-color);
    background: var(--white);
    padding: 1.2rem;

    .description {
      display: block;
      font-size: 1.4rem;
      line-height: 2rem;
      text-align: left;
      margin: 0;
      white-space: nowrap;
    }
  }
}
