@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .product-quickview-container {
      // min-height: 100vh;
      height: calc(var(--vhcart, 1vh) * 100)
    }
  }
}
.product-quickview-container {
  // min-height: 100vh;
  height: calc(var(--vhcart, 1vh) * 100);
  .quick-view-content-wrapper {
    justify-content: space-between;
  }
  .product-quickview {
    // min-height: 100vh;
    height: calc(var(--vhcart, 1vh) * 100);
  }
}
