.promo-strip {
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  border: 1px solid var(--border-color);
  background-color: var(--alternative-color);
  padding: 1rem 1.2rem;
  @include z('header');
  justify-content: space-between;
  width: 100%;
  &.promo-strip--negative {
    --alternative-color: var(--main-color);
    --border-color: var(--white);
    --text-color: var(--white);
  }
  &.promo-strip--full-width {
    left: 0;
    transform: none;
    width: 100%;
  }
  &.promo-strip__animate {
    .promo-strip__contents {
      overflow: hidden;
      .pd-text {
        display: flex;
      }
      p {
        flex-shrink: 0;
        width: 100%;
        box-sizing: border-box;
        padding: 10px;
        white-space: nowrap;
      }
    }
    @keyframes tickerh {
      0% {
        transform: translate3d(100%, 0, 0);
      }
      100% {
        transform: translate3d(-101%, 0, 0);
      }
    }
    .promo-strip__content:hover {
      animation-play-state: paused;
    }
  }
  &.clickable-link {
    cursor: pointer;
  }
  .promo-strip_link {
    width: 90%;
    height: 100%;
    position: absolute;
  }
}
.promo-strip__contents {
  width: 100%;
  overflow: hidden;
  position: relative;
  .pd-text {
    margin-bottom: 1rem;
  }
  p {
    margin: 0;
    line-height: 1;
  }
  p,
  a {
    color: var(--text-color);
  }
  a {
    position: relative;
    z-index: 1;
    &:hover {
      color: var(--text-color);
    }
  }
}

.promo-strip__close {
  @include resetButton;
  height: 1.5rem;
  svg {
    width: 1.5rem;
    height: 1.5rem;
    fill: var(--text-color);
  }
}
.promo-strip--positive {
  .pd-text {
    strong {
      font-weight: 500;
      background-color: var(--main-color);
      color: var(--white);
    }
  }
  .anchor-text {
    --primary-color: var(--white);
    --secondary-color: var(--main-color);
    &:hover {
      --secondary-color: var(--white);
      --primary-color: var(--main-color);
    }
  }
}
.promo-strip--negative {
  .pd-text {
    strong {
      font-weight: 500;
      background-color: var(--white);
      color: var(--main-color);
    }
  }
  .anchor-text {
    &:hover {
      --primary-color: var(--white);
      --secondary-color: var(--white);
    }
  }
}
