.product-quickview-container {
  //
  // min-height: 100vh;
  height: calc(var(--vhcart, 1vh) * 100);
  position: relative;
  @media (max-width: 991px) {
    height: fit-content;
  }
  @media (max-width: 991px) {
    .action-bar {
      display: none;
    }
  }
  .product-detail {
    .product-container_general {
      @media (max-width: 991px) {
        .product-content {
          border: none;
          border-top: 1px solid var(--main-color);
        }
      }
      .add-to-cart--main {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 0 1.6rem 1.6rem;
      }
    }
  }
  .hideMobile {
    display: block;
  }
  .add-to-cart {
    @media (max-width: 991px) {
      .add-to-wish-list-mobile {
        display: none;
      }
    }
  }

  .final-sale-message {
    color: var(--alternative-color);
    background-color: var(--main-color);
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    margin: 4px 0;
    width: fit-content;
    padding: 0.3rem 0.4rem;
    svg {
      fill: white;
      width: 17px;
      height: 17px;
      margin-right: 6px;
    }
  }
  .quick-view-image-wrapper {
    .carousel,
    .swiper-container,
    .product-slides,
    .product-slide {
      height: 34.8rem;
      .product-image {
        height: 34.8rem;
        margin: auto;
      }
    }
    .product-slider-container {
      position: relative;
      .container_swiper-button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        z-index: 1;
        padding: 2rem;
        .swiper-button-prev,
        .swiper-button-next {
          width: 2.4rem;
          height: 2.4rem;
          svg[class^='icon'] {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  .quick-view-content-inner {
    padding: 2rem;
    border-top: 1px solid var(--light-grey);
    border-bottom: 1px solid var(--light-grey);
    .section-title-price-quick-view {
      .product-name {
        margin: 0;
        font-size: 2.8rem;
        color: var(--black);
      }
    }
    .product-information-block {
      color: var(--black);;
      background: #ffff;
      overflow: hidden;
      line-height: 2;
      width: 50%;
      white-space: nowrap;
    }
    .product-space-ellipsis {
      margin: 0;
      margin-right: 1rem;
      color: var(--black);
    }
    .view-full-details{
      color: var(--black);
      &:active, &:hover {
        &:not(.highlight)::after{
          background-image: linear-gradient(90deg, var(--black), var(--black) 75%, transparent 75%, transparent 100%);
        }
      }
      &:not(.highlight)::after{
        background-image: linear-gradient(90deg, var(--black), var(--black));
      }
    }
  }

  .product-quickview-row {
    height: 768px;
  }

  .quick-view-detail-panel {
    .variation-attribute {
      .variation-attribute-color-container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h2 {
          margin: 0;
        }

        .attribute-value {
          border-radius: 50%;
          margin-left: 0.5rem;
          box-shadow: 0 0 0 1px var(--light-grey);
          &.selected {
            border: 1px solid var(--white);
            box-shadow: 0 0 0 1px var(--main-color);
            color: var(--main-color);
          }
        }
      }

      .attribute-value {
        border: 1px solid var(--white);
        background-color: transparent;
        cursor: pointer;
        padding: 0 1.6rem;
        height: 3.2rem;
        display: flex;
        align-items: center;
        &.selected {
          border: 1px solid var(--main-color);
          color: var(--main-color);
        }
        &.unavailable {
          text-decoration: line-through;
          pointer-events: none;
          opacity: 0.7;
          color: var(--light-grey);
        }
      }
      .product-variation-attribute {
        flex-wrap: wrap;
        display: flex;
        color: var(--black);
      }
      &.color {
        padding: 2rem;
        border-bottom: 1px solid var(--light-grey);

        @media (max-width: 767px) {
          padding: 1rem 2rem;
        }
      }
      &.size,
      &.length {
        padding: 0 2rem;

        #size-guide-panel {
          display: none;
        }
      }

      .attribute-label {
        font-size: 1.4rem;
        font-family: BananaGrotesk, sans-serif;
        font-weight: 500;
        font-style: normal;
        color: var(--black);

        .selected-color{
          color: rgba(0, 0, 0, .6);
        }
      }
      
      &.giftcardType {
        padding: 2rem;
      }
      &.giftcardAmount {
        padding: 0 2rem;
        .giftcard-amount {
          margin: 2rem 0;
        }
      }
    }
    .title-container--size {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.6rem;
      .attribute-label {
        color: var(--black);
      }
    }
  }
  .quick-view-content-wrapper {
    padding: 0;
    flex-direction: column;
    .info-attributes_quick-view {
      width: 100%;
    }
    @media (max-width: 767px) {
      height: auto;
      display: flex;
    }
  }
  .container-button_quick-view {
    text-align: center;
    padding: 2rem;
    width: 100%;
    .update-cart-product-global {
      width: 100%;
    }
    .add-to-wish-list {
      display: none;
    }
    @media (max-width: 767px) {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: end;
    }
    .cart-edit-update {
      width: 100%;
    }
  }

  .quick-view-image-wrapper {
    position: relative;
    .quick-view-title-mini {
      border: 1px solid var(--main-color);
      border-left: 0;
      border-bottom: 0;
      color: var(--main-color);
      width: fit-content;
      padding: 0 0.4rem;
      position: absolute;
      bottom: 0;
      z-index: 1;
      .text-quick-view-title-mini {
        font-size: 1.2rem;
        margin: 0;
      }
    }
  }
  .action-bar {
    border: 0;
    position: absolute;
    z-index: 9;
    .action-bar__button {
      border-bottom: 1px solid var(--main-color);
      background-color: var(--white);
    }
  }
}
.cart-edit-line-item-panel {
  .cart__modal {
    height: calc(var(--vhcart, 1vh) * 100);
    overflow-y: auto;
    margin: 0;

    @media (max-width: 767px) {
      height: 100%;

      .product-quickview-container {
        height: 768px;
      }
    }
  }
}
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .cart__modal {
      .product-quickview-container {
        // min-height: 120vh;
        height: calc(var(--vhcart, 1vh) * 100);
      }
    }
  }
}
[data-component='CartEditLineItemModal'] {
  #notify-me-icon {
    display: none;
  }
  .quick-view-title-mini {
    display: none;
  }
}

.quick-buy-modal {
  &.open {
    @media (max-width: 991px) {
      width: 100%;
    }
  }
  .container-down-pdp {
    display: none;
  }
  .header-panel {
    margin-top: 0;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 0;
    @media (max-width: 991px) {
      .action-bar__button {
        border-right: none;
        border-left: 1px solid var(--main-color);
      }
    }
  }
  .side-panel__inner {
    @media (max-width: 991px) {
      width: 100%;
    }
    width: 50rem;
  }
  .product-quickview-container {
    .quick-view-image-wrapper {
      .carousel {
        max-width: 50rem;
        .product-slider-container {
          max-width: 50rem;
        }
      }
    }
  }
  .quick-buy {
    padding: 0;
    .product-information-block {
      color: var(--black);
      background: #ffff;
      overflow: hidden;
      line-height: 2;
      width: 50%;
      white-space: nowrap;
    }
    .product-space-ellipsis {
      margin: 0;
      margin-right: 1rem;
      color: var(--black);
    }
    .product-quickview-container {
      .product-quickview {
        padding: 0;
      }
      .quick-view-image-wrapper {
        margin: 0;
        height: 100%;
        display: flex;
        flex-direction: column;
      }
    }
    .product-detail {
      .product-container_general {
        position: relative;
        padding: 0;
        margin: 0;
        flex-grow: 1;
        .pdp-product-container,
        .product-content,
        .product-content__body {
          height: 100%;
        }
        .product-content__badgeContainer {
          display: none;
        }
        .product-content {
          position: relative;
          top: 0;
          max-width: 100%;
          left: 0;
          width: 100%;
          border: none;
          .product-content__body {
            border-left: none;
            border-right: none;
            border-bottom: none;
            margin-bottom: 5rem;
            .description-product {
              display: flex;
              margin-bottom: 1.6rem;
            }
            .variation-attribute {
              &.color {
                border-top: 1px solid var(--main-color);
                .swatch-attribute-values {
                  .attribute-value {
                    display: none;
                    &.selected {
                      display: inline-flex;
                    }
                  }
                }
              }
            }
            .variation-attribute-color-container {
              align-items: center;
              display: flex;
              padding: 1.5rem 0;
            }
          }
        }
      }
    }
    .out-of-stock_panel {
      &.open {
        left: auto;
      }
    }
  }
}
