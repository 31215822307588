.module-11 {
  max-width: 62rem;
  margin: 0 auto;
  padding-top: 8rem;
  padding-bottom: 8rem;

  .module-11__multiple-title {
    .module-11__title {
      border: none;
      &--button {
        .pd-heading__container {
          .pd-heading {
            position: relative;

            &::before {
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              transition: transform var(--transition);
              transform-origin: left top;
              transform: scaleX(0);
              z-index: -1;
            }
          }
        }
        &:hover {
          border: none;
          background: transparent;
          .pd-heading__container .pd-heading {
            &::before {
              transform: scaleX(1);
            }
          }
        }
      }
    }
  }
  .container-module-11 {
    &.module-11-variant-2 {
      .module-11__title {
        justify-content: center;
      }
      .module-11__text {
        align-items: center;
        font-size: 1.8rem;
      }
    }
  }
}
.module-11_container {

  &.same-height-boxes {
    display: flex;

    .module-11_items_container {
      flex: 1 1 auto;

      .module-11__text {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }

  &.horizontal {
    .module-11_items_container {
      display: flex;
      flex-wrap: wrap;

      .module-11 {
        max-width: 43rem;
        width: calc(100% / 3);
      }
    }
  }

  &.module-13A {
    display: flex;
    justify-content: center;

    .module-11_items_container {
      flex-wrap: nowrap;
      justify-content: center;
      padding: 0 2rem;

      .swiper-slide {
        margin: 0;

        &:nth-child(2) {
          margin: 0 24px;
        }

        flex: 1 1 100%;
      }
    }
  }
}
