.side-panel {
  @include z(modal);
  position: fixed;
  right: 0;
  top: 100%;
  width: 0;
  height: 0;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color var(--transition);
  visibility: hidden;
  pointer-events: none;
  &.open {
    visibility: visible;
    pointer-events: all;
    width: 100vw;
    @include fullViewportHeight();
    //height: 100%;
    @media (max-width: 991px) {
      .header-panel {
        padding-bottom: 10rem;
      }
    }
    left: 0;
    top: 0;
    background-color: var(--black-opacity);
    .search-header-bar {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 4rem;
      border-bottom: 1px solid var(--main-color);
      opacity: 0;
      &.open {
        opacity: 1;
        cursor: pointer;
      }
      @media (min-width: 991px) {
        border: 0;
        .search-header-label {
          display: none;
        }
      }
      // &.search-animation {
      //   white-space: nowrap;
      //   overflow: hidden;
      //   top: 1.5rem;
      //   left: 1.5rem;
      //   animation: type 2s steps(80, end);
      //   animation-fill-mode: forwards;
      // }
    }
  }
  &.cursor-hidden {
       cursor: none;
  }
  .action-bar {
    // border-top: 1px solid var(--border-color);
    // border-left: 1px solid var(--border-color);
    // margin-top: 4rem;
    border-right: 1px solid var(--border-color);
    //border-bottom: none;
    justify-content: space-between;
    &.action-bar__filters {
      border-right: 0;
    }
    &.d-none{
      .action-bar__text{
        display: block;
        &.d-none{
          display: none;
        }
      }
    }
    .action-bar__text{
      display: none;
      &.search-visible {
        display: block;
      }
    }
  }
  &.no-transition {
    transition: none;
    .side-panel__inner {
      transition: none;
    }
  }
}
.side-panel__close {
  @include resetButton;
  display: none;
  background-color: var(--alternative-color);
  border: 1px solid var(--main-color);
  height: 4rem;
  padding: 0.9rem;
  position: absolute;
  right: 0;
  width: 5rem;

  svg {
    width: 100%;
    height: 100%;
    &.icon-close{
      fill: var(--main-color)!important;
    }
  }
}
.side-panel__inner {
  position: fixed;
  background-color: var(--alternative-color);
  height: 100%;
  width: 0;
  overflow: hidden;
  max-width: 48.4rem;
  top: 0;
  left: 100%;
  transform: translateX(100%);
  transition: transform var(--transition);
  visibility: hidden;

  .country-selector-globale {
    height: 100%;
  }

  &.open {
    &:has(.country-selector-globale) {
      border: .1rem solid var(--main-color);
    }
    right: 0;
    left: auto;
    width: 100%;
    transform: translateX(0);
    visibility: visible;
    .search-header-bar {
      /*
        display: flex;
        justify-content: center;
        align-items: center;
        height: 4rem;
        border-bottom: 1px solid red;
      */
      align-items: center;
      display: flex;
      border-bottom: 1px solid var(--main-color);
      height: 40px;
      justify-content: center;
      left: 0;
      position: absolute;
      top: -1px;
      width: 100vw;
    }
  }

  .auto-fit-height {
    overflow-y: auto;
    padding: 0rem 2rem 2rem 2rem;
    margin-right: 0.8rem;

    &.auto-fit-height-no-padding {
      padding: 0;
    }
  }

  .header-side-panel {

    .header-side-panel-title {
        padding-top: 1.8rem;
        padding-right: 0rem;
        padding-bottom: 1.8rem;
        padding-left: 2rem;
        margin: 0;
        @include primaryFont;
        color: var(--main-color);
        font-size: 1.8rem;
        line-height: 2.4rem;
        max-width: calc(100% - 5rem);
        display: flex;
        flex-direction: row;
        gap: 1rem;
        align-items: center;

        &.remove-left-padding {
          padding-left: 0rem;
        }

        &.remove-top-padding {
          padding-top: 0rem;
        }

        &.remove-bottom-padding {
          padding-bottom: 0rem;
        }
    }

    .header-side-panel-badge {
      width: 1.6rem;
      height: 1.6rem;
      font-size: 1.4rem;
      margin: 0;
    }

    .action-bar__button {
      svg {
        fill: var(--main-color);
        width: 2.4rem;
        height: 2.4rem;
        margin-left: 0;
      }
      // background-color: var(--white);
      z-index: 100;
      position: absolute;
      right: 0;
      top: 0;
      width: 5rem;
      height: 4rem;
    }

  }

  .header-side-panel-title {
    padding-top: 1.8rem;
    padding-right: 0rem;
    padding-bottom: 1.8rem;
    padding-left: 2rem;
    margin: 0;
    @include primaryFont;
    font-size: 1.8rem;
    line-height: 2.4rem;
    max-width: calc(100% - 5rem);

    &.remove-left-padding {
      padding-left: 0rem;
    }

    &.remove-top-padding {
      padding-top: 0rem;
    }

    &.remove-bottom-padding {
      padding-bottom: 0rem;
    }
}
}

.side-panel--header-panel {
  .side-panel__inner {
    border: 1px solid var(--border-color);
  }
  [data-close='true'] {
    border-right: none;
    border-bottom: 1px solid var(--border-color);
  }
}
.side-panel--search {
  .side-panel__inner {
    border: 1px solid var(--border-color);
    padding: 2rem;
    padding-top: 6rem;
    overflow: auto;
    z-index: -1;
  }
}
.cart__modal {
  //margin: 2rem;
  // max-height: 100vh;

  &__block {

    &:nth-child(1) {
      border-top: none;
    }

    p{
      color: var(--black-opacity);

      .product-to-remove{
        color: var(--black);
      }
    }
  }
}
.cart__modal-actions {
  .button {
    margin-bottom: 2rem;
  }
}
.cart__modal-title {
  @include primaryFont;
  font-size: 1.8rem;
  margin-bottom: 1.6rem;
  color: var(--black);
}