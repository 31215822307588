.module-18 {
  .split {
    column-gap: 2rem;
    row-gap: 2rem;
    margin: 1.6rem 2.4rem;

    .pd-img {
      flex: 1 1 50%;
      display: flex;
      flex-direction: column;

      a:not(.p-0), .title-nolink {
        height: 4rem;
        padding: .8rem 1rem 0 1rem;
        width: fit-content;
        border: 1px solid var(--main-color);
        border-bottom: none;
        background: var(--white);
        align-items: center;
        @include secondaryFont();
        font-weight: bolder;
        font-size: 2.4rem;
        line-height: 2.9rem;

        .ctaText {
          @include secondaryFont();
          font-weight: bolder;
          font-size: 2.4rem;
          line-height: 2.9rem;
        }
      }

      a {
        column-gap: 1rem;
        display: flex;
        flex-direction: row;

        &:hover {
          svg {
            fill: var(--white);
          }

          .ctaText {
            color: var(--white);
          }
        }

        svg {
          margin-bottom: .4rem;
          width: 2.4rem;
          height: 100%;
          fill: var(--main-color);
        }
      }

      .img-container {
        background-color: var(--white);
        border: 1px solid var(--main-color);
        height: 100%;
        max-width: 92.4rem;
        max-height: 92.4rem;

        .main-img {
          object-fit: cover;
        }
      }

      .vid-container {
        width: 100%;
        height: 100%;
        background-color: var(--white);
        border: 1px solid var(--main-color);

        video {
          object-fit: cover;
          max-width: 100%;
          max-height: 92.4rem;
        }
      }

      .video-container {
        background-color: var(--white);
        border: 1px solid var(--main-color);
        height: 100%;
        max-width: 92.4rem;
        max-height: 92.4rem;

        .vid-container {
          border: none;
        }
      }

    }
  }
}