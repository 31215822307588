.module-2__area {
  display: block;
  width: 100%;
  margin: 0 auto;
}

.module-2__variant-2 {
  .module-2__variant-2__title-area {
    margin: 0 auto;
    // position: relative;
    z-index: 2;
    width: fit-content;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    &--center {
      margin: 0 auto;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &--right {
      margin: 0 0 0 auto;
      left: auto;
      right: 0;
    }
    &--left {
      margin: 0 auto 0 0;
      left: 0;
    }
  }

  .module-2__variant-2__content-area {
    .module-2__item {
      display: block;

      &:nth-child(1) {
        top: 0;
        left: 70%;
      }
      &:nth-child(2) {
        top: 1rem;
        left: 36%;
      }
      &:nth-child(3) {
        bottom: 0;
        left: 22%;
      }
      &:nth-child(4) {
        bottom: 5%;
        left: 55%;
      }
      &:nth-child(5) {
        bottom: 8%;
        right: 2%;
      }
      &:nth-child(6) {
        top: 30%;
        left: 2%;
      }
    }
  }
}
