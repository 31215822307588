.pd-nav-menu-container{

  .back-container {
    border:1px solid var(--light-grey);
    border-bottom: none;
    padding: 8px 15px 8px 8px;
    margin-bottom: 0;
  }

  .main-container{
    display: block;
    border:1px solid var(--light-grey);
  }

  .select-container{
    display: none;
  }
}