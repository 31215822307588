.editorial__category {
    .editorial-category--title {
        margin: 0 0 1.5rem;
        padding: 0;
    }
}

.editorial-content {
    padding: 0;

    ul {
        &.navigation-subcategories {
            margin: 0;
        }

        .editorial-box {
            &.half-box {
                figure {
                    a {
                        &::before {
                            padding-top: calc((159 / 210) * 100%);
                        }
                    }
                }
            }

            figure {
                font-size: 1.4rem;

                a {
                    &::before {
                        padding-top: calc((139 / 178) * 100%);
                    }
                }
            }
        }
    }
}