.check-order-before-login {
  .error-label-wrapper {
    .error-label {
      display: inline-flex;
      opacity: 1;
      margin-left: 3.1rem;
      height: auto;
      transform: none;
    }

    .form-element__error {
      margin-top: 0;
    }
  }
  
}