@import '../../mixins';
@import '../../../pages/account/mixins';

.magazine__text {
  color: var(--main-color);
  margin-bottom: 3.2rem;
  padding: 0 1.3rem;

  @include media-breakpoint-up(lg) {
    margin-bottom: 6rem;
  }

  * {
    margin: 0;
  }
}
