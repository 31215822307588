select {
  --spacing: 0.5rem;
  appearance: none;
  display: block;
  margin-bottom: 3.2rem;
  width: 100%;
  border: none;
  padding: var(--spacing) 0;
  border-bottom: 1px solid var(--border-color);
  background-image: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='UTF-8'?%3E%3Csvg width='14px' height='8px' viewBox='0 0 14 8' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EPath-8-Copy-12%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolygon id='Path-8-Copy-12' fill='%23E4002B' fill-rule='nonzero' transform='translate(7.000000, 4.000000) scale(-1, 1) rotate(-270.000000) translate(-7.000000, -4.000000) ' points='3 -1.86488423 4.11947847 -3 11 4.021617 4.11767963 11 3.00179883 9.8612246 8.7642725 4.0173077'%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center right 1rem;
  border-radius: 0;
  & + .form-element__label,
  .form-element__label_mobile {
    font-size: 1rem;
    top: -1rem;
  }

  &.black {
    border-color: var(--black);
    background-image: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='UTF-8'?%3E%3Csvg width='14px' height='8px' viewBox='0 0 14 8' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EPath-8-Copy-12%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolygon id='Path-8-Copy-12' fill='%23002b' fill-rule='nonzero' transform='translate(7.000000, 4.000000) scale(-1, 1) rotate(-270.000000) translate(-7.000000, -4.000000) ' points='3 -1.86488423 4.11947847 -3 11 4.021617 4.11767963 11 3.00179883 9.8612246 8.7642725 4.0173077'%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E");
  }
}
