.module-11A_container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 6rem 2rem;

  &.white {
    background-color: var(--white);
  }

  &.red {
    background-color: var(--main-color);
    .removeBox{
      .button{
        background-color: var(--white);
        color: var(--main-color);
      }
      .cta{
        color: var(--white);
      }
      .cta:not(.highlight)::after {
        background-image: linear-gradient(90deg, var(--white), var(--white));
      }
      .cta:hover:not(.highlight)::after,
      .cta:focus:not(.highlight)::after,
      .cta:active:not(.highlight)::after {
        background-image: linear-gradient(90deg, var(--white), var(--white) 75%, transparent 75%, transparent 100%);
      }
    }
  }

  &.off-white {
    background-color: var(--lighting-grey);
  }

  .module-11A-title_container {
    -webkit-tap-highlight-color: transparent;
    align-items: center;
    background-color: var(--white);
    border: 1px solid var(--main-color);
    border-bottom: none;
    padding: 0.8rem 1rem 0.2rem 1rem;
    width: fit-content;
    height: unset;

    svg[class^='icon'] {
      width: 2.4rem;
      height: 2.4rem;
      margin-left: 1rem;
    }

    a {
      display: inline-flex;
      align-items: center;
      text-decoration: none;
      flex-flow: row nowrap;
    }
  }

  .module-11A-content_container {
    background-color: var(--white);
    border: 1px solid var(--main-color);
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    padding: 1.6rem;

    &.removeBox{
      border: none;
      background-color: transparent;
    }

    p{
      margin: 0;
      letter-spacing: 0.02rem;
    }

    .module11A-link {
      -webkit-tap-highlight-color: transparent;
    }
  }
}
