.module-static-plp--10 {
  position: relative;
  .product-carousel-container {
    overflow: visible;
    &.hidden {
      visibility: hidden;
      opacity: 0;
    }

    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }

    .scrollbar-filters {
      background: var(--light-grey);
      position: absolute;
      border-radius: 2px;
      margin: 0.6rem 0;
      width: 100%;
      bottom: -1.8rem;
      left: 50%;
      transform: translateX(-50%);

      .swiper-scrollbar-drag {
        background: var(--main-color);
        height: 0.2rem;
      }
    }
    
  }

  .content-heading {
    display: block;
    text-align: left;
    margin-bottom: 3rem;

    .content-element {
      border: 1px solid var(--main-color);
      padding: 1.2rem;
      p {
        margin: 0;
      }
    }

    .heading-link {
      display: inline-block;
      padding: 0 0.8rem;
      border: 1px solid var(--border-color);
      border-bottom: none;
      position: relative;
      max-width: 100%;
    }

    .element-heading {
      display: inline-block;
      vertical-align: middle;
      font-size: 3rem;
      margin: 0;
      max-width: 100%;

      span {
        display: inline-block;
        vertical-align: middle;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding-right: 0;
        font-size: 3rem;
        max-width: 100%;
      }
    }

    .icon-arrow-fill-down {
      display: none;
      width: 2.4rem;
      height: 2.4rem;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0.8rem;
      z-index: 1;
    }
  }

  .element-wrapper {
    display: flex;
    flex-direction: row;
    padding: 0;
    width: 100%;
    white-space: nowrap;
    overflow: visible;

    .single-item {
      display: flex;
      flex-direction: column;
      width: 100%;
      flex: 0 0 100%;
      margin-right: -1px;
      position: relative;

      &:hover,
      &--active {
        .content-text {
          background: var(--border-color);

          .text {
            color: var(--white);
          }
        }
      }

      &:hover {
        .content-img {
          &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(228, 0, 43, 0.5);
          }
        }
      }
    }
  }

  a {
    text-decoration: none;
  }

  .content-img {
    width: 100%;
    flex: 1;
    border-bottom: none;
    position: relative;

    img {
      display: block;
    }
  }

  .content-text {
    display: block;
    border-top: none;
    background: var(--white);
    padding: 1.2rem;
    margin-right: -1px;
    width: 100%;
    box-sizing: border-box;

    .text {
      display: block;
      font-size: 1.4rem;
      text-align: center;
      margin: 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.hide-box {
  .module-static-plp--10 {
    .content-heading {
      text-align: center;

      .element-heading {
        border: 0;
        width: 100%;
        text-align: left;
        padding-left: 0;
      }
      
      .content-element {
        border: 0;
        padding-left: 0;

        p {
          text-align: left;
        }
      }
    }
  }
}

.enhanced-visual-filters.view-desktop {
  display: none;
}

.enhanced-visual-filters.view-mobile {
  display: block;
  text-align: center;

  .content-heading {

    &.category-selected {
      .heading-link {
        margin-bottom: 1rem;
        text-align: start;
      }
    }

    .heading-link {
      font-size: 3.6rem;
      margin: 0 0 1.6rem;
    }

    .element-description {
      margin-bottom: 1.6rem;
    }
  }

  .wrapper-category-selected {
    border: 1px solid var(--main-color);
    display: flex;
    height: 16.3rem;
    margin-bottom: 2.6rem;

    .container-image {
      border-right: 1px solid var(--main-color);
      flex-shrink: 0;
      width: 112px;

      .vf-image-ratio {
        width: 100%;
        height: 16.3rem;
        max-height: 16.3rem;
        object-fit: fill;
        padding-bottom: 0.2rem;
      }
    }

    .description {
      line-height: 22px;
      margin: 1.6rem;
      text-align: initial;

      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
    }
  }

  .wrapper-categories {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.4rem;
    margin-bottom: 2.4rem;

    .btn-category-link {
      box-sizing: border-box;
      flex: 1 1 calc(33.33% - 10px);
      font-family: franklin-gothic-compressed;
      font-size: 14px;
      line-height: 12px;
      margin: 0 0.4rem 0.4rem 0;
      max-width: 109px;
      padding: 1rem 4.0rem;
      padding-top: 1.4rem;
      text-transform: uppercase;

      &.active {
        background-color: var(--main-color);
        color: var(--white);
      }
    }
  }

  .wrapper-cta {
    margin-bottom: 4rem;

    &.multiple-cta {
      display: flex;
      justify-content: space-between;
    }

  }
}