.privacy_prompt {
  &.explicit_consent {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
  }
  .cookie-popup {
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--white);
    max-width: 96rem;
  }
}
