.minicart__container {
  .minicart-panel-wrapper {
    margin-top: 0;
  }
  div.vue-module--shop-the-look {
    .product-carousel-products {
      .vue-module__tile {
        .container-card {
          img {
            border: 0;
          }
        }
      }
    }
  }
}