.experience-layout-moduleHero {
}
.module-hero {
  max-width: 62rem;

  .module-hero__multiple-title {
    .module-hero__title {
      border: none;
      &--button {
        .pd-heading__container {
          .pd-heading {
            position: relative;

            &::before {
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              transition: transform var(--transition);
              transform-origin: left top;
              transform: scaleX(0);
              z-index: -1;
            }
          }
        }
        &:hover {
          border: none;
          background: transparent;
          .pd-heading__container .pd-heading {
            &::before {
              transform: scaleX(1);
            }
          }
        }
      }
    }
    .pd-heading__container {
      .pd-heading {
        line-height: 4.2rem;
      }
    }
  }
  .container-module-hero {
    &.module-hero-variant-2 {
      .module-hero__title {
        justify-content: center;
      }
      .module-hero__text {
        align-items: center;
        font-size: 1.8rem;
      }
    }
  }
}
