@import '../../mixins';
@import '../../../pages/account/mixins';

html {
  scroll-behavior: smooth; // when this element is on the page
}

.loyalty-dashboard__complete-profile {
  color: var(--main-color);

  &__container {
    border: 1px solid var(--main-color);
    margin-top: -1px;
    height: 20rem;
    position: relative;
    width: 100%;

    [data-step='0'] & {
      margin-top: 0;
    }

    @include media-breakpoint-up(lg) {
      height: 24rem;
      margin-left: 0;
      margin-right: 0;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__cta {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    width: calc(100% - 4rem);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid var(--main-color);
    background: var(--white);
    text-decoration: none;
    max-width: 40rem;

    &__text {
      color: var(--main-color);
      width: calc(100% - 4rem);
      flex: calc(100% - 4rem);
      padding: 0 1.2rem;
      text-transform: uppercase;
      font-size: 1.2rem;
      line-height: 100%;

      @include media-breakpoint-up(lg) {
        font-size: 1.4rem;
      }
    }

    &__icon {
      background: var(--main-color);
      color: var(--white);
      width: 4rem;
      height: 4rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      svg {
        display: block;
        width: 2.4rem;
        height: 2.4rem;
        fill: var(--white);
      }
    }
  }
}

.loyalty-dashboard__complete-profile-2 {
  &__badge {
    @include loyalty-badge;

    * {
      margin: 0;
    }
  }

  &__box {
    @include loyalty-box;

    .loyalty-section &:last-child {
      display: none;
    }

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      flex-wrap: wrap;
    }

    .text-big {
      margin: 0 0 1.2rem 0;

      @include media-breakpoint-up(lg) {
        max-width: 48rem;
      }

      svg {
        display: inline;
        vertical-align: bottom;
        width: 2.4rem;
        height: 2.4rem;
        margin-right: 0.8rem;
      }
    }

    .text-description {
      margin: 0;

      @include media-breakpoint-up(lg) {
        max-width: 48rem;
      }
    }

    &__main {
      @include media-breakpoint-up(lg) {
        width: calc(100% - 9rem);
        flex: calc(100% - 9rem);
        order: 0;
      }
    }

    &__buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 2rem;

      @include media-breakpoint-up(lg) {
        order: 2;
        width: 100%;
        flex: 0 0 100%;
        flex-direction: row;
        align-items: center;
        margin-top: 3.2rem;
      }

      .button {
        width: 100%;
        margin-bottom: 2rem;

        @include media-breakpoint-up(lg) {
          width: 50%;
          max-width: 28rem;
          margin-bottom: 0;
          margin-left: auto;
          order: 1;
        }
      }
    }

    &__step {
      margin-top: 2.2rem;

      @include media-breakpoint-up(lg) {
        order: 1;
        width: 9rem;
        flex: 0 0 9rem;
        margin-left: auto;
        margin-top: 0;
      }

      .step-info {
        max-width: 9rem;
        margin: 0 auto;

        &__label {
          display: block;
          text-align: center;
          font-size: 1.2rem;
          color: var(--main-color);
          margin-bottom: 1.2rem;
        }

        &__progress {
          position: relative;
          padding-top: 0.2rem;

          &__line {
            display: block;
            height: 0.2rem;
            background: var(--main-color);
            opacity: 0.2;
          }

          &__active {
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 1%;
            height: 0.4rem;
            background: var(--main-color);
            z-index: 2;
          }
        }
      }
    }

    .content-choice {
      margin-top: 2rem;

      @include media-breakpoint-up(lg) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 3.2rem;
        margin-right: -9rem; // offset step counter
      }

      &--img {
        margin-bottom: -2rem;

        .choice {
          position: relative;
          padding: 1rem;
          margin-bottom: 1.6rem;
          border: 1px solid var(--main-color);
          display: flex;
          background-color: transparent;
          cursor: pointer;
          width: 100%;

          @include media-breakpoint-up(lg) {
            width: calc(50% - 1rem);
            flex: 0 0 calc(50% - 1rem);

            .registration-page & {
              width: 100%;
              flex: none;
            }
          }

          svg {
            width: 1.6rem;
            height: 1.6rem;
            flex: 0 0 1.6rem;
            border: 1px solid var(--main-color);
            margin: 0;
          }

          p {
            margin: -4px 1.6rem 0;
            text-align: left;
          }

          img {
            object-fit: cover;
            display: block;
            width: 7.6rem;
            flex: 0 0 7.6rem;
            height: 7.6rem;
            margin-left: auto;

            @include media-breakpoint-up(xl) {
              width: 11.6rem;
              flex: 0 0 11.6rem;
              height: 11.6rem;
            }
          }

          &:hover,
          &--active {
            svg {
              background-color: var(--main-color);
            }
          }
        }
      }
    }
  }
}

.js-complete-profile-container {
  @for $step from 0 through 2 {
    [data-step-#{$step}] {
      display: none;
    }

    &[data-step='#{$step}'] {
      [data-step-#{$step}] {
        display: flex;
      }
    }
  }
}

[data-step-2] {
  .loyalty-dashboard__complete-profile-2__box {
    &__ctas {
      text-align: center;
      width: 100%;

      a {
        text-align: center;
        width: 100%;

        &.button {
          margin: 3rem 0 2rem;
        }
      }

      .account-dashboard-page &,
      .my-hod-page & {
        display: none;
      }
    }

    &__step {
      .registration-page & {
        display: none;
      }
    }
  }
}