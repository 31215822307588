.side-panel-styling-advisor{
  .styling-advisor-wrapper{
    position: relative;
    display: flex;
    height: 100%;

      .back-btn{
      background-color: var(--white);
      color: var(--main-color);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: 4rem;
      height: 4rem;
      border: 1px solid var(--main-color);
      border-top: 0;
      border-right: 0;
      padding: 0;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 3;

      svg {
        width: 2rem;
        height: 2rem;
      }
    }

    .wishi-root {
      padding-right: 0;
      padding-left: 0;

      .wishi-chat {
        width: 100%;
        height: 100%;
      }
    }
  }

  .side-panel__inner {
    overflow-y: auto;
  }
}