@import '../../mixins';
@import '../../../pages/account/mixins';

.magazine__strip {
  color: var(--main-color);
  background: var(--main-color);
  margin-bottom: 3.2rem;
  padding: 5.8rem 2rem;
  font-size: 0;
  text-align: center;
  position: absolute;
  width: 100vw;
  left: 50%;
  transform: translate(-50%);
  @include media-breakpoint-up(lg) {
    margin-bottom: 6rem;
  }

  * {
    margin: 0;
  }

  &__text {
    display: inline-block;
    font-size: 2.8rem;
    background: var(--white);
    padding: 0.4rem;
    text-transform: uppercase;
  }
}
