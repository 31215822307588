.confirmation-registration {
  width: 65.5rem;
  margin-top: 3.7rem;
  background-color: var(--main-color);
  border: none;

  .loyalty-register-page__benefits {
    padding: 4rem 4rem 0;
  }

  .registration-form {
    padding: 2rem 4rem 4rem;
  }

  .cta-sho-more {
    margin: 3rem 0 2rem;
  }
}
