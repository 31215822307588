@import '../../mixins';
@import '../../../pages/account/mixins';

body .pd-page__content {
  margin-top: 0;
}

.loyalty-experience__hero {
  color: var(--main-color);

  &__container-full {
    width: 100%;
    height: calc(100vh - 5.3rem);
    position: relative;
    min-height: 55rem;

    @include media-breakpoint-up(lg) {
      height: 100vh;
    }

    &--small {
      height: 49.5rem;
    }
  }

  &__img-bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__video-bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__content-logo {
    display: block;
    width: calc(100% - 4rem);
    max-width: 33.5rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;

    @include media-breakpoint-up(lg) {
      max-width: 40rem;
    }
  }

  &__img-logo {
    display: block;
    width: 100%;
  }

  &__text {
    display: block;
    width: calc(100% - 4rem);
    max-width: 33.5rem;
    border: 1px solid var(--main-color);
    background: var(--white);
    padding: 2rem;
    position: absolute;
    top: 75%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;

    @include media-breakpoint-up(lg) {
      left: 25%;
    }

    &--under-logo {
      width: 100%;
      max-width: 100%;
      position: static;
      top: auto;
      left: auto;
      transform: none;
      padding: 1.2rem;
    }

    p {
      margin: 0;
    }
  }

  &__buttons {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 5;
    background: var(--white);
    border: 1px solid var(--main-color);
    display: flex;
    flex-direction: row;
    padding: 0 2rem;
    justify-content: space-around;

    &--fixed {
      @include media-breakpoint-down(md) {
        position: fixed;
        bottom: auto;
        top: 0;
      }
    }

    @include media-breakpoint-up(lg) {
      position: fixed;
      top: auto;
      bottom: 0;
      left: auto;
      right: 0;
      width: auto;
      padding: 0;
    }

    &__link {
      display: block;
      padding: 1.8rem 0;
      cursor: pointer;
      text-decoration: none;
      white-space: nowrap;

      @include media-breakpoint-up(lg) {
        padding: 2.6rem 0;
        margin: 0 2rem;
        height: 6.6rem;
      }

      &:first-child {
        @include media-breakpoint-up(lg) {
          margin-left: 4rem;
        }
      }

      &:hover,
      &.active {
        text-decoration: underline;
      }

      &--primary {
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 3;
        width: 100%;
        border: 1px solid var(--main-color);
        color: var(--white);
        text-align: center;
        padding: 1.8rem 4rem;
        height: 5rem;

        @include media-breakpoint-up(lg) {
          border-top: none;
          border-bottom: none;
          border-right: none;
          position: relative;
          margin-right: 0;
          padding: 2.4rem 4rem;
          height: 6.6rem;
        }

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: -1;
          background: var(--main-color);
          border: 1px solid var(--white);
        }
      }
    }
  }
}
