.m-0 {
	margin: 0;
}
.mt-0,
.my-0 {
	margin-top: 0;
}
.mr-0,
.mx-0 {
	margin-right: 0;
}
.mb-0,
.my-0 {
	margin-bottom: 0;
}
.ml-0,
.mx-0 {
	margin-left: 0;
}
.m-1 {
	margin: 0.25rem;
}
.mt-1,
.my-1 {
	margin-top: 0.25rem;
}
.mr-1,
.mx-1 {
	margin-right: 0.25rem;
}
.mb-1,
.my-1 {
	margin-bottom: 0.25rem;
}
.ml-1,
.mx-1 {
	margin-left: 0.25rem;
}
.m-2 {
	margin: 0.5rem;
}
.mt-2,
.my-2 {
	margin-top: 0.5rem;
}

.mr-2,
.mx-2 {
	margin-right: 0.5rem;
}
.mb-2,
.my-2 {
	margin-bottom: 0.5rem;
}
.ml-2,
.mx-2 {
	margin-left: 0.5rem;
}
.m-3 {
	margin: 1rem;
}
.mt-3,
.my-3 {
	margin-top: 1rem;
}
.mr-3,
.mx-3 {
	margin-right: 1rem;
}
.mb-3,
.my-3 {
	margin-bottom: 1rem;
}
.ml-3,
.mx-3 {
	margin-left: 1rem;
}
.m-4 {
	margin: 1.5rem;
}
.mt-4,
.my-4 {
	margin-top: 1.5rem;
}
.mr-4,
.mx-4 {
	margin-right: 1.5rem;
}
.mb-4,
.my-4 {
	margin-bottom: 1.5rem;
}
.ml-4,
.mx-4 {
	margin-left: 1.5rem;
}
.m-5 {
	margin: 3rem;
}
.mt-5,
.my-5 {
	margin-top: 3rem;
}
.mr-5,
.mx-5 {
	margin-right: 3rem;
}
.mb-5,
.my-5 {
	margin-bottom: 3rem;
}
.ml-5,
.mx-5 {
	margin-left: 3rem;
}
.p-0 {
	padding: 0;
}
.pt-0,
.py-0 {
	padding-top: 0;
}
.pr-0,
.px-0 {
	padding-right: 0;
}
.pb-0,
.py-0 {
	padding-bottom: 0;
}
.pl-0,
.px-0 {
	padding-left: 0;
}
.p-1 {
	padding: 0.25rem;
}
.pt-1,
.py-1 {
	padding-top: 0.25rem;
}
.pr-1,
.px-1 {
	padding-right: 0.25rem;
}
.pb-1,
.py-1 {
	padding-bottom: 0.25rem;
}
.pl-1,
.px-1 {
	padding-left: 0.25rem;
}
.p-2 {
	padding: 0.5rem;
}
.pt-2,
.py-2 {
	padding-top: 0.5rem;
}
.pr-2,
.px-2 {
	padding-right: 0.5rem;
}
.pb-2,
.py-2 {
	padding-bottom: 0.5rem;
}
.pl-2,
.px-2 {
	padding-left: 0.5rem;
}
.p-3 {
	padding: 1rem;
}
.pt-3,
.py-3 {
	padding-top: 1rem;
}
.pr-3,
.px-3 {
	padding-right: 1rem;
}
.pb-3,
.py-3 {
	padding-bottom: 1rem;
}
.pl-3,
.px-3 {
	padding-left: 1rem;
}
.p-4 {
	padding: 1.5rem;
}
.pt-4,
.py-4 {
	padding-top: 1.5rem;
}
.pr-4,
.px-4 {
	padding-right: 1.5rem;
}
.pb-4,
.py-4 {
	padding-bottom: 1.5rem;
}
.pl-4,
.px-4 {
	padding-left: 1.5rem;
}
.p-5 {
	padding: 3rem;
}
.pt-4-5 {
	padding-top: 2rem
}
.pt-5,
.py-5 {
	padding-top: 3rem;
}
.pr-5,
.px-5 {
	padding-right: 3rem;
}
.pb-5,
.py-5 {
	padding-bottom: 3rem;
}
.pl-5,
.px-5 {
	padding-left: 3rem;
}
.m-auto {
	margin: auto;
}
.mt-auto,
.my-auto {
	margin-top: auto;
}
.mr-auto,
.mx-auto {
	margin-right: auto;
}
.mb-auto,
.my-auto {
	margin-bottom: auto;
}
.ml-auto,
.mx-auto {
	margin-left: auto;
}