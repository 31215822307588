.main-header[data-action='Help-Show'] {
    height: auto;
}

.container-title-header_help-area {
  background-color: var(--main-color);
  height: 24rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -6.4rem;

  .title-header_help-area {
    color: var(--white);
    font-size: 3.4rem;
  }
}
.help-page {
  .container-help-page {
    display: flex;
    flex-wrap: wrap;
    padding: 6rem 0 0 0;
    .help-page__sidebar,
    .help-page__content {
      position: relative;
      width: 100%;
      min-height: 1px;
      padding-right: 20px;
      padding-left: 20px;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .general-help_nav-info {
      border: 1px solid var(--main-color);
      margin-bottom: 4rem;
      .help-page__info {
        padding: 2rem;
        border-bottom: 1px solid var(--main-color);
        .title-section_info {
          margin: 0;
          font-size: 1.8rem;
          line-height: 1.8rem;
        }
      }
      .help-page__navigation {
        .help_menu_holder {
          .top-item {
            font-size: 1.4rem;
            @include primaryFont($weight: 500);
            display: flex;
            align-items: center;
            margin: 0;
            padding: 1rem 2rem;
            cursor: pointer;
            &.topmenu_hide {
              display: none;
            }
          }
          .submenu {
            margin: 0;
            padding: 0;
            list-style: none;
            display: none;
            a {
              font-size: 1.4rem;
              @include primaryFont($weight: 500);
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 1rem 2rem;
              text-decoration: none;
              border: 0;
              flex-direction: unset;
              i[class^='icon'] {
                -webkit-transform: scaleX(-1);
                transform: scaleX(-1);
                width: 2rem;
              }
              &:hover {
                i[class^='icon'] {
                  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(229deg) brightness(100%) contrast(103%);
                }
              }
            }
            &.show_submenu {
              display: block;
            }
            li {
              &.active {
                a {
                  color: var(--primary-color);
                  i[class^='icon'] {
                    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(229deg) brightness(100%) contrast(103%);
                  }
                  &::before {
                    transform: scaleX(1);
                  }
                }
              }
            }
          }
          i[class^='icon'] {
            margin-right: 0.6rem;
          }
        }
      }
    }
  }
  .help_search_bar {
    .title-help_search_bar {
      font-size: 1.8rem;
      line-height: 1.8rem;
      margin: 0;
      @include primaryFont($weight: 500);
    }
    .search__container {
      margin: 2rem 0 0 0;
    }
    .container_popular-topics {
      margin-top: 3rem;
      .title_popular-topics {
        font-size: 1.4rem;
      }
      .button {
        margin-right: 0.6rem;
        margin-bottom: 0.6rem;
      }
    }
  }
  .conteiner-contentasset-help {
    border: 1px solid var(--main-color);
    padding: 2rem;
    margin-bottom: 4rem;
    h2,
    h3 {
      @include primaryFont($weight: 500);
    }
    h2 {
      font-size: 1.8rem;
    }
    .form-description-help {
      i[class^='icon'] {
        float: left;
        margin-right: 0.6rem;
      }
    }
    hr {
      display: block;
      height: 1px;
      border: 0;
      border-top: 1px solid var(--main-color);
      margin: 1em 0;
      padding: 0;
      opacity: 0.2;
    }
    table {
      border-collapse: collapse;
      border-spacing: 0;
      border: none;
      td,
      th {
        border: 1px solid var(--main-color) !important;
        padding: 0.8rem 1.2rem;
        color: var(--main-color);
        @include primaryFont($weight: 500);
        font-size: 1.4rem;
        text-align: center;
        background-color: var(--white) !important;
      }
    }
    .expand-block-body {
      margin: 2rem 0;
    }
    img {
      width: auto;
    }
    .giftcard-form-container {
      .checkout-info-popup {
        display: none;
      }
    }
  }
  i[class^='icon'] {
    filter: invert(10%) sepia(79%) saturate(6984%) hue-rotate(345deg) brightness(88%) contrast(103%);
    width: 2.3rem;
    height: 2.4rem;
  }
  fieldset {
    border: 0;
    padding: 0;
    margin: 0;
    legend {
      @include primaryFont($weight: 500);
      color: var(--border-color);
      font-size: 1.4rem;
      line-height: 2rem;
      padding: 2rem 0;
    }
    .form-group {
      width: 100%;
      float: unset;
      margin-bottom: 2rem;
      margin-right: 0;
      .form-control {
        border: 0;
        border: none;
        background-image: none;
        background-color: transparent;
        box-shadow: none;
        width: 100%;
        border-bottom: 1px solid var(--border-color);
        padding: var(--spacing) 0;
        border-radius: 0;
        color: var(--border-color);
        @include primaryFont($weight: 500);
        padding: 1rem 0;
        &::placeholder {
          color: var(--border-color);
        }
        &::-moz-placeholder {
          color: var(--border-color);
        }
      }
      &.form-group-textarea {
        width: 100%;
        float: unset;
        textarea {
          border: 1px solid var(--border-color);
          padding: 1rem;
        }
      }
    }
    .button {
      width: 100%;
    }
  }
  .holidays-modal {
    margin-top: 4rem;
  }
  .section-button-back-helparea {
    .button {
      border-bottom: 0;
      align-items: center;
      justify-content: flex-start;
      width: fit-content;
      padding: 1rem 1.5rem 1rem 0.5rem;
      flex-direction: unset;
      display: none;
      svg[class^='icon'] {
        width: 2rem;
        height: 2rem;
      }
      &.buttonsBack_show {
        display: flex;
      }
    }
  }
  .expand-block {
    .title-table-size-guide {
      display: flex;
      align-items: center;
      span {
        color: var(--main-color);
        cursor: pointer;
        width: fit-content;
        margin-right: 0.6rem;
      }
      i[class^='icon--plus'] {
        display: block;
      }
      i[class^='icon--minus'] {
        display: none;
      }
    }
    &.is-active {
      i[class^='icon--plus'] {
        display: none;
      }
      i[class^='icon--minus'] {
        display: block;
      }
    }
  }
}

/*
// already skinned in pages/account/_help.scss
.need-help-customer-service {
  border: 1px solid var(--main-color);
  padding: 0 2rem;
  margin-bottom: 4rem;
  .col-need-help-customer-service {
    padding: 2rem;
    border-bottom: 1px solid var(--main-color);
    &:last-child {
      border-bottom: 0;
    }
    .customer-service-link {
      display: flex;
      align-items: center;
      i[class^='icon'] {
        margin-right: 0.6rem;
      }
    }
    .heading-need-help {
      @include primaryFont($weight: 500);
      font-size: 1.8rem;
      margin-top: 0;
    }
  }
}
*/


.giftcard-button,
.giftcard-balance {
  display: flex;
  align-items: center;
}

.giftcard-balance {
  padding: 0;
  p {
    margin: 0;
  }
}

.advisor-header{
  height: 23rem;
  background-image: url('../imgs/icons-sprite/styling-advisor-header.png');
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: .1rem;
  left: 2.1rem;
  width: calc(100% - 4.2rem);

  h1{
    color: white;
  }
}

.js__styling-advisor{
  width: 100%;

  &.disabled{
    opacity: 1;
  }

  .loader-class{
    background-size: 11rem;
  }
}

.advisor-desc{
  margin-top: 22rem;
  .d-flex{
    flex-wrap: wrap;
  }
}