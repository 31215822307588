:root {
  --header-height: 0;
}
.breadcrumbs {
  padding: 2rem;
}
.breadcrumbs__item {
  text-decoration: none;
  color: #666;
  &:not(:last-child) {
    &::after {
      content: ' ● ';
    }
  }
}
