.container-box_popup {
  position: relative;
  border: 1px solid var(--main-color);
}
.title-box_pop_up {
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 1.6rem;
  margin-top: 0;
}
.description-box_pop_up {
  margin-top: 0;
  margin-bottom: 2rem;
  font-size: 1.4rem;
  font-weight: 500;
}
.container-content-box_pop_up {
  position: relative;
  overflow: hidden;
  z-index: 0;
  min-height: 18rem;
  padding: 0 2rem 0 2rem;
  .img-box_pop_up {
    position: absolute;
    object-fit: cover;
    z-index: -1;
    width: 100%;
    height: 100%;
  }
  .container-button__pop_up {
    padding-bottom: 2rem;
    width: 100%;
    .button-img-box_pup_up {
      margin-bottom: 1.6rem;
      &:last-child {
        margin: 0;
      }
    }
  }
  .container-pop-up-bag {
    .container-img-pop-up-bag {
      border: 1px solid var(--main-color);
      height: 17.3rem;
      width: 12.4rem;
      .img-product-pop-up-bag {
        width: 100%;
      }
    }
    .price-bag {
      margin: 1.2rem 0 2rem 0;
    }
  }
  .container-form-pop-up {
    .privacy-policy,
    .form-element__label_classic {
      font-weight: 500;
    }
  }
}
#__tealiumGDPRecModal {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 199;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: flex-end;
  .container {
    .container-general-pop-up-cookie {
      border: 1px solid var(--main-color);
      .section-text-cookie {
        padding: 2rem;
      }
      .section-button-cookie {
        .button-cookie {
          height: 100%;
          border: 0;
          padding: 3rem;
        }
      }
    }
  }
}

.container-title-box_pop_up {
  padding: 2rem 2rem 0 2rem;
}

.container-title-box_pop_up {
  .cta-pop-up {
    white-space: nowrap;
    height: max-content;
    margin-left: 2rem;
  }
}

.conatiner-button-close_pop_up {
  border: 1px solid var(--main-color);
  border-bottom: 0;
  width: min-content;
  margin-left: auto;
  .button-close_pop_up {
    border: 0;
    background: none;
    padding: 0.8rem 1.3rem;
    cursor: pointer;
    .icon-close {
      width: 1.6rem;
      height: 1.6rem;
    }
  }
}
.section-pop-up-age {
  position: fixed;
  z-index: 999;
  top: 0;
  width: 100%;
  height: 100%;
  align-items: flex-end;
  background-color: rgba(228, 0, 43, 0.7);
  backdrop-filter: blur(6.7px);
  display: flex;
  .container-box_popup {
    width: 100%;
    @media (min-width: 992px) {
      width: 38rem;
    }
    margin-left: auto;
    background-color: var(--white);
    .container-content-box_pop_up {
      padding-bottom: 2rem;
      min-height: auto;
      .button {
        width: 100%;
        margin-bottom: 1rem;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
