.navigation-search {
  width: 100%;
  padding: 1rem 2rem 2rem;
  margin-left: auto;
  margin-right: auto;

  .search__container {
    width: 100%;
    display: flex;
    align-items: flex-end;
    flex-flow: column;
    margin-bottom: 3rem;

    .form-element__input-container {
      position: relative;
      width: 100%;
      order: 2;

      .form-element__search {
        border: 1px solid var(--light-grey);
        color: var(--black);
        padding: 1.6rem 2rem;
        z-index: 8;
  
        &.placeholder-animation {
          pointer-events: none;
        }
  
        &::placeholder {
          color: var(--black);
        }
  
        &:placeholder-shown {
          background-image: none;
        }

        &::-webkit-search-cancel-button {
          content: none;
        }
  
        &:valid {
          & ~ .form-element__submit {
            background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.99 21.01l-5.22-5.3a8.27 8.27 0 001.95-5.35 8.36 8.36 0 10-8.35 8.36 8.26 8.26 0 005.34-1.95l5.21 5.24 1.07-1zM3.51 10.36a6.86 6.86 0 1113.72 0 6.86 6.86 0 01-13.72 0z' fill='%23121212' fill-rule='evenodd'/%3E%3C/svg%3E");
            cursor: pointer;
            pointer-events: fill;
          }
        }
        
        // &:placeholder-shown {
        //   background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.99 21.01l-5.22-5.3a8.27 8.27 0 001.95-5.35 8.36 8.36 0 10-8.35 8.36 8.26 8.26 0 005.34-1.95l5.21 5.24 1.07-1zM3.51 10.36a6.86 6.86 0 1113.72 0 6.86 6.86 0 01-13.72 0z' fill='%23dfdfdf' fill-rule='evenodd'/%3E%3C/svg%3E");
        // }
  
        // &::-webkit-search-cancel-button {
        //   content: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='UTF-8'?%3E%3Csvg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EIcons/Close%3C/title%3E%3Cg id='Icons/Close' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpath d='M18.8837209,4 L20,5.11627907 L13.116,11.999 L20,18.8837209 L18.8837209,20 L11.999,13.116 L5.11627907,20 L4,18.8837209 L10.883,12 L4,5.11627907 L5.11627907,4 L12,10.883 L18.8837209,4 Z' id='Combined-Shape' fill='%23dfdfdf'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
        // }
      }

      .form-element__submit {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 4.5rem;
        background: url("data:image/svg+xml,%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.99 21.01l-5.22-5.3a8.27 8.27 0 001.95-5.35 8.36 8.36 0 10-8.35 8.36 8.26 8.26 0 005.34-1.95l5.21 5.24 1.07-1zM3.51 10.36a6.86 6.86 0 1113.72 0 6.86 6.86 0 01-13.72 0z' fill='%23dfdfdf' fill-rule='evenodd'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-size: 2.5rem 2.5rem;
        background-position: center center;
        z-index: 9;
        cursor: default;
        pointer-events: none;
        border: none;
      }
    }
  }
  .form-element__checkbox {
    visibility: unset;
  }
  .search-editorial__term {
    color: var(--black);
    font-size: 1.4rem;
    margin-bottom: 2rem;
  }

  .search-category__list {
    display: none;

    .search-category__item {
      .checkbox__container {
        margin-bottom: 3rem;
        &.checkbox__container--toggle {
          .form-element__checkbox {
              border: 0.1rem solid var(--light-grey);
              visibility: unset;
        
              &:checked {
                & ~ .form-element__label_checkbox {
                  background-color: var(--light-grey);
                  color: var(--alternative-color);
                  z-index: 3;
                }
              } 
          }
        }
        .form-element__label_checkbox {
          border: 0.1rem solid var(--light-grey);
          color: var(--black);
        }
      }
    }
  } 

  .search-editorial__title {
    .pd-heading {
      font-family: franklin-gothic-compressed, sans-serif;
      font-size: 2rem;
      color: var(--black);
      text-transform: uppercase;
    }
  }

  .search-editorial__modules {
      & ~ div {
          margin-bottom: 4rem;

          &:last-child {
              margin-bottom: 0;
          }
      }
  }

  .search-category__list {
      margin: 0;
  }
}
