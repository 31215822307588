.navigation-search {
    padding: 8rem 0 5rem;
    max-width: 100rem;
   
    .search__container {
      width: 100%;
      display: flex;
      align-items: center;
      flex-flow: row;
      justify-content: space-between;
      margin-bottom: 3.9rem;

      .form-element__input-container {
        margin-right: 5rem;
        order: 1;

        .form-element__search {
          padding: 1.2rem 2rem;
        }
      }
    }
    .checkbox__container {
      margin-bottom: 3.9rem;
    }
    .search-editorial__term {
      margin-bottom: 1.2rem;
    }
  }