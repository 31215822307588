.module-05 {
  --dark-color: var(--main-color);
  --light-color: var(--alternative-color);

  border: 1px solid var(--main-color);
  border-right: none;
  margin-top: -1px;
  margin-right: -1px;
  padding: 0;
  display: flex;
  flex-direction: row;
  background-color: var(--light-color);

  &__text {
    padding: 1.2rem;
    border: 1px solid var(--main-color);
    p {
      margin: 0;
      text-align: left;
    }

    strong em u {
      background-color: var(--main-color);
      color: var(--alternative-color);
      font-style: normal;
      font-weight: 500;
      text-decoration: none;
      padding: 0 0.2rem;
    }
  }

  .product-tile .add-to-wish-list {
    display: none;
  }

  &.show-wishlist {
    .product-tile .add-to-wish-list {
      display: block;
    }
  }
}

.module-05-item-wrp {
  border-right: 1px solid var(--main-color);


  .product-tile--add-to-bag,
  .product-tile-body {
    display: none;
  }

  .product-tile-image {
    margin: 0;

    .tile-image-link {
      padding: 0;
      .product-tile-image__picture img {
        position: relative;
        top: unset;
        left: unset;
        z-index: 0;
      }
    }
  }
}

.module-05_container {
  position: relative;
  max-width: calc(100% - 4rem);
  margin: 2rem auto;
  --dark-color: var(--main-color);
  --light-color: var(--alternative-color);

  &-full {
    max-width: 100%;

    .tab-content {
      overflow-x: auto;
    }

    .product-grid-component {
      margin-top: 0;

      .product-grid {
        border-top: 0;

        .product-grid-item {
          border-top: 1px solid var(--main-color);

          &:nth-child(n + 9) {
            border-top: 0;
          }

          .product-tile-image {
            margin: 0;

            .tile-image-link {
              padding: 0;
              .product-tile-image__picture img {
                position: relative;
                top: unset;
                left: unset;
                z-index: 0;
              }
            }
          }

          .product-tile-body {
            position: absolute;
            bottom: -100%;
            background-color: var(--white);
            left: 0;
            width: 100%;

            .section-colors-single-product-plp {
              display: none;
            }

            .section-price-single-product-plp {
              margin-bottom: 0;
            }

            .container-badges {
              height: auto;
              z-index: 1;
              display: inline-flex;
              position: relative; // need to remove absolute positioning from the main style of the tiles badges container
              padding: 0; // need to remove padding from the main style of the tiles badges container
            }
          }
        }
      }
    }
  }

  .tab-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    .tab-content {
      display: flex;
      order: 2;
      max-width: 100%;

      > .tab-pane {
        display: none;
      }
      > .active {
        display: block;
      }
    }

    .module-05__text {
      display: flex;
      order: 1;
    }

    .toggle-container {
      display: flex;
      justify-content: center;
      order: 3;

      .nav-tabs {
        display: flex;
        list-style-type: none;
        padding: 0;
        height: 4.4rem;
        border: 1px solid var(--main-color);

        .nav-item {
          display: flex;
        }

        .nav-link {
          padding: 0 0.5rem;
          color: var(--main-color);
          background: var(--white);
          text-decoration: none;
          border: 2px solid var(--white);
          min-width: 5rem;
          text-align: center;
          display: flex;
          justify-content: center;
          align-content: center;
          flex-wrap: wrap;

          &.active {
            background-color: var(--main-color);
            color: var(--white);
          }
        }
      }
    }
  }

  .module-05__title {
    border: 1px solid var(--dark-color);
    background-color: var(--light-color);
    color: var(--dark-color);
    padding: 0.8rem;
    width: fit-content;
    display: flex;
    align-items: center;
    margin-bottom: -1px;
    .pd-heading {
      font-size: 3.2rem;
      @include secondaryFont();
      margin: 0;
      color: var(--dark-color);
    }
    .pd-icon {
      margin-left: 0.8rem;
      svg[class^='icon'] {
        width: 2.4rem;
        height: 2.4rem;
        fill: var(--main-color);
      }
    }
  }
  .module-05__link {
    text-decoration: none;
    width: fit-content;
    .module-05__title {
      height: auto;
      flex-direction: unset;

      &:hover {
        .pd-icon {
          svg[class^='icon'] {
            fill: var(--white);
          }
        }
      }
    }
    .icon-button-section-module-description {
      margin-left: 0.8rem;
    }
  }
}

.module-05_items_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  &--4 {
    margin-right: -3px;
    .module-05 {
      width: 25%;
      flex: 0 0 25%;
    }
  }
  &--3 {
    margin-right: -2px;
    .module-05 {
      width: 33.3333333%;
      flex: 0 0 33.3333333%;
    }
  }
  &--2 {
    margin-right: -1px;
    .module-05 {
      width: 50%;
      flex: 0 0 50%;
    }
  }
}

.module-05_container {
  &-full {
    .module-05_items_container {
      &--8 {
        display: grid;
        grid-template-columns: repeat(8, 120px);

        .module-05 {
          margin-top: 0;

          &:nth-child(n+9) {
            margin-top: -1px;
          }

          .container-badges {
            height: auto;
            z-index: 1;
            display: inline-flex;
            position: relative; // need to remove absolute positioning from the main style of the tiles badges container
            padding: 0; // need to remove padding from the main style of the tiles badges container
          }
        }
      }
    }
  }
}

