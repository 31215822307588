.justify-content-md-start {
    justify-content: flex-start;
}
.justify-content-md-end {
    justify-content: flex-end;
}
.justify-content-md-center {
    justify-content: center;
}
.justify-content-md-between {
    justify-content: space-between;
}
.justify-content-md-around {
    justify-content: space-around;
}
.align-items-md-start {
    align-items: flex-start;
}
.align-items-md-end {
    align-items: flex-end;
}
.align-items-md-center {
    align-items: center;
}
.align-items-md-baseline {
    align-items: baseline;
}
.align-items-md-stretch {
    align-items: stretch;
}
.align-content-md-start {
    align-content: flex-start;
}
.align-content-md-end {
    align-content: flex-end;
}
.align-content-md-center {
    align-content: center;
}
.align-content-md-between {
    align-content: space-between;
}
.align-content-md-around {
    align-content: space-around;
}
.align-content-md-stretch {
    align-content: stretch;
}
.align-self-md-auto {
    align-self: auto;
}
.align-self-md-start {
    align-self: flex-start;
}
.align-self-md-end {
    align-self: flex-end;
}
.align-self-md-center {
    align-self: center;
}
.align-self-md-baseline {
    align-self: baseline;
}
.align-self-md-stretch {
    align-self: stretch;
}