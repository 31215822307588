.module-static-plp--09 {
  .content-heading {
    .element-heading {
      font-size: 3.4rem;

      span {
        font-size: 3.4rem;
      }
    }
  }

  .element-scrollable {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }

  .element-wrapper {
    padding: 0;

    .single-item {
      width: 16.6666666%;
      flex: 0 0 16.6666666%;
    }
  }
}
