.module-empty-cart-container {
  display: block;
  max-width: 100%;
  
  .cart-item__items {
    width: 100%;
    margin: 0 auto;
    padding: 2rem;
    flex-wrap: unset;
    display: unset;
    padding: 0;
    display: block;

    .cart-item__item {
      margin-top: 0 0 4rem 0;
      width: calc(100% / 2);
      float: left;
      position: relative;
      width: 100%;
      display: flex;
      margin-left: 0;
      border: 1px solid var(--main-color);
      border-bottom: 0;

      &:nth-of-type(2n+1) {
        &::after {
          content: '';
          width: 1px;
          height: 100%;
          border-right: 1px solid var(--border-color);
          position: absolute;
          right: -1px;
          z-index: 1;
          top: 0;
        }

        .cart-item__name {
          border-right: 0;
        }

        // .cart-item__picture {
        //   border-right: 0;
        // }
      }

      &:last-of-type {
        border-bottom: 1px solid var(--border-color);
        box-sizing: border-box;
      }

      .cart-item__picture {
        border: 0;
        border-right: 1px solid var(--main-color);
        height: 8.3rem;
        width: 8.3rem;

        img {
          height: 100%;
          width: 100%;
        }
      }

      .cart-item__link {
        flex: 1;
        order: 1;

        .cart-item__name {
          display: flex;
          justify-content: space-between;
          flex: 1;
          padding: 1rem 2.1rem;
          border: none;
          height: 100%;
          padding: 0 2rem;
        }
      }
    }
  }
}
