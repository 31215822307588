.registration-page {
  .account-registration {
    .container-account-registration {
      .registration-form {
        .form-group-input-registration {
          width: 48%;

          &.form-gender-registration {
            width: 100%;
          }
        }
      }
    }
  }
}

.checkout-login-register-page.checkout-login-register-global .checkout-register-container {
  .checkout-register-wrapper {
    padding: 0;
    .container-info {
      padding: 3rem 4rem 2.5rem;
    }

    .js_checkout-register-form-container {
      padding: 2rem 4rem 3rem;
    }
  }
}