.module-static-plp--07 {
  background: var(--main-color);
  text-align: center;
  font-size: 0;

  .module-wrp {
    display: inline-block;
    padding: 6rem 2rem;
  }

  .content-text {
    text-align: left;
    font-size: 0;
  }

  .module-text {
    display: inline;
    box-shadow: 0 0 0 1px var(--main-color);
    background-color: var(--alternative-color);
    margin: 0 1px;
    font-size: 3rem;
    text-transform: uppercase;
    line-height: 1.2;
    padding: 0 0.5rem;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;

    a {
      color: inherit;
      text-decoration: underline;
    }
  }

  .content-image {
    margin-top: 1.2rem;
    text-align: left;
    font-size: 0;

    img {
      display: inline-block;
      margin: 0;
    }
  }
}
