html.view360-modal-open {
  overflow: hidden;
}

span.degree-sign:before {
  content: '\00B0';
}

button.product-btn__widget-360 {
  background-color: var(--white);
  color: var(--main-color);
  cursor: pointer;
  z-index: 2;
  text-transform: uppercase;
  border: 1px solid var(--main-color);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  align-items: center;
  position: absolute;
  right: 2rem;
  bottom: 8rem;

  svg {
    width: 3rem;
    height: 2rem;
  }
}

#View360Sidepanel {
  height: 100vh;

  .container {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: 20px;

    iframe {
      flex-grow: 1;
      border: none;
      width: 100%;
    }
    .title-modal-view360 {
      display: flex;
      align-items: center;
      svg {
        width: 2.4rem;
        height: 2.4rem;
        margin-right: 1rem;
      }
      h1 {
        @include primaryFont($weight: 500);
        font-size: 1.8rem;
        line-height: 2.4rem;
      }
    }
  }
}

.view3d-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 0;
  z-index: 101;

  &.hidden {
    visibility: hidden;
    opacity: 0;
    z-index: -99;
  }

  .modal-dialog {
    background-color: var(--white);
    border: 1px solid var(--main-color);
    position: relative;
    width: 100%;
    height: 100%;

    .back-btn {
      position: absolute;
      right: 0;
      top: 0;
      border: 0;
      border-left: 1px solid var(--main-color);
      border-bottom: 1px solid var(--main-color);
      width: 5rem;
      height: 4rem;
      padding: 0.4rem;
      background-color: var(--white);
      cursor: pointer;
      z-index: 100;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    .modal-content {
      width: 100%;
      height: 100%;
      .modal-body {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        .wanna-container {
          width: 100%;
          height: 100%;

          &>div {
            position: relative;
            width: 100%;
            height: 100%;
          }
          
        }

        .modal-actions-box {
          border-top: 1px solid var(--main-color);
          padding: 1.6rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;

          .info-container {
            display: flex;
            gap: 1rem;
            width: 100%;
            flex-wrap: wrap;
            justify-content: space-between;

            .product-title {
              margin: 0;
              color: var(--black);
              line-height: 1;
              text-transform: capitalize;
            }

            .product-information-block {
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              width: 100%;
            }
          }

          .actions-container {
            width: 100%;
            .actions-wrapper {
              display: flex;
              align-items: center;
              justify-content: flex-end;
              gap: 1.6rem;

              .product-tile_attributes {
                flex-basis: 50%;
              }

              .add-to-cart {
                flex-basis: 50%;

                button {
                  width: 100%;
                }

                .add-preorder-to-cart-section {
                  border: 1px solid var(--main-color);

                  .add-to-wish-list-btn-preorder {
                    .add-to-wish-list {
                      background: unset;
                      border: none;
                      height: 3.8rem;
                      display: flex;
                      align-items: center;
                      background-color: var(--white);

                      svg {
                        width: 2.4rem;
                        height: 2.4rem;
                      }
                    }
                  }
                }
              }

              .add-to-wish-list-btn {
                display: none;
              }

              .form-element {
                margin: 0;
                padding: 0 1.6rem;
                border: 1px solid var(--black);
                position: relative;

                .form-element__select {
                  margin: 0;
                  padding: 1rem 0;
                  border: 0;
                  margin-right: 1rem;
                  background-image: none;
                  cursor: pointer;
                  position: relative;
                  z-index: 1;
                  background-color: transparent;
                }
                svg {
                  transform: rotate(-90deg);
                  position: absolute;
                  right: 1rem;
                  top: 0.28rem;
                  width: 3rem;
                  height: 3rem;
                  fill: var(--black);
                }
              }
            }
          }
        }
      }
    }
  }
}
