.product-tile-body {
  position: relative;
  font-size: 1.2rem;
  padding: 1.6rem;

  & > .row {
    margin-left: -1.4rem;
    margin-right: -1.4rem;
    align-items: center;

    [class^="col-"]{
      padding-left: 1.4rem;
      padding-right: 1.4rem;
    }
  }

  .tile-promo__extra-info {
    width: 100%;
    font-size: 0;
    padding-bottom: 0.6rem;
    display: flex;
    // white-space: break-spaces;
    text-transform: uppercase;
    align-items: center;

    .product-badge-promotion__icon,
    .product-badge__icon {
      width: 1.2rem;
      height: 1.2rem;
    }
    img {
      display: inline-block;
      vertical-align: middle;
    }

    span {
      display: inline-block;
      vertical-align: middle;
      font-size: 1rem;
      font-weight: 600;
      padding-left: 0.2rem;
      color: var(--black);
      margin-top: 0.3rem;
    }

    a {
      font-size: 1rem;
      font-weight: 600;
      padding-left: 0.2rem;

      &:link { 
        text-decoration: none; 
      }
    } 
  }

  .plp__tile__members {
    margin: 0 0 0.6rem;
    text-transform: uppercase;
    font-size: 1rem;
    line-height: 1;
    display: flex;
    align-items: center;

    span {
      font-weight: 500;
    }

    a {
      font-weight: 500;
    }

    svg {
      width: 1.2rem;
      height: 1.2rem;
    }
    .preorder-badge-text-tile {
      font-weight: 600;
    }
  }

  .plp__tile__badge {
    margin: 0;
    text-transform: capitalize;
    font-size: 1rem;
    line-height: 1;
    display: flex;
    padding: 0.4rem;
    align-items: center;
    height: 1.9rem;
    max-width: 100%;

    img, svg {
      vertical-align: middle;
      width: 1rem;
      height: 1rem;
      margin-right: 0.5rem;
    }

    img.badge-3d-bag {
      width: 2.5rem;
      height: 2.5rem;
      margin-left: 0.5rem;
      marging-right: 0;
    }

    .text-micro {
      line-height: 1.7;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      &.uppercase {
        text-transform: uppercase;
      }
    }
  }

  &.offline-product {
    opacity: 0.5;
  }

}

.product-tile-body__color {
  font-size: 1rem;
  text-transform: uppercase;
  color: rgba(0,0,0,0.6);
  font-weight: 700;
  padding: 0;
}

.product-tile-body__badge {
  height: fit-content;
  background-color: white;
  margin-bottom: .4rem;
  margin-right: .4rem;
  border: 0.1rem solid var(--light-grey);
  color: var(--black);
  overflow: hidden;

  &.only-members-badge {
    color: var(--white);
    background-color: var(--main-color);

    svg {
      fill: var(--white)
    }
  }

  .badge-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.responsible-badge {
    padding: 0 0.2rem;

    .text-micro {
      display: none;
      line-height: 1.7;
    }

    &.standard {
      .plp__tile__badge {
        display: flex;
        align-items: center;
        padding: 0 0.2rem;
      }

      .badge-icon-container {
        height: 1.9rem;
        width: 2.1rem;
        margin: 0 -0.4rem;
      }
    }
  }

  &.standard {
    border: 1px solid #068922;
    background-color: #068922;
    .plp__tile__badge {
      color: var(--white);
    }
    .badge-icon-container {
      background-color: var(--white);
      width: 2.1rem;
      height: 1.9rem;
      margin: 0rem 0.5rem 0 -0.4rem;
      
      img {
        margin: 0;
      }
    }
  }
  
  &.v-none {
    visibility: hidden;
  }
  
  &.background-fill {
    background-color: red;
  }

  &.background-white {
    background-color: white;
  }

  &.rounded-border {
    border-radius: 50px;
  }

  &.dotted-border {
    border-style: dashed;
  }
}
.product-tile-body__product-second-hand {
  width: 100%;
  height: 100%;
  display: flex;
  font-size: 1.4rem;
  text-transform: uppercase;
  color: var(--black);
  font-weight: 700;
  align-items: flex-end;

  .icon-product-condition {
    width: 1.5rem;
    height: 1.5rem;
  }
}
.product-tile-body__link {
  color: var(--black);
  flex: 1 1 80%;
  text-decoration: none;
}

.find-in-store-link-wrapper {
  color: var(--black);
}

.vto-ar-badges {
  margin-bottom: 0.9rem;
  position: absolute;
  top: 1.2rem;
  left: 1.2rem;
  z-index: 4;

  &.wishlist-tile {
    left: 1.4rem;

    .resource-vto {
      display: none;
    }
  }
}

.view-3d-bags-badges {
  position: absolute;
  top: 0;
  bottom: unset;
  z-index: 4;
  right: unset;
  top: 1.6rem;
  left: 2rem;
  border: 1px solid var(--main-color);
  padding: 0.2rem 0.4rem;
  width: auto;

  .view-3d-bags-plp-badge {
    color: var(--main-color);
    text-transform: initial;

    span {
      font-size: 1.1rem;
    }
  }
}

.container-badges {
  // Properties that were previously set for the badge
  align-items: flex-end;
  width: 100%;
  display: flex;
  height: 2.5rem;
  z-index: 1;

  // New property for the badge
  position: relative;

  // The badges were previously positioned absolutely with these properties:
  //position: absolute;
  //padding: 0 1.6rem;
  //left: 0;
  //right: 0;
  //top: 0;

  .badges-list {
    align-items: center;
    display: flex;
    flex-flow: wrap;
    margin-bottom: 0.5rem;
    padding: 0;
  }
}

.container-price-name-carousel {
  width: 100%;

  .section-info-single-product-plp {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: inherit;
    color: var(--black);
    margin-bottom: 0.8rem;
  }

  .section-colors-single-product-plp {
    padding-right: 4rem;

    .second-hand-available-size {
      margin-left: 1rem;
      color: rgba(0,0,0,0.6);
      font-size: 1rem;
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  .section-price-single-product-plp,
  .section-colors-single-product-plp {
    .col-auto {
      padding: 0;
      margin-right: 0.25rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .section-colors-single-product-plp {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .section-price-single-product-plp {
    margin-bottom: 0.445rem;
    padding-right: 0;
  }
}

.product-tile-body__price {
  color: var(--black);

  &.discounted-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .value {
    font-size: 1.4rem;
    margin-right: 0;

    &.discounted {
      color: var(--main-color);
      width: 100%;
    }
  }

  .strike-through {
    .value {
      color: var(--alternative-grey);
      font-size: 1.2rem;
    }
  }

  .product-total-discount {
    color: var(--alternative-grey);

    .value {
      font-size: 1.2rem;
      margin-right: 0;
    }
  }

  .price-info-omnibus {
    display: none;
    height: 3.4rem;
    text-wrap-mode: wrap;

    .price-info-omnibus__text {
      color: var(--alternative-grey);
      font-size: 1.2rem;
      line-height: 1.7rem;
    }

    .popup-information-omnibus {
      display: none;
    }
  }

}

.diesel-library-badge__plp {
  position: absolute;
  //transform: translate(-90%) rotate(270deg);
  z-index: 3; 
  transform-origin: top right;
  top: 0;

  img {
    width: 0.56rem;
  }
  
  .library-badge {
    color: var(--black);
    margin: 1.6rem;
  }
} 

.fade-out-animation {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s .5s, opacity .5s linear;
}

.experience-productRegion {
  .product-tile-body__badge {
    display: none;
  }

  .product-tile-body__color {
    margin: .5rem 0;
  }

  .add-to-wish-list {
    display: none;
  }

  .diesel-library-badge__plp {
    display: none;
  }
}

.pd-carousel__products-area {
  .container-badges {
    display: none;
  }

  .diesel-library-badge__plp {
    display: none;
  }
}

.product-grid-content {
  .product-tile-body__badge {
    display: none;
  }

  .diesel-library-badge__plp {
    display: none;
  }

  .product-tile-body__color {
    display: none;
  }
}

.acc-wishlist__body {
  .product-tile-body__color {
    margin: 1rem 0;
  }
  
  .product-tile-body__badge {
    display: none;
  }

  .plp__tile__members {
    display: none;
  }
}