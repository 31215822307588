.pd-nav-menu-container{

  .back-container{
    border: none;
    color: #666666;
    text-decoration: none;
    display: inline-block;
    margin-bottom: 25px;

    .icon-back{
      width: 16px;
      height: 16px;
      vertical-align: middle;
    }

    .back-title{
      font-size: 1.2rem;
      line-height: 1.2rem;
      letter-spacing: 0.2px;
      margin-left: 8px;
      color: var(--black);
    }
  }

  .main-container{
    display: none;
  }

  .select-container{

    select{
      display: none;
    }
  }
}