.menu-backdrop {
  background-color: rgba(0,0,0,0.6);
  opacity: 0;
  position: fixed;
  z-index: 99;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: opacity 0.5s linear;
  pointer-events: none;

  &.is-open {
    opacity: 1;
    // transition: opacity 0.5s linear;
    transition: none;
    pointer-events: all;
  }
}

.header_navigation {
  @include z('header');
  background-color: var(--alternative-color);
  position: fixed;
  right: 0;
  top: 0;
  display: flex;
  .action-bar__text {
      font-size: 1.2rem;
  }

  .action-bar-for-lg {
    display: none;
  }

  .nav-user.d-none {
    display: none;
  }
  .nav-user,
  .nav-category {
    height: 4rem;
  }
  .nav-category {
    font-size: 1rem;
    display: none;
  }
  .nav-user {
    @include resetButton;
    // width: 5rem;
    width: 45px;
    align-items: center;
    display: flex;
    border: 1px solid var(--main-color);
    background-color: var(--alternative-color);
    height: 37px;
    z-index: 10;

    &.hamburger-menu {
      justify-content: center;
    }

    &:not(:last-child) {
      border-right: 0;
    }

    .icon__badge,
    svg {
      pointer-events: none;
    }

    .hamburger-icon {
      width: 2rem;
      height: 2.2rem;
      position: relative;
      display: block;
      pointer-events: none;
      transition: 0.3s;

      .bar {
        height: 0.2rem;
        width: 2rem;
        display: block;
        margin: 0.4rem auto;
        position: absolute;
        background-color: var(--main-color);
        pointer-events: none;
        transition: 0.4s;

        &:nth-of-type(1) {
          top: 0;
          transform: rotate(0);
          transition: top 0.3s ease 0.5s, transform 0.3s ease-out 0.1s;
        }
        &:nth-of-type(2) {
          opacity: 1;
          top: 0.6rem;
          transition: opacity 0.3s ease 0.3s;
        }
        &:nth-of-type(3) {
          top: 1.2rem;
          transform: rotate(0);
          transition: top 0.3s ease 0.5s, transform 0.3s ease-out 0.1s;
        }
      }
    }

    &.is-open-mobile {
      .hamburger-icon {
        .bar {
          &:nth-of-type(1) {
            top: 0.6rem;
            transform: rotate(45deg);
            transition: top 0.3s ease 0.1s, transform 0.3s ease-out 0.5s;
          }

          &:nth-of-type(2) {
            opacity: 0;
          }

          &:nth-of-type(3) {
            top: 0.6rem;
            transform: rotate(-45deg);
            transition: top 0.3s ease 0.1s, transform 0.3s ease-out 0.5s;
          }
        }
      }
    }
  }
}

.navigation--logo--link {
  cursor: default;
  display: block;
}

.navigation__bar {
    display: flex;
    height: 3.7rem;
    list-style: none;
    padding: 0;
    margin: 0;
    flex-direction: row;
    justify-content: space-between;
    border-left: 1px solid var(--main-color);

    &.d-none-mobile {
      display: none;
    }

    &.is-open {
      .navigation-dropdown {
        transform: scaleY(1);
        transition: none;
      }
    }

    &.navigation__bar-category {
      display: none;
    }

    .navigation__item--link {
      &.current-item,
      &.navigation__item--current,
      &.navigation__item--parent {
        position: relative;
        &::after {
          visibility: visible;
          transition: transform var(--transition), visibility var(--transition);
          transform: scaleX(1);
        }
      }
    }
    
    .navigation-dropdown {
      list-style: none;
      background-color: var(--alternative-color);
      width: 100%;
      height: 100%;
      position: fixed;
      z-index: -1;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      visibility: hidden;
      opacity: 0;
      transform: scaleY(0);
      transform-origin: top center;
      transition: transform .5s ease 0.25s, opacity 0.3s linear 0.75s, visibility 0.3s linear 0.75s;
      padding: 10rem 0 0;
      margin: 0;
      border: 1px solid var(--main-color);
      overflow-y: auto;

      &.navigation-dropdown--header-panel {
        overflow-y: auto;

        &.minicart-panel {
          height: auto;
          bottom: unset;
          padding: 5.5rem 0 0;
        }
      }

      .a11y-action-bar {
        position: absolute;
        top: 5rem;
        left: 0;
        z-index: 1;
        right: 0;
        justify-content: center;
      }

      &.is-focused {
        transform: scaleY(1);
        opacity: 1;
        visibility: visible;
        // transition: transform .5s ease,opacity 0ms linear 0s, visibility 0ms linear 0s;
        transition: none;

        .mobile-action,
        .navigation-column {
          transform: translateY(0);
          // transition: transform .3s,opacity .2s linear 0.5s;
          transition: none;
          opacity: 1;
        }
      }

      .navigation-column {
        transform: translateY(-1rem);
        transition: transform .1s,opacity .1s;
        transition-delay: 0s;
        opacity: 0;
        flex: 5;
      }
    }

    li.navigation__item {
        display: inline-flex;
        align-items: center;
  
        &.menu-separator {
          border-right: 0.1rem solid var(--main-color);
  
          &:last-child {
            border-right: none;
          }
        }
    }
}

.navigation--logo {
  position: fixed;
  z-index: 11;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0s;
  transition-delay: 1s;

  img {
      width: 11rem;
      display: block;
  }
}

body.navigation-is-open {
  .navigation--logo {
      opacity: 1;
      transition-delay: 0s;
  }
}

.loading-class {
  .navigation-column {
    visibility: hidden;
  }
}

.navigation__item--link {
    padding: 1rem 2rem;
    background-color: var(--alternative-color);
    font-size: 1.4rem;
    padding: 0 1.2rem;
    text-decoration: none;
    border-top: 1px solid var(--main-color);
    border-bottom: 1px solid var(--main-color);
    height: 100%;
    display: inline-flex;
    align-items: center;
    position: relative;

    span {
      pointer-events: none;
    }

    &::after {
      position: absolute;
      content: ' ';
      width: 100%;
      height: 0.3rem;
      left: 0;
      bottom: 0;
      transform: scaleX(0);
      background-color: var(--main-color);
      visibility: hidden;
      transition: transform var(--transition), visibility var(--transition);
    }

    &.highlighted {
      color: var(--alternative-color);

      &::before {
        content: '';
        position: absolute;
        z-index: 1;
        top: 50%;
        transform: translateY(-50%);
        height: 2rem;
        left: 0.4rem;
        right: 0.4rem;
        background-color: var(--main-color);
      }

      span {
        color: var(--alternative-color);
        z-index: 2;
      }
    }

    .icon__badge {
      pointer-events: none;

      & > svg {
        pointer-events: none;
      }
    }

    &.navigation__item--account {
        .button__badge {
            &:not(.hidden) {
                color: var(--main-color);
            }
        }
    }
}

.navigation-action-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0 2rem;
}

.close-menu {
  color: var(--black);
  display: block;

  &.close-account,
  &.close-search {
    margin-bottom: 2.3rem;
  }

  &.close-search {
    order: 1;
  }
}

.close-search {
  margin-bottom: 0;
  order: 2;
  &.cta{
    &:not(.highlight) {
      &::after {
        background-image: linear-gradient(90deg, black, black);

      }
    }
    &:hover,
    &:focus,
    &:active {
      &:not(.highlight) {
        &::after {
          background-image: linear-gradient(90deg, black, black 75%, transparent 75%, transparent 100%);
        }
      }
    }
  }
}

// ADA Accessibility Style 
body.cart-page.accessibility-zoom-active {
  .header_navigation {
    position: absolute;
  }
}
