@import '../../mixins';
@import '../../../pages/account/mixins';

h3.button.secondary {
  display: inline-flex;
  align-items: center;
  flex-direction: row;
  margin: 0;
  font-size: 3.2rem;
  border: 1px solid var(--border-color);
  padding: 0.8rem;
  white-space: nowrap;
  @include secondaryFont();
  svg[class^='icon'] {
    width: 2.4rem;
    height: 2.4rem;
    margin-left: 0.8rem;
  }
}
.magazine__card {
  color: var(--main-color);
  margin-bottom: 3.2rem;
  text-decoration: none;
  width: fit-content;

  @include media-breakpoint-up(lg) {
    margin-bottom: 6rem;
  }

  &__badge {
    * {
      margin: 0;
    }
  }

  &__box {
    border: 1px solid var(--main-color);
    margin-top: -1px;

    * {
      margin: 0;
    }

    &__content-image {
      border-bottom: 1px solid var(--main-color);
      margin-bottom: -1px;
      position: relative;

      img {
        display: block;
        width: 100%;
      }
    }

    &__content-text {
      padding: 1.2rem;
      display: flex;
      flex-direction: column;
      margin-top: -1px;

      @include media-breakpoint-up(lg) {
        padding: 0.9rem 1.2rem;
      }
    }

    &__tag-mobile {
      display: block;
      margin-bottom: 0.8rem;

      @include media-breakpoint-up(lg) {
        display: none;
      }
    }

    &__tag-desktop {
      display: none;
      position: absolute;
      top: 4rem;
      right: 0;
      transform: translateX(50%);
      z-index: 3;
      border: 1px solid var(--main-color);
      background: var(--white);
      padding: 0.9rem 1.2rem;
      white-space: nowrap;

      &--bottom {
        top: auto;
        right: auto;
        bottom: 4rem;
        left: 0;
        transform: translateX(-50%);
      }

      @include media-breakpoint-up(lg) {
        display: block;
      }
    }
  }
}
