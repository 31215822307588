.form-login-container {
  margin: 15% auto;
  max-width: 48.4rem;

  .login__title-container {
    .login__title {
      display: inline-block;
      font-size: 3.4rem;
      line-height: 3.2rem;
      letter-spacing: 0;
      border: 1px solid var(--main-color);
      border-bottom: none;
      margin: 0.4rem 0 0;
      padding: 0.8rem 1rem;
    }
  }
}

.form-login-wrapper {
  border: 1px solid var(--main-color);
  padding: 2.2rem 2rem 2rem;

  .text-big, .form-element__input ,.form-element__label, .form-element__label_checkbox {
    color: var(--black);
  }

  .form-element__input {
    border-bottom-color: var(--black);
  }

  .show-password-button {
    svg {
      fill: var(--black);
    }
  }

  .login-modal__forgot-remember-me {

    .checkbox__container.form-element__checkbox {
        border-color: var(--black);
    }

    .checkbox__container.form-element__checkbox:checked {
      background-color: var(--black);
    }
  }

  .register-account-link {
    margin-top: 3.2rem;
    color: var(--main-color);
    a {
      margin-left: .5rem;
    }
  }

  .action-bar-social-login {
    .action-bar__button {
      color: var(--main-color);
      border-left: 0;
      margin-bottom: 1rem;
    }
  }

  .transfer-social-account-form {
    .form-error {
      margin-top: -2rem;
    }

    .form-element__input {
      opacity: 0.3;
    }

    .form-element__input.disabled + .form-element__label,
    .form-element__input.disabled .form-element__label_mobile {
      text-decoration: none;
      opacity: 0.3;
    }
  }
}

@media (max-width: 992px) {
  .form-login-container {
    margin: 2rem;
    max-width: 100%;

    .login__title-container {
      .login__title {
        display: inline-block;
        font-size: 3.4rem;
        line-height: 3.2rem;
        letter-spacing: 0;
        border: 1px solid var(--main-color);
        border-bottom: none;
        margin: 0.4rem 0 0;
        padding: 0.8rem 1rem;
      }
    }
    .form-login-wrapper {
      border: 1px solid var(--main-color);
      padding: 2.2rem 2rem 2rem;
      .login {
        .login-modal__forgot-remember-me {
          display: block;
        }
      }
    }
  }
}
.complete-profile{
  .card-description{
    padding-bottom: 0.8rem;
  }
  select,
  .privacy-label{
    color: var(--main-color);
  }
  .card-header{
    display: inline-block;
    font-size: 3.4rem;
    line-height: 3.2rem;
    letter-spacing: 0;
    border: 1px solid var(--main-color);
    border-bottom: none;
    margin: 0.4rem 0 0;
    padding: 0.8rem 1rem;
  }
  .card-body{
    border: 1px solid var(--main-color);
    padding: 2.2rem 2rem 2rem;
    .button{
      margin-top: 3rem;
      width: 100%;
    }
  }
}
