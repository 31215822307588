.search-banner__content {
  .module-static-plp--10b {
    .content-element {
      padding: 1.2rem;
    }
    .library-visual-filters {
      justify-content: flex-start;
      flex-wrap: nowrap;
      padding-bottom: 1.2rem;

      .scroll-content {
        display: flex;
      }
    }
  }
}
