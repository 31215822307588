.module-1C {
  display: block;
  max-width: 1920px;
  height: 90vh;
  position: relative;
  margin: auto;

  &.bg-red{
    background-color: var(--main-color);
  }
  &.bg-white{
    background-color: var(--white);
  }
  &.bg-offwhite{
    background-color: var(--lighting-grey);
  }

  .module-1C-swiper-controls{
    display: block;
    position: absolute;
    width: 100%;
    z-index: 10;

    .module-1C-swiper-button-play,
    .module-1C-swiper-button-pause {
      display: none;
      border: none;
      cursor: pointer;
      text-decoration: none;
      background-color: transparent;
      margin: 0;
      padding: 0;
      position: absolute;
      right: 15px;
      bottom: 30px;

      &.button-active{
        display: block;
      }

      svg {
        width: 2.4rem;
        height: 2.4rem;
        fill: var(--white);
      }
    }

    .module-1C-swiper-button-prev,
    .module-1C-swiper-button-next {
      display: block;
      cursor: pointer;
      position: absolute;

      svg {
        width: 2.4rem;
        height: 2.4rem;
        fill: var(--main-color);
      }
    }

    .module-1C-swiper-button-next {
      right: 0px;
    }

    .module-1C-swiper-button-prev {
      right: 40px;
    }

  }

  .module-1C-swiper {

    .module-1C-slide {
      width: 100%;
      position: relative;

      .module-1C-container-background {
        height: 90vh;
        width: 100%;

        img {
          box-sizing: border-box;
          height: 90vh;
          width: 100%;
          object-fit: cover;
        }
      }

      .module-1C-container-box {
        //visibility: hidden;
        box-sizing: border-box;
        position: absolute;
        width: 100%;
        padding: 0 15px;

        &.layout-primary{

          .module-1C-box {
            min-height: 120px;

            &.tButtons {

              button, a {
                display: inline-flex;
                //flex: 1 1 auto;
                width: calc(50% - 23px);
                margin-top: 15px;
                margin-left: 15px;
              }

              /*.button:last-of-type, .link:last-of-type{
                margin-left: 15px;
              }*/
            }

            .pd-text {
              text-transform: uppercase;
              @include primaryFont;
              color: var(--main-color);
              padding: 15px;
              font-size: 14px;
              line-height: 14px;
              letter-spacing: .2px;
              border-bottom: 1px solid var(--main-color);
            }

            button, a {
              margin-top: 15px;
              margin-left: 15px;
            }
          }
        }

        &.layout-secondary{

          .module-1C-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            min-height: 145px;
            padding: 15px;

            .pd-text {
              display: flex;
              flex-direction: column;
              align-items: center;
              @include primaryFont;
              color: var(--main-color);
              font-size: 14px;
              line-height: 20px;
              letter-spacing: .2px;
              margin-bottom: 20px;

              p{
                &:first-of-type {
                  @include secondaryFont;
                  font-size: 3.2rem;
                  line-height: 3rem;
                  margin-bottom: 8px;
                }
              }
            }

            a{
              &.link{
                margin-top: 10px;
              }
            }
          }
        }

        .module-1C-box {
          border: 1px solid var(--main-color);
          background-color: var(--alternative-color);

          &:empty {
            display: none;
          }

          .pd-text {
            color: var(--main-color);
            overflow: hidden;
            text-overflow: ellipsis;
            //-webkit-line-clamp: 4;
            //display: -webkit-box;
            //-webkit-box-orient: vertical;

            p {
              @include primaryFont;
              margin: 0;

              /*&:first-of-type {
                font-size: 1.8rem;
                line-height: 2.4rem;
                margin-bottom: 8px;
              }*/

              strong {
                em {
                  u {
                    font-weight: 500;
                    // background-color: var(--main-color);
                    // color: var(--alternative-color);
                    font-style: normal;
                    text-decoration: none;
                  }
                }

                background: var(--main-color);
                color: var(--alternative-color);
              }
            }
          }

          a{

            &.link {
              display: block;
              text-decoration: underline;

              &:after {
                display: none !important;
              }
            }
          }
        }

        &.layout-anchor-link {
          .module-1C-box {
            height: 4rem;
            padding-left: 1.3rem;
            display: flex;
            align-items: center;

            a.link {
              display: flex;
              flex-direction: row-reverse;
              justify-content: space-between;
              width: 100%;
              text-decoration: none;

              .button__icon {
                background-color: var(--main-color);
                height: 3.85rem;
                width: 3.85rem;
                border: 1px solid var(--white);

                .pd-icon {
                  display: flex;
                  height: 4rem;
                  justify-content: center;
                  align-items: center;

                  svg {
                    fill: var(--white);
                  }
                }
              }
            }
          }
        }
      }
    }

    .swiper-slide {
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      &.center {
        justify-content: center;
      }

      &.left {
        justify-content: flex-start;
      }

      &.right {
        justify-content: flex-end;
      }

      /*&-active {
        .module-8__title,
        .module-8__container-text {
          visibility: visible;
        }
      }*/
    }
  }

  .module-1C-swiper-pagination {
    display: block;
    width: 100%;
    height: 5px;
    position: absolute;
    bottom: 15px;
    z-index: 10;

    .swiper-pagination-container {
      display: flex;
      justify-content: center;
      width: 100%;
      padding: 0 15px;

      .swiper-pagination-bullet {
        width: auto;
        height: 5px;
        border-radius: unset;
        flex: 1 1 auto;
        margin-left: 10px;
        background-color: transparent;
        position: relative;
        opacity: 1;
        overflow: hidden;

        &:only-child{
          display: block!important;
        }

        &:first-of-type {
          margin-left: 0;
        }

        &.swiper-pagination-bullet-active {

          .bullet-background-progress{
            animation-name: progressbar;
            //animation-iteration-count: 1;
            animation-timing-function: linear;
            //width: 100%;
            //transition-duration: 30000ms;
            //transition-timing-function: linear;
          }
        }

        .bullet-background-default{
          display: block;
          width: 100%;
          height: 5px;
          background-color: var(--white);
          opacity: 50%;
        }

        .bullet-background-progress{
          display: block;
          width: 0;
          height: 5px;
          background-color: var(--main-color);
          position: absolute;
          bottom: 0;
        }
      }
    }
  }
}

@keyframes progressbar {
  from { width: 0; }
  to { width: 100%; }
}