.pd-icon-and-text {
  display: flex;
  align-items: center;

  span {
    width: 2.4rem;
    height: 2.4rem;
    margin-right: .8rem;
  }

  p {
    margin: 0;
    color: var(--black);
    line-height: 1.4rem;
  }
}
