.static-carousel-container {

  .static-carousel {

    .swiper-wrapper {
      .swiper-slide {
        width: 33% !important;
      }
    }
  }
}