.module-static-plp {
  &.module-static-plp--05 {
    width: 100%;
    margin: 2rem auto 0;
  }
}

.module-static-plp--05 {

  .module-title {
    font-size: 3.2rem;
    margin: 0 0 1.6rem 0;
  }

  .module-text {
    color: var(--black);
    font-size: 1.6rem;
    margin: 0 0 2.4rem 0;
  }

  .content-grid-blocks-info {
    display: grid;
    grid-template-columns: 1fr;
    margin: 1.6rem 0 2.4rem 0;
    gap: 2.5rem;

    .info-title {
      font-size: 1.6rem;
      margin-bottom: 1.6rem;
      font-family: BananaGrotesk, sans-serif;
    }

    .info-description {
      color: var(--black);
      font-size: 1.4rem;
    }

    .info-description:last-child {
      margin-bottom: 0;
    }

    .module-cta-link {
      color: var(--black);
    }
  }

  .content-module-buttons {
    font-size: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .button {
      margin-right: 0.4rem;
      margin-bottom: 0.8rem;
      padding: 1.3rem 4rem;
    }
  }

  hr {
    border-top: thin;
  }
}
