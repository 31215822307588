.vue-module {
  margin: 6rem auto;
  .product-detail {
    .row {
      > div {
        .vue-module-main-image {
          padding-right: 2.7rem;
        }
      }
      .container-carousel-shop-the-look{
        margin: 0 auto;
      }
      @media (max-width: 991px) {
        .container-carousel-shop-the-look{
          padding-right: 0;
        }
      }
    }
  }
}

.vue-module__navigation {
  flex-wrap: wrap;
}
.vue-module__navigation-item {
  height: 3.2rem;
  padding: 0 1.6rem;
  margin-right: 1rem;
  &:hover,
  &.item--current {
    color: var(--primary-color);
    &::before {
      transform: scaleX(1);
    }
  }
}
.vue-module__carousels {
  position: relative;
  white-space: nowrap;
  font-size: 0;
  width: 100%;
}
.vue-module__content{
  .vue-module__carousel {
    @media (max-width: 991px) {
      &.item--current {
        border-right: none;
      }
    }
  }
}
.vue-module__carousel {
  overflow: hidden;
  display: none;
  &.item--current {
    display: block;
    position: relative;

   /*  &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      width: 1px;
      height: calc(100% - 32px);
      background: var(--main-color);
      z-index: 2;
    } */
    &::before {
      left: 0;
    }
    &::after {
      right: 0;
    }
  }
  @media (max-width: 991px) {
    &.item--current {
      border-right: none;
    }
  }
}
.vue-module__carousel-nav {
  position: absolute;
  top: -3rem;
  display: none;
  transition: opacity 500ms ease-out;
  background-color: transparent;
  border: none;
  cursor: pointer;
  svg {
    width: 2.4rem;
    height: 2.4rem;
  }
  &.vue-module__carousel-nav--prev {
    right: 3rem;
  }
  &.vue-module__carousel-nav--next {
    right: 0;
  }
}
.swiper-container-initialized {
  & ~ {
    .vue-module__carousel-nav {
      display: block;
      &.swiper-button-disabled {
        display: none;
      }
    }
  }
}
.container-card {
  border: 1px solid var(--main-color);
  border-left: none;
  @media (min-width: 992px) {
    &:first-child{
      border-left: 1px solid var(--main-color);
    }
  }
}
.vue-module__carousel-wrapper{
  @media (max-width: 991px) {
    &.swiper-wrapper{
      overflow-x: scroll;
      padding-bottom: 2rem;
      &::-webkit-scrollbar {
        width: 2px;
        height: 2px;

        &-thumb {
            background: var(--main-color);
        }

        &-track {
            background: #fff;
        }
    }
      .vue-module__tile{
        width: 30%;
      }
    }
  }
  &.swiper-wrapper.disabled {
    transform: translate3d(0px, 0, 0) !important;
    pointer-events: visible;
  }
}
.container-carousel-shop-the-look{
.swiper-pagination{
  &.disabled {
    display: none;
  }
}
.vue-module__carousel-nav{
  &.disabled {
    display: none;
  }
}
.vue-module__carousel-progress{
  &.disabled{
    display: none;
  }
}
}
@media (max-width: 991px) {
  .container-card{
    border: none;
    &:last-child{
      //border-right: 1px solid var(--main-color);
    }
    .vue-module__tile-image{
      border: 1px solid var(--main-color);
    }
  }
}
.vue-module__tile {
  font-size: 1rem;
  display: inline-block;
  a {
    display: block;
    text-decoration: none;
  }
}
.container-btn-quickbuy{
  margin: 1.2rem 1.6rem;
}
@media (max-width: 991px) {
.container-btn-quickbuy{
  display: none;  
  }
}
.btn-quickbuy{
  margin: 0;
  padding: 0;
  width: 100%;
}
.vue-module__cta {
  display: block;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  &.vue-module__price {
    .vue-module__initial-price {
      color: var(--dark-grey);
      font-weight: 500;
    }
  }
}
.vue-module__tile-image {
  display: block;
  width: 100%;
  height: auto;
  margin-bottom: 1.2rem;
}
.vue-module--style-with {
  .vue-module__product {
    width: 100%;
    border: 1px solid var(--main-color);
    grid-area: product;
  }
  .vue-module__carousels {
    grid-area: carousel;
  }
  .vue-module__content {
    .vue-module-navigation-wrapper {
      border-bottom: none;
      border-left: 1px solid var(--main-color);
      @media (max-width: 991px) {
        .vue-module__header {
        width: fit-content;
        .vue-module__title {
          width: fit-content;
          }
        }
      }
      .vue-module__header {
        padding: 0.5rem 0.8rem;
        border: 1px solid var(--main-color);
        border-left: 0;
        margin-bottom: -1px;
        .vue-module__title {
          margin: 0;
          @media (max-width: 991px) {
            width: fit-content;
          }
        }
      }

      .vue-module-navigation-nav-wrapper {
        border: 1px solid var(--main-color);
        padding: 2rem 1.5rem;
        display: none;
        .vue-module__description {
          margin-top: 0;
        }
      }
    }
  }
}

.minicart__container .vue-module--shop-the-look {
  margin: 0 auto;
  padding: 1.2rem 2rem;
  .vue-module__header {
    // display: none;
  }
  .vue-module__title {
    margin-top: 0;
    font-size: 1.4rem;
    line-height: 2rem;
    font-family: BananaGrotesk, sans-serif;
    font-weight: 500;
    font-style: normal;
  }
  .vue-module__items-wrapper {
    display: flex;
    width: 25%;
  }
  .vue-module__cta, .container-btn-quickbuy {
    display: none;
  }
  .vue-module__tile-image {
    margin-bottom: 0;
  }
}
.btn-anchor-shop-the-look {
  padding: 0 1.5rem;
  z-index: 1;
}