.promo-code-wrapper, .product-review-wrapper {
  .form-group {
    padding: 2rem;
    position: relative;
  }

  .form-element {
    margin-bottom: 0.6rem;
  }
  .invalid-feedback {
    position: relative;
    background-color: var(--main-color);
    font-size: 1.2rem;
    color: var(--white);
    padding: 3px 3px 1px;
    width: fit-content;
    letter-spacing: 0.2px;
    font-weight: 300;
    display: flex;
    flex-direction: row-reverse;

    &::after {
      content: '';
      filter: invert(100%) sepia(0%) saturate(2%) hue-rotate(109deg) brightness(104%) contrast(101%);
      @include fixedSprite(alert);
      margin-right: 5px;
    }
    &::before {
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      left: 5px;
      bottom: 11px;
      transform: translateY(-100%);
      border-style: solid;
      border-width: 0 5px 5px;
      border-color: transparent transparent #e4002b;
    }

    &.hidden {
      visibility: hidden;
      margin: 0;
    }
  }

  .coupon-price-adjustment {
    justify-content: space-between;

    + .coupon-price-adjustment {
      margin-top: 7px;
    }
  }

  .promo-code-btn {
    &:not(.success) {
      .feedback-msg {
        display: none;
      }

      .icon-check {
        display: none;
      }
    }

    &.success {
      background-color: var(--green-validation);
      color: var(--white);
      flex-direction: row;
      justify-content: space-between;
      padding: 0 1rem;
      border: none;

      .default-label {
        display: none;
      }

      .feedback-msg {
        display: flex;
        span {
          margin: 0 3px;
        }
      }

      &:hover,
      &:active,
      &:focus {
        --secondary-color: var(--green-validation);
        --primary-color: var(--primary-color);
      }
    }
    svg {
      filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(23deg) brightness(123%) contrast(110%);
      width: 20px;
    }
  }

  .promo-code-disclaimer {
    font-size: 1.2rem;
    margin-bottom: 20px;
    display: inline-block;
  }
}
