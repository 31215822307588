.search-editorial__term {
  @include resetButton;
  display: block;
  font-size: 1.8rem;
  color: var(--main-color);
  margin-bottom: 2rem;
}
.search-editorial__title {
  padding-bottom: 2rem;
  margin-top: 2rem;
  .pd-heading {
    @include primaryFont;
    font-size: 1.4rem;
  }
  @media (min-width: 992px) {
    margin-top: auto;
  }
}

.search-product {
  width: 100%;
  margin-bottom: 1.5rem;

  .search-product--image {
    width: 6.8rem;

    picture {
      border: 0.1rem solid var(--light-grey);
      width: 6.8rem;
      height: 9rem;
      display: block;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .search-product--info {
    padding-left: 1.5rem;
    width: 100%;

    .search-product--info--content {
      p {
        margin: 0;
        line-height: 1.43;
        color: var(--black);

        &.search-product--name {
          font-size: 1.4rem;
        }

        &.search-product--info--attribute {
          font-size: 1rem;
          font-weight: bold;
          text-transform: uppercase;
          color: rgba(0,0,0,0.6);
        }
      }
    }
  }

  .search-product--info--price {
    .product-price {
      .value {
        color: var(--black);
        font-size: 1.4rem;
      }

      .reduced-price {
        .value {
          color: var(--main-color);
        }

        .strike-through {
          text-decoration-color: rgba(0, 0, 0, 0.6);

          .value {
            color: rgba(0, 0, 0, 0.6);
          }
        }
      }
    }
  }
}

.search-suggestions-active {
  .search-terms {
    display: none;
  }
}

.search-categories--suggestions--group {
  list-style-type: none;
  padding: 0;
  margin: 0 0 2rem;
}

.search-categories--suggestions--group-title {
  font-size: 1.4rem;
  color: rgba(18, 18, 18, 0.5);
  line-height: 1.5;
  text-transform: uppercase;
  cursor: default;
  margin-top: 0;
  margin-bottom: 0.4rem;
}

.search-editorial__view-all {
  color: var(--black);
}

.search-editorial__results {
  p {
    color: var(--black);
    margin-top: 0;
    margin-bottom: 3rem;
  }
}