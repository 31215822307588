.navigation-user {
  display: flex;
  flex-direction: column;
  &.d-none {
    display: none;
  }
}
.navigation-user__item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 2rem;
  @media (max-width: 991px) {
    border-top: 1px solid var(--main-color);
    &:last-child {
      border-bottom: 0;
    }
  }
  &.navigation-user__item--login {
    width: 100%;
    flex: 1 0 100%;
    border-top: 1px solid var(--main-color);
    border-bottom: 1px solid var(--main-color);
    @media (max-width: 991px) {
      border-bottom: none;
    }
  }
  &.navigation-user__item--wishlist {
    a {
      text-decoration: none;
    }
  }

  &.user-info {
    display: block;
    .loyality-box {
      display: flex;
    }
    .loyalty-nav__left {
      display: flex;
      flex-direction: column;
      padding-top: 0.25rem;
    }
    .button__content {
      color: #fff;
      margin-left: 0.75rem;
    }
    .user-info-details {
      text-transform: uppercase;
      margin-bottom: 0.5rem;
      font-size: 10px;
      em {
        font-style: normal;
      }
    }
    .account-button {
      align-items: center;
      display: flex;
      .button__icon {
        svg {
          fill: (#fff);
        }
      }
    }
  }
  svg  {
    margin-right: 0.75rem;
    margin-left: 0;
  }
  &:not(.navigation-user__item--login) {
    flex: 1 0 50%;
  }
}
.navigation-user__action {
  @include resetButton;
  color: var(--main-color);
}

.header-panel {
  pointer-events: visible;

  &.login-form-panel {
    transition: opacity 0.3s linear 0.3s, visibility 0ms linear 0s;
  }

  &.password-recovery-panel,
  &.transfer-social-account-panel {
    background-color: #fff;
    margin-top: 0;
    position: absolute;
    top: 0;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s linear, visibility 0.3s linear 0.3s;

    .action-bar {
      padding: 0 2rem;
      border: 0;

      [data-user-action="back"] {
        color: var(--main-color);
        margin-bottom: 2.5rem;
        background-color: transparent;
        border: 0;
        padding: 0;
        margin: 0;
        text-decoration: underline;
      }
    }

    .form-element__input {
      border-bottom: 1px solid var(--black);
      color: var(--black);
    }

    .form-element__label {
      color: var(--black);
    }

    p {
      color: var(--black);
    }

    .header-panel__password-recoverysuccess {
      padding: 2rem;
    }

    .loyalty-register {
      p {
        color: var(--white);
      }
    }
  }

  &.transfer-social-account-panel {
    .header-panel__password-recovery {
      padding: 1rem 2rem 0;
    }
    
    .form-error {
      padding-top: 0;
    }

    .form-element {
      margin-top: 4rem;
    }

    .form-element__input {
      opacity: 0.3;
    }

    .form-element__input.disabled + .form-element__label,
    .form-element__input.disabled .form-element__label_mobile {
      text-decoration: none;
      opacity: 0.3;
    }

    .oauth-container {
      padding: 0 2rem 2rem;
    }
  }

  .action-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
}

.show-recovery-password {
  .header-panel.login-form-panel {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s linear, visibility 0.3s linear 0.3s;
  }

  .header-panel.password-recovery-panel {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s linear 0.3s, visibility 0ms linear 0s;
  }
}

.show-transfer-social-account {
  .header-panel.login-form-panel {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s linear, visibility 0.3s linear 0.3s;
  }

  .header-panel.transfer-social-account-panel {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s linear 0.3s, visibility 0ms linear 0s;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

[data-navigation-panel] {
  & > * {
    pointer-events: none;
  }
}

