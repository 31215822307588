.module4__counter {
  bottom: auto;
  top: 7.5rem;
  right: 0;
  transform: translateX(50%);
}

.moveLeft{
  transform: translateX(-215%);
}

.module4__items {
  margin: 6.4rem auto 6.4rem auto;
}
