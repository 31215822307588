.d-lg-none {
  display: none;
}
.d-lg-inline {
  display: inline;
}
.d-lg-inline-block {
  display: inline-block;
}
.d-lg-block {
  display: block;
}
.d-lg-table {
  display: table;
}
.d-lg-table-row {
  display: table-row;
}
.d-lg-table-cell {
  display: table-cell;
}
.d-lg-flex {
  display: flex;
}
.d-lg-inline-flex {
  display: inline-flex;
}
.hide-lg {
  display: none;
}
