.module-static-plp--02 {
  position: relative;

  .element-wrapper {
    display: flex;
    flex-direction: column;
    padding: 6rem 2rem;
    margin: 0 auto;
  }

  a {
    text-decoration: none;
  }

  .content-heading {
    padding: 0;
    font-size: 0;
    text-align: left;

    .heading-link {
      display: inline-block;
      padding: 0 0.8rem;
      margin: 0;
      border: 1px solid var(--border-color);
      border-bottom: none;
      position: relative;
      max-width: 100%;
    }

    .element-heading {
      display: inline-block;
      vertical-align: middle;
      font-size: 3.6rem;
      margin: 0;
      max-width: 100%;

      span {
        display: inline-block;
        vertical-align: middle;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding-right: 3rem;
        font-size: 3.6rem;
        max-width: 100%;
      }
    }

    .icon-arrow-cta {
      display: block;
      width: 2.4rem;
      height: 2.4rem;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0.8rem;
      z-index: 1;
    }
  }

  .content-img {
    width: 100%;
    flex: 1;
    border: 1px solid var(--border-color);

    img {
      display: block;
      width: 100%;
    }
  }

  .content-text {
    padding: 1.2rem;
    border: 1px solid var(--border-color);
    border-top: none;

    p {
      margin: 0;
    }
  }
  &--b {
    margin: auto;
  }
}
