.experience-assets-quickLinkBox{
  height: 100%;

  .pd-quick-link-box {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .pd-quick-link-box-header {
      display: flex;
      margin-bottom: 1.6rem;
      align-items: center;
    }

    img {
      width: 2.4rem;
      height: 2.4rem;
      margin-right: 0.8rem;
    }
  }
}
