.pd-contact-us-container {
  .title {
    margin-top: 0;
    margin-bottom: 0.8rem;
  }

  p {
    margin-top: 0;
    margin-bottom: 2.4rem;
  }

  .row {
    margin: 0;

    .pd-conctact-us-box-wrapper {
      padding: 0 0 1.6rem 0;

      &:last-child {
        padding-bottom: 0;
      }

      .region {
        border: 0.1rem solid var(--light-grey);
        padding: 1.6rem 1.6rem 0.8rem 1.6rem;
        height: 100%;
      }
    }
  }
}