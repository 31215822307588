.loyalty-nav {
  display: flex;
  background-color: var(--main-color);
  align-items: center;
  padding: 1.4rem 2rem;

  &,
  a {
    color: var(--alternative-color);
  }

  &__left,
  &__right {
    width: 50%;
    flex: 0 0 50%;
  }

  &__right {
    text-align: right;
    display: flex;
    justify-content: flex-end;

    img {
      display: block;
      width: 14rem;
      margin: 0;
    }

    svg {
      width: 140px;
      height: 32px;
    }
  }
}
