.module-static-plp--11 {
  position: relative;

  .content-heading {
    display: block;
    padding: 0;
    font-size: 0;
    text-align: left;
    width: fit-content;
    border-bottom: none;
    .heading-link {
      display: inline-block;
      padding: 0 0.8rem;
      border: 1px solid var(--main-color);
      border-bottom: 1px solid var(--main-color);
      position: relative;
      max-width: 100%;
    }

    .element-heading {
      display: inline-block;
      vertical-align: middle;
      font-size: 3rem;
      margin: 0;
      max-width: 100%;
      color: var(--main-color);

      span {
        display: inline-block;
        vertical-align: middle;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding-right: 0;
        font-size: 3rem;
        max-width: 100%;
        color: var(--main-color);
      }
    }

    .icon-arrow-fill-down {
      display: none;
      width: 2.4rem;
      height: 2.4rem;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0.8rem;
      z-index: 1;
    }
  }

  .content-element {
    padding: 1.2rem;
    border: 1px solid var(--main-color);

    p {
      margin: 0;
      color: var(--main-color);
    }

    .content-buttons {
      display: block;
      font-size: 0;
      text-align: left;
      white-space: nowrap;
      overflow-x: auto;
      padding-top: 1.7rem;
      margin-bottom: 1rem;
      .button {
        padding: 1.6rem;
        margin-right: 0.8rem;
        margin-bottom: 0.8rem;
      }

      &::-webkit-scrollbar {
        -webkit-appearance: none;
      }
      
      &::-webkit-scrollbar:horizontal {
          height: 0.2rem;
      }
      
      &::-webkit-scrollbar-thumb {
          background-color: var(--main-color);
          border-radius: 1rem;
      }
      
      &::-webkit-scrollbar-track {
          border-radius: 1rem;
          background-color: #ededed;
      }
    }
  }

  a {
    text-decoration: none;
  }
}
.search-banner__content {
  .module-static-plp--10b {
    .content-element {
      display: block;
    }
  }
}

.hide-box {
  .module-static-plp--11 {  
    margin: 0 auto 2rem;
    .content-heading {
      margin: auto;
      .heading-link {
        border-width: 0;
      }
    }
    .content-element {
      border-width: 0;
      p {
        text-align: center;
      }
      .content-buttons {
        text-align: center;
      }
    }
  }
}
