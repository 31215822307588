.module-static-plp--13 {
  position: relative;

  .content-heading {
    display: block;
    padding: 0;
    font-size: 0;
    text-align: left;

    .heading-link {
      display: inline-block;
      padding: 0 0.8rem;
      border: 1px solid var(--border-color);
      border-bottom: none;
      position: relative;
      max-width: 100%;
    }

    .element-heading {
      display: inline-block;
      vertical-align: middle;
      font-size: 3rem;
      margin: 0;
      max-width: 100%;

      span {
        display: inline-block;
        vertical-align: middle;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding-right: 0;
        font-size: 3rem;
        max-width: 100%;
      }
    }

    .icon-arrow-fill-down {
      display: none;
      width: 2.4rem;
      height: 2.4rem;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0.8rem;
      z-index: 1;
    }
  }

  .content-element {
    display: block;
    border: 1px solid var(--border-color);

    .content-image {
      img {
        display: block;
        width: 100%;
        margin: 0;
      }
    }
  }

  a {
    text-decoration: none;
  }
}
