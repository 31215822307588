.module-static-plp--03 {
  position: relative;

  .element-wrapper {
    display: flex;
    flex-direction: column;
    padding: 6rem 0;
    margin: 0 auto;
    //border-right: 1px solid var(--main-color);
    //border-left: 1px solid var(--main-color);
    //border-bottom: 1px solid var(--main-color);

    .single-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      border-top: 1px solid var(--main-color);

      &:last-child {
        border-bottom: 1px solid var(--main-color);
      }
    }
  }

  a {
    text-decoration: none;
  }

  .content-heading {
    display: block;
    width: calc(100% - 8.3rem);
    flex: 0 0 calc(100% - 8.3rem);
    padding: 2rem;
    font-size: 0;
    text-align: left;
    order: 2;

    .heading-link {
      display: block;
      padding: 0 3rem 0 0;
      margin: 0;
      position: relative;
    }

    .element-heading {
      display: block;
      font-size: 3.6rem;
      margin: 0;

      span {
        font-size: 3.6rem;
      }
    }

    .icon-arrow-cta {
      display: block;
      width: 2.4rem;
      height: 2.4rem;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      z-index: 1;
    }
  }

  .content-img {
    width: 8.3rem;
    flex: 0 0 8.3rem;
    border-right: 1px solid var(--border-color);
    order: 1;

    img {
      display: block;
      width: 100%;
    }
  }
}
