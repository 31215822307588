.pdpctasticky-wrapper {
  position: absolute;
  top: 0;
  right: 2rem;
  width: calc((100vw / 4) - 2rem);
  height: 100%;

  .pdp-product-container {
    position: sticky;
    top: 25vh;

    .product-short-description {
      @media (max-width: 991px) {
        display: none;
      }
    }
  }
  .product-content {
    border: 1px solid var(--main-color);
    padding: 2rem;
    background-color: white;
    .product-title {
      font-size: 3rem;
      line-height: 2.8rem;
      margin: 0;
    }
    .add-to-wish-list {
      background: unset;
      border: none;
      svg[class^='icon'] {
        width: 2.4rem;
        height: 2.4rem;
      }
    }
    .prices {
      margin: 0.8rem 0 2.4rem 0;
      .product-tile-body__price {
        .value {
          font-size: 1.4rem;
          line-height: 1.4rem;
        }
      }
    }
    .section-title_product {
      .prices {
        margin: 0;
      }
    }
  }
  .variation-attribute {
    margin-bottom: 2.4rem;
    color: var(--main-color);
    .swatch-attribute-values {
      display: flex;
      flex-flow: row wrap;
    }
    .attribute-value {
      background-color: transparent;
      color: var(--main-color);
      cursor: pointer;
      padding: 0 1.6rem;
      height: 3.2rem;
      border: 1px solid transparent;
      margin-bottom: 1rem;
      &.selected {
        border: 1px solid var(--main-color);
      }
      &.unavailable {
        text-decoration: line-through;
      }
      &.attribute-value--swatch {
        padding: 0;
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        margin-right: 1rem;
        background-repeat: no-repeat;
        border: 1px solid var(--white);
        overflow: hidden;
        &.attribute-value--border {
          box-shadow: inset 0 0 0 1px var(--light-grey);
        }
        &.selected {
          border: 1px solid var(--main-color);
        }
      }
    }
    .product-variation-attribute {
      color: var(--main-color);
      margin: 1rem 0;
      &.giftcard-amount {
        margin-bottom: 2rem;
        .attribute-value {
          padding: 0 0.5rem;
        }
      }
    }
    .attribute-label {
      font-size: 1.4rem;
      @include primaryFont($weight: 500);
    }
  }
  .title-container--size {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .attribute-label {
      color: var(--main-color);
    }
  }
  .attribute-values {
    &.invalid {
      & + .form-element__error {
        display: inline-block;
        opacity: 1;
        transform: translateY(0);
        height: auto;
      }
    }
  }
  .label-box_product {
    border: 1px solid var(--main-color);
    border-bottom: 0;
    width: fit-content;
    padding: 0.4rem;
    background-color: #fff;
    .text-label-box {
      margin: 0;
      font-size: 1.3rem;
      line-height: 1.3rem;
    }
  }
  .button_find-store {
    border: none;
    background: none;
    display: flex;
    align-items: center;
    padding: 0;
    margin-top: 1.2rem;
    cursor: pointer;
    .text-button_find-store {
      font-size: 1.4rem;
      line-height: 1.4rem;
      margin: 0;
    }
    .icon__location {
      margin-right: 0.4rem;
      svg[class^='icon'] {
        width: 2.4rem;
        height: 2.4rem;
      }
    }
  }
  .add-to-cart-sticky {
    .add-to-wish-list {
      border: 1px solid var(--main-color);
      border-left: 0;
      width: 4rem;
    }
  }
}
