@keyframes animateBorder {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -10rem 0;
  }
}
.module-carousel-container {
  --text-color: var(--main-color);
  --border-color: var(--main-color);
  .swiper-container {
    display: none;
    &.swiper-container-initialized {
      display: block;
    }
  }
  @media (min-width: 990px) {
    margin: 10rem 0;
  }

  svg {
    fill: var(--main-color);
  }
  position: relative;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  .module9__item {
    margin-top: 0;
    width: 206px;
  }
  .module9__items {
    margin-bottom: 0;
  }
  .module9__name {
    font-size: 3.4rem;
    max-width: 62.4rem;
  }
}

.module-9 {
  --text-color: var(--white);
  --border-color: var(--white);

  svg {
    fill: var(--white);
  }

  position: relative;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.module9__name {
  display: inline-flex;
  align-items: center;
  margin: 0;
  font-size: 3.6rem;
  border: 1px solid var(--border-color);
  border-bottom: none;
  padding: 0.2rem 0.8rem;
  svg {
    width: 2.4rem;
    height: 2.4rem;
    margin-left: 5px;
  }
}
.module9__link {
  text-decoration: none;
}

.module9__picture {
  display: flex;
  border: 1px solid var(--border-color);
  img {
    width: 100%;
  }
}

.module9__items {
  display: flex;
  flex-wrap: wrap;
  max-width: 61.8rem;
  margin-bottom: 6rem;
  .no-module-margin-top-bottom & {
    margin-bottom: 0;
  }

  &.swiper-container {
    margin-left: unset;
    margin-right: unset;
  }
}

.module9__item {
  width: calc(100% / 3);
  margin-top: 6rem;

  .no-module-margin-top-bottom & {
    margin-top: 0;
  }

  @media (min-width: 768px) {
    &:first-of-type {
      .module9__picture {
        border-left: 1px solid var(--border-color);
      }
    }
    &:last-of-type {
      .module9__picture {
        border-right: 1px solid var(--border-color);
      }
    }
    .module9__picture {
      border-left: 0;
      border-right: 0;
      position: relative;
      &::after {
        content: '';
        width: 1px;
        height: 100%;
        border-right: 1px solid var(--border-color);
        position: absolute;
        right: -1px;
        z-index: 1;
        top: 0;
      }
    }
  }
}

.module9__message {
  min-width: 20rem;

  .module9__name {
    .pd-heading {
      margin: 0;
    }
  }

  .module-9__content {
    border: 1px solid var(--border-color);
    padding: 1.2rem;
  }

  .module-9__text {
    .pd-text p {
      margin: 0;
      font-size: 1.4rem;
      line-height: 2rem;
      letter-spacing: 0.2;
    }
  }
  .module-9__cta {
    margin-top: 2rem;
    width: fit-content;
    a {
      color: var(--white);
      text-decoration: none;
      position: relative;

      &::after {
        content: ' ';
        position: absolute;
        left: 0;
        top: 100%;
        width: 100%;
        height: 1px;
        background-image: linear-gradient(90deg, var(--white), var(--white));
        background-size: 6px 1px;
        transition: 500ms;
      }
      &:hover::after {
        animation-duration: 2s;
        animation-name: animateBorder;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        background-image: linear-gradient(90deg, var(--white), var(--white) 75%, transparent 75%, transparent 100%);
      }
    }
  }
}

.module-9,
.module-carousel-container {
  .wrapper-carousel-module9 {
    position: relative;
  }
  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    top: calc(50% + 2.3rem);
    transform: translateY(-50%);
    z-index: 1;
    svg {
      width: 2.4rem;
      height: 2.4rem;
    }
    cursor: pointer;

    &::after {
      display: none;
    }
  }

  .swiper-button-disabled {
    opacity: 0.5;
    cursor: default;
  }

  .swiper-button-next {
    right: -2.5rem;
  }

  .swiper-button-prev {
    left: -2.5rem;
  }
}
