.module-13 {
  max-width: calc(100% - 4rem);
  border: 1px solid var(--main-color);
  margin: 5rem auto;
  .module-13__item {
    border-bottom: 1px solid var(--main-color);
    padding: 2rem;
    &:last-child {
      border: 0;
    }
    .module-13__heading {
      margin-bottom: 2rem;
      .pd-heading {
        margin: 0;
        font-size: 1.4rem;
        line-height: 2rem;
        letter-spacing: 0.2;
        @include primaryFont($weight: 500);
      }
      .pd-icon {
        svg[class^='icon'] {
          width: 2.4rem;
          height: 2.4rem;
          margin-right: 0.8rem;
        }
      }
    }
    .module-13__text {
      .pd-text p {
        margin: 0;
        font-size: 1.4rem;
        line-height: 2rem;
        letter-spacing: 0.2;
        strong {
          em {
            u {
              font-weight: 500;
              background-color: var(--main-color);
              color: var(--alternative-color);
              font-style: normal;
              text-decoration: none;
            }
          }
        }
      }
    }
    .module-13__cta {
      margin-top: 2rem;
    }
  }
}
