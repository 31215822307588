.pd-contact-button {
  display: flex;
  align-items: center;
  border: 0.1rem solid var(--main-color);
  color: var(--main-color);
  padding: 0.7rem 1.6rem;

  img {
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 0.4rem;
  }
}
