.pd-help-section {

  // ---CUSTOM HELPSECTION HEADINGS---
  h1 {
  }

  h1,h2,p.title {
    color: var(--black);
    font-size: 2.4rem;
    //font-style: normal;
    //font-weight: 600;
    line-height: 2.2rem;
    @include secondaryFont();
  }

  .subtitle {
    color: var(--black);
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1.4rem;
    text-transform: uppercase;
  }

  .p-black {
    color: var(--black);
    font-size: 1.4rem;
    line-height: 2rem;
  }

  .p-grey {
    color: var(--alternative-grey);
    font-size: 1.4rem;
    line-height: 2rem;
  }

  .p-grey-sm {
    color: var(--alternative-grey);
    font-size: 1.2rem;
    line-height: 1.8rem;
  }

  .bold {
    font-weight: bold;
  }
}