.module-8 {

  .module-8-swiper {
    padding: 0 2rem;

    .swiper-wrapper {
      .swiper-slide {
        width: 90%;
      }
    }
  }
}
