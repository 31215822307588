.page {
  flex-grow: 1;
  &[data-action='Checkout-Login'] {
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
      margin-top: 25px;
    }
  }
}
.page-section {
  // display: flex;
  // align-items: center;
  // justify-content: center;
  &.page-section--viewport-height-min {
    @include minFullViewportHeight();
  }
  &.page-section--viewport-height-max {
    @include fullViewportHeight();
  }
  .content {
    position: relative;
    width: 100%;
    height: 100%;
  }
}


@media (max-width: 990px) {
  .main-header + #maincontent .pd-page__content > .page-section:first-child > .content:first-child > div:first-child:not([class^='module-01']):not([class^='module-17']):not([class*='-contentAsset']):not([class*='-loyaltyHero']):not([class*='-module1C']) {
    padding-top: 10rem;
  }
}