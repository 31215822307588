.module-01 {
  height: 90vh;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .refresh {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 3;
  }
}
.module-01__slides {
  &.swiper-wrapper {
    box-sizing: inherit;
  }

  .module-11 {
    background: var(--main-color);
    max-width: 100%;
    padding: 0 2rem;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    .container-module-11 {
      width: 100%;
      max-width: 62rem;
      margin: 0 auto;
    }
    .module-11__title {
      width: 100%;
      .pd-heading {
        @include primaryFont($weight: 500);
        font-size: 1.4rem;
      }
      .pd-icon {
        margin-left: 0;
        margin-right: 0.8rem;

        svg {
          fill: var(--white) !important;
          width: 2rem;
          height: 2rem;
        }
      }
    }
    .pd-heading__container {
      order: 2;
    }
    .module-11__text {
      .pd-text {
        margin-bottom: 2rem;
        p {
          width: 100%;
        }
      }
      .button.tag {
        background: var(--white);
        color: var(--main-color);
        padding-top: 1.6rem;
        padding-bottom: 1.6rem;
        &:hover {
          color: var(--white);
        }
        ~ .pd-text {
          width: 50%;
          display: inline-flex;
          margin-top: 6rem;

          &-align-right p {
            text-align: right;
          }
        }
      }
    }
  }
}
.module-01__slide,
.module-01__slides,
.module-01__item {
  width: 100%;
  height: 100%;
}
.module-01__slide {
  display: flex;
  justify-content: center;
  flex-flow: column wrap;
  //DIESELMAN-1249
  //width: calc(100% - 0.2rem);
  width: 100%;
  // height: 50vh;
  // border: 1px solid var(--border-color);
}
.module-01__item {
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.module-01__progress {
  position: absolute;
  --duration: 0;
  background-color: var(--main-color);
  transform-origin: left;
  transform: scaleX(0);
  z-index: 2;
  left: 0;
  bottom: 0;
  height: 0.4rem;
  width: 100%;
  transition: transform var(--duration) linear;
}
.module-01__cta {
  @include debug;
  max-width: 40rem;
  width: 80%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
  background-color: var(--alternative-color);
  border: 1px solid var(--main-color);
  height: 4rem;
  .anchor-text {
    font-size: 1.4rem;
    width: 100%;
    height: 100%;
    &:hover{
      .anchor-text__icon {
        background-color: var(--white);
        border: 1px solid var(--main-color);
        svg {
          fill: var(--main-color);
        }
      }
    }
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      transition: transform var(--transition);
      transform-origin: left top;
      background-color: var(--main-color);
      transform: scaleX(0);
      z-index: -1;
    }
    &:hover,
    &:focus,
    &:active {
      color: white;
      &::before {
        transform: scaleX(1);
      }
    }
    .anchor-text__content {
      flex: 1 0 auto;
      height: 100%;
      display: flex;
      align-items: center;
      padding-left: 1.3rem;
    }
    .anchor-text__icon {
      width: 4rem;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--main-color);
      border: 1px solid var(--alternative-color);
      svg {
        fill: var(--alternative-color);
      }
    }
  }
}

.module-01__item--double {
  .module-01__slide {
    .visual {
      &:not(:first-child) {
        display: none;
      }
    }
  }
}
.module-01__banner-cta {
  @include debug;
  max-width: 40rem;
  width: 80%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
  background-color: var(--alternative-color);
  border: 1px solid var(--main-color);
  padding: 1.5rem;
  .pd-heading {
    @include primaryFont($weight: 500);
    font-size: 1.4rem;
    text-transform: uppercase;
    letter-spacing: 0.2px;
    &__container {
      padding-bottom: 1.5rem;
      position: relative;
      &:before {
        content: '';
        border-bottom: 1px solid var(--main-color);
        width: calc(100% + 3rem);
        position: absolute;
        bottom: 0;
        left: -1.5rem;
      }
    }
  }
  .button {
    margin: 1.3rem 1.3rem 0 0;
    max-width: 46%;
    &:nth-child(odd) {
      margin-right: 0;
    }
    .button__content {
      text-transform: capitalize;
    }
  }
}
.module-01__icon {
  position: absolute;
  bottom: 35px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  cursor: pointer;
  .pd-icon svg {
    height: 4rem;
    width: 4rem;
  }

  .pd-icon{
    opacity:0;
    animation: 
    sdb05 1.5s linear 0.25s 6 forwards,
    sdb05end 1s linear 10s forwards;
  }
  
}

@keyframes sdb05 {
  0% {
    transform: translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 20px);
    opacity: 0;
  }
}

@keyframes sdb05end {
  0% {
    transform: translate(0, 20px);
    opacity: 1;
  }
  100% {
    transform: translate(0, 20px);
    opacity: 1;
  }
}

