.d-sm-none {
    display: none;
}
.d-sm-inline {
    display: inline;
}
.d-sm-inline-block {
    display: inline-block;
}
.d-sm-block {
    display: block;
}
.d-sm-table {
    display: table;
}
.d-sm-table-row {
    display: table-row;
}
.d-sm-table-cell {
    display: table-cell;
}
.d-sm-flex {
    display: flex;
}
.d-sm-inline-flex {
    display: inline-flex;
}