.module__multiple-title {
  .module__title {
    border: none;
    &--button {
      .pd-heading__container {
        .pd-heading {
          position: relative;

          &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            transition: transform var(--transition);
            transform-origin: left top;
            transform: scaleX(0);
            z-index: -1;
          }
        }
      }
      &:hover {
        border: none;
        background: transparent;
        .pd-heading__container .pd-heading {
          &::before {
            transform: scaleX(1);
          }
        }
      }
    }
  }
  .pd-heading__container {
    .pd-heading {
      line-height: 4.2rem;
      font-size: 3.6rem;
    }
  }
}
