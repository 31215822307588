.m-sm-0 {
    margin: 0;
}
.mt-sm-0,
.my-sm-0 {
    margin-top: 0;
}
.mr-sm-0,
.mx-sm-0 {
    margin-right: 0;
}
.mb-sm-0,
.my-sm-0 {
    margin-bottom: 0;
}
.ml-sm-0,
.mx-sm-0 {
    margin-left: 0;
}
.m-sm-1 {
    margin: 0.25rem;
}
.mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem;
}
.mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem;
}
.mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem;
}
.ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem;
}
.m-sm-2 {
    margin: 0.5rem;
}
.mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem;
}
.mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem;
}
.mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem;
}
.ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem;
}
.m-sm-3 {
    margin: 1rem;
}
.mt-sm-3,
.my-sm-3 {
    margin-top: 1rem;
}
.mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem;
}
.mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem;
}
.ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem;
}
.m-sm-4 {
    margin: 1.5rem;
}
.mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem;
}
.mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem;
}
.mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem;
}
.ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem;
}
.m-sm-5 {
    margin: 3rem;
}
.mt-sm-5,
.my-sm-5 {
    margin-top: 3rem;
}
.mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem;
}
.mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem;
}
.ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem;
}
.p-sm-0 {
    padding: 0;
}
.pt-sm-0,
.py-sm-0 {
    padding-top: 0;
}
.pr-sm-0,
.px-sm-0 {
    padding-right: 0;
}
.pb-sm-0,
.py-sm-0 {
    padding-bottom: 0;
}
.pl-sm-0,
.px-sm-0 {
    padding-left: 0;
}
.p-sm-1 {
    padding: 0.25rem;
}
.pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem;
}
.pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem;
}
.pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem;
}
.pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem;
}
.p-sm-2 {
    padding: 0.5rem;
}
.pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem;
}
.pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem;
}
.pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem;
}
.pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem;
}
.p-sm-3 {
    padding: 1rem;
}
.pt-sm-3,
.py-sm-3 {
    padding-top: 1rem;
}
.pr-sm-3,
.px-sm-3 {
    padding-right: 1rem;
}
.pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem;
}
.pl-sm-3,
.px-sm-3 {
    padding-left: 1rem;
}
.p-sm-4 {
    padding: 1.5rem;
}
.pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem;
}
.pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem;
}
.pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem;
}
.pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem;
}
.p-sm-5 {
    padding: 3rem;
}
.pt-sm-5,
.py-sm-5 {
    padding-top: 3rem;
}
.pr-sm-5,
.px-sm-5 {
    padding-right: 3rem;
}
.pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem;
}
.pl-sm-5,
.px-sm-5 {
    padding-left: 3rem;
}
.m-sm-auto {
    margin: auto;
}
.mt-sm-auto,
.my-sm-auto {
    margin-top: auto;
}
.mr-sm-auto,
.mx-sm-auto {
    margin-right: auto;
}
.mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto;
}
.ml-sm-auto,
.mx-sm-auto {
    margin-left: auto;
}