.popup {
  transition: transform var(--transition);
  transform: translate(0, 100%);
  display: flex;
  flex-flow: column wrap;
  align-items: flex-end;
  [data-close] {
    @include resetButton;
    background-color: var(--alternative-color);
    border: 1px solid var(--border-color);
    border-bottom: none;
    width: 5rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      fill: var(--main-color);
      width: 2.4rem;
      height: 2.4rem;
    }
  }
  &.open {
    transform: translate(0);
  }
}
.popup__content {
  width: 100%;
  background-color: var(--alternative-color);
  border: 1px solid var(--border-color);
}
.popup__items {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  overflow: hidden;
  @include z(popup);
}
