.registration-page {
  display: flex;
  justify-content: center;

  .account-registration {
    margin-top: 7rem;

    .container-title_account-registration {
      border: 1px solid var(--main-color);
      border-bottom: 0;
      width: fit-content;
      padding: 0 0.8rem;
      .section-title {
        margin: 0;
        font-size: 3.4rem;
      }
    }

    .container-account-registration {
      border: 1px solid  var(--main-color);

      .registration-info-section {
        border-bottom: 1px solid var(--disabled-color);
        //margin-bottom: 2rem;
        padding: 2rem;

        .section-registration-info {
          margin-bottom: 1.4rem;

          i[class^='icon'] {
            width: 2rem;
            height: 2rem;
            margin-right: 1.2rem;
            filter: invert(10%) sepia(79%) saturate(6984%) hue-rotate(345deg) brightness(88%) contrast(103%);
          }
          .text-section-registration-info {
            margin: 0;
          }
        }
        .section-registration-description {
          margin: 0;
          margin-bottom: 2.4rem;
        }
      }

      .registration-form {
        padding: 2rem;
        .form-group-input-registration {
          width: 100%;
          margin-bottom: 3.2rem;

          .form-element__input {
            color: var(--black);
            border-color: var(--black);
          }

          .password-hint {
            color: var(--black);
            font-size: 1rem;
          }

          .show-password-button {
            svg {
              fill: var(--black);
            }
          }

          &.group-prefix-phone {
            @media (min-width: 768px) {
              margin-bottom: 0;
            }
          }

          &.input-birthday {
            margin-bottom: 0;
          }
          
          .radio__container .form-element__radio {
            border-color: var(--black);
          }

          .form-element {
            margin-bottom: 0;
          }
        }

        .form-control {
          color: var(--main-color);
          margin: 0;
        }       
      }

      .description-form-registration {
        margin-top: 0;
        margin-bottom: 3.3rem;
      }

      .custom-control-label, .form-element__label, .form-element__label_radio{
        color: var(--black);
      }

      .form-group__description {
        color: var(--black);
        margin: 0;
        margin-bottom: 3rem;

        a {
          color: var(--black);
        }

      }

      .custom-control-input.form-element__checkbox {
          border-color: var(--black);
      }

      .custom-control-input.form-element__checkbox:checked {
        background-color: var(--black);
      }

      .privacy-addtoemaillist {
        margin-top: 4rem;
      }

      .slot-account-registration {
        p {
          color: var(--black);
        }

        ul {
          li {
            display: flex;
            align-items: center;
            margin-bottom: 1rem;
            color: var(--black);

            i {
              margin-right: 1rem;
            }
          }

          .reward {
            display: list-item;
          }
        }
      }
    }
  }
  .button-form-register {
    margin-top: 3rem;
    width: 100%;
  }
}

.information-notice-panel {
  button {
    &.side-panel__close {
      display: none;
    }
  }

  &.open.cursor-hidden {
    cursor: auto;
  }

  .header-panel {
    margin-top: 0;
    position: relative;

    .action-bar {
      display: flex;
      justify-content: flex-end;
      border: 0;
      position: absolute;
      right: 0;
      top: 0;
    }

    .information-notice-panel-inner {
      padding: 2rem;

      .content-asset {
        p, li, h1, h2, h3, h4, h5 {
          color: #000;
          font-family: BananaGrotesk, sans-serif;
          font-weight: 500;
          font-style: normal;
        }
        h2 {
          font-size: 1.8rem;

          &:first-of-type {
            margin-top: 0;
            padding-right: 40px;
          }
        }
        p {
          font-size: 1.2rem;
          line-height: 1.8rem;
        }        
      }
    }
  }
}

@import '../../loyalty/modules/dashboard/complete-profile';
