.experience-assets-modulePromotionBox {
  display: flex;
  align-items: end;
  width: fit-content;

  .experience-textAndButton {
    width: 100%;
    border: 0.1rem solid var(--main-color);
    padding: 2.4rem;
    background-color: var(--white);

    .pd-text, .pd-heading__container {
      h1, h2, h3, h4, h5, h6 {
        font-size: 2.8rem;

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      p {
        margin-bottom: 0;
      }
    }

    a, button {
      margin-right: 1.6rem;

      &:not(:last-child) {
        margin-bottom: 1.6rem;
      }
    }
  }
}
