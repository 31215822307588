.module-2__variant-2 {
  width: 100%;
  height: 100%;
}
.module-2__variant-2__content-area {
  display: grid;
  width: calc(100% - 4rem);
  margin: 0 auto;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 2rem;
}

.module-2__variant-2 {
  
  .module-2__variant-2__title-area {
    margin: 0 auto;
    position: relative;
    z-index: 2;
    width: fit-content;
    &--center {
      margin: 0 2rem;
    }
    &--right {
      margin: 0 2rem 0 auto;
    }
    &--left {
      margin: 0 auto 0 2rem;
    }
    .module__link {
      text-decoration: none;
    }
    .module__title {
      .pd-heading {
        // line-height: 3.8rem;
        // font-size: 3.2rem;
        line-height: initial;
      }
    }
  }

  .module-2__variant-2__content-area {
    .module-2__item {
      display: none;
      &.module2__card-visible {
        display: block;
      }
    }
  }
}
