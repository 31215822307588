.experience-layout-productCarousel {

  .background-container {
    margin-left: 2.4rem;
    margin-right: 2.4rem;
  }
}

.product-tile-image {
  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    top: 50%;
    z-index: 2;
    display: none;
    svg {
      width: 24px;
      height: 24px;
    }
  }
  .swiper-button-prev {
    left: 1rem;
  }
  .swiper-button-next {
    right: 1rem;
  }
  &:hover {
    .swiper-button-prev,
    .swiper-button-next {
      display: block;
    }
  }
}
.cart-wishlist,
.cart-recommender {
  .pd-c-wrapper{
    &.swiper-even-centered-slide {
      margin-left: -12.5%;
    }
    .product-tile-body {
      .container-price-name-carousel{
        align-items: flex-start;
      }
    }
  }
}

.pd-product-carousel__container2 {
  .product-tile-body{
    .section-info-single-product-plp {
      width: 75%;
    }
  }
  .open-attributes{
    display: flex;
    right: 1.9rem;
    top: 40%;
    transform: translateY(-50%);
  }
}
