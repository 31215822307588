@import '../../typography';
.editorial__category {
    .editorial-category--title {
        position: relative;
        font-size: 2rem;
        line-height: 0.9;
        color: #121212;
        cursor: default;
        margin: 3rem 0 1.3rem;
        padding: 0 2rem;
        text-transform: uppercase;
        display: inline-block;
        @include secondaryFont();      
    }
}

.editorial-content {
    padding: 0 2rem;

    &.column {
        ul {
            flex-direction: column;
        }
    }

    ul {
        list-style: none;
        padding:0;
        margin: 0;
        display: flex;
        flex-flow: row wrap;
        margin-left: -1.5rem;
        margin-right: -1.5rem;

        &.column {
            flex-flow: column;
        }

        .editorial-box {
            flex: 0 0 100%;
            max-width: 100%;
            position: relative;
            padding: 0 1.5rem;
            margin-bottom: 1.5rem;

            &.half-box {
                flex: 0 0 50%;
                max-width: 50%;

                figure {
                    a {
                        &::before {
                            padding-top: calc((125 / 160) * 100%);
                        }
                    }
                }
            }
            
            .badge {
                position: absolute;
                top: 0;
                right: 1.5rem;
                background-color: var(--main-color);
                color: var(--alternative-color);
                font-size: 1rem;
                padding: 0.5rem;
                z-index: 3;
            }

            figure {
                border: 1px solid var(--main-color);
                display: block;
                margin: 0;
                overflow: hidden;

                a {
                    position: relative;
                    display: block;
                    overflow: hidden;

                    &:hover,
                    &:active,
                    &:focus {
                        span {
                            color: var(--alternative-color);
                            transition: color var(--transition);
    
                            &::before {
                                transform: translateX(0);
                                transition: transform var(--transition);
                            }
                            &::after {
                                filter: brightness(0) saturate(100%) invert(100%) sepia(13%) saturate(1%) hue-rotate(77deg) brightness(101%) contrast(101%);
                            }
                        }
                        
                        img {
                            transform: scale(1.1);
                            transition: transform var(--transition);
                        }
                    }

                    &::before {
                        content: '';
                        display: block;
                        width: 100%;
                        padding-top: calc((190 / 336) * 100%);
                    }
                    
                    img {
                        position: absolute;
                        top: 0;
                        right: 0;
                        left: 0;
                        bottom: 0;
                        border-bottom: 1px solid var(--main-color);
                        object-fit: cover;
                        object-position: center;
                        width: 100%;
                        height: calc(100% - 2.9rem);
                        transform: scale(1);
                        transition: transform var(--transition);
                        z-index: 1;
                    }

                    span {
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        left: 0;
                        pointer-events: none;
                        font-size: 1.1rem;
                        color: var(--main-color);
                        padding: 0 0.8rem;
                        overflow: hidden;
                        transition: color var(--transition);
                        background-color: var(--white);
                        z-index: 2;
                        height: 2.9rem;
                        overflow: hidden;
                        display: flex;
                        align-items: center;
                        white-space: pre-wrap;
    
                        &::before {
                            content: '';
                            background-color: var(--main-color);
                            position: absolute;
                            top: 0;
                            left: 0;
                            bottom: 0;
                            right: 0;
                            transform: translateX(-100%);
                            transition: transform var(--transition);
                            z-index: -1;
                        }
    
                        &::after {
                            content: url('../../imgs/icons-sprite/arrow-cta.svg');
                            color: var(--main-color);
                            fill: var(--main-color);
                            display: inline-block;
                            vertical-align: middle;
                            margin-left: 0.4rem;
                            filter: brightness(0) saturate(100%) invert(19%) sepia(71%) saturate(5940%) hue-rotate(344deg) brightness(117%) contrast(124%)
                        }
                    }
                }  
            }
        }
    }
}