@import '../../../pages/account/mixins';

.loyalty-register {
  flex: 1;
  background-color: var(--main-color);
  padding: 2rem;

  &,
  p {
    color: var(--alternative-color);
  }

  &__h-o-d {
    display: block;
    max-width: 21rem;
    margin-bottom: 2rem;
  }

  &__intro {
    margin-top: 0;
  }

  &__benefits {
    @include reset-list;

    margin-bottom: 0.8rem;
  }

  &__benefit {
    display: flex;
    align-items: center;
    color: var(--main-color);
    height: 3.2rem;

    .header-panel--loyalty & {
      color: var(--alternative-color);
    }

    svg {
      display: inline-block;
      margin-right: 0.8rem;
      width: 2.4rem;
      height: 2.4rem;
      fill: var(--alternative-color);
    }
  }

  &__cta {
    margin-top: 1.2rem;

    &.primary {
      border-color: var(--alternative-color);
    }

    &.secondary {
      &:hover,
      &:focus,
      &:active {
        border-color: var(--alternative-color);
      }
    }
  }
}

.header-panel {
  &--loyalty {
    display: flex;
    flex-direction: column;
  }
}

.login {
  &__oauth {
    color: var(--black);

    @include media-breakpoint-up(md) {
      display: flex;
      flex-direction: column;
      gap: 0.4rem;

      > a,
      > div {
        flex: 0 0 calc(50% - 0.2rem);
      }

      &.single-social-login {
        > a,
        > div {
          flex: 0 0 100%;
        }
      }

      .oauth-button.oauth-button--apple {
        margin-top: 0;
      }

      .oauth-button-container {
        cursor: pointer;
      }
    }
  }
}

.form-login-wrapper,
.registration-page {
  .login__oauth {
    @include media-breakpoint-up(md) {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 0.4rem;
    }
  }
}

.registration-page {

  .login-form__divider {
    display: none;
  }

  .transfer-oauth .login-form__divider {
    display: block;
  }


  .login__oauth {
    margin-top: 1.6rem;
  }
}
