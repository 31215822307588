.m-lg-0 {
    margin: 0;
}
.mt-lg-0,
.my-lg-0 {
    margin-top: 0;
}
.mr-lg-0,
.mx-lg-0 {
    margin-right: 0;
}
.mb-lg-0,
.my-lg-0 {
    margin-bottom: 0;
}
.ml-lg-0,
.mx-lg-0 {
    margin-left: 0;
}
.m-lg-1 {
    margin: 0.25rem;
}
.mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem;
}
.mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem;
}
.mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem;
}
.ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem;
}
.m-lg-2 {
    margin: 0.5rem;
}
.mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem;
}
.mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem;
}
.mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem;
}
.ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem;
}
.m-lg-3 {
    margin: 1rem;
}
.mt-lg-3,
.my-lg-3 {
    margin-top: 1rem;
}
.mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem;
}
.mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem;
}
.ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem;
}
.m-lg-4 {
    margin: 1.5rem;
}
.mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem;
}
.mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem;
}
.mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem;
}
.ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem;
}
.m-lg-5 {
    margin: 3rem;
}
.mt-lg-5,
.my-lg-5 {
    margin-top: 3rem;
}
.mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem;
}
.mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem;
}
.ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem;
}
.p-lg-0 {
    padding: 0;
}
.pt-lg-0,
.py-lg-0 {
    padding-top: 0;
}
.pr-lg-0,
.px-lg-0 {
    padding-right: 0;
}
.pb-lg-0,
.py-lg-0 {
    padding-bottom: 0;
}
.pl-lg-0,
.px-lg-0 {
    padding-left: 0;
}
.p-lg-1 {
    padding: 0.25rem;
}
.pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem;
}
.pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem;
}
.pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem;
}
.pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem;
}
.p-lg-2 {
    padding: 0.5rem;
}
.pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem;
}
.pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem;
}
.pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem;
}
.pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem;
}
.p-lg-3 {
    padding: 1rem;
}
.pt-lg-3,
.py-lg-3 {
    padding-top: 1rem;
}
.pr-lg-3,
.px-lg-3 {
    padding-right: 1rem;
}
.pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem;
}
.pl-lg-3,
.px-lg-3 {
    padding-left: 1rem;
}
.p-lg-4 {
    padding: 1.5rem;
}
.pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem;
}
.pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem;
}
.pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem;
}
.pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem;
}
.p-lg-5 {
    padding: 3rem;
}
.pt-lg-5,
.py-lg-5 {
    padding-top: 3rem;
}
.pr-lg-5,
.px-lg-5 {
    padding-right: 3rem;
}
.pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem;
}
.pl-lg-5,
.px-lg-5 {
    padding-left: 3rem;
}
.m-lg-auto {
    margin: auto;
}
.mt-lg-auto,
.my-lg-auto {
    margin-top: auto;
}
.mr-lg-auto,
.mx-lg-auto {
    margin-right: auto;
}
.mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto;
}
.ml-lg-auto,
.mx-lg-auto {
    margin-left: auto;
}