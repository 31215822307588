.module-15 {
  --dark-color: var(--main-color);
  --light-color: var(--alternative-color);

  border: 1px solid var(--main-color);
  margin-bottom: -1px;
  padding: 1.2rem;
  display: flex;
  flex-direction: row;
  background-color: var(--light-color);

  &__container-img {
    width: 10rem;
    flex: 0 0 10rem;

    .module-15__img {
      display: block;
      width: 7.5rem;
      margin: 0 auto;
    }
  }

  &__content-stars {
    font-size: 0;
    text-align: left;
    margin: 0 0 0.6rem 0;

    svg {
      display: inline-block;
      width: 2.4rem;
      height: 2.4rem;
      margin-right: 0.2rem;
      fill: var(--main-color);
    }
  }

  &__text-wrp {
    width: 100%;
    flex: 0 0 100%;
    color: var(--main-color);

    &--small {
      width: calc(100% - 10rem);
      flex: 0 0 calc(100% - 10rem);
      padding-left: 1.2rem;
    }
  }

  &__text-title {
    margin: 0 0 0.2rem 0;
    font-size: 3.4rem;
    color: inherit;
  }

  &__subtitle {
    margin: 0 0 0.6rem 0;
    text-transform: uppercase;
    color: inherit;
  }

  &__container-text {
    p {
      margin: 0;
      text-align: left;
      color: inherit;
    }

    ul {
      li {
        color: inherit;
      }
    }

    strong em u {
      background-color: var(--main-color);
      color: var(--alternative-color);
      font-style: normal;
      font-weight: 500;
      text-decoration: none;
      padding: 0 0.2rem;
    }
  }
}



.module-15_container {
  max-width: calc(100% - 4rem);
  margin: 2rem auto;
  --dark-color: var(--main-color);
  --light-color: var(--alternative-color);

  .module-15__title {
    border: 1px solid var(--dark-color);
    border-bottom: none;
    background-color: var(--light-color);
    color: var(--dark-color);
    padding: 0.8rem;
    width: fit-content;
    display: flex;
    align-items: center;
    .pd-heading {
      font-size: 3.2rem;
      @include secondaryFont();
      margin: 0;
      color: var(--dark-color);
    }
    .pd-icon {
      margin-left: 0.8rem;
      svg[class^='icon'] {
        width: 2.4rem;
        height: 2.4rem;
        fill: var(--main-color);
      }
    }
  }
  .module-15__link {
    text-decoration: none;
    width: fit-content;
    .module-15__title {
      height: auto;
      flex-direction: unset;

      &:hover {
        .pd-icon {
          svg[class^='icon'] {
            fill: var(--white);
          }
        }
      }
    }
    .icon-button-section-module-description {
      margin-left: 0.8rem;
    }
  }
}

.module-15_items_container {
  display: flex;
  flex-direction: column;
}

.module-11__text {
  p {
    color: inherit;
    strong {
      em {
        background: var(--main-color);
        color: var(--alternative-color);
      }
    }
    a {
      color: var(--main-color);
      position: relative;
      text-decoration: none;
      width: fit-content;

      &::after {
        content: ' ';
        position: absolute;
        left: 0;
        top: 100%;
        width: 100%;
        height: 1px;
        background-image: linear-gradient(90deg, var(--main-color), var(--main-color));
        background-size: 6px 1px;
        transition: 500ms;
      }

      &:hover,
      &:focus,
      &:active {
        &::after {
          background-image: linear-gradient(90deg, var(--main-color), var(--main-color) 75%, transparent 75%, transparent 100%);
          animation-duration: 2s;
          animation-name: animateBorder;
          animation-iteration-count: infinite;
          animation-timing-function: linear;
        }
      }

      u {
        text-decoration: none;
      }
    }
  }
}