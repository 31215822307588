.pd-quick-links-container {
  .row {
    margin: 0;

    .pd-quick-link-box-wrapper {
      padding: 0 0 2.4rem 0;

      &:last-child {
        padding-bottom: 0;
      }

      .region {
        background-color: var(--white);
        padding: 1.6rem;
        height: 100%;
        border: 1px solid #ededed;
      }
    }
  }
}