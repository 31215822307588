.library-visual-filters {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  overflow-x: auto;
  overflow-y: hidden;

  .scroll-content {
    display: contents;
  }

  &.content-filters {
    padding-top: 1.7rem;
    .library-filter {
      margin-left: 0;
      margin-right: 1rem;
    }
  }

  .library-filter {
    position: relative;
    margin-left: 1.2rem;

    label,
    input {
      cursor: pointer;
    }

    input {
      position: absolute;
      opacity: 0;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;

      &:checked + label {
        color: var(--white);
        background-color: var(--main-color);
      }
      &:disabled + label {
        opacity: 0.4;
      }
    }

    label {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      padding: 1.3rem 0.8rem;
      border: 1px solid var(--main-color);
      color: var(--main-color);
      height: 3.7rem;

      .img {
        width: 3.9rem;
        height: 3.7rem;
        box-sizing: border-box;
        display: inline-block;
        margin-left: -0.8rem;
        margin-right: 0.8rem;
      }

      &.is-selected {
        color: var(--white);
        background-color: var(--main-color);
      }
      &.disabled {
        opacity: 0.4;
      }
    }
    &:last-child {
      margin-right: 0;
    }
  }

  & + .product-grid-content {
    margin-top: 2rem;
  }
}
.module-static-plp--11 {
  .library-visual-filters {
    &.content-filters {
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
}
.module-static-plp--10b {
  .library-visual-filters {
    padding-bottom: 1.7rem;
    overflow-y: visible;
    row-gap: 1rem;
    margin-bottom: 1rem;
    flex-wrap: wrap;
    justify-content: flex-start;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }
    
    &::-webkit-scrollbar:horizontal {
        height: 0.2rem;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: var(--main-color);
        border-radius: 1rem;
    }
    
    &::-webkit-scrollbar-track {
        border-radius: 1rem;
        background-color: #ededed;
    }
  }
}

.hide-box {
  .module-static-plp--10b {
    margin: 0 auto 2rem;

    .library-visual-filters {
      justify-content: center;
    }
    
  }
}
