.login-modal__forgot-remember-me {
  display: flex;
  justify-content: space-between;
}
.login-modal__store {
  text-align: center;
  text-transform: uppercase;
  font-size: 1.8rem;
}
.login__oauth{
  @media (max-width: 991px) {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }
  .google-button-login{
    border: 1px solid #d9dcdf;
    justify-content: center;
    padding: 1rem;
    column-gap: 0.8rem;
    color: var(--black);
    text-decoration: none;
    width: 100%;
    letter-spacing: 0.2px;
  }
  .oauth-button-container{
    border: 1px solid #d9dcdf;
    display: flex;
    justify-content: center;
    position: relative;
    padding: 1rem;
    column-gap: 0.8rem;
    align-items: center;
    svg{
      height: 2rem;
      width: 2rem;
      fill: var(--black);
    }
  }
  .oauth-button--apple{
    border: 1px solid #d9dcdf;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;

    svg{
      fill: var(--black);
    }
    div{
      div{
        &:last-child{
          display: none;
        }
      }
    }
  }
}