
.navigation-dropdown--categories {
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 1.5rem;
    bottom: 0;
    left: 1.5rem;
    height: 100%;
    background: rgb(2,0,36);
    background: -moz-linear-gradient(360deg, rgba(2,0,36,1) 0%, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 5%);
    background: -webkit-linear-gradient(360deg, rgba(2,0,36,1) 0%, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 5%);
    background: linear-gradient(360deg, rgba(2,0,36,1) 0%, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 5%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#020024",endColorstr="#ffffff",GradientType=1);
    user-select: none;
    pointer-events: none;
    z-index: 10;
  }
}

.navigation-dropdown--content {
  height: 100%;
  width: 100%;
  padding: 0;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 9.3rem 2rem;

  .navigation-categories--content {
    display: flex;
    flex-flow: column;
    list-style: none;
    padding: 0;

    & > div:empty {
      display: none;
    }

    .navigation-column {
      margin-left: -2rem;
      margin-right: -2rem;
    }
  } 
}

.navigation-categories {
  list-style-type: none;
  display: block;
  margin: 0;
  padding: 0;
}

.navigation-category--column {
  border-top: 1px solid var(--light-grey);
  padding: 0;

  &:not(.highlighted):last-child {
    border-bottom: 0.1rem solid var(--light-grey);
  }

  a {
    text-decoration: none;
  }

  &.highlighted {
    max-width: 100%;
    flex: 0 0 100%;
    padding: 0;
  }
}

.navigation-category--title {
  position: relative;
  font-family: BananaGrotesk, sans-serif;
  font-size: 1.8rem;
  line-height: 0.9;
  color: #121212;
  cursor: default;
  margin: 0;
  padding: 2rem 0 2rem 2rem;
}

.navigation-category--title-link {
  pointer-events: none;
}

.navigation-subcategories {
  margin-top: 2rem;
}

.navigation-subcategories,
.navigation-subcategories-grouped--list {
  list-style-type: none;
  padding: 0;
  margin: 0;

  li {
    &:last-child {
      padding-bottom: 0.5rem;
    }

    .navigation-category--link {
      font-size: 1.4rem;
      color: var(--black);
      text-decoration: none;
      display: block;
      width: fit-content;
      position: relative;
      padding: 0.5rem 0;
    }
  }
}

.navigation-subcategories-grouped {
  display: flex;
  flex-flow: column;
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-top: 2.5rem;
 
  span.navigation-subcategories-grouped--title {
    font-size: 1.4rem;
    color: rgba(18, 18, 18, 0.5);
    line-height: 1.5;
    text-transform: uppercase;
    cursor: default;
    margin-top: 0;
    margin-bottom: 1.2rem;
    margin-left: 2rem;
    font-weight: 700;
    display: inline-block;
  }

  li.navigation-subcategories-grouped--column {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 1.25rem 0;

    &:last-child {
      padding-bottom: 1rem;
    }
  }
}

.navigation-subcategories--visual-filters {
  margin-top: 3.7rem;

  .navigation-subcategories--visual-filters--grid {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    grid-template-rows: auto;
    display: grid;
    grid-template-columns: repeat(4, 7.3rem);

    &.column-2 {
      grid-template-columns: repeat(2, 7.3rem);
    }

    &.column-3 {
      grid-template-columns: repeat(3, 7.3rem);
    }

    .navigation-subcategories--visual-filter {
      .navigation-subcategories--visual-filter--link {
        display: block;
        border: 0.1rem solid var(--light-grey);
        background-color: var(--alternative-color);
        color: var(--main-color);
        font-size: 1rem;
        height: 100%;
        transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);

        &:hover,
        &.hover,
        &:focus,
        &:active {
          background-color: var(--main-color);
          border: 0.1rem solid var(--main-color);
          color: var(--alternative-color);
          transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
        }

        figure {
          position: relative;
          overflow: hidden;
          width: 100%;
          margin: 0;
          pointer-events: none;

          &::before {
            content: '';
            display: block;
            width: 100%;
            padding-top: calc((109 / 73) * 100%);
          }

          img {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            object-fit: cover;
            object-position: center;
          }
        }

        span {
          display: block;
          text-align: center;
          padding: 0.47rem 0 1.4rem;
          pointer-events: none;
        }
      }
    }
  }
}

.menu-tag {
  font-size: 1.2rem;
  display: inline-block;
  margin-right: 0.5rem;
  padding: 0 0.2rem;
  cursor: pointer;
  border-width: 0.1rem;

  svg,
  i {
    width: 1rem;
    height: 1rem;
    display: inline-block;
    vertical-align: middle;
  }
}

// .navigation-categories__bar {
//   display: flex;
//   list-style: none;
//   padding: 0;
//   margin: 0;
//   flex-direction: row;
//   justify-content: space-between;

//   li.nav-item {
//     display: inline-flex;
//     align-items: center;
//   }

//   &.is-open {
//     .dropdown-menu {
//       transform: scaleY(1);
//       transition: none;
//     }
//   }

//   .dropdown-menu {
//     background-color: var(--alternative-color);
//     opacity: 0;
//     width: 100%;
//     position: fixed;
//     z-index: -1;
//     left: 0;
//     right: 0;
//     top: 0;
//     max-height: 85vh;
//     transform: scaleY(0);
//     transform-origin: top center;
//     visibility: hidden;
//     transition: transform .1s ease, opacity 0ms linear .1s, visibility 0ms linear .1s;
//     white-space: nowrap;
//     padding: 11.5rem 4rem 5rem;
//     list-style: none;
//     margin: 0;
//     display: flex;
//     flex-flow: row wrap;
//     justify-content: center;
//     overflow-y: auto;

//     &::before {
//       content: '';
//       position: absolute;
//       top: 0;
//       right: 0;
//       bottom: 0;
//       left: 0;
//       height: 100%;
//       background: rgb(2,0,36);
//       background: -moz-linear-gradient(360deg, rgba(2,0,36,1) 0%, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 15%);
//       background: -webkit-linear-gradient(360deg, rgba(2,0,36,1) 0%, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 15%);
//       background: linear-gradient(360deg, rgba(2,0,36,1) 0%, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 15%);
//       filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#020024",endColorstr="#ffffff",GradientType=1);
//       user-select: none;
//       pointer-events: none;
//     }

//     &.is-focused {
//       transform: scaleY(1);
//       opacity: 1;
//       visibility: visible;
//       transition: transform .25s ease,opacity 0ms linear 0s, visibility 0ms linear 0s;

//       .dropdown-column {
//         opacity: 1;
//         transform: translateY(0);
//         transition: transform .3s,opacity .2s;        
//         transition-delay: 0.3s;
//       }
//     }

//     .dropdown-column {
//       transform: translateY(-1rem);
//       transition: transform .1s,opacity .1s;
//       transition-delay: 0;
//       opacity: 0;
//       flex: 1 1 auto;

//       .categories {
//         display: flex;
//         flex-flow: row wrap;
//         list-style-type: none;
//         padding: 0;
//         margin-left: -1.5rem;
//         margin-right: -1.5rem;

//         .category-column {
//           padding-left: 1.5rem;
//           padding-right: 1.5rem;
//           flex: 0 0 19.4rem;
//           max-width: 19.4rem;
//         }
        
//         h2 {
//           font-size: 2rem;
//           line-height: 0.9;
//           color: #121212;
//           margin-bottom: 1.5rem;
//           text-transform: uppercase;
//         }

//         .subcategories {
//           list-style-type: none;
//           padding: 0;
//           margin: 0;
          
//           li {
//             margin-bottom: 1rem;

//             &:last-child {
//               margin-bottom: 0;
//             }

//             .dropdown-link {
//               font-size: 1.2rem;
//               color: var(--black);
//               text-decoration: none;
//               display: block;
//               width: fit-content;
//               position: relative;

//               &::after {
//                 position: absolute;
//                 content: " ";
//                 width: 100%;
//                 height: 0.1rem;
//                 left: 0;
//                 bottom: 0;
//                 visibility: hidden;
//                 transform: scaleX(0);
//                 background-color: var(--main-color);
//                 transition: transform var(--transition), visibility var(--transition);
//                 transform-origin: right;
//               }

//               &:hover {
//                 color: var(--main-color);

//                 &::after {
//                   visibility: visible;
//                   transform: scaleX(1);
//                   transform-origin: left;
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// }



// .menu-logo {
//   position: fixed;
//   z-index: 10;
//   top: 0;
//   left: 0;
//   opacity: 0;
//   transform: translateY(-10px);
//   transition: transform .1s,opacity .1s;
//   transition-delay: 0s;

//   img {
//     width: 14.5rem;
//     display: block;
//   }
// }

// body.menu-is-open {
//   .menu-logo {
//     opacity: 1;
//     transform: translateY(0);
//     transition: transform .3s,opacity .2s;        
//     transition-delay: 0.3s;
//   }
// }
