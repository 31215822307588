.module-static-plp--05 {
  padding: 0 2rem;


  .content-grid-blocks-info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 1.6rem 0 2.4rem 0;
    gap: 2.5rem;
  }
}
