.footer__boxes {
  .footer__editorial-box {
    .container-img-cta_box-footer {
      .button_img_box-footer {
        &--upper {
          margin-bottom: 2.2rem;
          @include media-breakpoint-up(lg) {
            bottom: 5rem;
          }
        }
      }
    }

    .hod-logo {
      display: block;
      width: 15rem;
      height: 3.4rem;
    }

    &--double {
      @include media-breakpoint-up(lg) {
        flex: 0 0 50%;
        display: flex;
        flex-direction: row;
        background: var(--white);
      }

      .box-image {
        width: 50%;
        flex: 0 0 50%;

        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .box-text {
        @include media-breakpoint-up(lg) {
          width: 50%;
          flex: 0 0 50%;
          height: 100%;
        }
      }
    }

    .newsletter__privacy-disclaimer {
      margin-bottom: 1.2rem;

      p,
      .pd-text {
        color: var(--main-color);
        font-size: 1rem;
      }
    }
  }
}
