.module-10-title{
  @include secondaryFont();
  text-align: center;
  font-size: 3.6rem;
  line-height: 3.4rem;
  margin-bottom: 4rem;
  h3{
    margin-top: 0;
  }
}

.wrapper-carousel-module10{
  border-top: 1px solid var(--main-color);
  border-bottom: 1px solid var(--main-color);
}

.module-10 {
  width: 100%;
  margin-top: 6.4rem;
  margin-bottom: 6.4rem;
  .module-10_items_container {
    display: flex;
    flex-flow: row nowrap;
  }
  .module-10_item {
    flex: 1 0 auto;
    a {
      text-decoration: none;
      display: inline-block;
      font-size: 0;
    }
    &:last-child {
      border-right: 1px solid var(--main-color);
    }
    .module-10_item__img-container {
      width: 10rem;
      height: 10rem;
      border-right: 1px solid var(--main-color);
      border-left: 1px solid var(--main-color);
      display: block;
      .module-10_item__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .module-10_item__text {
      .pd-heading {
        margin: 0 3.2rem;
        white-space: nowrap;
        @include secondaryFont();
        font-size: 3.2rem;
        line-height: 3rem;
      }
    }
  }
  .swiper-slide {
    width: fit-content;
  }
  .swiper-wrapper {
    transition-timing-function: linear !important;
  }
}
