
body.editorial-bar-is-open{
  .virtualTryOn{
    top: 4rem;
  }
}

.desktop-vto{
  width: 70%;
  z-index: 99;

  .modal-header{
    top: 7.5rem;

    .back-btn{
      width: 100%;
      border-top: 1px solid var(--main-color);
      display: flex;
      align-items: center;
      gap: .625rem;
      padding: 0 0.875rem;

      span{
        text-transform: uppercase;
        display: block;
      }
    }
  }

  .hide-vto-component{
    display: none;
  }
}

.show_3d_bags {
  span {
    display: block;
  }
}
