.container-general_module {
  min-height: 100vh;
  width: 100vw;
  position: relative;
}
.container-button-bk_module {
  border: 1px solid var(--main-color);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  background-color: var(--alternative-color);
  width: 25.5rem;
  max-width: 25.5rem;
  .text-button-bk_module {
    padding: 1.3rem;
    margin: 0;
    line-height: 1.4rem;
  }
}
.drag-area {
  .button-draggable {
    @include secondaryFont();
    font-size: 3.6rem;
    line-height: 3.4rem;
    letter-spacing: 0;
    padding: 0.2rem 0.8rem 0.2rem 1.2rem;
  }
}
.section-module_product-description {
  width: calc(100% - 4rem);
  .product-image_module {
    border: 1px solid var(--main-color);
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
.section-description_module-product {
  .description_module-product {
    font-size: 3rem;
    line-height: 2.8rem;
    span {
      border: 1px solid var(--main-color);
      display: block;
      width: fit-content;
      margin-top: -1px;
      background-color: var(--alternative-color);
      padding: 0.9rem 0.4rem;
    }
  }
}
.container_general-cols-product_module {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  height: fit-content;
  padding: 2rem;
  .col-product_module {
    padding: 0;
    width: 100%;
    margin-bottom: 10rem;
    .product_module-category {
      position: relative;
      .container-img-product_module-category {
        display: block;
        position: relative;
        width: 16.8rem;
        .img-product_module-category {
          width: 16.8rem;
          height: 25rem;
          object-fit: cover;
          border: 1px solid var(--main-color);
        }
        .counter-unit-product {
          background-color: var(--alternative-color);
          border: 1px solid var(--main-color);
          padding: 1.2rem;
          position: absolute;
          bottom: -10%;
          right: 8%;
          .text-counter-unit-product {
            line-height: 1.4rem;
            margin: 0;
          }
        }
      }
    }
  }
  .col-product_module-two-img {
    width: 100%;
    margin-bottom: 6rem;
  }
}
.section-module_eight_product-description {
  width: calc(100% - 4rem);
  .product-image_module {
    border: 1px solid var(--main-color);
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
.section-description_module_eight {
  width: 100%;
  padding: 1.2rem;
  border: 1px solid var(--main-color);
  background-color: var(--alternative-color);
  .text-description_module_eight {
    margin: 0;
    span {
      background-color: var(--main-color);
      color: var(--alternative-color);
    }
  }
}
