.visual {
  &.tile {
    height: 40vh;
    padding: 0 1rem;
    min-height: 26rem;
  }

  &.tile_vertical,
  &.tile_rescale {
    picture {
      max-width: 100vw;
      margin: 5% 5%;
    }
  }

  &.module01_half,
  &.module01_half_v2 {
    height: 65vh;
    max-height: 485px;
    height: 100vh;
    padding: 0 1rem;
    max-width: 449px;
    margin: auto;
    height: auto;
  }
  &.module01_half_full {
    max-width: fit-content;
    margin: auto;
    height: auto;
    margin-top: 10rem;
    img {
      max-width: 90vw;
      margin: 5rem auto;
    }
  }

  &.module01_fullbleed,
  &.module01_fullbleed_focal {
    picture {
      border: none;
    }
  }
  &.module01_fullbleed_focal {
    height: 100vh;
    picture {
      .visual__image {
        object-fit: cover;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        object-position: 27% 81%;
      }
    }
  }
}
