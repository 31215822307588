.main-header[data-action='Help-Show'] {
  .header__logo {
    width: 36rem;
  }
}

.container-title-header_help-area {
  margin-top: 0;
}

.help-page {
  .container-help-page {
    padding: 6rem 12rem 0 12rem;
    .help-page__sidebar {
      flex: 0 0 29rem;
      max-width: 29rem;
    }
    .help-page__content {
      flex: 1 0;
    }
  }
  fieldset {
    .form-group {
      width: 45%;
      float: left;
      margin-right: 2rem;
    }
    .button {
      width: 40%;
      &.giftcard__apply-button {
        width: 100%;
      }
    }
  }
}

.need-help-customer-service {
  .col-need-help-customer-service {
    border-bottom: none;
    border-right: 1px solid var(--main-color);
    &:last-child {
      border-right: 0;
    }
  }
}

.js__styling-advisor{
  width: auto;
}
