.module-15 {
  margin-right: -1px;
  flex-direction: column;
  padding-top: 1.8rem;
  width: 100%;

  &:last-child {
    margin-right: 0;
  }

  &__container-img {
    width: 100%;
    flex: 0;
    margin-bottom: 2.2rem;

    .module-15__img {
      width: 10rem;
    }
  }

  &__content-stars {
    margin-bottom: 0.8rem;
  }

  &__text-wrp {
    width: 100%;
    flex: 0;

    &--small {
      width: 100%;
      flex: 0;
      padding-left: 0;
    }
  }

  &__text-title {
    margin-bottom: 0.6rem;
  }

  &__subtitle {
    margin-bottom: 0.8rem;
  }
}

.module-15_container {
  max-width: 62rem;
  margin: 0 auto;
  margin-top: 6rem;
  margin-bottom: 6rem;

  .module-15__title {
    .pd-heading {
      font-size: 3.6rem;
    }
  }
}

.module-15_items_container {
  flex-direction: row;
  flex: 1 1 0;
}
