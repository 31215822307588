.module-static-plp--01 {
    &__border {
        width: calc(100% - 8rem);
    }
}

.product-grid {
    .module-static-plp--01 {
        &__border {
            width: 100%;
        }
    }
}