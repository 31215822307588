.icon__badge {
  display: block;
  position: relative;
  width: 2.4rem;
  height: 2.4rem;
  margin: 0 auto;
  & > svg {
    display: block;
    width: 100%;
    height: 100%;
  }
  &.icon__badge--center {
    background-color: var(--main-color);
    color: var(--alternative-color);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &.icon__badge--hidden {
    display: none;
  }
  &.icon__badge-search {
    width: 2.9rem;
  }
  &.icon__badge-menu {
    width: 2rem;
  }
}
