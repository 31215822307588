.view3d-modal {
  padding: 5rem;
  .modal-dialog {
    .modal-content {
      .modal-body {

        .modal-actions-box {
          flex-direction: row;
          padding: 2.4rem 5rem;

          .info-container {
            width: 60%;
            flex-wrap: nowrap;
            justify-content: flex-start;
            flex-direction: column;
            .product-information-block {
              width: 80%;
            }
          }

          .actions-container {
            form {
              justify-content: flex-end;
              .form-element {
                min-width: 16.4rem;
              }

              .product-tile_attributes {
                flex-basis: unset;
              }

              .add-to-cart-wishList {
                flex-basis: unset;

                button {
                  width: auto;
                }
              }
            }
          }
        }
      }
    }
  }
}