@import 'swiper/swiper';
@import '~tom-select/dist/scss/tom-select.default';
@import 'utils/display';
@import 'utils/grid';
@import 'utils/spacing';
@import 'utils/flex';
@import 'utils/text-align';
@import 'no-result-page';
@import 'components/button';
@import 'components/forms';
@import 'components/input_checkbox';
@import 'components/input_radio';
@import 'components/input_search';
@import 'components/select';
@import 'components/custom-scrollbar';
@import 'components/footer/footer';
@import 'components/header/header';
@import 'components/pop_up';
@import 'components/draggable';
@import 'components/page';
@import 'components/navigation/navigation-categories';
@import 'components/action-bar';
@import 'components/feedback-badge';
@import 'components/product-tile/product-tile';
@import 'components/search/search-filters';
@import 'components/side-panel/side-panel';
@import 'components/accordion';
@import 'components/icon-badge';
@import 'components/icon-product-condition';
@import 'components/login-modal';
@import 'components/login-page';
@import 'components/modules';
@import 'components/module-1C/module-1C';
@import 'components/module-01/module-01';
@import 'components/module-02/module-2';
@import 'components/module-02-variant2/module-2-variant2';
@import 'components/module-03/module-3';
@import 'components/module-04/module4';
@import 'components/module-05/module-05';
@import 'components/module-07/module-07';
@import 'components/module-08/module-8';
@import 'components/module-09/module-9';
@import 'components/module-10/module-10';
@import 'components/module-11/module-11';
@import 'components/module-11A/module-11A';
@import 'components/module-11B/module-11B';
@import 'components/module-13/module-13';
@import 'components/module-13B/module-13B';
@import 'components/module-15/module-15';
@import 'components/module-15B/module-15B';
@import 'components/module-16/module-16';
@import 'components/discord/discord';
@import 'components/module-17/module-17';
@import 'components/module-accordions-container/module-accordions-container';
@import 'components/module-18/module-18';
@import 'components/module-19/module-19';
@import 'components/module-plp-banner/module-plp-banner';
@import 'components/module-empty-cart/module-empty-cart';
@import 'components/multipleLineTitle/multipleLineTitle';
@import 'components/module-static-plp/module-static-plp';
@import 'components/module-static-plp-01/module-static-plp-01';
@import 'components/module-static-plp-02/module-static-plp-02';
@import 'components/module-static-plp-03/module-static-plp-03';
@import 'components/module-static-plp-03-nuovo/module-static-plp-03-nuovo';
@import 'components/module-static-plp-04/module-static-plp-04';
@import 'components/module-static-plp-05/module-static-plp-05';
@import 'components/module-static-plp-06/module-static-plp-06';
@import 'components/module-static-plp-07/module-static-plp-07';
@import 'components/module-static-plp-08/module-static-plp-08';
@import 'components/module-static-plp-09/module-static-plp-09';
@import 'components/module-static-plp-10/module-static-plp-10';
@import 'components/module-static-plp-11/module-static-plp-11';
@import 'components/module-static-plp-12/module-static-plp-12';
@import 'components/module-static-plp-13/module-static-plp-13';
@import 'components/module-static-plp-14/module-static-plp-14';
@import 'components/module-static-plp-15/module-static-plp-15';
@import 'components/navigation/navigation';
@import 'components/navigation/hamburger-menu';
@import 'components/navigation/navigation-editorial-contents';
@import 'components/navigation/navigation-user';
@import 'components/navigation/navigation-search';
@import 'components/language-selector/language-selector';
@import 'components/country-selector';
@import 'components/header-panel/header-panel';
@import 'experience/assets/anchor-text';
@import 'experience/assets/white-space';
@import 'experience/assets/experience-article';
@import 'experience/assets/text';
@import 'experience/assets/video';
@import 'experience/layout/doubleBorderContainer';
@import 'components/search/search-category';
@import 'components/search/search-editorial';
@import 'components/promo-strip/promo-strip';
@import 'components/popup/popup';
@import 'components/newsletter/newsletter';
@import 'components/visual/visual';
@import 'components/breadcrumbs/breadcrumbs';
@import 'components/login/login';
@import 'components/checkout/confirmation/confrimation-main';
@import 'components/checkout/confirmation/confirmation-create-account';
@import 'components/datepicker/datepicker';
@import 'components/datepicker/dob';
@import 'components/registration/registration';
@import 'components/menu-bag/menu-bag';
@import 'components/pdp/out-of-stock-panel';
@import 'components/pdp/pdp-cta-sticky';
@import 'components/promocode/promocode';
@import 'components/quick-view/quick-view';
@import 'components/add-to-wishlist/add-to-wishlist';
@import 'components/product-grid-component/product-grid-component';
@import 'components/library-visual-filters/library-visual-filters';
@import 'components/mini-tile';
// @import 'components/vuestyle/shopTheLook';
@import 'components/vuestyle/stylewith';
@import 'components/vuestyle/shopTheLookModal';
@import 'components/cookie';
@import 'components/product-carousel/product-carousel';
@import 'components/adobe/adobe';
@import 'components/helpArea/helpArea';
@import 'components/resetPassword/resetPassword';
@import 'components/cookie-panel/cookie-panel';
@import 'components/georedirect/georedirect';
@import 'components/virtual_try_on/virtual_try_on';
@import 'components/styling-advisor/styling_advisor';
@import 'components/view360/view360';
@import 'components/editorialHeaderBar/editorialHeaderBar';
@import 'components/einstein/einsteinCarousel';
// @import 'components/vuestyle/vueBadge';
// @import 'components/vuestyle/vueModule';
@import 'components/tom-select/tom-select';

@import 'loyalty/state';
@import 'loyalty/modules/global/nav';
@import 'loyalty/modules/account/tabs';
@import 'loyalty/modules/account/orders';
@import 'loyalty/modules/global/register';
@import 'loyalty/modules/global/footer';
@import 'loyalty/modules/global/header';
@import 'loyalty/modules/experience/hero';
@import 'loyalty/modules/experience/how-it-works';
@import 'loyalty/modules/experience/rooms';
@import 'loyalty/modules/experience/questions';
@import 'loyalty/modules/experience/promo';

@import 'magazine/modules/experience/card';
@import 'magazine/modules/experience/text';
@import 'magazine/modules/experience/strip';
@import 'magazine/modules/experience/shop-the-look';
@import 'magazine/modules/experience/carousel';
@import 'magazine/modules/experience/hero';
@import 'magazine/modules/experience/highlight-news';
@import "experience/assets/helpsection/_topic-box";
@import "experience/assets/helpsection/_contact-button";
@import "experience/layout/helpsection/_topic-boxes-container";
@import "experience/layout/helpsection/_contact-us-container";
@import "experience/layout/helpsection/_quick-links-container";
@import 'experience/layout/helpsection/navigation-menu-container';
@import "experience/assets/helpsection/_quick-link-box";
@import "experience/assets/helpsection/_contact-us-box";
@import "experience/layout/helpsection/_image-editorial-container";
@import "experience/assets/helpsection/_utils";
@import "experience/assets/helpsection/_accordion";
@import 'experience/assets/helpsection/navigation-menu-element';
@import 'experience/layout/helpsection/cookies-page-container';
@import 'experience/layout/helpsection/check-order-page-container';
@import 'experience/assets/helpsection/livechat-button';
@import "experience/layout/helpsection/accordions-container";
@import 'experience/layout/editorialModules/module-1b-container';
@import 'experience/assets/editorialModules/module-1b-panel';
@import "experience/assets/helpsection/_icon-and-text";

@import 'experience/pages/helpPage';
@import 'experience/utils/editorialModules';
@import 'components/module-hero/module-hero';
@import 'components/module-promotion/module-promotion';
@import 'components/module-promotion-panel/module-promotion-panel';
@import 'components/module-promotion-box/module-promotion-box';
@import 'components/module-clipboard/module-clipboard';

// Customer care / RED Entry point style
@import 'components/chat-entry-point/chat-entry-point';


body::before {
  content: 'xsmall';
  display: none;
  visibility: hidden;
}

//Mobile first means is to develop on the mobile frontend and then edit only
//the changing parts for other breakpoints

@media (min-width: 576px) {
  body::before {
    content: 'small';
  }
  //Checkpoint sm
  @import 'utils/display.sm';
  @import 'utils/grid.sm';
  @import 'utils/flex.sm';
  @import 'utils/spacing.sm';
  @import 'utils/text-align.sm';
  @import 'components/product-tile/product-tile-image.sm';
  @import 'components/module-static-plp-03-nuovo/module-static-plp-03-nuovo.md';
  @import 'components/module-1C/module-1C.sm';

  // Customer care / RED Entry point style
  @import 'components/chat-entry-point/chat-entry-point.sm';
}

@media (min-width: 768px) {
  body::before {
    content: 'medium';
  }
  //Checkpoint md
  @import 'utils/display.md';
  @import 'utils/grid.md';
  @import 'utils/flex.md';
  @import 'utils/spacing.md';
  @import 'utils/text-align.md';
  @import 'components/visual/visual.md';
  @import 'components/product-grid-component/product-grid-component.md';
  @import 'experience/layout/doubleBorderContainer.lg';
  @import 'components/module-plp-banner/module-plp-banner.md';

}

@media (max-width: 767px) {
  @import 'components/visual/visual.sm.max';
  @import 'components/module-09/module-9.sm.max';
  @import 'components/module-empty-cart/module-empty-cart.sm.max';
  @import 'components/input_search.sm.max';
  @import 'components/product-grid-component/product-grid-component.sm.max';
  @import 'components/product-add-to-cart/product-add-to-cart.sm.max';
  @import 'components/module-18/module-18.sm.max';
}

@media (max-width: 991px) {
  @import 'components/side-panel/side-panel.md.max';
  @import 'components/menu-bag/menu-bag.md.max';
  @import 'components/module-08/module-8.md.max';
  @import 'components/vuestyle/stylewith.md.max';
  // @import 'components/pdp/pdp-carousel.lg.max';
  @import 'components/line-item/line-item.lg.max';
  @import 'components/product-tile/product-tile-body.lg.max';
  @import 'components/header/header.lg.max';
  @import 'components/navigation/navigation-categories.lg.max';
  @import 'components/checkout/confirmation/confrimation-main.lg.max';
  @import 'components/page.md.max';
  @import 'components/module-02/module-2.lg.max';
  @import 'components/module-03/module-3.lg.max';
  @import 'components/library-visual-filters/library-visual-filters.lg.max';
}

@media (min-width: 992px) {
  body::before {
    content: 'large';
  }
  //Checkpoint lg
  @import 'components/side-panel/side-panel.lg';
  @import 'utils/display.lg';
  @import 'utils/grid.lg';
  @import 'utils/flex.lg';
  @import 'utils/spacing.lg';
  @import 'no-result-page.lg';
  @import 'components/footer/footer.lg';
  @import 'components/header/header.lg';
  @import 'utils/text-align.lg';
  @import 'components/navigation/navigation-categories.lg';
  @import 'components/modules.lg';
  @import 'components/module-01/module-01.lg';
  @import 'components/module-02/module-2.lg';
  @import 'components/module-02-variant2/module-2-variant2.lg';
  @import 'components/module-04/module4.lg';
  @import 'components/module-03/module-3.lg';
  @import 'components/module-05/module-05.lg';
  @import 'components/module-07/module-07.lg';
  @import 'components/module-08/module-8.lg';
  @import 'components/module-10/module-10.lg';
  @import 'components/module-11/module-11.lg';
  @import 'components/module-11A/module-11A.lg';
  @import 'components/module-11B/module-11B.lg';
  @import 'components/module-13/module-13.lg';
  @import 'components/module-13B/module-13B.lg';
  @import 'components/module-15/module-15.lg';
  @import 'components/module-15B/module-15B.lg';
  @import 'components/module-16/module-16.lg';
  @import 'components/module-17/module-17.lg';
  @import 'components/module-19/module-19.lg';
  @import 'components/multipleLineTitle/multipleLineTitle.lg';
  @import 'components/module-static-plp/module-static-plp.lg';
  @import 'components/module-static-plp-01/module-static-plp-01.lg';
  @import 'components/module-static-plp-02/module-static-plp-02.lg';
  @import 'components/module-static-plp-03/module-static-plp-03.lg';
  @import 'components/module-static-plp-03-nuovo/module-static-plp-03-nuovo.lg';
  @import 'components/module-static-plp-04/module-static-plp-04.lg';
  @import 'components/module-static-plp-05/module-static-plp-05.lg';
  @import 'components/module-static-plp-06/module-static-plp-06.lg';
  @import 'components/module-static-plp-07/module-static-plp-07.lg';
  @import 'components/module-static-plp-08/module-static-plp-08.lg';
  @import 'components/module-static-plp-09/module-static-plp-09.lg';
  @import 'components/module-static-plp-10/module-static-plp-10.lg';
  @import 'components/module-static-plp-11/module-static-plp-11.lg';
  @import 'components/module-static-plp-12/module-static-plp-12.lg';
  @import 'components/module-static-plp-13/module-static-plp-13.lg';
  @import 'components/module-static-plp-14/module-static-plp-14.lg';
  @import 'components/header-panel/header-panel.lg';
  @import 'components/promo-strip/promo-strip.lg';
  @import 'components/popup/popup.lg';
  @import 'components/draggable.lg';
  // @import 'components/search/search-banner.lg';
  // @import 'components/search/header.lg';
  // @import 'components/search/search-filters.lg';
  @import 'components/search/search-editorial.lg';
  @import 'components/checkout/confirmation/confrimation-main.lg';
  @import 'components/checkout/confirmation/confirmation.lg';
  @import 'components/checkout/confirmation/confirmation-create-account.lg';
  @import 'components/registration/registration.lg';
  @import 'components/quick-view/quick-view.lg';
  @import 'components/helpArea/helpArea.lg';
  @import 'components/product-carousel/product-carousel.lg';
  @import 'components/product-tile/product-tile-body.lg';
  @import 'components/product-tile/product-tile-add-to-bag.lg';
  @import 'components/georedirect/georedirect.lg';
  @import 'components/resetPassword/resetPassword.lg';
  @import 'components/vuestyle/shopTheLookModal.lg';
  @import 'components/virtual_try_on/virtual_try_on.lg';
  @import 'components/styling-advisor/styling_advisor.lg';
  @import "components/module-accordions-container/module-accordions-container.lg";
  @import "experience/assets/helpsection/topic-box.lg";
  @import "experience/layout/helpsection/_topic-boxes-container.lg";
  @import "experience/layout/helpsection/_contact-us-container.lg";
  @import "experience/layout/helpsection/_quick-links-container.lg";
  @import "experience/assets/helpsection/_quick-link-box.lg";
  @import "experience/assets/helpsection/_accordion.lg";
  @import "experience/layout/helpsection/_image-editorial-container.lg";
  @import 'experience/layout/helpsection/navigation-menu-container.lg';
  @import "experience/layout/helpsection/accordions-container.lg";
  @import 'experience/pages/helpPage.lg';
  @import 'components/view360/view360.lg';
  @import 'experience/layout/editorialModules/module-1b-container.lg';
  @import 'experience/assets/editorialModules/module-1b-panel.lg';
  @import "experience/assets/helpsection/icon-and-text.lg";
  @import 'components/module-hero/module-hero.lg';
  @import 'components/module-promotion/module-promotion.lg';
  @import 'components/module-promotion-panel/module-promotion-panel.lg';
  @import 'components/module-promotion-box/module-promotion-box.lg';
  @import 'components/module-clipboard/module-clipboard.lg';

  // Customer care / RED Entry point style
  @import 'components/chat-entry-point/chat-entry-point.lg';
}

@media (min-width: 1025px) {
  body::before {
    content: 'xlarge'
  }

  @import 'components/navigation/navigation-user.lg';
  @import 'components/navigation/navigation.lg';
  @import 'components/navigation/navigation-editorial-contents.lg';
  @import 'components/navigation/navigation-search.lg';
  @import 'components/editorialHeaderBar/editorialHeaderBar.lg';
  @import 'components/module-static-plp-01/module-static-plp-01.xl';
}

@media (min-width: 1200px) {
  body::before {
    content: 'xlarge';
  }

  @import 'components/module-static-plp-12/module-static-plp-12.xl';

  //Checkpoint xl
  @import 'utils/display.xl';
  @import 'utils/grid.xl';
  @import 'utils/flex.xl';
  @import 'utils/spacing.xl';
  @import 'utils/text-align.xl';
  @import 'components/vuestyle/shopTheLookModal.xl';
  @import 'components/module-07/module-07.xl';

}

@media (min-width: 1400px) {
  // [TODO] Il breakpointManager gestisce solo fino ad xlarge. Ad oggi questa gestione è suddivisa in troppi file per poter creare un nuovo breakpoint su breakpointManager.
  // Valutare in un refactor FE l'aggiunta di questo breakpoint
  //
  // body::before {
  //   content: 'xxlarge';
  // }

  @import 'experience/pages/helpPage.xxl';
  @import 'components/module-07/module-07.xxl';
}

// body::after {
//   content: "CSS: Revamp Base";
//   display: block;
//   position: fixed;
//   z-index: 999999;
//   background-color: red;
//   border: 1px solid white;
//   color: white;
//   top: 0;
//   left: 50px;
// }
