@import '../../mixins';
@import '../../../pages/account/mixins';

.loyalty-experience__promo {
  color: var(--main-color);
  padding: 0;
  position: relative;
  margin-bottom: 4rem;

  @include media-breakpoint-up(lg) {
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    position: relative;
    margin-bottom: 0;
  }

  &__content-img {
    width: 100%;
    height: 0;
    padding: 0 0 100% 0;
    position: relative;

    @include media-breakpoint-up(lg) {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 50%;
      height: 100%;
      padding: 0;
    }

    img {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__main {
    padding: 4rem 2rem;

    @include media-breakpoint-up(lg) {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 50%;
      width: 50%;
      height: 100%;
      padding: 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    &--no-image {
      @include media-breakpoint-up(lg) {
        left: 0;
        width: 100%;
      }

      .loyalty-experience__promo__card {
        @include media-breakpoint-up(lg) {
          width: 62rem;
        }
      }
    }
  }

  &__card {
    @include media-breakpoint-up(lg) {
      width: 75%;
      margin: 0 auto;
      min-width: 47rem;
    }
  }

  &__badge {
    @include loyalty-badge;

    * {
      margin: 0;
    }
  }

  &__box {
    @include loyalty-box;

    * {
      margin: 0;
    }

    &__text {
      margin-bottom: 2.5rem;
    }

    &__qr {
      width: 25rem;
      height: 25rem;
      border: 1px solid var(--main-color);
      padding: 2.5rem;
      margin-bottom: 3rem;

      &__img {
        display: block;
        width: 20rem;
        height: 20rem;
      }
    }

    &__qr-buttons {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      padding-bottom: 0.7rem;

      .qr-button {
        margin-bottom: 2rem;
        text-decoration: none;

        @include media-breakpoint-up(lg) {
          margin-bottom: 0;
        }

        &:first-child {
          margin-right: 1.3rem;
        }

        &--wallet {
          margin-right: 1rem;
        }

        &--map {
          margin-bottom: 0;

          @include media-breakpoint-up(lg) {
            margin-left: auto;
            align-self: flex-end;
            margin-bottom: 0.4rem;
          }

          svg {
            width: 2rem;
            height: 2rem;
            vertical-align: middle;
          }

          &__text {
            padding-bottom: 0.2rem;
            border-bottom: 1px solid var(--main-color);
            margin-left: 0.4rem;
            vertical-align: middle;
          }
        }
      }

      img {
        display: block;
        width: 11rem;
        height: 3.4rem;
      }
    }

    &__simple-button {
      .simple-button {
        width: 100%;
        margin-top: 0.9rem;

        @include media-breakpoint-up(lg) {
          width: 18rem;
          padding-left: 1rem;
          padding-right: 1rem;
        }
      }
    }

    &__form-element {
      margin-bottom: 1.1rem;
    }

    &__note {
      margin-top: 2rem;
    }
  }

  &__list {
    &__item {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 1.6rem;

      &__content-img {
        width: 2.4rem;
        flex: 0 0 2.4rem;
      }

      &__img {
        display: block;
        width: 2.4rem;
        height: 2.4rem;
      }

      &__text {
        display: block;
        margin: 0;
        width: calc(100% - 2.4rem);
        flex: 0 0 calc(100% - 2.4rem);
        padding-left: 0.8rem;
      }
    }
  }
}
