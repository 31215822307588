.adobe-recommendations, .empty-cart__carousel, .product-carousel-container {
  .pd-c-container{
    //border-right: none; // DSL-2900 - OTB24-128: removed because regressions are visible on staging and not in production
    .pd-c-wrapper{
      box-sizing: border-box;
    }
    // .swiper-slide{ // DSL-2900 - OTB24-128: removed because regressions are visible on staging and not in production
    //   //border-right: none;
    // }
    .product-tile{
      // width: 99.5%; // DSL2900 - OTB24-128: removed because regressions are visible on staging and not in production
      // border-right: 1px solid var(--main-color);
      box-sizing: border-box;
      //padding-left: 0.3rem;
      //padding-right: 0.3rem;

      .product-tile-body {
        padding: 1rem 1.9rem 1rem 1.2rem;

        .container-badges {
          display: none;
        }

        .section-info-single-product-plp {
          width: 70%;

          @media (max-width: 991px) {
            width: 100%;
          }
        }

        .section-price-single-product-plp {
          margin-bottom: 0;
        }

        .product-tile--add-to-bag {
          .section-price-single-product-plp {
            margin-bottom: 2.4rem;
          }

          .open-attributes {
            bottom: 0;
            right: 1.9rem;
            top: 40%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }

  &.hidden {
    opacity: 0;
    visibility: hidden;
  }

  position: relative;
  width: 100%;
  overflow: hidden;
  .product-carousel-products {
    display: flex;
  }
  .pd-carousel__products-area {
    width: 100%;
  }
  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    top: 3rem;
    right: 0;
    cursor: pointer;
    svg {
      width: 2.4rem;
      height: 2.4rem;
      fill: var(--main-color);
    }
    &::after {
      display: none;
    }
  }
  .swiper-button-prev {
    right: 2.5rem;
    left: unset;
  }

  // .swiper-slide {
  //   max-width: 25%;
  // }

  // @media (max-width: 767px) { // DSL-2900 - OTB24-128: removed because regressions are visible on staging and not in production
  //   .pd-c-container{
  //     .product-tile{
  //       width: 99.2%;
  //     }
  //   }
  //   // .swiper-slide {
  //   //   max-width: 50%;
  //   // }
  //   .swiper-button-prev,
  //   .swiper-button-next {
  //     //top: 1.5rem;
  //   }
  // }
}
