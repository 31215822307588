@import '../utils/mixins';
.radio__container {
  display: inline-flex;
  align-items: center;
  .form-element__radio {
    width: 1.7rem;
    height: 1.7rem;
    margin: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid var(--main-color);
    cursor: pointer;
    flex-shrink: 0;
    border-radius: 100%;
    // margin-left: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    & + label {
      display: inline-block;
      padding-left: 0.8rem;
      cursor: pointer;
      position: relative;
      @include darkMode {
        color: black;
      }
    }
    &::after {
      position: relative;
      display: block;
      height: 0.9rem;
      width: 0.9rem;
      box-sizing: border-box;
      border-radius: 100%;
      background: var(--main-color);
    }
    &:checked::after {
      content: '';
    }
    /* &:nth-of-type(1) {
      margin-left: 0;
    } */
    &.gift {
      border: 1px solid var(--black);
      &::after {
        position: relative;
        display: block;
        height: 0.9rem;
        width: 0.9rem;
        box-sizing: border-box;
        border-radius: 100%;
        background: var(--black);
      }
    }
  }
  .form-element__label_radio {
    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
  .form-element__radio-item {
    display: inline-flex;
    margin-right: 2rem;
  }
}
