@import '../../pages/account/mixins';

.pd-editorial-header_bar {
  width: 100%;
  height: 4rem;
  position: relative;
  font-size: 1.2rem;
  z-index: 101;
  .button {
    font-size: 1.2rem;
    padding: 0 1rem;
    height: 2rem;
  }
  p {
    padding-right: 1rem;
  }
  a {
    text-decoration: none;
  }
  button, a {
    &.margin-right {
      margin-right: 1rem;
    }
  }
  .close-header_bar {
    background: unset;
    border: unset;
    padding: 0;
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    cursor: pointer;
    svg[class^='icon'] {
      width: 1.5rem;
      height: 1.5rem;
      fill: var(--black);
      opacity: 0.6;
    }
  }

  .editorial-bar-header__carousel {
    &.hidden {
      visibility: hidden;
    }
  }

  .editorial-bar-header_carousel {
    width: 100%;
    position: relative;
    height: 4rem;
    margin: 0 3rem 0 2rem;
  }
  .editorial-bar-header_item {
    overflow: hidden;
    &,
    .editorial-bar-header_item-content {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      &.swiper__single-slide {
        justify-content: center;
      }
      &.justify-center-mobile {
        &,
        .editorial-bar-header_item-content--long-content-double-cta > div,
        .editorial-bar-header_item-content--long-content-single-cta > div a {
          justify-content: center;
        }
      }
    }
    &.justify-start {
      justify-content: flex-start;
    }
    .editorial-bar-header_item-content {
      position: relative;
      white-space: nowrap;
      transition: all 1s ease;
      > div {
        display: flex;
        align-items: center;
        justify-content: center;
        a {
          display: flex;
        }

        &.content-bar {
          width: 100%;
          justify-content: space-between;
          box-sizing: border-box;

          &.flex-wrap {
            flex-wrap: wrap;
          }
        }
      }
      &--long-content {
        justify-content: flex-start !important;
        animation: marquee 15s infinite linear;
        > div {
          padding-left: 10px;
        }
        &-double-cta,
        &-single-cta {
          > div {
            justify-content: flex-start;
          }
          p {
            overflow: hidden;
            padding: 0;
            margin-right: 0.8rem;
            span {
              display: inline-block;
              animation: marquee 15s infinite linear;
            }            
          }

          p,
          .button {
            font-size: 1.2rem;
            flex: 1 1 0;
            text-align: center;
          }
        }
      }      
    }
  }
  .btn-swiper-editorial-bar {
    &__prev, &__next {
      display: none;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 2;
      padding: 0;
      background: unset;
      border: none;
      width: 1.5rem;
      height: 1.5rem;
      cursor: pointer;
      svg[class^='icon'] {
        fill: var(--black);
        opacity: 0.6;
        width: 1rem;
      height: 1rem;
      }
    }
    &__prev {
      left: 0;
    }
    &__next {
      right: 0;
    }
  }
  &.section-color {
    &_grey {
      background-color: #ebebeb;
      p {
        color: var(--black);
      }
    }
    &_white {
      background-color: var(--white);
      p {
        color: var(--black);
      }
    }
    &_black {
      background-color: var(--black);
      color: var(--white);
      svg[class^='icon'] {
        fill: var(--white);
        opacity: 1;
      }
      p {
        color: var(--white);
      }
    }
    &_red {
      background-color: var(--main-color);
      color: var(--white);
      svg[class^='icon'] {
        fill: var(--white);
        opacity: 1;
      }
      p {
        color: var(--white);
      }
      .cta {
        color: var(--white);
        &::after {
          background-image: linear-gradient(90deg, var(--white), var(--white));
        }
      }
    }
  }
  &.section-close {
    transform: translateY(-100%);
    transition: 0.3;
  }
  &.section-open {
    transform: translateY(0);
  }
  .container-klarna-message_promo-bar {
    *::part(osm-container) {
      background-color: unset;
      padding: 0;
    }
    *::part(osm-message),
    *::part(osm-cta) {
      font-size: 12px;
      color: var(--main-color);
    }
  }
}

.active-editorial-bar-header {
  position: relative;
  .header_navigation {
    position: absolute;
  }

  &.header-sticky {
    .header_navigation {
      position: fixed;
    }
  }
}
body:has(.active-editorial-bar-header) {
  .store-finder-panel {
    &.open {
      top: 3rem;
      @media (max-width: 991px) {
        .store-locator-panel {
          padding-top: 13rem;
        }
      }
    }
  }
}

.countdown-component {
  font-size: 0.8rem;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  padding: 0 1.9rem;
  margin-left: -0.25rem;
  margin-right: -0.25rem;

  span {
    padding: 0 0.25rem;

    &.time {
      display: flex;
      flex-flow: wrap;
      justify-content: center;
      align-items: center;
      padding: 0.2rem 0.4rem;
      width: 3rem;
    }
  }
}

@keyframes marquee {
  0% {
    transform: translate3d(20%, 0, 0);
  } 
  100% {
    transform: translate3d(-100%, 0, 0);
  }
}