.experience-assets-modulePromotionPanel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .experience-ancorAndPromotionBox {
    padding: 4rem 2rem;

    .experience-assets-modulePromotionBox {
      &:not(:first-child) {
        margin-top: 0.8rem;
      }
    }

    .anchor-text {
      display: flex;
      position: relative;
      @include debug;
      max-width: 40rem;
      z-index: 5;
      background-color: var(--alternative-color);
      border: 1px solid var(--main-color);
      height: 4rem;
      font-size: 1.4rem;
      width: 100%;

      &:not(:first-child) {
        margin-top: 1.6rem;
      }

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        transition: transform var(--transition);
        transform-origin: left top;
        background-color: var(--main-color);
        transform: scaleX(0);
        z-index: -1;
      }

      &:hover,
      &:focus,
      &:active {
        color: white;

        &::before {
          transform: scaleX(1);
        }
      }

      .anchor-text__content {
        flex: 1 0 auto;
        height: 100%;
        display: flex;
        align-items: center;
        padding-left: 1.3rem;

      }

      .anchor-text__icon {
        width: 4rem;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--main-color);

        svg {
          fill: var(--alternative-color);
        }
      }
    }
  }
}

.red {
  .anchor-text__icon {
    border: 1px solid var(--white);
  }
}