.module-static-plp--12 {
  position: relative;

  .content-heading {
    display: block;
    padding: 0;
    font-size: 0;
    text-align: left;

    .heading-link {
      display: inline-block;
      padding: 0 0.8rem;
      border: 1px solid var(--border-color);
      border-bottom: none;
      position: relative;
      max-width: 100%;
    }

    .element-heading {
      display: inline-block;
      vertical-align: middle;
      font-size: 3rem;
      margin: 0;
      max-width: 100%;

      span {
        display: inline-block;
        vertical-align: middle;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding-right: 0;
        font-size: 3rem;
        max-width: 100%;
      }

      &.jp-element-heading{
        span {
          font-size: 2.4rem;
        }
      }
    }

    .icon-arrow-fill-down {
      display: none;
      width: 2.4rem;
      height: 2.4rem;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0.8rem;
      z-index: 1;
    }
  }

  .content-element {
    display: block;
    border: 1px solid var(--border-color);

    .content-image {
      img {
        display: block;
        width: 100%;
        margin: 0;
      }
    }

    .content-text {
      .content-button-close {
        display: flex;
        flex-direction: row;

        .button-close {
          display: block;
          width: 5rem;
          height: 4rem;
          padding: 0.8rem;
          cursor: pointer;
          background: var(--white);
          border: 1px solid var(--border-color);
          margin-left: auto;
          margin-bottom: -1px;

          .icon-close {
            display: block;
            margin: 0 auto;
            width: 2.4rem;
            height: 2.4rem;
          }
        }
      }

      .content-info {
        background: var(--white);
        padding: 1.2rem;

        p {
          margin: 0;
          color: var(--main-color);
        }
      }

      &.close {
        .button-close {
          .icon-close {
            transform: rotate(45deg);
          }
        }
        .content-info {
          visibility: hidden;
          opacity: 0;
        }
      }
    }
  }

  a {
    text-decoration: none;
  }

  &.with-accordion {
    .content-heading {
      .heading-link {
        border-bottom: none;
        cursor: pointer;

        .element-heading {
          span {
            padding-right: 3rem;
          }

          .icon-arrow-fill-down {
            display: block;
            position: absolute;
            top: 50%;
            right: 5px;
            transition: 0.5s;
          }
        }

        &.active {
          border-bottom: 1px solid var(--border-color);

          .icon-arrow-fill-down {
            transform: translate(0, -50%) rotate(180deg);
          }
        }
      }
    }

    .content-text {
      border: 1px solid var(--main-color);
      border-bottom: none;
      margin-top: -1px;
      padding: 1rem;

      &.collapsed {
        display: none;
      }
    }
  }
}
