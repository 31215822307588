html.virtual-try-on-modal-open {
  overflow: hidden;
}

.side-panel-virtual_try_on {
  .side-panel__inner {
    border: 1px solid var(--main-color);

    .action-bar {
      justify-content: flex-end;
      border: 0;
      position: absolute;
      display: flex;

      .action-bar__button {
        border: 0;
        border-left: 1px solid var(--main-color);
        border-bottom: 1px solid var(--main-color);
      }
    }
  }

  .title {
    display: flex;
    flex-direction: row;
    align-items: center;

    svg {
      width: 3rem;
      height: 2.5rem;
      margin-right: 5px;
    }

    h1 {
      font-family: BananaGrotesk, sans-serif;
      font-size: 18px;
    }
  }

  p {
    font-size: 14px;
    color: var(--black);
  }

  .qrCodeCanvas {
    width: 10rem !important;
    height: 10rem !important;
  }
}

.show_3d_bags,
.virtual_try_on-try-it-on-ar {
  background-color: var(--white);
  cursor: pointer;
  color: #666;
  border: 1px solid #666;
  z-index: 1;
  padding: 0 1rem;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  right: 2.3rem;
  bottom: 7rem;

  &.red {
    color: var(--main-color);
    border: 1px solid var(--main-color);
  }

  svg {
    width: 3rem;
    height: 2rem;
  }
}

.virtual_try_on-try-it-on-ar {

  padding: 0.7rem 0.8rem;

  svg {
    width: 2rem;
    height: 1.7rem;
  }

  span {
    margin-left: 0.7rem;
    text-transform: none;
  }
}

.show_3d_bags {
  color: var(--main-color);
  border-color: var(--main-color);
  padding: 0.7rem 0.8rem;

  svg {
    width: 3rem;
    height: 3rem;
  }

  span {
    margin-right: 0.7rem;
    text-transform: none;
  }
}

.virtual_try_on-try-it-on-ar-plp-badge,
.view-3d-bags-plp-badge,
.ar-try-it-on-ar-plp-badge {
  color: var(--black);
  cursor: pointer;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  font-size: 1rem;
  align-items: center;
  z-index: 2;

  span {
    margin-right: 0.5rem;

    @media (max-width: 768px) {
      display: none;
    }
  }

  svg {
    width: 2rem;
    height: 2rem;
    fill: var(--black);
  }
}

.view-3d-bags-plp-badge {
  span {
    @media (max-width: 768px) {
      display: block;
    }
  }
}

video.virtualTryOn360Video {
  width: 100%;
}

.virtualTryOn {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 101;
  top: 0;
  right: 0;
  background: var(--white);
  border: 1px solid var(--main-color);
  transition: transform 700ms ease-in;
  transform: translateX(100%);
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);

  .back-btn {
    background-color: var(--white);
    color: var(--main-color);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 4rem;
    height: 4rem;
    border: 1px solid var(--main-color);
    border-top: 0;
    border-right: 0;
    padding: 0;
    position: relative;
    z-index: 1;

    svg {
      width: 2rem;
      height: 2rem;
    }

    span{
      display: none;
    }
  }

  h1.product-title {
    margin-bottom: 0;
  }

  p.subtitle {
    margin-top: 0;
  }

  .product-tile-body__price {
    margin: 1.3em;
  }

  #virtual-try-on-root {
    height: 100%;
    width: 100%;
    position: relative;
  }

  .modal-header {
    position: absolute;
    right: 0;
  }

  .modal-dialog,
  .modal-content {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .modal-body {
    flex: 1 0;
    border-bottom: 1px solid var(--main-color);
  }

  .modal-footer {
    padding-bottom: 20px;

    .product-cta {
      a {
        flex: 1 0;
      }

      .add-to-wish-list {
        background: transparent;
        border: 1px solid var(--main-color);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;

        svg {
          height: 3rem;
          width: 3rem;
        }
      }
    }
  }

  #loader {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
    color: var(--main-color);

    #progress {
      border: 1px solid var(--main-color);
      background: var(--white);
      color: var(--main-color);
      height: 1rem;
      width: 100%;
      margin: 1rem 0;
    }

    #progress::-moz-progress-bar {
      background: var(--white);
    }

    #progress::-webkit-progress-value {
      background: var(--main-color);
    }

    #progress::-webkit-progress-bar {
      background: var(--white);
    }

    .icon-diesel-logo {
      width: 12rem;
      height: 10rem;
    }
  }

  #suggestion {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: 30%;
    width: 25rem;
    text-align: center;

    p {
      color: var(--white);
    }
  }

  #swiper-container,
  #virtual-tryon-carousel-container {
    background: var(--white);
    height: 100px;
    overflow: hidden;
    border-bottom: 1px solid var(--main-color);

    .virtual-tryon-carousel-wrapper {
      position: relative;
      width: 100%;
      height: 100%;
      z-index: 1;
      display: flex;
      transition-property: transform;
      box-sizing: content-box;
    }

    .virtual-tryon-carousel {
      overflow-x: auto;
      width: 100%;
    }

    .virtual-tryon-carousel-slide {
      width: 75px !important;
      flex-shrink: 0;
      width: 100%;
      height: 100%;
      position: relative;
      transition-property: transform;
    }

    .swiper-slide {
      width: 75px !important;
    }

    .selected-product span {
      width: 100%;
      height: 100%;
      position: absolute;
      border: 1px solid var(--main-color);
      margin: -1px;
    }
  }
}

.open-modal-animation{
  transition-delay: 100ms;
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
}
