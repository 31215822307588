.new-password-page {
  height: 70vh;
  .card-reset-password {
    width: 48.4rem; //da cambiare desktop
    .card-reset-password_content {
      .change-password-form {
        .form-newpassword-confirmed {
          .form-newpassword-button {
            .btn-newpassword-feedback {
              margin: 0;
              margin-right: 1.2rem;
              width: 100%;
              &:last-child {
                margin: 0;
              }
            }
          }

          .cta-home {
            margin-top: 2.4rem;
          }
        }
      }
    }
  }
}
