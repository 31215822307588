.pd-topic-boxes-container {
  .title {
    margin-top: 0;
    margin-bottom: 2.4rem;
  }

  .row {
    margin: 0;
    border-top: 0.1rem solid var(--light-grey);
    border-left: 0.1rem solid var(--light-grey);
  }
}