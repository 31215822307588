.module-static-plp--11 {
  .content-heading {
    .element-heading {
      font-size: 3.6rem;
      .heading-link {
        border-bottom: none;
      }
      span {
        font-size: 3.6rem;
      }
    }
  }

  .content-element {
    display: block;
    p {
      display: block;
      margin: 0;
    }

    .content-buttons {
      display: block;
      font-size: 0;
      text-align: left;
      white-space: unset;
      overflow-x: auto;
      padding-top: 1.7rem;
      .button {
        padding: 1.6rem;
        margin-right: 0.8rem;
        margin-bottom: 0.8rem;
      }
    }
  }
}
