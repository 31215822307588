.experience-layout-module-accordionsContainer {
  &.red {
    background-color: var(--main-color);
  }
  &.white{
    background-color: var(--white);
  }
  &.off-white{
    background-color: var(--lighting-grey);
  }
}

.pd-module-accordions-container {
  padding: 3.2rem 2rem;
    .accordionsContainerInnerContent{
      padding: 1rem;
      &.red {
        background-color: var(--white);
        padding: 2rem;
      }
      .ac-trigger{
        -webkit-tap-highlight-color: transparent;
      }
    }
  .title {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    padding: 1rem;
    &.red{
      background-color: var(--white);
      border-bottom: 1px solid var(--main-color);
    }
    &.red-title{
      color: var(--main-color);;
      line-height: 2.9rem;
    }
  }
  .row {
    margin: 0;
  }
  .experience-assets-accordion {
    &:not(:last-child):not(:only-child)::after {
      content: '';
      display: block;
      margin: 0.8rem 0;
      width: 100%;
      border-top: 1px solid var(--light-grey);
    }
  }
}
