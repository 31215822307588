.module-8 {

  &.negative {
    background-color: var(--main-color);
  }

  .module-8-swiper {

    //padding: 10rem 2rem 0;
    //margin-bottom: auto;
    overflow: visible !important;
    //position: relative;

    .module-8__item {
      position: relative;

      .module-8__title {
        visibility: hidden;
        border: 1px solid var(--main-color);
        background-color: var(--alternative-color);
        padding: 0 1rem;
        height: 4rem;
        width: auto;
        display: inline-flex;
        flex-direction: row;
        align-items: center;

        &:empty {
          display: none;
        }

        &-empty {
          min-height: 46px;
        }

        .pd-heading {
          font-size: 2.4rem;
          line-height: 2.9rem;
          @include secondaryFont();
          margin: 0;
        }

        .pd-icon {
          text-align: right;
          margin-left: 0.8rem;

          svg[class^='icon'] {
            width: 2.4rem;
            height: 2.4rem;
          }
        }
      }

      .module-8__link {
        display: block;

        .module-8__title {

          &:hover {
            border: 1px solid var(--white);

            .pd-heading {
              color: var(--white);
            }
          }
        }
      }

      .module-8__container-img {
        width: 100%;

        .module-8__img {
          border: 1px solid var(--main-color);
          box-sizing: border-box;
          height: 350px;
          width: 620px;
          object-fit: cover;
          // max-width: 620px;
        }
      }

      .module-8__container-text {
        visibility: hidden;
        padding: 1.2rem;
        border: 1px solid var(--main-color);
        background-color: var(--alternative-color);
        cursor: pointer;
        right: 2.5px;
        left: 5px;
        top: -20px;
        position: absolute;

        .module-8__text {
          &:empty {
            display: none;
          }

          .pd-text {
            color: var(--main-color);

            p {
              margin: 0;
              font-size: 1.4rem;
              line-height: 2rem;
              letter-spacing: .2px;
              color: inherit;

              strong {
                em {
                  u {
                    font-weight: 500;
                    // background-color: var(--main-color);
                    // color: var(--alternative-color);
                    font-style: normal;
                    text-decoration: none;
                  }
                }

                background: var(--main-color);
                color: var(--alternative-color);
              }

              a {
                color: var(--main-color);
                text-decoration: underline;
              }
            }
          }
        }
      }

      .draggable {
        position: relative;
      }
    }

    .swiper-slide {
      background-color: transparent;
      display: flex;
      align-items: center;
      width: auto;

      &.center {
        justify-content: center;
      }

      &.left {
        justify-content: flex-start;
      }

      &.right {
        justify-content: flex-end;
      }

      &-active {
        .module-8__title,
        .module-8__container-text {
          visibility: visible;
        }
      }
    }

    .module-8-swiper-button-prev,
    .module-8-swiper-button-next {
      display: block;
      cursor: pointer;
      position: absolute;
      z-index: 10;

      svg {
        width: 2.4rem;
        height: 2.4rem;
        fill: var(--main-color);
      }
    }

    .module-8-swiper-button-next {
      right: 15px;
    }

    .module-8-swiper-button-prev {
      right: 60px;
    }

    &.negative {
      background-color: var(--main-color);

      .module-8-swiper-button-prev,
      .module-8-swiper-button-next {
        svg {
          fill: var(--alternative-color);
        }
      }
    }
  }

  .module-8-header {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-top: 6.5rem;
    padding-bottom: 0;

    &.negative {
      background-color: var(--main-color);

      .module-8-maintitle {
        color: var(--white);
        margin-bottom: 4rem;
      }
    }

    .module-8-maintitle {
      display: block;
      @include secondaryFont();
      color: var(--main-color);
      line-height: 3rem;
      font-size: 3.2rem;
    }
  }
}
