.container-general_confirmation {
  padding: 10vw 0;
  .confirmation-wrapper {
    padding: 2rem;
    border: 1px solid #dfdfdf;
    .confirmation-section {
      .thank-you-title {
        font-size: 3rem;
        line-height: 3.4rem;
      }
      .thank-you-desc {
        color: var(--black);
        margin-bottom: 2rem;
      }
    }
  }
  .pdp_menu-details {
    margin-top: 4rem;
    border: 1px solid #dfdfdf;
    .confirmation-refinement__title {
      padding: 2rem;
      margin: 0;
      .order-details-title {
        font-size: 1.8rem;
        line-height: 1.4rem;
        color: var(--black);
        margin: 0;
        @include primaryFont($weight: 500);
      }
    }
    .confirmation-products-card {
      .confirmation-products-head {
        padding: 2rem;
        border-top: 1px solid #dfdfdf;
        border-bottom: 1px solid #dfdfdf;
        .icon-confirmation-products-head {
          margin-right: 1.2rem;
          svg[class^='icon'] {
            width: 2rem;
            height: 2rem;
          }
        }
        .confirmation-products-title {
          @include primaryFont($weight: 500);
          font-size: 1.8rem;
          line-height: 1.4rem;
          color: var(--black);
          margin: 0;
        }
        .confirmation-shipment-info-wrapper {
          text-align: right;
          .confirmation-shipment-title {
            margin-bottom: 1.8rem;
            font-size: 1.8rem;
            line-height: 1.4rem;
          }
        }
      }
      .confirmation-product-wrapper {
        padding: 2rem;
        border-bottom: 1px solid #dfdfdf;
        display: flex;
        .product-image-link {
          margin-right: 1.6rem;
          .confirmation-product-image {
            width: 10.2rem;
            height: 13.6rem;
            border: 1px solid #dfdfdf;
          }
        }
        .section-product-name {
          margin-bottom: 1.4rem;
          .product-name {
            text-decoration: none;
            font-size: 1.8rem;
            line-height: 1.4rem;
            color: var(--black);
          }
        }
        .product-attributes {
          line-height: 1.4;
        }
        .confirmation-product-details {
          margin-right: 1.6rem;
          .final-sale-message {
            background-color: var(--white);
            border: 1px solid var(--light-grey);
            color: var(--black);
            padding: 0.2rem 0.5rem;
            font-size: 1.2rem;
            margin-top: 10px;
          }
        }
        .confirmation-product-additional {
          text-align: right;
          display: flex;
          align-items: flex-end;
          flex-direction: column;
          flex: 1;
          .product-shipment-eta {
            margin-top: auto;
            &.has-final-sale {
              margin-top: 0;
            }
            .product-shipment-eta-title {
              background-color: var(--main-color);
              color: var(--white);
              padding: 0.2rem 0.5rem;
              font-size: 1.2rem;
            }
          }

          .final-sale-message {
            margin-top: auto;
            margin-bottom: 1rem;
            .final-sale-message__text {
              background-color: var(--main-color);
              color: var(--white);
              padding: 0.2rem 0.5rem;
              font-size: 1.2rem;
            }
          }
          .product-price {
            .container-price {
              text-transform: uppercase;
            }
          }
        }
      }
    }
  }
  .confirmation-section-wrapper {
    padding: 2rem;

    .confirmation-billing-address,
    .confirmation-payment {
      margin-bottom: 3.8rem;
    }

    .confirmation-billing-address-title,
    .confirmation-payment-title,
    .confirmation-summary-title {
      @include primaryFont($weight: 500);

      color: var(--black);
      font-size: 1.8rem;
      line-height: 1.4rem;
      margin-top: 0;
      margin-bottom: 1.8rem;
    }

    .confirmation-gift {
      margin-bottom: 3.8rem;

      .confirmation-gift-title {
        margin: 0 0 0.8rem;
      }

      .confirmation-gift-section-label {
        margin: 0
      }
    }

    .summary-data,
    .payment-details,
    .price-summary {
      @include primaryFont($weight: 500);

      color: var(--black);
      font-size: 1.4rem;
      line-height: 2.4rem;
      margin: 0;
    }

    .confirmation-summary {
      .order-total-summary-header {
        display: none;
      }
    }

    .price-summary,
    .price-summary-total {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      max-width: 45rem;
      margin: 0 0 0.4rem;

      dt {
        width: 50%;
        flex: 0 0 50%;
        margin: 0 0 0.4rem;
      }

      dd {
        width: 50%;
        flex: 0 0 50%;
        margin: 0;
        text-align: right;
      }
    }
  }
}
