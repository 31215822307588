.modulePLPBanner {

  .main-container {

    .box-container{
      width: 510px;

      &.style-secondary{
      }

      &.style-primary{

        .pd-text {

          p {

            &:first-child {
              font-size: 36px;
              line-height: 34px;
            }
          }
        }
      }

    }
  }
}