.pd-topic-box {
  // Override properties for .button .secondary classes
  height: unset;
  border-top: unset;
  border-left: unset;
  white-space: unset;
  flex-direction: unset;
  justify-content: unset;

  display: flex;
  align-items: center;
  border-right: 0.1rem solid var(--light-grey);
  border-bottom: 0.1rem solid var(--light-grey);
  padding: 2.4rem;
  color: var(--black);
  text-decoration: none;

  span {
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 1rem;
  }
}
