.panel-country-selector {
  @extend .panel-language-selector;


  &-globale {
    .header-panel {
      margin-right: .75rem;  
      &::-webkit-scrollbar {
        width: .2rem;
        border-radius: 1.4rem;
        font-weight: bold;
        &-thumb {
          background: var(--main-color);
          padding: 2rem;
        }
        &-track {
          background: white;
          margin-top: 6.2rem;
        }
      }
    }
  }
  
  .country-selector {
    padding: 0 2rem;

    .continents {
      color: var(--main-color);
      display: block;
      text-transform: uppercase;
      margin: 2rem 0;
    }

    .country-list {
      a {
        color: var(--main-color);
        display: block;
        text-decoration: none;
        margin: 2rem 0;     
      }
    }
    
    &-globale {
      padding: 0;
      
      .country-list {

        .country-container {
          &:hover {
            svg {
              fill: var(--main-color);
            }
          }

          a {
            &.isActive {
              color: var(--main-color);
              svg {
                fill: var(--main-color);
              }
            }
            
            padding-bottom: 0.1rem;
            background: linear-gradient(0deg,var(--main-color),var(--main-color)) no-repeat right bottom/0 .1rem;
            transition: background-size var(--transition);

            &:hover {
              background-size: 100% .1rem;
              background-position-x: left;
              color: var(--main-color);

              svg {
                fill: var(--main-color);      
              }
            }
          }
        }

        svg {
          fill: var(--black);
          width: 1.6rem;
          height: 1.6rem;
          cursor: pointer;
          margin-left: 1rem;
        }

        a {
          color: var(--black);
          text-decoration: none;
          display: inline;
          margin: 0;
        }

        div.country-container {
          display: flex;
          align-items: center;
          margin-bottom: 1.6rem;

          span {
            display: flex;
            align-items: center;
          }

          &:first-child {
            margin-top: 1rem;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .continents {
        color: var(--black);
        display: flex;
        align-items: center;
        text-transform: uppercase;
      }



      .ac-header {
        color: var(--black);
        margin: 0;
  
        svg {
          fill: var(--black);
          width: 2.4rem;
          height: 2.4rem;
        }
      }
  
  
      

      .continent-accordion[data-component="AccordionNew"] {
        border-bottom: 1px solid var(--light-grey);
        padding: 1.8rem 0rem;
        margin-left: 2rem;
        margin-right: 1rem;
        &:last-child {
          border: none;
        }
      }

      .country-selector-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 2rem;
        margin-bottom: 2.4rem;
  
        .action-bar__button {
          svg {
            fill: var(--main-color);
            width: 2.4rem;
            height: 2.4rem; 
            margin-left: 0;
          }
          background-color: var(--white);
          position: absolute;
          right: 0;
          width: 5rem;
          height: 4rem;
        }
      }

    }
  }
}