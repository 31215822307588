.experience-layout-module19 {

  .module-19 {
    padding: 80px 0 80px 90px;

    .module-19-head {
      margin-right: 90px;
      margin-bottom: 30px;

      .title {
        font-size: 36px;
        line-height: 34px;
      }

      .slider-controls {
        position: relative;

        .module-19-swiper-button-prev,
        .module-19-swiper-button-next {
          display: block;
          cursor: pointer;
          position: absolute;
          z-index: 10;

          svg {
            width: 2.4rem;
            height: 2.4rem;
          }
        }

        .module-19-swiper-button-next {
          right: 0px;
        }

        .module-19-swiper-button-prev {
          right: 40px;
        }
      }
    }

    .module-19-swiper {

      .module-19-slide {

        .module-19-container-img {
          height: 580px;
          width: 400px;

          .module-19-img {
            height: 580px;
            width: 400px;
          }
        }

        .module-19-container-box {
          min-height: 130px;
          width: 400px;

          .module-19-box {

            .pd-text {
              height: 70px;

              p {
                &:not(:first-of-type){
                  -webkit-line-clamp: 2;
                }
              }
            }
          }
        }
      }
    }
  }
}
