.module-static-plp--03 {
  position: relative;

  .element-wrapper {
    flex-direction: row;
    max-width: 90%;
    border: none;
    justify-content: center;

    .single-item {
      width: 16.6666666%;
      flex: 0 0 16.6666666%;
      flex-direction: column;
      border: none;
      margin-left: -1px;

      &:last-child {
        border-bottom: none;
      }
    }
  }

  .content-heading {
    display: block;
    width: 100%;
    flex: 0;
    padding: 0;
    order: 1;
    font-size: 0;

    .heading-link {
      display: inline-block;
      padding: 0 0.8rem;
      border: 1px solid var(--border-color);
      border-bottom: none;
      max-width: 100%;
    }

    .element-heading {
      display: inline-block;
      vertical-align: middle;
      max-width: 100%;
      font-size: 0;

      span {
        display: inline-block;
        vertical-align: middle;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
        padding-right: 3rem;
      }
    }

    .icon-arrow-cta {
      right: 0.8rem;
    }
  }

  .content-img {
    width: 100%;
    flex: 0;
    border: 1px solid var(--border-color);
  }
}
