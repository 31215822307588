.navigation-categories {
  .navigation-categories__mobile-search {
    &.has-results {
      flex: 1 1 0;
    }
  }

  .search-editorial__modules {
    flex: 1 1 0;
    overflow: auto;
  }
}
