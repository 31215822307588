.confirmation-registration {
  width: 100%;
  padding: 2rem;
  height: fit-content;
  border: 1px solid var(--main-color);

  .loyalty-register-page__benefits {
    background: var(--white);
    border: none;
    margin-bottom: 0;
    padding-bottom: 0;
    position: initial;
    transform: none;
    width: 100%;

    .title-form-registration {
      font-size: 2rem;
    }
  }

  .title-box-order-conf-registration {
    font-size: 1.8rem;
    line-height: 1.8rem;
    color: var(--black);
    margin-top: 0;
    margin-bottom: 2rem;
    @include primaryFont($weight: 500);
  }

  .description-box-order-conf-registration {
    color: var(--black);
    margin-top: 0;
    margin-bottom: 2rem;
  }

  .registration-form {
    background-color: var(--white);

    .form-element__input {
      border-bottom: 1px solid var(--black);
      color: var(--black);
    }

    .form-element__label, .form-element__label_radio, .form-group__description {
      color: var(--black);
    }

    .custom-control-input.form-element__checkbox:checked {
      background-color: var(--black);
    }

    .form-group__description {
      a {
        color: var(--black);
      }
    }

    .radio__container .form-element__radio, .checkbox__container .form-element__checkbox {
      border-color: var(--black);
    }

    .show-password-button{
      svg {
        fill: var(--black);
      }
    }

    .form-group__privacy-container {
      margin-bottom: 2rem;

      .privacy-newsletter, .privacy-addtoemaillist {
        display: flex;
        align-items: flex-start;
        .checkbox__container {
          margin-top: 0.4rem;
        }
        .form-group__description {
          margin-top: 0;
        }
      }

      .privacy-newsletter {
        margin-bottom: 1rem;

        .form-group__description {
          margin-bottom: 2rem;
        }
      }
    }

  }

  .cta-sho-more {
    margin: 2.4rem 0 2rem;
  }
}
.order-confirm-text-wrapper {
  padding: 2rem;
  background-color: var(--white);
  .section-title {
    color: var(--black);
    margin-top: 0;
  }
  .list-no-style {
    margin: 2rem 0 0 0;
    padding: 0;
    list-style: none;
    li {
      color: var(--black);
    }
  }
}
.confirmation-page {
  .privacy-popup {
    h2,
    p {
      color: var(--black);
    }
  }
}
