@mixin debug($color: red) {
  border: 1px dashed $color;
}
.pd-debug__preview {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999;
}
.pd-debug__link {
  @include debug(black);
  min-width: 60rem;
}
/* .pd-debug__link {
  display: inline-block;
  width: auto !important;
  height: auto !important;
  min-width: 500px;
} */
