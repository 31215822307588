@mixin magazine-badge {
  display: inline-block;
  border: 1px solid var(--main-color);
  font-size: 3rem;
  padding: 0.6rem 2rem 0.6rem 0.8rem;
  max-width: 100%;

  @include media-breakpoint-up(lg) {
    font-size: 3.4rem;
    padding: 0.4rem 1rem 0.4rem 0.8rem;
  }

  h3 {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 3rem;

    @include media-breakpoint-up(lg) {
      font-size: 3.4rem;
    }
  }
}
