.noresult-page-wrapper {
  height: 80vh;
}
.noresults-wrapper {
  margin: 0 auto;
  .label-box {
    .noresults-header {
      font-size: 3.6rem;
    }
  }
}
