.side-panel__shop-the-look {
  .side-panel__inner {
    max-width: 70rem !important;
  }
  .container-shop-the-look-modal__product-big {
    display: flex;
  }
  .shop-the-look-modal__product {
    .product-tile {
      .product-tile__presentation {
        .product-tile-body {
          width: 65%;
        }
      }
    }
  }
}