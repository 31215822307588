.experience-layout-module13B {
  .pd-contact-us-container{
    width: 96rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;
    padding-right: 0;


    .pd-conctact-us-box-wrapper {

      &:last-child {
        padding-bottom: 0;
      }

      .region {
        border: 0.1rem solid var(--light-grey);
        padding: 1.6rem;
        height: 100%;
      }
    }
  }
  .pd-vertical-container {
    padding-top: 8rem;
    padding-bottom: 8rem;
    width: 62rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .pd-vertical-boxes {
    flex-direction: column;
  }
}
