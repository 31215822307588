.cart__product {

  .cart-product-content {
    align-self: center;
  }

  .quantity-form {
    bottom: -3.5rem;
    position: absolute;
    right: 1.6rem;
    z-index: 2;
  }

  .cart-controls-wrapper {
    order: 3;
  }

  .pli-attributes-mobile {
    order: 4;
  }

  .cart-actions-wrapper.product-move {
    order: 2;
    position: absolute;
    right: 1.6rem;
  }

  .preorder-to-cart-section {
    order: 5;
  }

  .cart-content-wrapper-mobile {
    padding: 1.6rem;

    .cart-msg-edit-wishlist-wrapper-mobile {
      .container-msg {
        bottom: 3.2rem;
        margin-bottom: 1.6rem;
        right: 1.6rem;
      }

      .cta-icon {
        margin-right: 1.6rem;
      }

      .quantity-form {
        align-self: flex-end;
      }
    }

    .error-message-cart-item {
      margin: 0 1.6rem 1.6rem;
    }

  }
}

.cart-products {
  .cart__product {
    .container-other-options{
      padding: 0 2rem 2rem;
      display: block;
      .remove-button{
        width: 100%;
        background-color: white;
        color: var(--main-color);
        border: 1px solid var(--light-grey);
        display: flex;
        flex-direction: row;
        column-gap: 0.5rem;
        svg{
          height: 1.6rem;
          width: 1.6rem;
        }
      }
      .btn{
        border: 1px solid var(--light-grey);
        background-color: white;
        padding: 1rem;
        color: var(--main-color);
        width: 100%;
      }
    }
    .bopis-actions-wrapper {
      flex-direction: column;
      align-items: flex-start;
      row-gap: 0.8rem;
      &.d-xs-none{
        display: none;
      }
      .sdd-available-cart {
        margin-top: 1.2rem;
        margin-left: 0.8rem;
        width: 80%;
      }
    }
    .cart__shipment {
      width: 100%;
      right: 0;
    }
  }
}

.cart__totals-wrapper.cart__totals-wrapper--buttons {
  border: none;
}

.cart__totals-wrapper--buttons {
  bottom: 0;
  left: 0;
  margin: 0 -2rem;
  width: calc(100% + 4rem);
  z-index: 2;

  &.is-sticky {
    border-top: 1px solid var(--light-grey);
  }

  div {
    background-color: var(--lighting-grey);
  }
}

.cart__totals-dynamic {
  border-bottom: 1px solid var(--light-grey);
}

.container-other-option-modal {
  display: block;
  height: 100%;
  // height: calc(var(--vhcart, 1vh) * 100);
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 200;
  left: 0;
  background-color: var(--black-opacity);
  &.d-none{
    display: none;
  }
  .container-overlay{
    height: 100%;
    width: 100%;
  }
}

.other-option-modal {
  position: fixed;
  bottom: 0;
  z-index: 999;
  width: 100%;
  left: 0;
  .button-close{
    border: 1px solid var(--light-grey);
    border-bottom: none;
    position: absolute;
    right: 0;
    top: -4.6rem;
    width: fit-content;
    height: fit-content;
    background-color: white;
    span{
      margin: 1rem;
    }
  }
  .cart__shipment{
    background-color: white;
    border: 1px solid var(--light-grey);
    padding: 0.5rem 0 2rem;
  }
  .cart-edit-wrapper-modal{
    padding: 1.5rem 2rem 0;
    .cart__shipment{
      text-align: center;
      padding: 1rem 0;
      svg{
        height: 2rem;
        width: 2rem;
      }
    }
    .button{
      border: 1px solid var(--light-grey);
      background-color: white;
      padding: 1rem;
      color: var(--main-color);
      width: 100%;
      display: flex;
      flex-direction: row;
      column-gap: 0.5rem;
      &.edit-btn{
        svg{
          height: 2rem;
          width: 2rem;
        }
      }
      svg{
        height: 1.6rem;
        width: 1.6rem;
      }
    }
  }
}

/*.cart-products {
  .cart__product {

    &.has-sdd {
      padding-bottom: 8rem;
      .cart-content-wrapper {
        .cart-actions-wrapper {
          bottom: -13.6rem;
        }
        .cart-controls-wrapper {
          .container-msg {
            bottom: -6.7rem;
            left: 1rem;
          }
        }
      }
    }
    &.final-sale,
    &.has-sdd {
      .invalid-quantity {
        bottom: -90px;
      }
    }

    .final-sale-message {
      text-align: left;
      left: 0;
      width: fit-content;
      font-size: 1rem;
    }
    .invalid-quantity {
      position: relative;
    }
    .error-message-cart-item {
      position: initial;
      margin: 0;
      margin-right: 0;
      margin-top: 1rem;
      order: 5;

      p {
        width: 80%;
      }

      svg {
        width: 2rem;
        height: 2rem;
      }
    }
  }

  .cart-content-wrapper {
    position: relative;

    .cart-line-item-promo {
      position: absolute;
      left: 0;
      bottom: -40px;
    }

    .cart-total-price {
      display: flex;
      justify-content: end;
      flex-direction: column-reverse;
    }

    .cart-product-content {
      flex: 0 0 60%;
      min-width: 60%;
      max-width: 60%;

      .cart-item-name {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 100%;
        max-width: 150px;
      }
    }

    .pli-attributes {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 100%;
    }
    .cart-total-price {
      display: flex;
      justify-content: end;
      flex-direction: row;
      gap: 0;
    }

    .cart-product-info {
      display: flex;
      flex-wrap: wrap;

      .quantity-form {
        .name-quantity {
          display: none;
        }
        order: 3;
        width: auto;
        justify-content: flex-end;
        min-height: 5rem;
        input[name=quantity] {
          max-width: 3rem;
        }
      }
      .cart-actions-wrapper {
        order: 4;
        align-items: flex-end;
        .cart-edit-wrapper{
          .cta{
            font-size: 1.2rem;
          }
        }
      }
    }
  }
  .cart-edit-wrapper{
    display: none;
  }

  .cart-edit-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}*/

/*.price-container {
  .discount-item {
    display: none;
  }
}*/

/*.cart-sorted-products {
  .section-title {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    row-gap: 0.6rem;
    .container-informations {
      width: 100%;
      justify-content: space-between;
      &.open {
        .content {
          width: auto;
          z-index: 2;
          top: 2rem;
          left: 0;
        }
      }
    }
    .cart__modal-actions {
      svg {
        display: none;
      }
      .cta {
        &::after {
          left: 0;
          width: 100%;
        }
      }
    }
  }
}*/

/*.cart {
  margin-bottom: 0;
}
.cart__content {
  padding-bottom: 0;
}
.cart__totals-wrapper {
  padding: 1.3rem 2rem;
}*/


/*.cart__totals-dynamic {
  border: 1px solid var(--light-grey);
  padding: 1.3rem 2rem;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.14;
  letter-spacing: 0.2px;
  .cart__totals-wrapper {
    border: none;
    padding: 0;
    &.cart__total-wrapper {
      padding-top: 1.4rem;
    }
    .cart-sales-tax {
      border-bottom: 1px solid var(--light-grey);
    }
    .cart-total {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: 0.2px;
      &-globale {
        line-height: 2;
      }
    }
  }
}*/

/*.container-msg{
  .sdd-available-cart{
    display: none;
  }
  &.mobile{
    display: block;
    padding: 0 2rem;
    .final-sale-message,.sdd-available-cart{
      position: relative;
      padding: 0.3rem 0.7rem;
      color: var(--black);
      font-size: 1.2rem;
      width: fit-content;
      border: 1px solid var(--light-grey);
    }
  }
}*/

/*.cart-content-wrapper {
  .cart-controls-wrapper {
    max-width: none;
    align-items: flex-end;
    .container-msg {
      bottom: 3.5rem;
      right: 1rem;
      align-items: flex-start;

      .sdd-available-cart {
        text-align: left;
      }
    }
  }
}*/
