.action-bar {
  display: flex;
  align-items: center;
  height: 4rem;
  border-bottom: 1px solid var(--main-color);
  width: 100%;

  &.action-bar__filters {
    padding-bottom: 2rem;
    height: 6rem;
    border-right: 0;
    border-bottom: 0;
    align-items: flex-end; 
  
    .action-bar__button {
      &.action-bar__button__filters {
        border-right: 0;
        width: 4.9rem;
        height: 3.9rem;
      }
    }
  
    .title-container {
      display: flex; 
      flex-direction: row; 
      gap: 1rem; 
      align-items: flex-end; 
      padding-left: 2rem;
  
    }
    .title {
      font-size: 1.6rem;
      margin: 0;
    }
    .applied-filters-indicator {
      background-color: var(--main-color); 
      border-radius: 100%;
      padding: 0.1rem 0.5rem; 
      display: flex; 
      align-items: center; 
      justify-content: center; 
      color: var(--white);
    }
  }
}
.action-bar__text,
.action-bar__search {
  text-align: center;
  margin: 0;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  padding: 0 1px;
  &.d-none {
    display: none;
  }
}
.action-bar__text {
  padding: 1rem 0.1rem;
}
.action-bar__button {
  @include resetButton;
  width: 5rem;
  height: 100%;
  align-items: center;
  &:first-of-type {
    border-right: 0;
  }
  &:last-of-type {
    border-left: 1px solid var(--main-color);
    border-bottom: 1px solid var(--main-color);
  }
  &.hidden {
    visibility: hidden;
    pointer-events: none;
  }
  &.action-bar__button-back {
    margin-right: auto;
  }
}
