.anchor-text {
  --primary-color: var(--main-color);
  --secondary-color: var(--primary-color);
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  &:hover,
  &:focus,
  &:active {
    color: var(--primary-color);
    svg {
      fill: var(--primary-color);
    }
  }
  &.icon-align-left {
    .anchor-text__icon {
      order: 1;
      margin-right: 1.2rem;
    }
    .anchor-text__content {
      order: 2;
    }
  }
}

.uppercase {
  text-transform: uppercase;
}
