.experience-layout-moduleHero {
  padding: 10.4rem 0 4.2rem 0;

  &.with-background {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    padding: 0;

    .module-hero_content {
      position: absolute;
    }
  }

  .module-hero_background {
    display: flex;
    width: 100%;

    .experience-assets-imageplus{
      display:block;
      width: 100%;

      picture {
        display: flex;
      }
    }

    img {
      width: 100%;
    }
  }

  .module-hero_content {
    display: flex;
    justify-content: center;
  }
}
.module-hero {
  max-width: calc(100% - 4rem);
  --dark-color: var(--main-color);
  --light-color: var(--alternative-color);

  &.negative {
    --dark-color: var(--alternative-color);
    --light-color: var(--main-color);
    --border-color: var(--alternative-color);
    .module-hero__title {
      .pd-icon {
        svg[class^='icon'] {
          fill: var(--white);
        }
      }
      &:hover {
        border: 1px solid $white;
        border-bottom: none;
      }
    }
    .module-hero__multiple-title {
      .module-hero__title {
        &:hover {
          border: none;
        }
      }
    }
    .container-module-hero {
      padding: 1px 0;
    }
    .module-hero__text {
      .pd-text {
        h1, h2, h3, h4, h5, p, p a {
          color: var(--alternative-color);

          &:first-child {
            margin-top: 0;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .pd-countdown__container {
        color: var(--alternative-color);
      }
      p {
        color: inherit;
        strong {
          em {
            background: var(--main-color);
            color: var(--alternative-color);
          }
        }
        a {
          color: var(--alternative-color);
          position: relative;
          text-decoration: none;
          width: fit-content;

          &::after {
            content: ' ';
            position: absolute;
            left: 0;
            top: 100%;
            width: 100%;
            height: 1px;
            background-image: linear-gradient(90deg, var(--alternative-color), var(--alternative-color));
            background-size: 6px 1px;
            transition: 500ms;
          }

          &:hover,
          &:focus,
          &:active {
            &::after {
              background-image: linear-gradient(90deg, var(--alternative-color), var(--alternative-color) 75%, transparent 75%, transparent 100%);
              animation-duration: 2s;
              animation-name: animateBorder;
              animation-iteration-count: infinite;
              animation-timing-function: linear;
            }
          }

          u {
            text-decoration: none;
          }
        }
      }
    }
    .module-hero__container-img {
      border-color: var(--alternative-color);
    }
    .module-hero__link {
      &:hover .module-hero__title {
        border-color: var(--main-color);
        border-bottom: none;
      }
      .module-hero__title {
        &:hover {
          --secondary-color: var(--alternative-color);
          .pd-heading {
            color: var(--main-color);
          }
          .pd-icon {
            svg[class^='icon'] {
              fill: var(--main-color);
            }
          }
        }
      }
    }
  }

  .module-hero__title {
    border: 1px solid var(--dark-color);
    border-bottom: none;
    background-color: var(--light-color);
    color: var(--dark-color);
    padding: 0.8rem 1rem 0.2rem 0.8rem;
    width: fit-content;
    display: flex;
    align-items: center;
    .pd-heading {
      font-size: 2.4rem;
      line-height: 2.9rem;
      @include secondaryFont();
      margin: 0;
      color: var(--dark-color);
    }
    .pd-icon {
      margin-left: 1rem;
      svg[class^='icon'] {
        width: 2.4rem;
        height: 2.4rem;
      }
    }
    &:hover {
      border: 1px solid var(--main-color);
      border-bottom: none;
      .pd-icon {
        svg[class^='icon'] {
          fill: var(--main-color);
        }
      }
    }
  }
  .module-hero__link {
    text-decoration: none;
    width: fit-content;

    &:hover .module-hero__title {
      border-color: $white;
      border-bottom: none;
    }
    .module-hero__title {
      height: auto;
      flex-direction: unset;

      &:hover {
        .pd-icon {
          svg[class^='icon'] {
            fill: var(--white);
          }
        }
      }
    }
    .icon-button-section-module-description {
      margin-left: 0.8rem;
    }
  }
  .module-hero__container-img {
    width: 100%;
    border: 1px solid var(--main-color);
    margin-bottom: -1px;
    .module-hero__img {
      display: block;
      width: 100%;
    }
  }
  .module-hero__text {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    position: relative;
    height: 100%;

    .pd-text {
      margin: 1.6rem 0 0.8rem 0;
      strong em u {
        background-color: var(--main-color);
        color: var(--alternative-color);
        font-style: normal;
        font-weight: 500;
        text-decoration: none;
        padding: 0 0.2rem;
      }
    }
    border: 1px solid var(--dark-color);
    // color: var(--light-color);
    color: var(--dark-color);
    background-color: var(--light-color);
    p {
      margin: 0;
    }
    .button.tag {
      margin: 1.2rem;
      border: 1px solid var(--dark-color);
      color: var(--dark-color);
      margin: 0 1.2rem 1.2rem 0;
    }
    .anchor-text {
      margin-bottom: 1.2rem;
    }
    .button {
      margin-right: 1.6rem;
      margin-bottom: 1.6rem;
      padding: 0 1.6rem;
    }
    .pd-countdown__container {
      position: absolute;
      bottom: 1.2rem;
      color: var(--main-color);
      letter-spacing: 0.2px;
    }
  }

  .module-hero__multiple-title {
    .module-hero__link {
      .module-hero__title {
        &:hover .pd-heading__container .pd-heading {
          box-shadow: 0 0 0 1px var(--light-color);
          background-color: var(--dark-color);
          color: var(--light-color);
        }
      }
    }
    .module-hero__title {
      display: block;
      border: none;
      background: transparent;
      padding: 0;
      color: inherit;
      width: 100%;
      white-space: initial;

      &--button {
        .pd-heading__container {
          .pd-heading {
            &::before {
              background-color: var(--dark-color);
            }
          }
        }
        &:hover .pd-heading__container .pd-heading {
          color: var(--light-color);
          background-color: transparent;
          box-shadow: 0 0 0 1px var(--light-color);
        }
      }
    }

    .pd-heading__container {
      padding: 0;
      width: 100%;
      display: block;
      margin-bottom: -1px;
      padding-top: 1px;

      .pd-heading {
        line-height: initial;
        display: inline;
        box-shadow: 0 0 0 1px var(--dark-color);
        background-color: var(--light-color);
        margin: 0 1px;
        padding: 0 0.8rem;
        -webkit-box-decoration-break: clone;
        box-decoration-break: clone;
      }
    }
  }
  .container-module-hero {
    display: flex;
    flex-direction: column;
    height: 100%;

    &--ordered {
      .module-hero__container-img-link {
        order: 1;
      }
      .module-hero__container-img {
        margin-bottom: 0;
        margin-top: -1px;
        order: 1;
      }
    }

    &.module-hero-variant-2 {
      .module-hero__title, .module-hero__text {
        border: none;
      }
      .module-hero__title {
        width: 100%;
        justify-content: flex-start;
      }
      .module-hero__text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-size: 1.4rem;
        .pd-text {
          margin: 0 0 2rem 0;
        }
      }
    }
  }
}
