.navigation-dropdown--content {
  &.navigation-dropdown--content--lg {
    position: relative;
    height: auto;
    max-height: 85vh;
    padding: 0;
    scrollbar-width: auto;
    scrollbar-color: var(--dark-grey) var(--white);
    /* Chrome, Edge, and Safari */
    &::-webkit-scrollbar {
      width: 0.6rem;
    }
    &::-webkit-scrollbar-track {
        background: var(--white);
    }
    &::-webkit-scrollbar-thumb {
      background-color: var(--dark-grey);
      border-radius: 0.3rem;
      border: 0.6rem solid var(--dark-grey);
    }
  }
}

.navigation-categories--content {
  &.navigation-categories--content--lg {
    flex-flow: row wrap;
    white-space: nowrap;
    justify-content: center;

    .navigation-column {
      padding: 11.5rem 4rem 4rem;
      margin-left: 0;
      margin-right: 0;

      &.column-left {
        flex: 1;
        border-right: 1px solid var(--light-grey);
        max-width: 25.8rem;
      }

      &.highlighted {
        background-color: #fafafa;
        padding-left: 3.9rem;
        padding-right: 3.9rem;
        flex: none;
        width: clamp(21rem, 20%, 20.3rem);
        border-right: 1px solid var(--light-grey);

        .navigation-subcategories-grouped {
          flex-flow: column wrap;
        }
      }

      &.column-right {
        flex: 2;
        border-left: 1px solid var(--light-grey);
      }
    }
  }
}

.navigation-categories {
  // display: grid;
  // grid-template-columns: repeat(auto-fill, minmax(max-content, 19rem));
  display: flex;
  flex-flow: row wrap;
  margin-left: clamp(-1rem, -2.4%, -2.65rem);
  margin-right: clamp(-1rem, -2.4%, -2.65rem);
}

.navigation-category--column {
  flex: 0 0 clamp(17.8rem, 21%, 20.2rem);
  max-width: clamp(17.8rem, 21%, 20.2rem);
  padding-top: 0;
  padding-bottom: 0;
  padding-left: clamp(1rem, 2.4%, 2.65rem);
  padding-right: clamp(1rem, 2.4%, 2.65rem);
  margin-bottom: 2rem;
  border-top: 0;
  white-space: normal;

  &:not(.highlighted):last-child {
    border-bottom: 0;
  }

  &.has-group {
    &:not(.highlighted) {
      flex: 0 0 clamp(20rem, 50%, 40.9rem);
      max-width: clamp(20rem, 50%, 40.9rem);
    }

    &.one-group {
      flex: 0 0 auto;
    }
  }

  a {
    text-decoration: none;
  }
}

.navigation-category--title {
  font-family: franklin-gothic-compressed,sans-serif;
  font-size: 2rem;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
  padding: 0;
  display: inline-block;

  &.is-open {
    background-color: transparent;
  }

  .icon__arrow {
    display: none;
  }
}

.navigation-subcategories {
  margin-top: 0;
}

[data-whatintent="keyboard"] {
  .navigation-subcategories,
  .navigation-subcategories-grouped--list {
    li {
      .navigation-category--link {
        &:focus {
          outline: none;

          .hover-link {
            background-size: 100% 0.1rem;
            background-position-x: left;
          }
        }
      }
    }
  }
}

.navigation-subcategories,
.navigation-subcategories-grouped--list {
  li {
    .navigation-category--link {
      font-size: 1.2rem;
      padding-left: 0;

      .hover-link {
        pointer-events: none;
        background: linear-gradient(0deg, var(--main-color), var(--main-color)) no-repeat right bottom / 0 0.1rem;
        transition: background-size var(--transition);
        padding-bottom: 0.1rem;
        line-height: 1.2;
      }

      &:hover,
      &:focus,
      &:active,
      &.hover {
        color: var(--main-color) !important;

        .hover-link {
          background-size: 100% 0.1rem;
          background-position-x: left;
        }
      }
    }
  }
}

.navigation-subcategories-grouped {
  flex-flow: row wrap;
  margin-left: clamp(-1rem, -2.4%, -1.65rem);
  margin-right: clamp(-1rem, -2.4%, -1.65rem);
  margin-top: 0;

  span.navigation-subcategories-grouped--title {
    font-size: 1.2rem;
    margin-top: 0;
    margin-left: 0;
  }

  li.navigation-subcategories-grouped--column {
    flex: 0 0 clamp(14.5rem, 50%, 18.2rem);
    padding-left: clamp(1rem, 2.4%, 1.65rem);
    padding-right: clamp(1rem, 2.4%, 1.65rem);
    margin-bottom: 0;
  }
}

.navigation-subcategories--visual-filters {
  display: block;
}

.menu-tag {
  font-size: 1rem;
}