.new-password-page {
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  padding: 2rem;
  .card-reset-password {
    width: 100%;
    text-align: left;
    .card-header {
      width: fit-content;
      font-size: 3.4rem;
      line-height: 3.2rem;
      border: 1px solid var(--main-color);
      border-bottom: 0;
      padding: 1rem 0.8rem;
      margin: 0;
    }
    .card-reset-password_content {
      border: 1px solid var(--main-color);
      padding: 2.4rem 2rem;
      .change-password-form {
        .save-new-pass {
          border-radius: 0;
          margin-top: 2rem;
          width: 100%;
        }
        input {
          width: 100%;
          border: 0;
          border-bottom: 1px solid var(--main-color);
        }
        .invalid-feedback,
        .password-hint-label {
          color: var(--main-color);
        }
        .password-hint-label {
          font-size: 1rem;
        }
        .checkbox__container {
          margin: 1rem 0;
          .form-element__checkbox {
            width: 1.8rem;
            border: 1px solid var(--main-color);
          }
        }
        .form-group {
          margin-bottom: 3rem;
          &.form-group-confirm {
            margin-bottom: 2rem;
          }
          &.form-group-first-input {
            .form-element {
              margin-bottom: 0;
            }
          }
        }
        .form-newpassword-confirmed {
          .form-newpassword-feedback {
            background-color: var(--green-validation);
            padding: 1.2rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 2rem;
            .form-newpassword-text {
              color: var(--white);
              margin: 0;
            }
            svg {
              width: 2rem;
              height: 2rem;
              fill: var(--white);
            }
          }
          .form-newpassword-button {
            .btn-newpassword-feedback {
              margin-bottom: 2rem;
              border-radius: 0;
              width: 100%;
              &:last-child {
                margin-bottom: 0;
              }
            }
          }

          .cta-home {
            display: flex;
            justify-content: center;
            margin-top: 2.1rem;
            text-decoration: none;

            span {
              border-bottom: 1px solid var(--main-color);
              padding-bottom: 0.2rem;
            }
          }
        }
      }
    }
  }
}
html:not([data-whatinput="keyboard"]), html:not([data-whatintent="keyboard"]){
  .new-password-page {
    .form-element__input {
      &:focus {
        outline: none;
      }
    }
  }
}