.pd-nav-menu-element{
  display: flex;
  width: 100%;
  padding: 1rem 1.6rem;
  color: var(--black);
  text-decoration: none;

  &.active{
    color: var(--white);
    background-color: var(--main-color);
    .icon-pd{

      svg{

        &>*{
          fill:var(--white);
        }
      }
    }
  }

  &:hover:not(.active){

    color: var(--main-color);
    .icon-pd{

      svg{

        &>*{
          fill:var(--main-color);
        }
      }
    }

  }

  .icon-pd{
    vertical-align: middle;

    svg{
      width: 1.6rem;
      height: 1.6rem;

      &>*{
        fill:var(--black);
      }
    }
  }

  .nav-title{
    font-size: 1.4rem;
    line-height: 2rem;
    letter-spacing: 0.02rem;
    margin-left: 0.8rem;
  }
}
