.experience-assets-topicBox {
  height: 100%;
}

.pd-topic-box {
  flex-direction: column;
  align-items: flex-start;
  height: 100%;

  span {
    margin-right: 0;
    margin-bottom: 1rem;
  }

  div {
    min-height: 3.4rem;
  }
}
