.module-3 {
  padding: 0 2rem;
  position: relative;
  overflow: hidden;
  .module-3__item {
    .module-3__title {
      border-bottom: none;
      padding: 0.8rem;
      width: fit-content;
      display: flex;
      align-items: center;
      &:empty {
        display: none;
      }
      .pd-heading {
        text-decoration: none;
        font-size: 3.2rem;
        @include secondaryFont();

        margin: 0;
      }
      .pd-icon {
        margin-left: 0.8rem;
        svg[class^='icon'] {
          width: 2.4rem;
          height: 2.4rem;
        }
      }
    }
    .module-3__link {
      text-decoration: none;
      width: fit-content;
      .module-3__title {
        height: auto;
        flex-direction: unset;
      }
    }
    a {
      text-decoration: none;
    }
    .module-3__container-img {
      width: 100%;
      border: 1px solid var(--main-color);
      .module-3__img {
        display: block;
        width: 100%;
      }
    }
    .draggable {
      position: unset;
      width: 100% !important;
    }
    .module-3__container-text {
      .module-3__text {
        &:empty {
          display: none;
        }
        p {
          @include secondaryFont;

          display: inline;
          box-shadow: 0 0 0 1px var(--main-color);
          background-color: var(--alternative-color);
          margin: 0 1px;
          font-size: 3rem;
          line-height: 1.2;
          padding: 0 0.5rem;
          -webkit-box-decoration-break: clone;
          box-decoration-break: clone;
          strong em u {
            background-color: var(--main-color);
            color: var(--alternative-color);
            font-style: normal;
            font-weight: 500;
            text-decoration: none;
            padding: 0 0.2rem;
          }
        }

        &.use-single-block {
          & > * {
            background-color: var(--alternative-color);
            box-shadow: 0 0 0 .1rem var(--main-color);
          }
          p {
            box-shadow: none;
            background: transparent;
          }
          .pd-text {
            padding: .5rem;
            & > * {
              margin: 0;
            }
          }
        }
      }
    }
    .module-3__container-description {
      border: 1px solid var(--main-color);
      background: var(--alternative-color);
      margin-top: -1px;

      .module-3__text {
        padding: 1.2rem;
        &:empty {
          display: none;
        }
        p {
          @include primaryFont;

          display: block;
          margin: 0;
          font-size: 1.4rem;
          line-height: 2rem;
          padding: 0;
          box-shadow: none;

          strong em u {
            background-color: var(--main-color);
            color: var(--alternative-color);
            font-style: normal;
            font-weight: 500;
            text-decoration: none;
            padding: 0 0.2rem;
          }
        }
      }
    }
  }

  &.positive {
    .module-3__item .module-3__title {
      &:not(.button) {
        border: 1px solid var(--main-color);
        border-bottom: none;
        color: var(--main-color);
      }
    }
  }

  &.negative {
    .module-3__item .module-3__title {
      &:not(.button) {
        border: 1px solid var(--white);
        border-bottom: none;
        color: var(--white);
        .pd-heading {
          color: var(--white);
        }
      }
    }
    .module-3__item .module-3__container-img {
      border-color: var(--white);
    }
    .module-3__item .module-3__container-description {
      border-color: var(--white);
    }
  }
}

.module-3 {
  &.negative {
    background-color: var(--main-color);
    .button.module-3__title:hover {
      border: 1px solid var(--white);
      border-bottom: none;
    }
  }
}
