@mixin loyalty-badge {
  display: inline-block;
  border: 1px solid var(--main-color);
  font-size: 2em;
  padding: 0.4rem;
  text-decoration: none;
}

@mixin loyalty-box {
  border: 1px solid var(--main-color);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  margin-top: -1px;
}

@mixin loyalty-skeleton {
  color: var(--main-color) !important;
  background-color: var(--main-color) !important;
  opacity: 0.1 !important;
}
