.static-plp-1-full-desktop {
  padding: 6.5rem 4rem;
}

.static-plp-1-title-video-desc-desktop {
  padding: 9rem 4rem;
}

.static-plp-1-video-desc-cta-desktop {
  padding: 8.5rem 4rem;
}

.static-plp-1-video-desc-desktop {
  padding: 11rem 4rem;
}

.static-plp-1-video-only-desktop {
  padding: 14.5rem 4rem;
}

.static-plp-1-video-only-9-16-desktop {
  padding: 8rem 4rem;
}

.module-plp-video.module-static-plp--01 {
  .container-video-description {
    .element-wrapper {
      &:hover {
        .controls {
          display: block;
        }
      }

      .controls {
        display: none;
      }
    }

  }
}

.product-grid {
  .content-asset-desktop {
    display: flex;
  }

  .content-asset-mobile {
    display: none;
  }
}