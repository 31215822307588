.module-15B_container {
  padding-top: 4rem;
  padding-bottom: 4rem;
  padding-left: 2rem;

  &.white {
    background-color: var(--white);
  }
  &.off-white {
    background-color: var(--lighting-grey);
  }
  &.red{
    background-color: var(--main-color);
  }

  .module15B{

    .module-15B-title_container {
      -webkit-tap-highlight-color: transparent;
      align-items: center;
      border: 1px solid var(--main-color);
      border-bottom: none;
      padding: 0.8rem 1rem 0.8rem 1rem;
      width: fit-content;
      font-size: 2.4rem;
      font-weight: 600;
      line-height: 2.9rem;

      svg[class^='icon'] {
        width: 2.4rem;
        height: 2.4rem;
        margin-left: 1rem;
      }

      a{
        display: inline-flex;
        align-items: center;
        text-decoration: none;
        flex-flow: row nowrap;
      }
    }

    .module-15B-title_container:hover, .module-15B-title_container:focus, .module-15B-title_container:active {
      h3{
        color: var(--white);
      }
    }

    .noTitleUrl{
      -webkit-appearance: none;
      cursor: pointer;
      text-decoration: none;
      margin: 0;
      font-family: inherit;
      white-space: nowrap;
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      height: 4rem;
      color: var(--main-color);
      background-color: var(--white);
      position: relative;
      overflow: hidden;
      z-index: 0;
    }

    .noTitleUrl:hover, .noTitleUrl:focus, .noTitleUrl:active {
      h3{
        color: var(--main-color);
      }
    }

    .module-15B-swiper{

      .module-15B-swiper-wrapper{
        border-left: 1px solid var(--main-color);
      }

      .swiper-scrollbar{
        background-color: var(--white);
        height: 0.2rem;
        margin-top: 2.2rem;
        .swiper-scrollbar-drag{
          background-color: var(--main-color);
          height: 0.2rem;
        }
        &.red{
          background-color: rgba(237, 237, 237, 0.50);
          .swiper-scrollbar-drag{
            background-color: var(--white);
          }
        }

      }

      .module-15B__item{
        height: fit-content;
        width: 55.1vw;

        .module15B-item-text{
          background-color: var(--white);
          border: 1px solid var(--main-color);
          border-left: none;
          border-top: none;
          display: flex;
          flex-direction: column;
          gap: 1rem;
          padding: 1.6rem 1rem;

          p{
            margin: 0;
          }

          .module-15B-item-title{
            p{
              font-size: 1.4rem;
              line-height: 2rem;
              font-weight: 500;
              letter-spacing: 0.02rem;
            }
          }
          .module-15B-item-paragraph{
            p{
              font-size: 1.2rem;
              font-style: normal;
              font-weight: 500;
              line-height: 1.8rem;
              letter-spacing: 0.02rem;
              color: var(--black);
            }
          }
        }
        .module-15B-container-img{
          overflow: hidden;
          width: 55.1vw;
          .module-15B-img{
            border: 1px solid var(--main-color);
            border-left: none;
            box-sizing: border-box;
            height: 100%;
            width: 100%;
            img{
              height: 100%;
              width: 100%;
            }
          }
        }
      }
    }
  }
}
