.module-9 {
  flex-direction: column-reverse;
}

.module9__items {
  width: 100%;
  margin: 0 auto;
  padding: 2rem;
  flex-wrap: unset;
  display: unset;
  .swiper-wrapper {
    flex-wrap: wrap;
  }
}
.wrapper-carousel-module9 {
  padding: 2rem;
}
.module9__item {
  margin-top: 0;
  //display: flex;
  width: calc(100% / 2);
  float: left;
  margin-bottom: 4rem;
  position: relative;
  &:nth-of-type(2n+1) {
    &::after {
      content: '';
      width: 1px;
      height: 100%;
      border-right: 1px solid var(--border-color);
      position: absolute;
      right: -1px;
      z-index: 1;
      top: 0;
    }
    .module9__name {
      border-right: 0;
    }
    .module9__picture {
      border-right: 0;
    }
  }
  &:last-of-type {
    border-bottom: 1px solid var(--border-color);
    box-sizing: border-box;
  }
}

.module9__link {
  flex: 1;
  order: 1;
}

.module9__name {
  display: flex;
  justify-content: space-between;
  flex: 1;
  //height: 100%;
  padding: 1rem 2.1rem;
}

.module9__visual {
  height: 24rem;
  width: auto;
  order: 0;
}

.module9__message {
  width: calc(100% - 40px);
  max-width: 61.8rem;
  margin: 2rem auto 4rem;

  .module9__name {
    padding: 1.2rem;
  }
  .module9__link {
    display: inline-flex;
  }
}

.module-9,
.module-carousel-container {
  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }
}
.module-carousel-container {
  display: block;
  .wrapper-carousel-module9 {
    padding: 0;
  }
  .module9__items {
    padding: 0;
    display: block;
    .swiper-wrapper {
      flex-direction: column;
    }
  }
  .module9__item {
    margin: 0;
    width: 100%;
    display: flex;
  }
  .module9__link {
    border: 1px solid var(--main-color);
    border-bottom: none;
  }
  .module9__name {
    border: none;
    height: 100%;
    padding: 0 2rem;
  }
  .module9__visual {
    height: auto;
  }
  .module9__picture {
    height: 100%;
    img {
      max-height: 136px;
      max-width: 92px;
      width: auto;
      height: auto;
    }
  }
}
