.add-to-wish-list {
  .icon-wishlist {
    vertical-align: middle;
  }
  
  &.is-full {
    .icon-wishlist {
      display: none;
    }
  }

  &:not(.is-full) {
    .icon-wishlist-filled {
      display: none;
    }

    .icon-remove-btn {
      display: none;
    }
  }

  &.is-full {
    .icon-wishlist {
      display: none;
    }
  }

  &.is-full:not(.is-wishlist-tile) {
    .icon-remove-btn {
      display: none;
    }
  }

  &.is-full.is-wishlist-tile {
    .icon-wishlist-filled {
      display: none;
    }

    .icon-remove-btn {
      display: block;
      width: 1.4rem;
      height: 1.5rem;
    }
  }

  &.is-full.is-tile {
    .icon-wishlist-filled {
      display: block;
    }

    .icon-remove-btn {
      display: none;
    }
  }
}
