.einstein-carousel {
  .product-carousel {
      .swiper-heading-wrapper {
          border: 1px solid var(--main-color);
          border-bottom: none;
          display: inline-block;
          padding: 0.3rem 0.5rem;

          h2 {
              margin: 0;
          }
      }

      .swiper-slides {
          border: 1px solid var(--main-color);

          .swiper-slide {
              border-right: 1px solid var(--main-color);
          }
      }
  }
}