.pd-image-editorial-container {
  .title {
    margin-top: 0;
    margin-bottom: 2.4rem;
  }

  img {
    width: 100%;
    margin-bottom: 2.4rem;
  }

  p {
    margin: 0;
  }
}