@mixin primaryFont($weight: 500) {
  font-family: BananaGrotesk, sans-serif;
  font-weight: $weight;
  font-style: normal;
}
.side-panel {
  &.minicart {
    .side-panel__inner {
      height: auto;
      max-width: 48.2rem;
    }
  }
}
.minicart-panel-wrapper {
  margin-top: 4rem;
  .container-price {
    display: flex;
    flex-direction: column;
    color: var(--black);

    .non-adjusted-price {
      color: var(--main-color);
      margin-bottom: 0.6rem;
    }
  }
  .discount-item {
    text-decoration: none;
    color: var(--white);
    background-color: var(--main-color);
  }
  .info-bag-menu {
    padding: 2rem;

  }
  .info-bag-menu-price {
    border-top: 1px solid var(--main-color);
  }
}
.section-navigation-header-red {
  border: 0;
  background-color: var(--main-color);
  .info-bag-menu {
    border-bottom: 1px solid var(--alternative-color);
    border-top: 1px solid var(--alternative-color);
  }
  .text-info-bag-menu {
    color: var(--alternative-color);
  }
}

.section-navigation-header {
  .info-bag-menu {
    padding: 2rem;
  }
  .button-ratio-menu {
    margin-bottom: 0.8rem;
  }
  .description-section-button-ratio-menu {
    margin-bottom: 2rem;
  }
  .info-bag-menu-price {
    border-top: 1px solid var(--main-color);
  }
}
.container-content-menu-bag {
  max-height: 35vh;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-flow: column;
  margin-right: 0.3rem;
  scrollbar-width: auto;
  scrollbar-color: var(--dark-grey) var(--white);

  /* Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    width: 0.6rem;
  }
  &::-webkit-scrollbar-track {
      background: var(--white);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--dark-grey);
    border-radius: 0.3rem;
    border: 0.6rem solid var(--dark-grey);
  }
  .section-container-general-product-bag {
    border-bottom: 1px solid var(--light-grey);
    padding: 2rem;
    .section-product-img-mini-bag {
      border: 1px solid var(--light-grey);
      height: 15.3rem;
      width: 10.2rem;
      .product-img-mini-bag {
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .section-product-info-mini-bag {
      padding-left: 1.2rem;
      width: calc(100% - 10.2rem);
      .container-info-product-mini-bag,
      .container-price-product-mini-bag {
        .name-pronduct-mini-bag {
          margin-top: 0;
          margin-bottom: 1rem;
          line-height: 1.4rem;
        }
        .info-pronduct-mini-bag {
          margin: 0;
          margin-bottom: 0.6rem;
          &:last-child {
            margin-bottom: 0;
          }
        }
        .container-content-info-product-mini-bag {
          height: calc(100% - 2rem);
          p {
            color: var(--black);
          }
        }
      }
      .container-price-product-mini-bag {
        min-width: fit-content;
        text-align: right;
        .cta {
          color: var(--black);
          --primary-color: var(--black);
          --secondary-color: var(--black);
          &:not(.highlight)::after {
            background-image: linear-gradient(90deg, var(--black), var(--black));
          }
          &:hover,
          &:focus,
          &:active {
            &:not(.highlight)::after {
              background-image: linear-gradient(90deg, var(--black), var(--black) 75%, transparent 75%, transparent 100%);
            }
          }
        }
      }
    }
    &:last-child {
      border-bottom: 0;
    }
  }
}
.section-button-mini-bag {
  padding: 2rem;
}

.notify-add-to-cart {
  display: none;
}

/*
Minicart restyling rules
*/
.minicart-container {

  .container-info-product-mini-bag {
    .quantity-form {
      .quantity-input {
        margin-left: 0rem;
        padding-left: 0rem;

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        input[type=number] {
          -moz-appearance: textfield;
        }

        .rounded-minicart-button {
          vertical-align: middle;
          padding: 0rem 0rem 0rem 0rem;
          width: 1.333rem;
          height: 1.333rem;
          background: none;
          text-align: center;
          text-decoration: none;
          display: inline-block;
          border: none;
          cursor: pointer;

          .icon-minus,
          .icon-plus,
          .icon-dropdown-up,
          .icon-dropdown-down {
            fill: #000000;
            width: 1.333rem;
            height: 1.333rem;
          }

          svg {
            .icon-minus,.icon-plus {
              fill: var(--black);
            }
          }
        }
      }
    }
  }

  .total-tax-recap {
    border-bottom: 0.1rem solid var(--light-grey);
    border-top: 0.1rem solid var(--light-grey);
    padding: 0;

    &.is-order-promo-active {
      .free-shipping-info {
        margin-top: -0.8rem;
      }
    }

    &:not(.is-order-promo-active) {
      .free-shipping-info {
        margin-top: 1.6rem;
      }
    }
    
    .minicart-sales-price-wrapper {
      flex-direction: column;
      align-items: flex-end;
      gap: 0.8rem;
    }

    p {
      &.subtotal-sales {
        color: var(--color-red);
      }

      &.subtotal-striked {
        color: #666666;
        font-size: 14px;
      }
    }

    .free-shipping-info {
      margin-top: 0rem;
      margin-bottom: 1.6rem;
    }

    .price-basic-big {
      &.m-0 {
        &.text-info-bag-menu {
          color: var(--black);
        }
      }
    }

    .info-bag-menu {
      &.info-bag-menu-price {
        &.d-flex {
          &.align-items-center {
            &.justify-content-between {
              border-bottom: none;
              border-top: none;
              padding-bottom: 0rem;
              align-items: flex-start;

              .text-regular.m-0.text-info-bag-menu {
                color: var(--black);
              }
            }
          }
        }
      }
    }

    &.cart__totals-dynamic {
      .free-shipping-info {
        .free-shipping {
          font-size: 1.2rem;
        }
      }
    }
  }

  .text-info-bag-menu {
    &.free-shipping {
      padding: 0rem 2rem;
      color: var(--dark-grey);
      margin-top: 0;
      margin-bottom: 0;

      &.taxes-and-fee {
        margin-top: 0.08rem;
      }
    }
  }

  .text-regular {
    &.m-0 {
      &.text-info-bag-menu {
        font-size: 1.8rem;
        font-weight: 500;
      }
    }
  }

  .section-button-mini-bag {
    padding: 2.4rem;
    border-bottom: 0.2rem solid var(--light-grey);
  }

  .section-container-general-product-bag {
    &.d-flex {
      .section-product-img-mini-bag {
        border-right: 0.2rem solid var(--light-grey);
        position: relative;
        a {
          position: absolute;
          height: 100%;
        }
      }

      .pricing {
        &.line-item-total-price-amount {
          color: var(--black);
        }
      }

      .sales {
        &.line-item-total-price-amount {
          color: var(--color-red);
          display: flex;
          flex-direction: column;
          font-size: 1.8rem;
          padding-top: 0.7rem;
        }
      }

      .strike-through {
        &.list.non-adjusted-price {
          color: #666666;
          font-size: 1.4rem;
        }
      }
    }
  }

  .section-product-info-mini-bag {
    &.d-flex {
      &.justify-content-between {
        padding: 2rem;

        .decrease, .increase {
          &.rounded-minicart-button {
            padding-left: 0rem;
            padding-right: 0rem;
          }
        }

        .input-text {
          &.quantity-input {
            margin: 0rem;
            vertical-align: middle;
          }
        }

        .text-regular {
          &.name-product-mini-bag {
            &.product-title {
              margin-top: 0rem;
              margin-bottom: 0rem;
              color: var(--black);
            }
          }
        }

        .minicart-lineitem {
          &.input-text {
            display: inline-block;
            color: black;
            
            &.quantity {
              &.quantity-input {
                border: 0rem;
                width: 2.2rem;
                text-align: center;
                padding-top: 0.2rem;
              }
            }
          }
        }
      }
    }
  }

  &.container-content-menu-bag {
    .section-product-img-mini-bag {
      border: 0rem;
    }
  }

  &.container-content-menu-bag {
    .section-container-general-product-bag {
      &.d-flex {
        padding: 0rem 0rem;
      }
    }
  }
}

.minicart-header {
  &.section-navigation-header {
    background-color: #068922;
    height: 4rem;
    .info-bag-menu {
      padding: 0 2rem;
    }

    .text-big {
      &.m-0 {
        &.text-info-bag-menu {
          color: var(--white);
          font-weight: normal;
          font-size: 1.4rem;

          img {
            float: right;
            padding-top: 0.4rem;
          }
        }
      }
    }
  }
}

.minicart__container {

  .product-summary {
    overflow-y: auto;
    .minicart-container {
      &.container-content-menu-bag {
        height: 100%;
      }
    }
  }

  div.vue-module--shop-the-look {
    padding: 0;

    .product-carousel-title {
      margin: 0;
      border: 0;
      border-bottom: 1px solid var(--light-grey);
      padding: 2rem;
      display: flex;
      align-items: center;
      color: var(--black);
      @include primaryFont;
      font-size: 1.8rem;
      width: 100%;
    }

    .product-carousel-products {
      border: 0;

      .pd-c-container {
        border: 0;
      }

      .vue-module__tile {
        flex-shrink: 0;
        height: auto;

        .container-card {
          height: auto;
          border: 0;
          border-right: 1px solid var(--light-grey);
          padding: 1rem;

          a{
            padding: 0 1.7rem;
          }

          p {
            color: var(--black);
          }

          .name {
            font-size: 1.2rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-bottom: .5rem;
          }

          .price {
            font-size: 1rem;
            margin: 0;
          }

        }
      }

      .swiper-button-next {
        top: 2rem;
        right: 2rem;
      }

      .swiper-button-prev {
        top: 2rem;
        right: 6rem;
      }
    }
  }
}

.minicart-body {
  &.tax-summary {
    height: 7.8rem;
    border-bottom: 0.2 rem solid var(--light-grey);
    padding: 1.6rem 2rem;

    .text-big {
      &.m-0 {
        &.text-info-bag-menu {
          color: var(--black);
          font-size: 1.8rem;
        }
      }
    }

    .free-shipping-info {
      padding-top: 0.1rem;

      .text-info-bag-menu {
        &.free-shipping {
          color: var(--dark-grey);
          font-size: 1.2rem;
        }
      }
    }
  }
}

.minicart-panel-wrapper {
  margin-top: 3.6rem;
  .minicart-header {
    &.section-navigation-header {
      .info-bag-menu {
        &.notify-add-to-cart {
          display: block;
          height: 100%;
          .text-info-bag-menu {
            display: flex;
            width: 100%;
            height: 100%;
            align-items: center;
            justify-content: space-between;
          }
        }
      }
    }
  }
  .product-summary {
    .container-info-product-mini-bag {
      .container-content-info-product-mini-bag {
        .text-lightgrey {
          &.info-product-mini-bag {
            color: var(--dark-grey);
            font-size: 1.2rem;
            margin-top: 0rem;
            margin-bottom: 0rem;
          }
        }
      }
    }
  }
}

.side-panel__inner {
  &.open {
    .cart__modal {
      .cart__modal__block {
        color: var(--black);
        padding: 2rem;

        .cart__modal-title {
          color: var(--black);
        }

        .product-to-remove {
          color: var(--black);
        }

        p {
          color: var(--black);
        }
      }
    }
  }
}

.minicart {
  &.adobe-recommendations {
    &.recommendations {
      .pd-c-container.swiper-slide.product {
        border-right: 0rem;
      }

      .minicart {
        &.product-carousel-title {
          border: none;
          color: var(--black);
          padding-left: 2rem;
          padding-top: 0.6rem;
          padding-bottom: 2.1rem;
          font-family: BananaGrotesk;
          font-weight: 500;
          font-style: normal;
          font-size: 1.8rem;
        }
      }
    }
  }
}

.minicart {
  &.product-carousel-products {
    margin-bottom: 1.9rem;
    border-top: 0.2rem solid var(--light-grey);

    .product-tile-body {
      padding-top: 0rem;
      padding-right: 0rem;

      .product-tile-body__price {
        span {
          font-size: 1rem;
        }
      }

      .product-tile-body__link {
        font-size: 1.2rem;
      }
    }

    .tile-image-link {
      border-left: 0.05rem solid var(--light-grey);
      border-right: 0.05rem solid var(--light-grey);
    }

    .product-tile {
      height: 17rem;

      .product-tile-body {
        margin: 0rem 0rem 0rem 0rem;
        padding: 0rem 1rem 0rem 1rem;

        .section-info-single-product-plp {
          padding-top: 1rem;
          padding-bottom: 0.6rem;
        }
      }
    }

    .product-tile-image {
      height: 16rem;
      margin-bottom: 0rem;

      .lazyloaded {
        width: 10rem;
        height: 16rem;
        padding-left: 2.5rem;
        padding-top: 0.1rem;
      }
    }

    .pd-carousel__products-area {
      .swiper-slide {
        .product {
          .add-to-cart {
            &.add-to-cart--main {
              display: none;
            }
          }

          .product-tile--add-to-bag {
            display: none;
          }
        }
      }

      .product-tile {
        border-right: 0px;
      }

      .product {
        border-right: 0px;
      }

      .js-swiper-button--prev {
        &.swiper-button-prev {
          padding-right: 3rem;
        }
      }

      .js-swiper-button--next {
        &.swiper-button-next {
          padding-right: 2.5rem;
        }
      }
    }

    .pd-c-container {
      &.js-pd-c-container {
        &.swiper-container {
          border: none;
        }
      }
    }

    .pd-c-container {
      &.swiper-slide {
        &.product-tile {
          border-right: none;
          height: 10rem;
        }
      }
    }
  }

  &.product-carousel-container {
    height: 28.3rem;
    .minicart {
      &.product-carousel-products {
        border-left: 0rem;
      }
    }

    .container-badges {
      display: none;
    }
  }

  &.panel-wrapper {
    margin-top: 2.58rem;
    padding-top: 0.3rem;
  }
}

.side-panel__inner.open {
  .cart__modal {
    .cart__close-modal {
      width: 4rem;
      height: 4rem;
      color: var(--main-color);
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 0;
      top: 0;
      border: 1px solid var(--main-color);
      cursor: pointer;

      svg {
        width: 2rem;
        height: 2rem;
      }
    }
  }
}

.isAddToCart {
  .notify-add-to-cart {
    display: block;
  }
  .info-bag-menu-price {
    //display: none;
  }
}