@import '../../mixins';
@import '../../../pages/account/mixins';

.loyalty-experience__how-it-works {
  color: var(--main-color);
  padding: 6rem 0;

  @include media-breakpoint-up(lg) {
    padding: 8rem 0;
  }

  &__main {
    width: 100%;
    padding: 0 2rem;
    margin: 0 auto;
    max-width: 100rem;

    @include media-breakpoint-up(xl) {
      min-width: 100rem;
      max-width: 132rem;
      width: calc(69% + 4rem);
    }
  }

  &__badge {
    @include loyalty-badge;

    border-bottom: none;
    padding: 0.8rem 1rem 0;

    h3 {
      font-size: 3.2rem;
      font-weight: 400;
    }

    * {
      margin: 0;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      flex: 1 1 0;
      margin-top: -1px;

      > * {
        width: 100%;
        margin-right: -1px;
      }
    }
  }

  &__item {
    border: 1px solid var(--main-color);
    padding: 1.6rem;
    display: flex;
    flex-direction: row;
    margin-top: -1px;

    @include media-breakpoint-up(lg) {
      margin-top: 0;
      width: 100%;
      height: 100%;
      flex-direction: column;
      padding: 2rem;
    }

    .item {
      &__content-icon {
        margin-bottom: 2rem;

        &__icon {
          display: block;
          width: 5.5rem;
        }
      }

      &__content-text {
        width: calc(100% - 7.5rem);
        flex: 0 0 calc(100% - 7.5rem);
        padding-left: 1.6rem;
        display: flex;
        flex-direction: column;

        @include media-breakpoint-up(lg) {
          width: 100%;
          flex: 1;
          padding-left: 0;
        }

        &__title {
          display: block;
          text-transform: uppercase;
          margin: 0 0 0.8rem 0;

          @include media-breakpoint-up(lg) {
            font-size: 1.8rem;
            margin-bottom: 1.6rem;
          }
        }

        &__text {
          color: var(--black);
          display: block;
          margin: 0 0 1.2rem 0;
        }

        &__content-cta {
          text-align: left;

          @include media-breakpoint-up(lg) {
            margin-top: auto;
          }

          &__cta {
            display: inline-block;
            padding-bottom: 0.1rem;
            border-bottom: 1px solid var(--main-color);
            text-decoration: none;
          }
        }
      }
    }
  }
}
