.module-static-plp--14 {
  position: relative;
  padding: 1.6rem;
  height: 100%;

  .element-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  a {
    text-decoration: none;
  }

  .content-img {
    height: 4.8rem;
    width: 4.8rem;

    img {
      display: block;
      height: 100%;
      width: 100%;
    }
  }

  .content-text {
    padding-top: 1.2rem;

    .item-title {
      margin: 0 0 1.2rem 0;
      font-size: 2.4rem;
      line-height: 2.4rem;
    }

    p {
      margin: 0 0 0.8rem 0;
      font-size: 1.2rem;
      line-height: 1.8rem;
    }

    .module-cta-link {
      text-decoration: underline;
    }
  }
}
