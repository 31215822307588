.search-product {
    .search-product--image {
      width: 8.8rem;

      picture {
        width: 8.8rem;
        height: 11.6rem;
      }
    }
  
    .search-product--info {
      flex-flow: column;
      justify-content: flex-start;
    }
  
    .search-product--info--price {
        margin-top: 1.5rem;

        .product-price {
            .value {
            color: var(--black);
            font-size: 1.4rem;
            }
        }
    }
}

.search-categories--suggestions--group-title {
  font-size: 1.2rem;
}

.search-editorial__results {
  p {
    margin-bottom: 2.7rem;
  }
}