.static-carousel-container {
    background-color: var(--main-color);
    width: 100%;

    .static-carousel {
        position: relative;
        max-width: 60%;
        margin: 6rem auto;
        padding: 0;
        border: 1px solid var(--main-color);
        overflow: hidden;
        

        .swiper-wrapper {
            margin-top: 0;
            overflow: visible;

            .swiper-slide {
                width: 25% !important;
                border-left: 1px solid var(--main-color);
                box-sizing: border-box;
                margin-top: 4rem;

                .content-img {
                img {
                    display: block;
                    width: 100%;
                }
                }

                .content-text {
                    text-align: center;
                    background-color: white;
                a { 
                        line-height: 4rem;
                        text-decoration: none;
                    }
                }

                .content-title {
                    width: fit-content;
                    position: relative;
                    background-color: white;
                    border: 1px solid var(--main-color);
                    margin-top: -4rem;

                    h3 { 
                        font-size: 2.4rem;
                        padding: 0.5rem;
                        margin: 0;
                    }

                    .icon-arrow-cta {
                        width: 2rem;
                        height: 2rem;
                    }
                }
            }

            &.jc-center {
                justify-content: center;
            }
        }

        .navigation-control {
            position: absolute;
            top: 5px;
            right: 0;
            display: flex;
            z-index: 9;

      
            .swiper-button-prev,
            .swiper-button-next {
              width: 2.4rem;
              height: 2.4rem;
              cursor: pointer;
      
              svg[class^='icon'] {
                width: 100%;
                height: 100%;
                fill: white;
              }
            }

            .swiper-button-prev {
                margin-right: 5px;
            }
          }
    }

    

    .content-description-container {
        width: fit-content;
        position: absolute;
        top: 50%;
        left: 5%;
        transform: translate(-20px, -50%);

            .content-description {
                padding: 1.5rem;
                color: var(--main-color);
                width: 70%;
                background: white;
            }

            .content-title  {
                display: none;
            }

            &.mobile {
                display: none;
            }

            &.desktop {
                display: block;
            }
        }

    &.white-bg {
        background-color: white;
        .static-carousel {
            border: none;
            margin: 6rem auto;
            .swiper-wrapper{
                .swiper-slide {
                    .content-title, .content-text, .content-img {
                        background-color: white;
                        border-bottom: 0;
                    }

                    .content-title {
                        border: 1px solid var(--main-color);
                        border-bottom: 0;
                    }

                    .content-text {
                        border-bottom: 1px solid var(--main-color);
                        border-right: 1px solid var(--main-color);
                    }

                    .content-img {
                        border-top: 1px solid var(--main-color);
                        border-right: 1px solid var(--main-color);
                    }
                }
            }

            .navigation-control {
                .swiper-button-prev,
                .swiper-button-next {
                    svg[class^='icon'] {
                        fill: var(--main-color);
                    }
            }
            }
        }
    }
}