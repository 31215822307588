.container-general_confirmation {
  .pdp_menu-details {
    .confirmation-products-card {
      .confirmation-products-head {
        flex-direction: column;
        row-gap: 2rem;
        .confirmation-products-text {
          padding-left: 3.3rem;
          margin-top: 0.8rem;
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.83;
          letter-spacing: 0.17px;
        }
        .confirmation-shipment-info {
          text-align: left;
          padding-left: 3.3rem;
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.83;
          letter-spacing: 0.17px;
          .confirmation-shipment-title {
            display: none;
          }
          .store-name {
            font-size: 18px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: 0.26px;
            margin-bottom: 0.4rem;
          }
        }
      }
      .confirmation-product-wrapper {
        position: relative;
        padding-bottom: 6rem;

        .confirmation-product-details {
          flex: 1;
        }
        .confirmation-product-additional {
          flex: 1;

          .product-shipment-eta {
            position: absolute;
            bottom: 2rem;
            left: 2rem;

            &.has-final-sale {
              bottom: 1rem
            }
          }
          .final-sale-message {
            position: absolute;
            bottom: 3.5rem;
            left: 2rem;
            margin: 0;
          }
        }
      }
    }
  }
}
