@import '../../mixins';
@import '../../../pages/account/mixins';

.loyalty-account {
  &__header {
    background-color: var(--main-color);
    position: relative;

    @include media-breakpoint-down(md) {
      background-color: transparent;
    }

    &__pass {
      position: absolute;
      right: 0.1rem;
      bottom: 2rem;
      background: var(--alternative-color);
      color: var(--main-color);
      height: 4rem;
      display: flex;
      align-items: center;
      padding: 1.2rem;
      line-height: 1;
      text-decoration: none;
    }

    > h1,
    > h2,
    > h3 {
      display: none;
      color: var(--alternative-color);
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      @include media-breakpoint-up(md) {
        display: block;
      }
    }

    + .page {
      position: relative;
    }
  }

  &__logo {
    width: 432px;
    height: 237px;

    @media (max-width: 767px) {
      width: 14.9rem;
      height: 100%;
    }

    @include media-breakpoint-up(sm) {
      max-width: none;
    }

    @include media-breakpoint-up(lg) {
      width: 25vw;
    }
  }

  &__logo svg {
    width: 100%;
    height: 100%;
    fill: initial;
  }
}
