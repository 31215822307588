.pd-cookies-table-container {
    width: 100%;
    overflow: auto;
}

.pd-cookies-table {
    width: 100%;
    border-collapse: collapse;

    .pd-cookies-table-head {
        .pd-cookies-table-heading {
            text-align: center;
            vertical-align: middle;
            color: var(--black);
            font-weight: 700;
            font-size: 1.4rem;
            border: 1px solid #ededed;
            padding: 2.4rem;
        }
    }

    .pd-cookies-table-datacell {
        text-align: center;
        vertical-align: middle;
        border: 1px solid #ededed;
        padding: 0.8rem 1rem;
        color: var(--black);
        font-weight: 500;
        font-size: 1.2rem;
        width: 145px;
    }
}

.cookie-page {
    .pd-help-section {
        h2 {
            margin-bottom: 1.6rem;
        }
    }
    .mt-3,
    .my-3 {
        margin-top: 0.8rem;
    }
    .mb-3,
    .my-3 {
        margin-bottom: 0.8rem;
    }

    .mb-4,
    .my-4 {
        margin-bottom: 1.6rem;
    }
    .mt-4,
    .my-4 {
        margin-top: 1.6rem;
    }
}