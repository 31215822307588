.experience-assets-modulePromotionPanel {
  .experience-ancorAndPromotionBox {
    padding: 0 5rem;
  }
}

.red {
  .anchor-text:hover {
    .anchor-text__content {
      border: 1px solid var(--white);
    }
    .anchor-text__icon {
      border: 1px solid var(--white);
      background-color: var(--white);
      svg {
        fill: var(--main-color);
      }
    }
  }
}

.experience-layout-modulePromotion:not(.red) {
  .anchor-text {
    &:hover {
      .anchor-text__icon {
        background-color: var(--white);
        border: 1px solid var(--main-color);

        svg {
          fill: var(--main-color);
        }
      }
    }
  }
}