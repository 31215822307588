@import '../utils/vars';

.pd-heading-large {
  font-size: $font-heading-medium;

  @media screen and (min-width: 992px) {
    font-size: $font-heading-large;
  }
}
.pd-heading-medium {
  font-size: $font-heading-medium;
}
.pd-heading-small {
  font-size: $font-heading-small;
}

.pd-description-large {
  font-size: $font-description-large;
  @media screen and (min-width: 992px) {
    font-size: $font-description-medium;
  }
}
.pd-description-medium {
  font-size: $font-description-medium;
}
.pd-description-small {
  font-size: $font-description-small;
}

.pd-richtext {
  p {
    font-size: inherit;
    color: inherit;
  }
}

.pd-text-align-left {
  text-align: left;
}
.pd-text-align-center {
  text-align: center;
}
.pd-text-align-right {
  text-align: right;
}

.pd-text-style-bold {
  font-weight: bold;
}
.pd-text-style-italic {
  font-style: italic;
}
.pd-text-style-underline {
  text-decoration: underline;
}
.pd-text-style-strike {
  text-decoration: line-through;
}

.pd-h1-style,
.pd-h2-style,
.pd-h3-style,
.pd-h4-style,
.pd-h5-style,
.pd-h6-style {
  margin: 0;
  line-height: 1;
}
