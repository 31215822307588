.product {
  &.loading-class {
    .loader-class {
      z-index: 9;
    }
  }
}

.product-tile {
  position: relative;
  overflow: hidden;
  padding: 0;
  height: 100%;
  
  .add-to-wish-list {
    @include resetButton;
    position: absolute;
    right: 1.2rem;
    top: 1.2rem;
    z-index: 1;
    svg {
      margin: 0;
      display: block;
      width: 2.4rem;
      height: 2.4rem;
      fill: var(--main-color);
    }
  }
  .product-tile__presentation {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .library-badge {
      margin: 1rem;
      width: 0.5rem;
      height: 7rem;
    }
  }
  .preorder-limitation-message {
    height: auto;
    opacity: 1;
    margin-top: 1rem;
    padding: 1rem;
    transform: unset;
  }

  &__wishlist-cta {
    .remove-from-wishlist {
      background: transparent;
      border: 0;
      padding: 0;
      display: inline-block;

      .icon-remove-btn {
        margin: 0;
      }

      &.cta {
        &:after {
          content: none;
        }
      }
    }
  }
}
.additional_content {
  .product-tile {
    height: 100%;
    .product-tile__presentation {
      display: flex;
      flex-direction: column;
      align-content: start;
      height: 100%;
      .product-tile-body {
        margin-top: auto;
      }
    }
    .product-tile-image {
      overflow: hidden;
    }
  }
}

@import './product-tile-body';
@import './product-tile-image';
@import './product-badge';
@import './product-tile-add-to-bag';
