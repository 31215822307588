.pd-contact-us-container {
  .row {
    .pd-conctact-us-box-wrapper {
      padding: 0 1.6rem 0 0;

      &:last-child,
      &:only-child {
        padding-right: 0;
      }
    }
  }
}