.action-buttons {
    position: fixed;
    bottom: 60px;
    right: 20px;
    z-index: 99;
    gap: 4px;

    .red-panel-button,
    .cta-panel-button {
        color: #E4002B;
        border: 1px solid #E4002B;
        background-color: #fff;
        padding: 8px;
        font-size: 1.4rem;
        cursor: pointer;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.40);
        align-items: center;
        display: flex;
        height: 40px;
    }

    .red-panel-button {
        font-weight: 700;
        padding: 13px 12px;
    }

    .cta-panel-button {
        &.selected,
        &:hover {
            svg path{
                fill: #fff;
            }
        }

        &.selected {
            background: #E4002B;
        }

        &:not(.selected):focus {
            &:before {
                background: #fff;
            }

            svg path {
                fill: #E4002B;
            }
        }
    }
}

.cta-panel {
    border: 1px solid #E4002B;
    border-bottom: none;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.40);
    width: 100%;
    height: fit-content;
    position: fixed;
    bottom: 110px;
    right: 20px;
    background: white;
    z-index: 2000;
    max-height: 410px;
    max-width: calc(100% - 40px);
    

    .cta-panel-header {
        padding: 2rem 2.4rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #E4002B;
        
        .title {
            font-size: 2.4rem;
            color: #121212;
            margin: 0;
        }

        .subtitle {
            margin: 0;
            width: 100%;
            color: #121212;
        }

        .cta-close-panel {
            background: none;
            border: none;
            cursor: pointer;
        }
    }

    .cta-list {
        list-style-type: none;
        margin: 0;
        padding: 0;

        .cta-item {
            padding: 2.4rem;
            border-bottom: 1px solid #E4002B;

            .cta-item-link {
                color: #121212;
                text-decoration: none;
                display: flex;
                align-items: center;

                span {
                    margin-left: 1.5rem;
                }
            }
        }
    }


    &.ai-panel-open {
        max-width: 100%;
        max-height: 100vh;
        height: 100%;
        bottom: 0;
        right: 0;
        border-bottom: 1px solid #E4002B;
    
        .cta-panel-header {
            display: none;
        }
    }
}

.ai-panel {
    height: 100%;

    .ai-panel-header {
        padding: 2rem 2.4rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #EDEDED;
        
        .title {
            font-size: 2.4rem;
            color: #121212;
            margin: 0;
        }

        .cta-close-panel {
            background: none;
            cursor: pointer;
            border: none;
        }
    }
}

.chatbot-render {
    height: calc(100% - 70px);
    #red-iframe {
        width: 100%;
        height: 100%;
    }
}

body.red-is-active { // REQUIRED to overwrite PowerFront external script display property
    #inside_tabs {
        display: none !important;
    }

    &.pdp-page {
        .action-buttons.pdp-mobile {
            bottom: 90px;

            &.sticky {
                bottom: 135px;
            }

            &.sticky + .cta-panel {
                bottom: 185px;
            }

            &:not(.d-none) {
                & + .cta-panel {
                    bottom: 140px;
                }
            }
        }
    }
}
