.pd-video {
  width: 100%;

  &--object-fit-contain {
    object-fit: contain;
  }

  &--object-fit-cover {
    object-fit: cover;
  }

  &--object-fit-contain,
  &--object-fit-cover,
  &__container {
    height: 100%;
  }
}
