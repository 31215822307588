.ac-trigger {
  cursor: pointer;
  background-color: inherit;
  border: none;
  font-size: 1.4rem;
  color: inherit;
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--black);
  font-weight: bold;
  text-transform: uppercase;
  svg {
    // display: block;
    fill: var(--black);
    width: 2.4rem;
    height: 2.4rem;
    flex: 0 0 2.4rem;
    margin-left: 0.5rem;
  }
  .icon-plus {
    display: block;
  }
  .icon-minus {
    display: none;
  }
  /* &::after {
    @include fixedSprite(plus);
    content: ' ';
  } */
}
.ac .ac-panel {
  overflow: hidden;
  transition-property: height, visibility;
  transition-timing-function: ease;
}

.ac.js-enabled .ac-panel {
  visibility: hidden;
  overflow: hidden;
}

.ac.is-active {
  .ac-panel {
    visibility: visible;
    //   overflow: visible;
  }
  .ac-trigger {
    .icon-plus {
      display: none;
    }
    .icon-minus {
      display: block;
    }
  }
  /* .ac-trigger {
    &::after {
      @include fixedSprite(minus);
    }
  } */
}

.accordion {
  .accordion-item {
    .accordion-trigger {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      background: transparent;
      border: none;
      height: 5rem;
      height: fit-content;
      cursor: pointer;
      //border-bottom: 1px solid $accordion-border;

      .icon-minus,
      .icon-plus,
      .icon-dropdown-up,
      .icon-dropdown-down {
        display: none;
        width: 2.4rem;
        height: 2.4rem;
      }

      &[aria-expanded='false'] {
        .icon-plus,
        .icon-dropdown-down {
          display: inline-block;
        }
      }

      &[aria-expanded='true'] {
        .icon-minus,
        .icon-dropdown-up {
          display: inline-block;
        }
      }
    }

    .accordion-content {
      transition: all ease-in 0.3s;
      max-height: 0;
      display: none;
      opacity: 0;

      &.credit-card-content-wrapper {
        width: 100%;
      }

      li {
        a {
          display: block;
          padding: 1rem 0;
        }
      }
    }

    &.is-active {
      .accordion-content {
        display: block;
        max-height: 20000px;
      }

      &.is-visible {
        .accordion-content {
          opacity: 1;
        }
      }
    }
  }
}

.payment-information {
  .accordion {
    .accordion-item {
      .accordion-trigger {
        .form-check , .payment-option-img-wrapper {
          margin: 0.8rem 2rem;
        }
      }
      .accordion-content {
        padding: 0.8rem 2rem;
      }
    }
  }
}
