.justify-content-lg-start {
    justify-content: flex-start;
}
.justify-content-lg-end {
    justify-content: flex-end;
}
.justify-content-lg-center {
    justify-content: center;
}
.justify-content-lg-between {
    justify-content: space-between;
}
.justify-content-lg-around {
    justify-content: space-around;
}
.align-items-lg-start {
    align-items: flex-start;
}
.align-items-lg-end {
    align-items: flex-end;
}
.align-items-lg-center {
    align-items: center;
}
.align-items-lg-baseline {
    align-items: baseline;
}
.align-items-lg-stretch {
    align-items: stretch;
}
.align-content-lg-start {
    align-content: flex-start;
}
.align-content-lg-end {
    align-content: flex-end;
}
.align-content-lg-center {
    align-content: center;
}
.align-content-lg-between {
    align-content: space-between;
}
.align-content-lg-around {
    align-content: space-around;
}
.align-content-lg-stretch {
    align-content: stretch;
}
.align-self-lg-auto {
    align-self: auto;
}
.align-self-lg-start {
    align-self: flex-start;
}
.align-self-lg-end {
    align-self: flex-end;
}
.align-self-lg-center {
    align-self: center;
}
.align-self-lg-baseline {
    align-self: baseline;
}
.align-self-lg-stretch {
    align-self: stretch;
}