.navigation--logo--link {
  cursor: default;
  // display: none;
}

.header_navigation {
  .nav-user {
    display: inline-flex;
    background-color: var(--alternative-color);

    &[data-hamburger] {
      display: none;
    }
    &.account_nav-user {
      display: inline-flex;
    }
  }
}

.active-editorial-bar-header:not(.header-sticky) {
  &.main-header {
    &.is-open {
      .navigation-dropdown--categories,
      .navigation-dropdown,
      .menu-backdrop,
      .navigation--logo { 
        top: var(--nav-position);
      }
    }
  }
}

.main-header {
  &.is-open {
    .navigation__item--link {
      &.navigation__item--current:not(.current-item) {
        &::after {
          transform: scaleX(0);
          visibility: hidden;
          transition: transform var(--transition), visibility var(--transition);
        }
      }
    }
  }
}

.navigation__bar {
  position: static;
  z-index: unset;
  width: auto;
  height: 3.7rem;
  opacity: 1;
  visibility: visible;
  flex-direction: row;
  border: 0;
  border-left: 1px solid var(--main-color);
  padding: 0;

  &.d-none-mobile {
    display: flex;
  }

  &.navigation__bar-category {
    display: flex;
  } 

  li.navigation__item {
    width: auto;
    display: inline-flex;
    align-items: center;
    padding: 0;
    margin-bottom: 0;
    opacity: 1;

    &.menu-separator {
      border-right: 0.1rem solid var(--main-color);

      &:last-child {
        border-right: none;
      }
    }

    &.navigation-bottom {
      &.navigation-bottom--lg {
        display: none;
      }
    }

    .navigation__item--link {
      font-size: 1.4rem;
      border-top: 1px solid var(--main-color);
      border-bottom: 1px solid var(--main-color);
      color: var(--main-color);
      height: 100%;
      display: inline-flex;
      font-family: BananaGrotesk,sans-serif;
      font-weight: normal;
      text-decoration: unset;
      opacity: 1;
      transition: none;

      &.navigation__item--link--lg {
        .icon__arrow {
          display: none;
        }
      }
    }
  }  

  .navigation-dropdown {
    left: unset;
    bottom: unset;
    height: auto;
    max-height: 85vh;
    border: 0;
    border-bottom: 1px solid var(--main-color);
    padding: 0;
    overflow: hidden;

    &.navigation-scrollable {
      overflow-y: auto;
    }

    /* Chrome, Edge, and Safari */
    &::-webkit-scrollbar {
      width: 0.6rem;
    }
    &::-webkit-scrollbar-track {
        background: var(--white);
    }
    &::-webkit-scrollbar-thumb {
      background-color: var(--dark-grey);
      border-radius: 0.3rem;
      border: 0.6rem solid var(--dark-grey);
    }

    &.navigation-dropdown--header-panel {
      min-height: 30vh;
      max-height: 100vh;
      max-width: 36.5rem;
      padding: 0;
      border-left: 0.1rem solid var(--main-color);
      border-right: 0.1rem solid var(--main-color);
      border-bottom: 0.1rem solid var(--main-color);

      /* Chrome, Edge, and Safari */
      &::-webkit-scrollbar {
        width: 0.6rem;
      }
      &::-webkit-scrollbar-track {
          background: var(--white);
      }
      &::-webkit-scrollbar-thumb {
        background-color: var(--dark-grey);
        border-radius: 0.3rem;
        border: 0.6rem solid var(--dark-grey);
      }

      &.is-focused {
        // transition: transform .25s ease,opacity 0ms linear 0s, visibility 0ms linear 0s, max-height var(--transition);
        transition: none;
      }

      &.minicart-panel {
        max-width: 48.4rem;
        padding: 0;
      }
    }
  }
}

[data-whatintent="keyboard"] {
  *:focus {
    outline: 1px solid --var(--main-color);
  }

  .navigation__item--link:focus {
    position: relative;
    outline: transparent;

      &::after {
        visibility: visible;
        transition: transform var(--transition), visibility var(--transition);
        transform: scaleX(1);
      }
  }
}

.navigation__item--link {
  &::after {
    position: absolute;
    content: ' ';
    width: 100%;
    height: 0.3rem;
    left: 0;
    bottom: 0;
    transform: scaleX(0);
    background-color: var(--main-color);
    visibility: hidden;
    transition: transform var(--transition), visibility var(--transition);
  }

  &:hover,
  &.current,
  &.current-item,
  &.navigation__item--current,
  &.navigation__item--parent {
    position: relative;
    &::after {
      visibility: visible;
      transition: transform var(--transition), visibility var(--transition);
      transform: scaleX(1);
    }
  }
}

.navigation--logo {
  &.navigation--logo-lg {
    transform: translateY(-1rem);
    transition: transform .1s, opacity 0.1s;
    transition-delay: 0s;
  
    img {
      width: 14.5rem;
    }
  }
}

body.navigation-is-open {
  .navigation--logo {
    &.navigation--logo-lg {
      transform: translateY(0);
      // transition: transform .3s,opacity .2s;        
      // transition-delay: 0.3s;
      transition: none;
      visibility: visible;
    }
  }

  #kampyleButtonContainer {
    display: none;
  }
}

.hamburger__panel {
  display: none;
}

.close-menu {
  &.close-account {
    display: none;
  }

  &.close-search {
    margin-bottom: 0;
    order: 2;
  }
}